import axios from "axios";
const { REACT_APP_LIBER_API_URL } = process.env;

export const addNewDirection = (payload, accessToken) => {
    const request = {
      method: "POST",
      url: `${REACT_APP_LIBER_API_URL}/business/add-location`,
      data: payload,
      body: JSON.stringify(payload),
      headers: {
        "content-type": "application/json",
        auth_Token: accessToken,
      },
  
    };
    return axios(request);
};
  
export const updateDirection = (payload, accessToken) => {
    const request = {
      method: "POST",
      url: `${REACT_APP_LIBER_API_URL}/business/update-location`,
      data: payload,
      body: JSON.stringify(payload),
      headers: {
        "content-type": "application/json",
        auth_Token: accessToken,
      },
    };
    return axios(request);
};
  