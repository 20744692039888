import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const CardAutopaqueteStyle = makeStyles((theme) => ({
  contentCard: {
    padding: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 30,
    [theme.breakpoints.down(1150)]: {
      padding: 0,
    },
    [theme.breakpoints.down(960)]: {
      padding: 10,
    },
    [theme.breakpoints.down(600)]: {
      padding: 0,
    },
  },
  containerCard: {
    width: 290,
    height: 400,
    borderRadius: 10,
    [theme.breakpoints.down(1920)]: {
      width: 320,
    },
    [theme.breakpoints.down(1660)]: {
      width: 280,
    },
    [theme.breakpoints.down(1590)]: {
      width: 250,
      height: 360,
    },
    [theme.breakpoints.down(1400)]: {
      width: 220,
    },
    [theme.breakpoints.down(1290)]: {
      width: 200,
      height: 300,
    },
    [theme.breakpoints.down(1150)]: {
      width: 185,
      height: 275,
    },
    [theme.breakpoints.down(1100)]: {
      width: 170,
    },
    [theme.breakpoints.down(1040)]: {
      width: 160,
    },
    [theme.breakpoints.down(960)]: {
      width: 230,
    },
    [theme.breakpoints.down(600)]: {
      width: 130,
      height: 205,
    },
  },
  containerBody: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  containerColorLiber: {
    boxShadow: "0px 1px 12px 0px #00ff0a80",
  },
  containerColorThird: {
    boxShadow: "0px 1px 12px 0px #FF00004a",
  },
  containerColorSelected: {
    boxShadow: "0px 1px 12px 0px #4a4a4a",
  },
  image: {
    width: "100%",
    height: 90,
    borderRadius: "10px 10px 0px 0px",
    marginBottom: 20,
    objectFit: "cover",
    [theme.breakpoints.down(1590)]: {
      height: 80,
      marginBottom: 15,
    },
    [theme.breakpoints.down(1290)]: {
      height: 70,
      marginBottom: 6,
    },
    [theme.breakpoints.down(1150)]: {
      height: 60,
    },
    [theme.breakpoints.down(960)]: {
      height: 66,
    },
    [theme.breakpoints.down(600)]: {
      height: 35,
      marginBottom: 4,
    },
  },
  containerTitle: {
    display: "flex",
    alignItems: "baseline",
  },
  title: {
    fontSize: 25,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    //color: "#DF2E38",
    textAlign: "center",
    [theme.breakpoints.down(1590)]: {
      fontSize: 20,
    },
    [theme.breakpoints.down(1290)]: {
      fontSize: 18,
    },
    [theme.breakpoints.down(1150)]: {
      fontSize: 15.5,
    },
    [theme.breakpoints.down(960)]: {
      fontSize: 16,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 13,
    },
  },
  deliveryText: {
    fontSize: 19,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    marginTop: 20,
    [theme.breakpoints.down(1590)]: {
      fontSize: 16,
    },
    [theme.breakpoints.down(1290)]: {
      fontSize: 14,
      marginTop: 10,
    },
    [theme.breakpoints.down(1150)]: {
      fontSize: 12.5,
    },
    [theme.breakpoints.down(960)]: {
      fontSize: 13,
      marginTop: 10,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 10.5,
      marginTop: 5,
    },
  },
  dayText: {
    fontSize: 16,
    marginTop: 10,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    [theme.breakpoints.down(1590)]: {
      fontSize: 13,
    },
    [theme.breakpoints.down(1290)]: {
      fontSize: 12,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 9.5,
    },
  },
  amount: {
    fontSize: 23,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    marginTop: 20,
    [theme.breakpoints.down(1590)]: {
      fontSize: 18,
    },
    [theme.breakpoints.down(1290)]: {
      fontSize: 16,
      marginTop: 10,
    },
    [theme.breakpoints.down(960)]: {
      fontSize: 15.5,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 12.5,
    },
  },
  textDetails: {
    fontSize: 12,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    textTransform: "capitalize",
    [theme.breakpoints.down(1590)]: {
      fontSize: 11,
    },
    [theme.breakpoints.down(1290)]: {
      fontSize: 10,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 9,
    },
  },
  buttonDetails: {
    margin: 0,
    padding: 0,
    marginTop: 15,
    [theme.breakpoints.down(1290)]: {
      marginTop: 10,
    },
  },
  button: {
    background: "#DF2E38",
    width: 130,
    height: 35,
    borderRadius: 20,
    marginTop: 18,
    marginBottom: 10,
    [theme.breakpoints.down(1290)]: {
      width: 115,
      height: 33,
    },
    [theme.breakpoints.down(1150)]: {
      width: 100,
      height: 30,
    },
    [theme.breakpoints.down(960)]: {
      width: 110,
      height: 28,
      marginTop: 12,
    },
    [theme.breakpoints.down(600)]: {
      width: 85,
      height: 23,
      marginTop: 10,
    },
  },
  buttonText: {
    color: "white",
    fontSize: 13,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    [theme.breakpoints.down(1290)]: {
      fontSize: 12,
    },
    [theme.breakpoints.down(1150)]: {
      fontSize: 10.5,
    },
    [theme.breakpoints.down(960)]: {
      fontSize: 11,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 9.5,
    },
  },
}));

export default CardAutopaqueteStyle;
