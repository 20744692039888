import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const DataFilterOrderStyle = makeStyles((theme) => ({
  text: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    [theme.breakpoints.down(1150)]: {
      fontSize: 12,
    },
    [theme.breakpoints.down(700)]: {
      fontSize: 11,
    },
  },
  image: {
    width: 35,
    height: 35,
    [theme.breakpoints.down(1150)]: {
      width: 30,
      height: 30,
    },
    [theme.breakpoints.down(700)]: {
      width: 23,
      height: 23,
    },
  },
  containerIcon: {
    background: "#d15252",
    width: 25,
    height: 25,
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  containerIconTermica: {
    background: "#474747f7",
    width: 25,
    height: 25,
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    color: "white",
    width: 20,
    height: 20,
    [theme.breakpoints.down(700)]: {
      width: 17,
      height: 17,
    },
  },
  iconTermica: {
    width: 18,
    height: 17,
    [theme.breakpoints.down(700)]: {
      width: 17,
      height: 16,
    },
  },
  bgGray: {
    background: "#eeeeee87",
    [theme.breakpoints.down(1350)]: {
      padding: "3px 6px 3px 6px",
    },
  },
  bgWhite: {
    background: "white",
    [theme.breakpoints.down(1350)]: {
      padding: "3px 6px 3px 6px",
    },
  },
  content: {
    display: "flex",
    justifyContent: "center",
  },
  container: {
    width: "100%",
    [theme.breakpoints.down(1060)]: {
      maxWidth: 680,
    },
    [theme.breakpoints.down(960)]: {
      maxWidth: 750,
    },
    [theme.breakpoints.down(880)]: {
      maxWidth: 680,
    },
    [theme.breakpoints.down(800)]: {
      width: 600,
    },
    [theme.breakpoints.down(700)]: {
      width: 500,
    },
    [theme.breakpoints.down(600)]: {
      width: 420,
    },
    [theme.breakpoints.down(500)]: {
      width: 370,
    },
    [theme.breakpoints.down(450)]: {
      width: 320,
    },
  },
  densePadding: {
    marginTop: 20,
  },
  containerButton: {
    display: "flex",
    justifyContent: "center",
    width: "60px",
    alignItems: "center",
  },
  containerButtons: {
    display: "flex",
    justifyContent: "space-between",
    width: "60px",
    alignItems: "center",
  },
  containerAddress: {
    width: 150,
  },
  containerNoRecords: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down(500)]: {
      alignItems: "start",
      marginLeft: 20,
      width: 170,
    },
  },
  imageNoRecords: {
    width: 70,
    height: 70,
    marginBottom: 10,
    [theme.breakpoints.down(500)]: {
      width: 50,
      height: 50,
    },
  },
  textNoRecords: {
    [theme.breakpoints.down(500)]: {
      fontSize: 14.5,
    },
  },
}));

export default DataFilterOrderStyle;
