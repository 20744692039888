import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";
import { MAIN_COLORS } from "../../../constants/theme/colors";

const PaymentOptionsStyle = makeStyles((theme) => ({
  content: {
    width: 545,
    paddingBottom: 50,
    background: "#ffffff",
    borderRadius: 10,
    boxShadow: 24,
    paddingLeft: 60,
    paddingRight: 60,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: 400,
      paddingBottom: 30,
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  title: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 20,
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
    },
  },
  text: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 12,
    color: "#5A5151",
    marginTop: 20,
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
  },
  containerButtons: {
    marginTop: 40,
  },
  gridPaymentOptionsContainer: {
    marginBottom: 15,
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  paymentOptionContainer: {
    width: 120,
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "#EAE5E5",
      cursor: "pointer",
    },
    "&:active": {
      border: "1px solid red",
    },
    [theme.breakpoints.down("md")]: {
      width: 180,
      height: 50,
    },
  },
  gridPaymentOptions: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  closeButton: {
    right: theme.spacing(-30),
    [theme.breakpoints.down("md")]: {
      right: theme.spacing(-21),
    },
    [theme.breakpoints.down("xs")]: {
      right: theme.spacing(-15),
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  hrLine: {
    background: "rgba(0, 0, 0, 0.10)",
    marginTop: 20,
  },
  percentageValues: {
    color: "rgba(0, 0, 0, 0.50)",
    fontSize: 12,
    [theme.breakpoints.down("xs")]: {
      marginTop: 5,
      fontSize: 11,
    },
  },
  headerCredits: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  creditAmount: {
    fontSize: 12,
    marginTop: 20,
  },
  creditInput: {
    width: 135,
    height: 30,
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10,
  },
  left: {
    width: "50%",
  },
  right: {
    width: "50%",
    textAlign: "right",
  },
  paragraph: {
    margin: theme.spacing(1, 0),
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 12,
  },
  totalPrice: {
    margin: theme.spacing(1, 0),
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    fontSize: 16,
  },
  paymentDetailHeader: {
    color: "rgba(0, 0, 0, 0.50)",
    fontSize: 12,
    marginTop: 30,
    [theme.breakpoints.down("xs")]: {
      marginTop: 40,
      fontSize: 11,
    },
  },
  paymentOptionsContainerModal: {
    height: 400,
    width: 600,
    backgroundColor: MAIN_COLORS.WHITE,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  //Transfer container
  transferContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: 10,
  },
  infoBankHeader: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    fontSize: 15,
    marginTop: 10,
  },
  infoBank: {
    marginTop: 10,
    fontSize: 13,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
  },
  infoTransfer: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 14,
    marginTop: 20,
  },
  //SaldoButton
  buttonSaldo: {
    width: "80%",
    height: 45,
    borderRadius: 10,
    backgroundColor: MAIN_COLORS.MAIN_RED_BLACK,
    color: MAIN_COLORS.WHITE,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    textTransform: "none",
    marginTop: 20,
    "&:hover": {
      backgroundColor: MAIN_COLORS.MAIN_RED,
    },
  },
  buttonSaldoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  currentBalanceValue: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 12,
    marginTop:20
  },
}));
export default PaymentOptionsStyle;
