export const headers = [
  {
    id: "batch_id",
    numeric: false,
    disablePadding: true,
    label: "No. de Lote",
  },
  {
    id: "total_guides",
    numeric: false,
    disablePadding: false,
    label: "Total de guías",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "Costo de recolección",
  },
  {
    id: "status_pago",
    numeric: false,
    disablePadding: false,
    label: "Estado del pago",
  },
  {
    id: "status_lote",
    numeric: false,
    disablePadding: false,
    label: "Estado del lote",
  },
  {
    id: "created_date",
    numeric: false,
    disablePadding: false,
    label: "Creación",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];
