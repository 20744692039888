export const headers = [
  {
    id: "tracking_code",
    numeric: false,
    disablePadding: true,
    label: "No. de Guía",
  },
  {
    id: "icon",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "recipient_fullname",
    numeric: false,
    disablePadding: false,
    label: "Destinatario",
  },
  {
    id: "destino",
    numeric: false,
    disablePadding: false,
    label: "Dirección Destino",
  },
  {
    id: "origen",
    numeric: false,
    disablePadding: false,
    label: "Dirección Origen",
  },
  { id: "zona", numeric: false, disablePadding: false, label: "Zona" },
  {
    id: "peso",
    numeric: false,
    disablePadding: false,
    label: "Peso",
  },
  {
    id: "guide_creation_date",
    numeric: false,
    disablePadding: false,
    label: "Creación",
  },
  {
    id: "guide",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];
