import React from "react";
import DrawerDash from "../../components/DrawerDash";
import ImageAndLink from "../../components/sharedComponents/ImageAndLink";
import imgEntregaRapida from "../../assets/img/dashboard/img-entregaRapida.svg";
import imgEntregaEstandar from "../../assets/img/dashboard/img-entregaEstandar.svg";
import { ROUTES_CONSTANTS } from "../../constants/routesConstants";
import { useNavigate } from "react-router-dom";

const ShippingType = () => {
  const navigate = useNavigate();

  const handleRedirectEstandar = () => {
    navigate(ROUTES_CONSTANTS.GENERAR_GUIAS_MASIVO, { replace: true });
  };
  const handleRedirectExpress = () => {
    navigate(ROUTES_CONSTANTS.TIPO_ENVIO, { replace: true });
  };
  return (
    <div>
      <DrawerDash>
        <ImageAndLink
          title="Envio Express"
          img={imgEntregaRapida}
          titleText="Los envíos Express se entregan en el lapso de 1 día."
          text="Este tipo de envío actualmente está sujetos a cuestiones como disponibilidad en la zona y a horarios específicos dentro de algunas 
          áreas."
          textButton="Express"
          disabled={true}
          handleClick={handleRedirectExpress}
        />

        <ImageAndLink
          title="Envio Estandar"
          img={imgEntregaEstandar}
          titleText="Los envíos Estándar se entregan en el lapso de tres días."
          text="Este tipo de envío es el más común, el cuál contempla el tiempo de recolección, hasta la entrega al cliente final."
          textButton="Estandar"
          link={ROUTES_CONSTANTS.GENERAR_GUIAS_MASIVO}
          disabled={false}
          handleClick={handleRedirectEstandar}
        />
      </DrawerDash>
    </div>
  );
};

export default ShippingType;
