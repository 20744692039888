import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const PaymentOptionCardStyle = makeStyles((theme) => ({
  paymentOptionContainer: {
    width: 180,
    height: 50,
    margin:10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "#EAE5E5",
      cursor: "pointer",
    },
    "&:active": {
      border: "1px solid red",
    },
    [theme.breakpoints.down("md")]: {
      width: 180,
      height: 50,
      margin:5,

    },
  },
  iconAndTextContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },

  textIcon: {
    marginLeft: 8,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 14,
  },
  button: {
    margin: theme.spacing(1),
    border: "1px solid black",
    transition: "border-color 0.3s",
  },
  buttonActive: {
    border: "1px solid red",
  },
  iconText: {
    height: 25,
  },
  
}));

export default PaymentOptionCardStyle;
