import { Box, Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import GuideSummaryinformationStyles from "./GuideSummaryinformationStyles";
import FormBottomButtons from "../FormBottomButtons";
import { useSelector } from "react-redux";
import { selectBusinessInformation } from "../../../store/reducers/business/BusinessInformationSlice";

const GuideSummaryInformation = (props) => {
  const {
    handleClickBackStep,
    handleClickNextStep,
    recipient_info,
    selectedLocation,
    handleBackOrigin,
    handleBackDestination,
  } = props;
  const classes = GuideSummaryinformationStyles();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const UserInformationLabel = (props) => {
    const { header, description } = props;
    return (
      <Box className={classes.userInfoContainer}>
        <Typography className={classes.headerUserInfoLabel}>
          {header}
        </Typography>
        <Typography className={classes.descriptionUserInfoLabel}>
          {description}
        </Typography>
      </Box>
    );
  };

  const HeaderSectionChange = (props) => {
    const { description, changeSection } = props;
    return (
      <Box className={classes.remitentContainer}>
        <Typography className={classes.remitentHeader}>
          {" "}
          {description}
        </Typography>
        <Typography className={classes.changeLabel}>
          <span onClick={changeSection}>Cambiar</span>
        </Typography>
      </Box>
    );
  };

  const userInformation = useSelector(selectBusinessInformation);
  const fullName = `${userInformation.user_name} ${userInformation.paternal_lastname} ${userInformation.maternal_lastname} `;

  return (
    <Box style={{ maxWidth: 600 }}>
      <Typography className={classes.headerTitle}>
        Resumen de los datos{" "}
      </Typography>

      <HeaderSectionChange
        description="Remitente"
        changeSection={handleBackOrigin}
      />
      <Box>
        <UserInformationLabel header="Nombre" description={fullName} />
        <UserInformationLabel
          header="Empresa / Negocio"
          description={selectedLocation.alias}
        />
        {/* <UserInformationLabel header="Teléfono" description={selectedLocation.telephone_number} /> */}
        <UserInformationLabel
          header="Dirección"
          description={`
          ${selectedLocation.street_name} #${selectedLocation.ext_number}
          ${
            selectedLocation.int_number === ""
              ? ""
              : " Interior " + selectedLocation.int_number
          } 
          ${selectedLocation.colony},
          ${selectedLocation.postal_code},
          ${selectedLocation.city}
          ${selectedLocation.state}
          `}
        />
        <UserInformationLabel
          header="Referencias"
          description={
            selectedLocation.location_references
              ? selectedLocation.location_references
              : "No existen referencias"
          }
        />
        <HeaderSectionChange
          description="Destinatario"
          changeSection={handleBackDestination}
        />

        <UserInformationLabel
          header="Nombre"
          description={recipient_info.full_name}
        />
        <UserInformationLabel
          header="Teléfono"
          description={recipient_info.telephone_number}
        />
        <UserInformationLabel
          header="Dirección"
          description={`
          ${recipient_info.street_name} #${recipient_info.ext_number}
          ${
            recipient_info.int_number === ""
              ? ""
              : " Interior " + recipient_info.int_number
          } 
          ${recipient_info.colony},
          ${recipient_info.postal_code},
          ${recipient_info.city}
          ${recipient_info.state}
          `}
        />
        <UserInformationLabel
          header="Referencias"
          description={
            recipient_info.location_references
              ? recipient_info.location_references
              : "No exiten referencias"
          }
        />
        <Grid container>
          <Grid item xs={12}>
            <FormBottomButtons
              handleNext={handleClickNextStep}
              handleBack={handleClickBackStep}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default GuideSummaryInformation;
