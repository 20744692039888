import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const CardDecriptionStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    flexDirection: "column",
    padding: 30,
    width: 400,
    height: 300,
  },
  title: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_EXTRABOLD,
    fontSize: 25,
    color: "#fff",
    marginBottom:5
  },
  description: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 15,
    color: "#fff",
    textAlign: "justify",
  },
  image: {
    textAlign: "start",
    marginBottom: 20,
    width: 50,
    height: 50,
  },
}));

export default CardDecriptionStyle;
