import insta from "../../assets/icons/insta.svg";
import facebook from "../../assets/icons/facebook.svg";
import twitter from "../../assets/icons/twitter.svg";

export const linksPlataform = [
  {
    id: 1,
    url: "/nosotros",
    text: "Nosotros",
  },
  {
    id: 2,
    url: "/rastreo",
    text: "Rastreo",
  },
  {
    id: 3,
    url: "/cotizacion",
    text: "Cotizacion",
  },
  {
    id: 4,
    url: "/preguntasFrecuentes",
    text: "FAQs",
  },
];

export const socialMedia = [
  {
    id: 1,
    url: "https://www.facebook.com/profile.php?id=100042235166005&mibextid=LQQJ4d",
    icon: facebook,
  },
  {
    id: 2,
    url: "https://www.facebook.com/profile.php?id=100042235166005&mibextid=LQQJ4d",
    icon: twitter,
  },
  {
    id: 3,
    url: "https://instagram.com/paqueterialiber?igshid=YmMyMTA2M2Y=",
    icon: insta,
  },
];

export const officialDocs = [
  {
    id: 1,
    url: "https://assets-liber.s3.amazonaws.com/docs/legal_docs/Aviso_de_privacidad.pdf",
    text: "Politica de privacidad",
  },
  {
    id: 2,
    url: "https://assets-liber.s3.amazonaws.com/docs/legal_docs/Articulos_prohibidos_para_transportar.pdf",
    text: "Articulos prohibidos",
  },
  {
    id: 3,
    url: "https://assets-liber.s3.amazonaws.com/docs/legal_docs/Condiciones_de_uso_de_liber_expres.pdf",
    text: "Condiciones de uso",
  },
  {
    id: 4,
    url: "https://assets-liber.s3.amazonaws.com/docs/legal_docs/Politicas_de_envio_de_liber_expres.pdf",
    text: "Politicas de envio",
  },
  {
    id: 5,
    url: "https://assets-liber.s3.amazonaws.com/docs/legal_docs/Seguro_de_envio_de_liber_expres.pdf",
    text: "Seguro de envio",
  },
];
