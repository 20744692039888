import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";

/**
 * Genera un hash HMACSHA256 y lo codifica en Base64.
 * @param {string} data - El string que se va a hashear.
 * @param {string} secretKey - La clave secreta para HMAC.
 * @returns {string} El hash codificado en Base64.
 */
export const generateHMACSHA256Base64 = (data, secretKey) => {
  // Crear el hash HMAC
  const hash = CryptoJS.HmacSHA256(data, secretKey);

  // Codificar el hash en Base64
  const base64Hash = CryptoJS.enc.Base64.stringify(hash);

  return base64Hash;
};

/**
 * Calcula la comisión basada en una cantidad dada con una tasa del 3.49% más IVA.
 *
 * @param {number} cantidad - La cantidad sobre la cual se calcula la comisión.
 * @returns {number} - La comisión calculada con IVA incluido.
 *
 * @example
 * const cantidad = 1000;
 * const comision = calcularComision(cantidad);
 * console.log(`La comisión para ${cantidad} es: ${comision.toFixed(2)}`);
 */
export function getMercadoPagoComission(cantidad) {
  const tasaComision = 0.0442;
  const valorFijo = 4.64;
  const comision = cantidad * tasaComision;
  const comisionTotal = comision + valorFijo;
  return parseFloat(comisionTotal);
}

/**
 * Calculates the total amount with Fiserv commission and IVA.
 *
 * This function takes an initial amount, applies a Fiserv commission rate of 2.2%,
 * and then applies an IVA (Value Added Tax) rate of 16% to the amount including the commission.
 * The final amount is returned as a numeric value rounded to two decimal places.
 *
 * @param {number} amount - The initial amount to which the commission and IVA will be applied.
 * @returns {number} - The final total amount after applying the commission and IVA, rounded to two decimal places.
 *
 * @example
 * const total = getFiservTotalWithCommission(100);
 * console.log(total); // Output: 119.55
 */
export const getFiservTotalWithCommission = (amount) => {
  const commissionRate = 2.2 / 100; // 2.2%
  const ivaRate = 16 / 100; // 16% IVA

  const commission = amount * commissionRate;
  const iva = amount * ivaRate;
  const totalCommissionAndIVA = commission + iva;

  return Number(totalCommissionAndIVA.toFixed(2));
};

export const getOrderIDByUuid = () => {
  const uuid = uuidv4();
  const orderID = uuid.replace(/-/g, "");
  return orderID;
};
