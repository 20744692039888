import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Switch,
  Tooltip,
  Typography,
  Zoom,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";
import InfoPerfilStyle from "./InfoPerfilStyle";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBusinessInformation,
  selectBusinessLocations,
  setBusinessLocationsInformation,
} from "../../../store/reducers/business/BusinessInformationSlice";
import { verifyPostalCode } from "../../../api/guidesFormApi";
import { notification } from "../../../utils/notification";
import { addNewDirection, updateDirection } from "../../../api/addNewDirection";
import {
  selectUserInformation,
  setLimitedLocation,
  setLaserSize,
} from "../../../store/reducers/user/UserInformationSlice";
import Loader from "../../Loader";
import { getInfoNegocio } from "../../../api/userApi";
import { AUTH_MESSAGES } from "../../../constants/messages";
import AddEditAddressModal from "./AddEditAddressModal";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import questionIcon from "../../../assets/icons/questionIcon.svg";
import imgPerfil from "../../../assets/img/dashboard/img-dashPerfil.svg";
import useLogout from "../../../hooks/useLogout";
import MainAddressModal from "./MainAddressModal";
import { changePreferences } from "../../../api/perfilApi";

const InfoPerfil = () => {
  const classes = InfoPerfilStyle();

  const [logout] = useLogout();

  const dispatch = useDispatch();
  // Modal Add Direcction
  const [openModalAddDirection, setOpenModalAddDirection] = useState(false);
  const [location, setLocation] = useState({ lat: 0, lng: 0 });
  const [loaderPreferences, setLoaderPreferences] = useState(false);

  const handleOpenModalAddDireccion = () => {
    setOpenModalAddDirection(true);
    setflagAdd(true);
  };
  const handleCloseModalAddDireccion = () => {
    setOpenModalAddDirection(false);
    setNewDirections({
      alias: "",
      streetName: "",
      numExt: "",
      numInt: "",
      colony: "",
      postalCode: "",
      city: "",
      state: "",
      references: "",
    });
    setLocation({
      lat: 0,
      lng: 0,
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseMainModal = () => {
    setOpenMainModal(false);
  };

  // Modal Loadding
  const [openModalLoadding, setOpenModalLoadding] = useState(false);
  // Nombre completo
  const businessInformation = useSelector(selectBusinessInformation);
  // Direcciones
  const businessLocations = useSelector(selectBusinessLocations);
  const userInformation = useSelector(selectUserInformation);
  const accessToken = userInformation.auth_token;
  const full_name = `${businessInformation.user_name} ${businessInformation.paternal_lastname} ${businessInformation.maternal_lastname}`;
  // nueva direccion
  const [newDirections, setNewDirections] = useState({
    alias: "",
    streetName: "",
    numExt: "",
    numInt: "",
    colony: "",
    postalCode: "",
    city: "",
    state: "",
    references: "",
  });
  const [verifyCode, setVerifyCode] = useState(false);
  const [idPostalCode, setIdPostalCode] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [flagAdd, setflagAdd] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openMainModal, setOpenMainModal] = useState(false);
  const [aliasMainModal, setAliasMainModal] = useState("");
  const [locationIdMainModal, setLocationIdMainModal] = useState(0);
  const PrinterText =
    "Esta opción se recomienda para poder generar el pdf de la guía en un formato para impresora térmica, por defecto el formato que se generá para el pdf es solo tamaño carta cuando la opción no esta activada, pero una vez activada generará ambos formatos.";
  const hideInformationText = `Esta opción se recomienda para mantener un nivel más alto de seguridad. Por ejemplo, negocios atendidos desde casa. Sin embargo se debe considerar que Liber se deslinda de  cualquier suceso, del que, el paquete requiera de la dirección.`;

  // Validaciones formulario modal
  const handleValidatePostalCode = () => {
    let payload = { postal_code: newDirections.postalCode };

    verifyPostalCode(payload)
      .then((res) => {
        if (
          res.data.status_Code === 200 &&
          res.data.status_Message === "postal code is validated"
        ) {
          handleAddNewDirection(res.data.id_postal_code);
        } else {
          setIdPostalCode(res.data.id_postal_code);
          setOpenDialog(true);
        }
      })
      .catch((err) => {
        setIdPostalCode("");
        notification("Ocurrió un error al guardar la información");
      });
  };

  const handleChangeNewDirection = (e) => {
    const { name, value } = e.target;
    setNewDirections((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "postalCode") {
      let justNumbers = /^[0-9]+$/;
      if (value.length === 5 && value.match(justNumbers)) {
        setVerifyCode(true);
      } else {
        setVerifyCode(false);
      }
    }
  };
  const disableButton =
    newDirections.alias === "" ||
    newDirections.streetName === "" ||
    newDirections.numExt === "" ||
    newDirections.colony === "" ||
    newDirections.city === "" ||
    newDirections.state === "" ||
    location.lat === 0;

  const handleAddNewDirection = (newIdPostalCode) => {
    setOpenModalLoadding(true);

    const payload = {
      business_location: {
        id_postal_code: newIdPostalCode,
        alias: newDirections.alias,
        state: newDirections.state,
        city: newDirections.city,
        colony: newDirections.colony,
        street: newDirections.streetName,
        ext_number: newDirections.numExt,
        int_number: newDirections.numInt,
        references: newDirections.references,
        latitude: location.lat,
        longitude: location.lng,
      },
    };

    addNewDirection(payload, accessToken)
      .then((res) => {
        if (
          res.data.status_Code === 201 &&
          res.data.status_Message === AUTH_MESSAGES.LOCATION_ADD
        ) {
          getInfoNegocio(accessToken)
            .then((res) => {
              if (
                res.data.status_Code === 200 &&
                res.data.status_Message === AUTH_MESSAGES.INFO_COMPLETE
              ) {
                setOpenModalLoadding(false);
                setOpenModalAddDirection(false);
                const newBusinessLocation = res.data.business_Locations;
                dispatch(setBusinessLocationsInformation(newBusinessLocation));
                notification("La dirección se agrego correctamente", "success");
                setNewDirections({
                  alias: "",
                  streetName: "",
                  numExt: "",
                  numInt: "",
                  colony: "",
                  postalCode: "",
                  city: "",
                  state: "",
                  references: "",
                });
                setLocation({
                  lat: 0,
                  lng: 0,
                });
              } else if (res.data.status_Code === 401) {
                setOpenModalLoadding(false);
                notification("La sesión ha caducado");
                logout();
              } else {
                setOpenModalLoadding(false);
                notification(
                  "Ocurrio un error al actualizar la información del perfil"
                );
              }
            })
            .catch((err) => {
              setOpenModalLoadding(false);
              notification(
                "Ocurrio un error al actualizar la información del perfil"
              );
            });
        } else if (
          res.data.status_Code === 200 &&
          res.data.status_Message === "alias repeats"
        ) {
          setOpenModalLoadding(false);
          notification(
            `Ya existe una dirección con el alias ${newDirections.alias}`
          );
        } else if (res.data.status_Code === 401) {
          setOpenModalLoadding(false);
          notification("La sesión ha caducado");
          logout();
        } else {
          setOpenModalLoadding(false);
          notification("Ocurrio un error al agregar dirección");
        }
      })
      .catch((err) => {
        setOpenModalLoadding(false);
        notification("Ocurrio un error al agregar dirección");
      });
  };

  const [idDirectionUpdate, setIdDirectionUpdate] = useState(0);
  // Obtener datos para actualizar
  const handleUpdateDirection = (idDirection) => {
    setOpenModalAddDirection(true);
    setflagAdd(false);
    const direction = businessLocations.find(
      (direction) => direction.id_location === idDirection
    );
    if (direction) {
      setNewDirections({
        alias: direction.alias,
        streetName: direction.street_name,
        numExt: direction.ext_number,
        numInt: direction.int_number,
        colony: direction.colony,
        postalCode: direction.postal_code,
        city: direction.city,
        state: direction.state,
        references: direction.location_references,
      });
      setLocation({
        lat: direction.latitude,
        lng: direction.longitude,
      });
      setVerifyCode(true);
      setIdDirectionUpdate(idDirection);
      setIdPostalCode(direction.id_postal_code);
      setPostalCode(direction.postal_code);
    }
  };

  const updateDirectionFuntion = (newIdPostalCode) => {
    setOpenModalLoadding(true);

    const payloadUpdate = {
      business_location: {
        id_location: idDirectionUpdate,
        id_postal_code: newIdPostalCode,
        alias: newDirections.alias,
        state: newDirections.state,
        city: newDirections.city,
        colony: newDirections.colony,
        street_name: newDirections.streetName,
        ext_number: newDirections.numExt,
        int_number: newDirections.numInt,
        references: newDirections.references,
        latitude: location.lat,
        longitude: location.lng,
      },
    };

    updateDirection(payloadUpdate, accessToken)
      .then((res) => {
        if (
          res.data.status_Code === 200 &&
          res.data.status_Message === AUTH_MESSAGES.LOCATION_UPDATE
        ) {
          setOpenModalAddDirection(false);
          notification("La dirección se actualizó correctamente", "success");
          getInfoNegocio(accessToken)
            .then((res) => {
              if (
                res.data.status_Code === 200 &&
                res.data.status_Message === AUTH_MESSAGES.INFO_COMPLETE
              ) {
                const updateBusinessLocation = res.data.business_Locations;
                dispatch(
                  setBusinessLocationsInformation(updateBusinessLocation)
                );
                setOpenModalLoadding(false);
                setNewDirections({
                  alias: "",
                  streetName: "",
                  numExt: "",
                  numInt: "",
                  colony: "",
                  postalCode: "",
                  city: "",
                  state: "",
                  references: "",
                });
                setLocation({
                  lat: 0,
                  lng: 0,
                });
              } else if (res.data.status_Code === 401) {
                setOpenModalLoadding(false);
                notification("La sesión ha caducado");
                logout();
              } else {
                notification(
                  "Ocurrio un error al actualizar la información del perfil"
                );
                setOpenModalLoadding(false);
              }
            })
            .catch((err) => {
              setOpenModalLoadding(false);
              notification(
                "Ocurrio un error al actualizar la información del perfil"
              );
            });
        } else if (
          res.data.status_Code === 200 &&
          res.data.status_Message === "alias repeats"
        ) {
          setOpenModalLoadding(false);
          notification(
            `Ya existe una dirección con el alias ${newDirections.alias}`
          );
        } else if (res.data.status_Code === 401) {
          setOpenModalLoadding(false);
          notification("La sesión ha caducado");
          logout();
        } else {
          setOpenModalLoadding(false);
          notification("Ocurrio un error al actualizar la dirección");
        }
      })
      .catch((err) => {
        setOpenModalLoadding(false);
        notification("Ocurrio un error al actualizar la dirección");
      });
  };

  const updatePreferences = (e, text) => {
    setLoaderPreferences(true);
    let value = e.target.checked;

    let payload = {
      limited_location:
        text === "limitedLocation" ? value : userInformation.limited_location,
      laser_size_required:
        text === "laserSize" ? value : userInformation.laser_size_required,
    };

    changePreferences(payload)
      .then((res) => {
        if (
          res.data.status_Code === 200 &&
          res.data.status_Message === "preferences was updated"
        ) {
          console.log(res);
          notification("Se modificó la oprión correctamente", "success");
          dispatch(
            text === "limitedLocation"
              ? setLimitedLocation(value)
              : setLaserSize(value)
          );
        } else if (res.data.status_Code === 401) {
          notification("La sesión ha caducado");
          logout();
        } else {
          notification("Ocurrio un error al realizar la modificación");
        }
      })
      .catch((err) => {
        notification("Error de conexión, por favor intentalo mas tarde");
      })
      .finally(() => setLoaderPreferences(false));
  };

  // Componente direcciones
  const Directions = (props) => {
    const {
      alias,
      street_name,
      ext_number,
      int_number,
      colony,
      postal_code,
      city,
      state,
      references,
      principal,
      id_location,
      handleUpdateDirection,
    } = props;

    const address = (
      <Fragment>
        <span className={classes.alias}>{alias}:</span>
        <span>{street_name} </span>
        <span>#{ext_number} </span>
        <span>{int_number === "" ? "" : " Interior " + int_number + " "}</span>
        <span>{colony}, </span>
        <span>{postal_code} </span>
        <span>{city}, </span>
        <span>{state}</span>
        <span>{references === "" ? "" : ", " + references}</span>
      </Fragment>
    );

    return (
      <Box>
        <Box
          className={
            principal
              ? classes.containerDireccionesTextPrincipal
              : classes.containerDireccionesText
          }
        >
          {principal ? (
            <Typography className={classes.textDirectionsMain}>
              {address}
            </Typography>
          ) : (
            <Box
              onClick={() => {
                setAliasMainModal(alias);
                setLocationIdMainModal(id_location);
                setOpenMainModal(true);
              }}
            >
              <Typography className={classes.textDirections}>
                {address}
              </Typography>
            </Box>
          )}
          <Button
            variant="outlined"
            className={classes.buttonEdit}
            onClick={handleUpdateDirection}
          >
            Editar
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <div>
      <Box className={classes.container}>
        <Box className={classes.containerInfo}>
          <Box className={classes.containerLabels}>
            <Box className={classes.containerInput}>
              <Typography className={classes.label}>Nombre</Typography>
              <input
                value={full_name}
                disabled
                className={classes.inputNombre}
              />
            </Box>
            <Box className={classes.containerInput}>
              <Typography className={classes.label}>Empresa/Negocio</Typography>
              <input
                disabled
                className={classes.inputNombre}
                value={businessInformation.business_name}
              />
            </Box>
            <Box className={classes.containerInput}>
              <Typography className={classes.label}>Télefono</Typography>
              <input
                value={businessInformation.telephone_number}
                disabled
                className={classes.inputComun}
              />
            </Box>
            <Box className={classes.containerInput}>
              <FormControlLabel
                control={
                  <Switch
                    checked={userInformation.laser_size_required}
                    onChange={(e) => updatePreferences(e, "laserSize")}
                    name="laserSize"
                  />
                }
                className={classes.swith}
                labelPlacement="end"
                label={
                  <Typography className={classes.switchText}>
                    Habilitar pdf para impresora térmica{" "}
                    <Tooltip
                      TransitionComponent={Zoom}
                      title={
                        <Box>
                          <Typography className={classes.tooltipText}>
                            {PrinterText}
                          </Typography>
                        </Box>
                      }
                      arrow
                    >
                      <img src={questionIcon} alt="questionIcon" />
                    </Tooltip>
                  </Typography>
                }
              />
            </Box>
            <Box className={classes.containerInput}>
              <FormControlLabel
                control={
                  <Switch
                    checked={userInformation.limited_location}
                    onChange={(e) => updatePreferences(e, "limitedLocation")}
                    name="limitedLocation"
                  />
                }
                className={classes.swith}
                labelPlacement="end"
                label={
                  <Typography className={classes.switchText}>
                    No mostrar mi información personal en la guía{" "}
                    <Tooltip
                      TransitionComponent={Zoom}
                      title={
                        <Box>
                          <Typography className={classes.tooltipText}>
                            {hideInformationText}
                          </Typography>
                        </Box>
                      }
                      arrow
                    >
                      <img src={questionIcon} alt="questionIcon" />
                    </Tooltip>
                  </Typography>
                }
              />
            </Box>
          </Box>
          <img src={imgPerfil} alt="imgPerfil" className={classes.imagen} />
        </Box>
        <Box className={classes.textAndButton}>
          <Typography className={classes.textDivider}>Direcciones</Typography>
          <Box className={classes.containerButtonModalAddDirection}>
            <Button
              onClick={handleOpenModalAddDireccion}
              className={classes.buttonAddDirection}
              endIcon={<AddCircleIcon />}
            >
              Agregar dirección
            </Button>
          </Box>
        </Box>
        <hr className={classes.divider} />
        <Typography className={classes.textDirectionNote}>
          <strong>Nota:</strong> La dirección principal se muestra resaltada en
          color rojo, para cambiar la dirección principal se debe hacer click
          sobre la dirección deseada.
        </Typography>
        {businessLocations.map((direccion) => (
          <Box key={direccion.id_location}>
            <Directions
              alias={direccion.alias}
              street_name={direccion.street_name}
              ext_number={direccion.ext_number}
              int_number={direccion.int_number}
              colony={direccion.colony}
              postal_code={direccion.postal_code}
              city={direccion.city}
              state={direccion.state}
              references={direccion.location_references}
              principal={direccion.principal}
              id_location={direccion.id_location}
              handleUpdateDirection={() =>
                handleUpdateDirection(direccion.id_location)
              }
            />
          </Box>
        ))}
      </Box>

      <AddEditAddressModal
        open={openModalAddDirection}
        flagAdd={flagAdd}
        newDirections={newDirections}
        handleChangeNewDirection={handleChangeNewDirection}
        handleCloseModalAddDireccion={handleCloseModalAddDireccion}
        disableButton={disableButton}
        verifyCode={verifyCode}
        handleValidatePostalCode={handleValidatePostalCode}
        postalCode={postalCode}
        idPostalCode={idPostalCode}
        updateDirectionFuntion={updateDirectionFuntion}
        location={location}
        setLocation={setLocation}
      />

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Nota"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            El código postal agregado por el momento no cuenta con servicio de
            recolección de la paquetería Liber, de igual forma podrá utilizar la
            plataforma para generar generar sus guías con las diferentes
            paqueterías que tenemos, entre ellas estan: (Liber, Estafeta, Fedex,
            J&T, entre otras). Una vez que el administrados agregue su código
            postal a la las zonas de cobertura podrá tener el servicio de
            recolección.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            className={classes.backButton}
            onClick={handleCloseDialog}
          >
            Cerrar
          </Button>
          <Button
            variant="contained"
            className={classes.continue}
            onClick={() => {
              setOpenDialog(false);
              flagAdd
                ? handleAddNewDirection(idPostalCode)
                : updateDirectionFuntion(idPostalCode);
            }}
            autoFocus
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
      {/* Modal Loadding */}
      <Loader
        showLoader={openModalLoadding}
        text={flagAdd ? "Agregando dirección..." : "Actualizando dirección..."}
      />
      <Loader showLoader={loaderPreferences} text={"Cargando..."} />

      <MainAddressModal
        open={openMainModal}
        handleClose={handleCloseMainModal}
        alias={aliasMainModal}
        locationId={locationIdMainModal}
      />
    </div>
  );
};

export default InfoPerfil;
