import React, { Fragment, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MenuIcon from "@material-ui/icons/Menu";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import { Hidden, Typography } from "@material-ui/core";
import "../Navbar/Navbar.css";
import MenuItems from "./MenuItems";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/img/LogoNavbar.svg";
import { ROUTES_CONSTANTS } from "../../constants/routesConstants";
import NavbarStyle from "./NavbarStyle";

export default function Navbar() {
  const classes = NavbarStyle();
  const [open, setOpen] = useState(false);
  const { REACT_APP_IN_MAINTENANCE } = process.env;

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };
  const navigate = useNavigate();

  const handleRedirectToHome = () => navigate(ROUTES_CONSTANTS.LANDING);

  const handleRedirect = (url) => {
    toggleDrawer(false);
    navigate(url);
  };

  const list = () => (
    <List>
      {MenuItems.map((item, index) => {
        return (
          <Fragment key={index}>
            <ListItem button onClick={() => handleRedirect(item.url)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <Typography className={classes.elementsDrawer}>
                {item.title}
              </Typography>
            </ListItem>
            <Divider style={{ background: "white" }} />
          </Fragment>
        );
      })}
      <ListItem
        button
        key={6}
        onClick={() => handleRedirect(ROUTES_CONSTANTS.REGISTRO)}
      >
        <ListItemIcon>
          <ContactMailIcon style={{ color: "white" }} />
        </ListItemIcon>
        <Typography className={classes.elementsDrawer}>Registro</Typography>
      </ListItem>
    </List>
  );

  const isInMaintenance = REACT_APP_IN_MAINTENANCE === "true" ? true : false;

  return (
    <div>
      <Drawer
        classes={{ paper: classes.styleDrawer }}
        anchor={"right"}
        open={open}
        onClose={toggleDrawer(false)}
      >
        {list("right")}
      </Drawer>

      <Hidden mdDown>
        <nav className={classes.navbarItems}>
          <img
            src={logo}
            alt="logo"
            className={classes.navbarLogo}
            onClick={handleRedirectToHome}
          />

          {!isInMaintenance && (
            <ul className={classes.navMenu}>
              {MenuItems.map((item, index) => {
                return (
                  <li key={index}>
                    <Link
                      to={item.url}
                      className={
                        item.title === "Iniciar Sesión"
                          ? `${classes.navLinks} ${classes.navLinkInicio}`
                          : classes.navLinks
                      }
                    >
                      {item.title}
                    </Link>
                  </li>
                );
              })}
              <Link to={ROUTES_CONSTANTS.REGISTRO} className={classes.button}>
                Registro
              </Link>
            </ul>
          )}
        </nav>
      </Hidden>

      <Hidden lgUp>
        <nav className={classes.navbarItems}>
          <img
            src={logo}
            alt="logo"
            className={classes.navbarLogo}
            onClick={handleRedirectToHome}
          />
          {!isInMaintenance && (
            <Button
              className={classes.hamburguerButton}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </Button>
          )}
        </nav>
      </Hidden>
    </div>
  );
}
