import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: {
    sender: "",
    telephone_number: "",
    street_name: "",
    ext_number: "",
    int_number: "",
    colony: "",
    city: "",
    state: "",
    postal_code: "",
  },
  recipientInfo: {
    full_name: "",
    telephone_number: "",
    street_name: "",
    ext_number: "",
    int_number: "",
    colony: "",
    city: "",
    state: "",
    postal_code: null,
    location_references:"",
  },
  packageInfo: {
    package_weight: null,
    package_height: null,
    package_length: null,
    package_width: null,
    estimated_delivery_date: "",
    destinity_zone:"",
    destinity_area:"",
    destinity_sector:"",
    destinity_subsector:"",
  },
  guides_history:[],
  packageFound: false,
  trackingCode : ""
};

export const TrackingInformationSlice = createSlice({
  name: "tracking",
  initialState,
  reducers: {
    setTrackingUserInformation(state, { payload }) {
      state.userInfo = payload;
    },
    setRecipientInformation(state, { payload }) {
      state.recipientInfo = payload;
    },
    setPackageInformation(state, { payload }) {
      state.packageInfo = payload;
    },
    setGuidesHistory(state, {payload}){
      state.guides_history = payload
    },
    setPackageFound(state, { payload }) {
        state.packageFound = payload;
    },
    setTrackingCode(state, { payload }) {
      state.trackingCode = payload;
    }
  },
});

export const {
  setTrackingUserInformation,
  setRecipientInformation,
  setPackageInformation,
  setPackageFound,
  setTrackingCode,
  setIsSearchingId,
  setTraikingId,
  setGuidesHistory
} = TrackingInformationSlice.actions;

export default TrackingInformationSlice.reducer;

//selectors
export const selectUserTrackingInformation = (state) =>
  state.tracking ? state.tracking.userInfo : {};
export const selectRecipientInformation = (state) =>
  state.tracking ? state.tracking.recipientInfo : {};
export const selectPackageInformation = (state) =>
  state.tracking ? state.tracking.packageInfo : {};
export const selectGuidesHistory = (state) =>
  state.tracking ? state.tracking.guides_history : [];
export const selectPackageFoud = (state) =>
  state.tracking ? state.tracking.packageFound : null;
export const selectTrackingCode = (state) =>
  state.tracking ? state.tracking.trackingCode : '';
