import axios from "axios";
import { getCookie } from "../utils/sessionCookie";
const { REACT_APP_LIBER_API_URL } = process.env;

export const verifyPostalCode = (payload) => {
  const request = {
    method: "POST",
    url: `${REACT_APP_LIBER_API_URL}/zones/check-existence-postal-code`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
    },
  };

  return axios(request);
};

export const getRecipients = (payload) => {
  const request = {
    method: "GET",
    url: `${REACT_APP_LIBER_API_URL}/business/get-concurrent-recipients`,
    headers: {
      "content-type": "application/json",
      auth_Token: getCookie("accessToken"),
    },
  };

  return axios(request);
};

export const addRecipient = (payload) => {
  const request = {
    method: "POST",
    url: `${REACT_APP_LIBER_API_URL}/business/add-concurrent-recipients`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
      auth_Token: getCookie("accessToken"),
    },
  };

  return axios(request);
};

export const updateRecipient = (payload) => {
  const request = {
    method: "POST",
    url: `${REACT_APP_LIBER_API_URL}/business/update-concurrent-recipient`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
      auth_Token: getCookie("accessToken"),
    },
  };

  return axios(request);
};

export const getRates = (payload) => {
  const request = {
    method: "POST",
    url: `${REACT_APP_LIBER_API_URL}/autopaquete/parcel-rates`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
    },
  };

  return axios(request);
};

export const createGuideThird = (payload) => {
  const request = {
    method: "POST",
    url: `${REACT_APP_LIBER_API_URL}/autopaquete/add-shipping`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
      auth_Token: getCookie("accessToken"),
    },
  };

  return axios(request);
};

export const addUpdatePackage = (payload) => {
  const request = {
    method: "POST",
    url: `${REACT_APP_LIBER_API_URL}/business/add-update-concurrent-product`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
      auth_Token: getCookie("accessToken"),
    },
  };

  return axios(request);
};

export const getPackages = () => {
  const request = {
    method: "GET",
    url: `${REACT_APP_LIBER_API_URL}/business/get-concurrent-products`,
    headers: {
      "content-type": "application/json",
      auth_Token: getCookie("accessToken"),
    },
  };

  return axios(request);
};

export const changeMainAddress = (payload) => {
  const request = {
    method: "POST",
    url: `${REACT_APP_LIBER_API_URL}/business/set-principal-location`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
      auth_Token: getCookie("accessToken"),
    },
  };

  return axios(request);
};

export const validateLiber = (payload) => {
  const request = {
    method: "POST",
    url: `${REACT_APP_LIBER_API_URL}/autopaquete/pre-rates`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
    },
  };

  return axios(request);
};

export const getLocation = (payload) => {
  const request = {
    method: "POST",
    url: `${REACT_APP_LIBER_API_URL}/delivery_man/get-latitude-longitude`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
      auth_Token: getCookie("accessToken"),
    },
  };

  return axios(request);
};
