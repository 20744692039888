import React from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import wrapperImageStyles from "./wrapperImage.styles";
const WrapperImageContainer = (props) => {
  const { wrapperTitle, wrapperDesc, wrapperImage, imagePosition } = props;
  const classes = wrapperImageStyles();

  const theme = useTheme();
  const showImage = useMediaQuery(theme.breakpoints.up("md"));

  const GridItemDescription = () => (
    <Grid item md={6}>
      <Box className={classes.wrapperTitleContainer}>
        {typeof wrapperTitle !== "string" ? (
          wrapperTitle
        ) : (
          <Typography className={classes.question}>{wrapperTitle}</Typography>
        )}
      </Box>
      <Box className={classes.wrapperDescriptionContainer}>
        {typeof wrapperDesc !== "string" ? (
          wrapperDesc
        ) : (
          <Typography className={classes.description}>{wrapperDesc}</Typography>
        )}
      </Box>
    </Grid>
  );

  const GridItemImage = () => (
    <Grid item md={6}>
      <img
        src={wrapperImage}
        className={classes.wrapperImage}
        alt="wrapperImg"
      />
    </Grid>
  );

  return (
    <Grid container className={classes.mainContainer}>
      {imagePosition === "right" ? (
        <>
          <GridItemDescription />
          {showImage && <GridItemImage />}
        </>
      ) : (
        <>
          {showImage && <GridItemImage />}
          <GridItemDescription />
        </>
      )}
    </Grid>
  );
};

export default WrapperImageContainer;
