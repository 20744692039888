import { makeStyles } from "@material-ui/core";

const DimentionsTypesPackagesStyle = makeStyles((theme) => ({
      container:{
        borderRadius:20,
        boxShadow:"0px 0px 10px rgba(0, 0, 0, 0.25)",
        padding:20,
        margin:"0px 10px"
      }
}))
export default DimentionsTypesPackagesStyle