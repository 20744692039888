import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoadingInfo: false,
};

export const LoadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoadingInfo(state, { payload }) {
      state.isLoadingInfo = payload;
    },
  },
});

export const { setLoadingInfo } = LoadingSlice.actions;

export default LoadingSlice.reducer;

//selectors
export const selectIsLoadingInfo = (state) =>
  state.loading ? state.loading.isLoadingInfo : false;
