import React, { Fragment, useState, useEffect } from "react";
import {
  TableRow,
  TableCell,
  Paper,
  TableContainer,
  Table,
  TableBody,
  Box,
  Collapse,
  Typography,
  TableHead,
  IconButton,
} from "@material-ui/core";
import { getLots } from "../../../api/lotsApi";
import { notification } from "../../../utils/notification";
import TableSkeleton from "../../sharedComponents/TableSkeleton";
import CustomPagination from "../../sharedComponents/CustomPagination";
import TableHeader from "./TableHeader";
import DataFilterLotsStyle from "./DataFilterLotsStyle";
import clsx from "clsx";
import useLogout from "../../../hooks/useLogout";
import NotFoundRows from "../../sharedComponents/NotFoundRows";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import VisibilityIcon from "@material-ui/icons/Visibility";

const DataFilterLots = () => {
  const classes = DataFilterLotsStyle();
  const [logout] = useLogout();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [rowsData, setRowsData] = useState([]);

  useEffect(() => {
    setLoading(true);

    getLots()
      .then((res) => {
        if (res.data.status_Code === 200) {
          setRowsData(res.data.batches);
        } else if (res.data.status_Code === 401) {
          notification("La sesión ha caducado", "error");
          logout();
        } else {
          notification("Ocurrió un error al recuperas los lotes", "error");
        }
      })
      .catch((err) => {
        notification(
          "Error de conexión, no se pudieron recuperar los lotes, revisa tu conexión de internet",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Row = (props) => {
    const { row, index } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <Fragment>
        <TableRow
          hover
          role="checkbox"
          tabIndex={-1}
          key={index}
          style={{ background: "none" }}
        >
          <TableCell
            align="left"
            className={clsx(
              classes.text,
              index % 2 ? classes.bgWhite : classes.bgGray
            )}
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell
            align="left"
            className={clsx(
              classes.text,
              index % 2 ? classes.bgWhite : classes.bgGray
            )}
          >
            {row.id_batch}
          </TableCell>
          <TableCell
            align="left"
            className={clsx(
              classes.text,
              index % 2 ? classes.bgWhite : classes.bgGray
            )}
          >
            {row.total_guides}
          </TableCell>
          <TableCell
            align="left"
            className={clsx(
              classes.text,
              index % 2 ? classes.bgWhite : classes.bgGray
            )}
          >
            {row.amount}
          </TableCell>
          <TableCell
            align="left"
            className={clsx(
              classes.text,
              index % 2 ? classes.bgWhite : classes.bgGray
            )}
          >
            {row.payment_status}
          </TableCell>
          <TableCell
            align="left"
            className={clsx(
              classes.text,
              index % 2 ? classes.bgWhite : classes.bgGray
            )}
          >
            {row.batch_status}
          </TableCell>
          <TableCell
            align="left"
            className={clsx(
              classes.text,
              index % 2 ? classes.bgWhite : classes.bgGray
            )}
          >
            {row.creation_date}
          </TableCell>
          <TableCell
            align="left"
            className={index % 2 ? classes.bgWhite : classes.bgGray}
          >
            <a
              href={row.url_pdf_s3}
              target="_blank"
              rel="noreferrer"
              title="PDF tamaño normal"
            >
              <VisibilityIcon className={classes.icon} />
            </a>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Guías del Lote {row.lote_id}
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className={classes.textHeader}>
                        No. de Guía
                      </TableCell>
                      <TableCell align="center" className={classes.textHeader}>
                        Destinatario
                      </TableCell>
                      <TableCell align="center" className={classes.textHeader}>
                        Zona
                      </TableCell>
                      <TableCell align="center" className={classes.textHeader}>
                        Tipo de envío
                      </TableCell>
                      <TableCell align="center" className={classes.textHeader}>
                        Peso
                      </TableCell>
                      <TableCell align="center" className={classes.textHeader}>
                        Creación
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.ls_guides.map((guide, indexGuide) => (
                      <TableRow key={indexGuide}>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          className={classes.text}
                        >
                          {guide.tracking_code}
                        </TableCell>
                        <TableCell align="center" className={classes.text}>
                          {guide.fullname}
                        </TableCell>
                        <TableCell align="center" className={classes.text}>
                          {guide.zone}
                        </TableCell>
                        <TableCell align="center" className={classes.text}>
                          {guide.shipping_type}
                        </TableCell>
                        <TableCell align="center" className={classes.text}>
                          {guide.weight}
                        </TableCell>
                        <TableCell align="center" className={classes.text}>
                          {guide.creation_date}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div className={classes.content}>
        <Paper className={classes.container}>
          <TableContainer>
            <Table aria-label="collapsible table" size="small">
              <TableHeader />
              <TableBody>
                {!loading ? (
                  rowsData.length > 0 ? (
                    rowsData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <Row key={index} row={row} index={index} />
                      ))
                  ) : (
                    <NotFoundRows colSpan={7} />
                  )
                ) : (
                  <TableSkeleton rowsNum={5} columnsNum={8} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            count={rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Paper>
      </div>
    </Fragment>
  );
};

export default DataFilterLots;
