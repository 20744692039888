import { Box, Typography } from "@material-ui/core";
import React from "react";
import TargetFaqsStyle from "./TargetFaqs.style";

const TargetFaqs = (props) => {
  const classes = TargetFaqsStyle();

  return (
    <Box className={classes.container}>
      <img src={props.imagen} alt="imagen" className={classes.imagen} />
      <Typography className={classes.pregunta}>{props.pregunta}</Typography>
      <Typography className={classes.respuesta}>{props.respuesta}</Typography>
    </Box>
  );
};

export default TargetFaqs;
