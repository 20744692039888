import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../../constants/fontConsts";

const PaymentSummaryModalStyles = makeStyles((theme) => ({
  content: {
    maxWidth: 487,
    maxHeight: 574,
    width: "90%",
    height: "90%",
    overflow: "auto",
    background: "white",
    borderRadius: 30,
    [theme.breakpoints.down("md")]: {
      maxWidth: 450,
      maxHeight: 550,
    },
    [theme.breakpoints.down(600)]: {
      maxWidth: "400px !important",
      maxHeight: "450px !important",
    },
    [theme.breakpoints.down(450)]: {
      maxWidth: "300px !important",
      maxHeight: "400px !important",
    },
    "@media (max-height: 900px)": {
      maxWidth: 450,
      maxHeight: 550,
    },
    "@media (max-height: 666px)": {
      maxWidth: 400,
      maxHeight: 450,
    },
  },
  containerHeader: {
    background: "rgba(246, 255, 252, 1)",
    height: 130,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      height: 120,
    },
    [theme.breakpoints.down(600)]: {
      height: "100px !important",
    },
    [theme.breakpoints.down(450)]: {
      height: "70px !important",
    },
    "@media (max-height: 900px)": {
      height: 120,
    },
    "@media (max-height: 666px)": {
      height: 100,
    },
  },
  containerBody: {
    height: "calc(574px - (130px + 100px))",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      height: "calc(550px - (120px + 85px))",
    },
    [theme.breakpoints.down(600)]: {
      height: "calc(450px - (100px + 75px)) !important",
    },
    [theme.breakpoints.down(450)]: {
      height: "calc(400px - (70px + 60px)) !important",
    },
    "@media (max-height: 900px)": {
      height: "calc(550px - (120px + 85px))",
    },
    "@media (max-height: 666px)": {
      height: "calc(450px - (100px + 75px))",
    },
  },
  containerFooter: {
    height: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderTop: "1px solid rgba(0, 0, 0, 0.25)",
    [theme.breakpoints.down("md")]: {
      height: 85,
    },
    [theme.breakpoints.down(600)]: {
      height: "75px !important",
    },
    [theme.breakpoints.down(450)]: {
      height: "60px !important",
    },
    "@media (max-height: 900px)": {
      height: 85,
    },
    "@media (max-height: 666px)": {
      height: 75,
    },
  },
  textHeader: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 25,
    color: "#000",
    [theme.breakpoints.down("md")]: {
      fontSize: 23,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "20px !important",
    },
    [theme.breakpoints.down(450)]: {
      fontSize: "18px !important",
    },
    "@media (max-height: 900px)": {
      fontSize: 23,
    },
    "@media (max-height: 666px)": {
      fontSize: 20,
    },
  },
  textTitle: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 19.5,
    fontWeight: 500,
    lineHeight: "20.38px",
    color: "#000",
    [theme.breakpoints.down("md")]: {
      fontSize: 17.5,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "16px !important",
    },
    [theme.breakpoints.down(450)]: {
      fontSize: "15px !important",
    },
    "@media (max-height: 900px)": {
      fontSize: 17.5,
    },
    "@media (max-height: 666px)": {
      fontSize: 15,
    },
  },
  textNumber: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 22.5,
    lineHeight: "30.5px",
    fontWeight: 400,
    color: "#000",
    marginBottom: 27.5,
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
      marginBottom: 17.5,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "17px !important",
    },
    [theme.breakpoints.down(450)]: {
      fontSize: "16px !important",
    },
    "@media (max-height: 900px)": {
      fontSize: 20,
      marginBottom: 17.5,
    },
    "@media (max-height: 666px)": {
      fontSize: 17,
      marginBottom: 7.5,
    },
  },
  marginTextTotal: {
    marginTop: 20,
    [theme.breakpoints.down("md")]: {
      marginBottom: 15,
    },
    [theme.breakpoints.down(450)]: {
      marginTop: "5px !important",
    },
    "@media (max-height: 900px)": {
      marginBottom: 15,
    },
    "@media (max-height: 666px)": {
      marginBottom: 10,
      marginTop: 10,
    },
  },
  textTotalAmount: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    color: "white",
    fontSize: 28,
    background:
      "linear-gradient(179.85deg, #F8822E 0.13%, rgba(255, 198, 158, 0) 388.75%);",
    borderRadius: "10px",
    width: 280,
    height: 55,
    marginTop: 20,
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      width: 250,
      height: 48,
      marginTop: 0,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "19px !important",
      width: "200px !important",
      height: "43px !important",
    },
    [theme.breakpoints.down(450)]: {
      fontSize: "17px !important",
      width: "180px !important",
      height: "40px !important",
    },
    "@media (max-height: 900px)": {
      fontSize: 24,
      width: 250,
      height: 48,
      marginTop: 0,
    },
    "@media (max-height: 666px)": {
      width: 180,
      height: 38,
      fontSize: 18,
    },
  },
  textButtonPay: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    color: "white",
    fontSize: 20,
    [theme.breakpoints.down("md")]: {
      fontSize: 17,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "14px !important",
    },
    [theme.breakpoints.down(450)]: {
      fontSize: "13px !important",
    },
    "@media (max-height: 900px)": {
      fontSize: 17,
    },
    "@media (max-height: 666px)": {
      fontSize: 13.5,
    },
  },
  image: {
    width: 25,
    height: 49,
    marginLeft: 32,
    marginBottom: 31,
    [theme.breakpoints.down("md")]: {
      marginBottom: 20,
      width: 23,
      height: 45,
    },
    [theme.breakpoints.down(600)]: {
      width: "20px !important",
      height: "40px !important",
    },
    [theme.breakpoints.down(450)]: {
      display: "none !important",
    },
    "@media (max-height: 900px)": {
      marginBottom: 20,
      width: 23,
      height: 45,
    },
    "@media (max-height: 666px)": {
      marginBottom: 19,
      width: 20,
      height: 40,
      marginLeft: 15,
    },
  },
  buttonPay: {
    background: "#D15252",
    borderRadius: "10px",
    width: 176,
    height: 50,
    color: "white",
    border: "none",
    "&:hover": {
      opacity: 0.7,
      background: "#D15252 !important",
    },
    [theme.breakpoints.down("md")]: {
      width: 140,
      height: 42,
    },
    [theme.breakpoints.down(600)]: {
      width: "120px !important",
      height: "35px !important",
    },
    [theme.breakpoints.down(450)]: {
      width: "110px !important",
      height: "33px !important",
    },
    "@media (max-height: 900px)": {
      width: 140,
      height: 42,
    },
    "@media (max-height: 666px)": {
      width: 120,
      height: 35,
    },
  },
}));

export default PaymentSummaryModalStyles;
