import { Box, Typography } from "@material-ui/core";
import React from "react";
import CardDescription from "./CardDescription.style";

const CardDecription = (props) => {
  const classes = CardDescription();

  return (
    <Box className={classes.container}>
      <Box>
        <img
          className={classes.image}
          src={props.image}
          alt="Imagen de misión"
        />
      </Box>
      <Box className={classes.containerText}>
        <Typography className={classes.title}>{props.title}</Typography>
        <Typography className={classes.text}>{props.text}</Typography>
        <Typography className={classes.textMin}>{props.textMin}</Typography>
        <Typography className={classes.textMinSec}>{props.textMinSec}</Typography>
      </Box>
    </Box>
  );
};

export default CardDecription;
