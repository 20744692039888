import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../constants/fontConsts";

const ShipmentInformationStyle = makeStyles((theme) =>({
    container:{
        background:"#FFF7F7", 
        padding:30,
    },
    title:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_BOLD,
        fontSize:15,
        textAlign:"center", 
        marginBottom:15
    },
    containerButton:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        marginTop:60
    },
    button:{
        background:"#D15353",
        color:"#fff",
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_BOLD,
        fontSize:15,
        width:200,
    },
    buttonVolver:{
        textTransform:"capitalize",
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
        fontSize:15,
    },
    iconVolver:{
        color:"rgba(0, 0, 0, 0.29)"
    }
}))
export default ShipmentInformationStyle