import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const PaymentSuccessStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F9F6EE",
    height: "100vh",
    width: "100vw",
  },
  messageContainer: {
    height: 400,
    width: 600,
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: 10,
  },
  paymentSuccessHeader: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    fontSize: 24,
    marginBottom: 20,
  },
  redirectMessage: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 14,
    marginTop: 20,
    color: "#B3ACAA",
  },

  [theme.breakpoints.down("sm")]: {
    messageContainer: {
      height: 300,
      width: 250,
    },
    paymentSuccessHeader: {
      fontSize: 14,
      marginBottom: 20,
      textAlign: "center",
    },
    redirectMessage: {
      fontSize: 12,
      textAlign: "center",
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  [theme.breakpoints.between("sm", "md")]: {
    messageContainer: {
      height: 400,
      width: 350,
    },
    paymentSuccessHeader: {
      fontSize: 14,
      marginBottom: 20,
      textAlign: "center",
    },
    redirectMessage: {
      fontSize: 12,
      textAlign: "center",
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
}));
export default PaymentSuccessStyle;
