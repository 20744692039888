import { makeStyles } from "@material-ui/core";

const ErrorAnimationStyles = makeStyles((theme) => ({
  "@keyframes aniErrorLine": {
    to: { strokeDashoffset: 0 },
  },
  "@keyframes aniErrorCircle": {
    "0%": {
      strokeDasharray: "0, 260.75219024795285px",
      strokeDashoffset: 0,
    },
    "35%": {
      strokeDasharray: "120px, 120px",
      strokeDashoffset: "-120px",
    },
    "70%": {
      strokeDasharray: "0, 260.75219024795285px",
      strokeDashoffset: "-260.75219024795285px",
    },
    "100%": {
      strokeDasharray: "260.75219024795285px, 0",
      strokeDashoffset: "-260.75219024795285px",
    },
  },
  uiError: {
    width: "100px",
    height: "100px",
    margin: "40px",
  },
  uiErrorCircle: {
    strokeDasharray: "260.75219024795285px, 260.75219024795285px",
    strokeDashoffset: "260.75219024795285px",
    animation: "$aniErrorCircle 1.2s linear",
  },
  uiErrorLine1: {
    strokeDasharray: "54px 55px",
    strokeDashoffset: "55px",
    strokeLinecap: "round",
    animation: "$aniErrorLine .15s 1.2s linear both",
  },
  uiErrorLine2: {
    strokeDasharray: "54px 55px",
    strokeDashoffset: "55px",
    strokeLinecap: "round",
    animation: "$aniErrorLine .2s .9s linear both",
  },
}));

export default ErrorAnimationStyles;
