import { makeStyles } from "@material-ui/core";

const TableToolbarStyle = makeStyles((theme) => ({
  highlight: {
    color: "white",
    backgroundColor: "#D15353",
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    color: "#D15353",
    textTransform: "capitalize",
    border: "1px solid white",
    fontWeight: "bold",
    background: "white",
    boxShadow: "none",
    [theme.breakpoints.down(1050)]: {
      fontSize: 13,
    },
    [theme.breakpoints.down(700)]: {
      fontSize: 11,
      padding: "4px 8px",
    },
  },
  title: {
    [theme.breakpoints.down(700)]: {
      fontSize: 14,
    },
  },
}));

export default TableToolbarStyle;
