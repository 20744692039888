import { makeStyles } from "@material-ui/core";
import {MONTSERRAT_FONTS} from "../../../constants/fontConsts"

const SenderStyle = makeStyles((theme) =>({
    container:{
        display:"flex",
        flexDirection:"column",
        alignItems:"start",
        marginBottom:20
    },
    title:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_BOLD,
        fontSize:14,
        color:"rgba(0, 0, 0, 0.7)",
        marginBottom:10,
        [theme.breakpoints.down("md")]:{
            fontSize:18
        }
    },
    informationSender:{
        display:"flex",
        flexDirection:"column",
        alignItems:"start"
    },
    text:{
        fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
        fontSize: 12,
    },
    textName:{
        fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
        fontSize: 12,
        marginBottom:5,
        [theme.breakpoints.down("md")]:{
            fontSize:15
        },
    }

}))

export default SenderStyle