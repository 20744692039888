import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS, POPPINS_FONTS } from "../../constants/fontConsts";

const QuotesStyle = makeStyles((theme) => ({
  container: {
    background:
      "linear-gradient(10.79deg, #FFFFFF 2.97%, #EFF1FC 59.78%, #FFFFFF 97.12%)",
    paddingTop: 140,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 10,
    },
  },
  title: {
    fontFamily: POPPINS_FONTS.POPPINS_BOLD,
    fontSize: 35,
    marginBottom: 80,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      marginBottom: 0,
    },
  },
  inputMedida: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 40,
      },
      "&:hover fieldset": {
        border: "none", // Elimina el borde al pasar el mouse sobre el TextField
      },
    },
    background: "white",
    borderRadius: 20,
    textAlign: "center",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 8,
  },
  containerCards: {
    display: "flex",
    justifyContent: "space-evenly",
    padding: "0px 100px",
    alignItems: "center",
    [theme.breakpoints.down('md')]:{
        flexWrap:"wrap",
        padding: "0px 50px",
    },
    [theme.breakpoints.between('md','sm')]:{
        padding: "0px 0px",
    }
  },
  cardOrigen: {
    width: 233,
    height: 208,
    background: "#fff",
    borderRadius: 10,
    padding: "14px 22px",
    boxShadow:"0px 4px 24px rgba(199, 201, 204, 0.20)",
    [theme.breakpoints.down('md')]:{
        margin:10
    }
  },
  cardDestino: {
    width: 233,
    height: 208,
    background: "#fff",
    borderRadius: 10,
    padding: "14px 22px",
    boxShadow:"0px 4px 24px rgba(199, 201, 204, 0.20)",
    [theme.breakpoints.down('md')]:{
        margin:10
    }
  },
  cardInfoPaquete: {
    width: 303,
    height: 208,
    background: "#fff",
    borderRadius: 10,
    padding: "14px 22px",
    boxShadow:"0px 4px 24px rgba(199, 201, 204, 0.20)",
    [theme.breakpoints.down('md')]:{
        margin:10
    }
  },
  cardDimentions: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: 18,
  },
  cardProducts: {
    display: "flex",
    justifyContent: "space-evenly",
    paddingTop: 20,
  },
  titleCard: {
    fontFamily: POPPINS_FONTS.POPPINS_SEMIBOLD,
    fontSize: 14,
  },
  containerInputCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  textInputCard: {
    fontFamily: POPPINS_FONTS.POPPINS_REGULAR,
    fontSize: 9,
    marginBottom: 4,
  },
  inputCp: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 50, // Elimina el borde
      },
      "&:hover fieldset": {
        border: "none", // Elimina el borde al pasar el mouse sobre el TextField
      },
    },
    background: "white",
    borderRadius: 15,
    textAlign: "center",
    fontFamily: POPPINS_FONTS.POPPINS_REGULAR,
    fontSize: 22,
    paddingTop: 25,
    paddingBottom: 24,
    "&:focus": {
      outline: "none",
      backgroundColor: "#ffff",
      border: "2px solid #FFF",
    },
  },
  estado: {
    fontFamily: POPPINS_FONTS.POPPINS_SEMIBOLD,
    fontSize: 12,
    paddingBottom: 14,
  },
  estadoRes: {
    fontFamily: POPPINS_FONTS.POPPINS_REGULAR,
    fontSize: 10,
  },
  containerButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 48,
    paddingBottom: 30,
  },
  boton: {
    background: "#DF2E38",
    color: "#fff",
    fontSize: 15,
    fontFamily: POPPINS_FONTS.POPPINS_BOLD,
    textTransform: "capitalize",
    padding: "11px 77px",
    borderRadius: 50,
    [theme.breakpoints.down("md")]: {
      padding: "3px 30px",
      fontSize: 16,

    },
    [theme.breakpoints.between("xs", "sm")]: {
      padding: "3px 30px",
      fontSize: 12,
    },
  },
  containerTargets: {
    /* display: "grid",
    gridTemplateColumns: "auto auto auto auto", */
    width: "90%",
    margin: "0 auto",
    justifyContent: "space-around",
    paddingBottom:50,
    [theme.breakpoints.down('md')]:{
        gridTemplateColumns: "auto auto auto",
        width: "85%",
    },
    [theme.breakpoints.down('sm')]:{
        gridTemplateColumns: "auto",
        width: "90%",
        justifyContent: "center",
    }
  },
  containerResultTarget: {
    width: 253,
    height: 330,
    background: "#fff",
    borderRadius: 10,
    boxShadow: "0px 1px 12px 0px rgba(255, 0, 0, 0.25)",
    marginBottom:30,
    display:"flex",
    flexDirection:"column",
    [theme.breakpoints.down(1290)]:{
        width: 240,
        height: 310,
        alignItems:"center",
    },
    [theme.breakpoints.down(1150)]: {
      width: 220,
      height: 270,
    },
    [theme.breakpoints.down(800)]: {
      width: 200,
      height: 250,
    },
    [theme.breakpoints.down(768)]: {
      width: 440,
      height: 160,
      flexDirection:"row",
      alignItems:"center",
      justifyContent:"space-evenly",
      paddingTop:18,
    },

    [theme.breakpoints.down('xs')]:{
        width: 348,
        height: 125,
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"space-evenly",
        paddingTop:18,
    }
  },
  imagenTarget: {
    width: "100%",
    height: 90,
    borderRadius: "10px 10px 0px 0px",
    objectFit: "cover",
    [theme.breakpoints.down(1290)]:{
      height: 70,
    },
    [theme.breakpoints.down('sm')]:{
        width: 110,
        height: "80%",
        objectFit: "fill",
        borderRadius: "10px 10px 10px 10px",
    }
  },
  containerTargetBody: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down(600)]:{
        marginTop:"-40px"
    }
  },
  containerTitle: {
    display: "flex",
    alignItems: "baseline",
  },
  titleTarget: {
    fontSize: 18,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    textAlign: "center",
    marginTop: 14,
    [theme.breakpoints.down(1590)]: {
      fontSize: 18,
    },
    [theme.breakpoints.down(1290)]: {
      fontSize: 18,
    },
    [theme.breakpoints.down(1150)]: {
      fontSize: 15.5,
    },
    [theme.breakpoints.down(960)]: {
      fontSize: 16,
    },
    [theme.breakpoints.down(670)]: {
      fontSize: 10,
    },
  },
  deliveryText: {
    fontSize: 19,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    marginTop: 20,
    color: "rgba(0, 0, 0, 0.67)",
    [theme.breakpoints.down(1590)]: {
      fontSize: 16,
    },
    [theme.breakpoints.down(1290)]: {
      fontSize: 14,
      marginTop: 10,
    },
    [theme.breakpoints.down(1150)]: {
      fontSize: 12.5,
    },
    [theme.breakpoints.down(960)]: {
      fontSize: 13,
      marginTop: 10,
    },
    [theme.breakpoints.down(670)]: {
      fontSize: 10.5,
      marginTop: 5,
    },
  },
  dayText: {
    fontSize: 16,
    marginTop: 10,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    color: "rgba(0, 0, 0, 0.67)",
    [theme.breakpoints.down(1590)]: {
      fontSize: 13,
    },
    [theme.breakpoints.down(1290)]: {
      fontSize: 12,
    },
    [theme.breakpoints.down(670)]: {
      fontSize: 9.5,
    },
  },
  amount: {
    fontSize: 23,
    marginTop: 12,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    [theme.breakpoints.down(1290)]: {
      fontSize: 16,
      marginTop: 10,
    },
    [theme.breakpoints.down(960)]: {
      fontSize: 15.5,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 12.5,
    },
  },
  buttonSelection: {
    background: "#DF2E38",
    width: 130,
    height: 35,
    borderRadius: 50,
    marginTop: 18,
    marginBottom: 10,
    [theme.breakpoints.down(1290)]: {
      width: 115,
      height: 33,
    },
    [theme.breakpoints.down(1150)]: {
      width: 100,
      height: 30,
    },
    [theme.breakpoints.down(960)]: {
      width: 110,
      height: 28,
      marginTop: 12,
    },
    [theme.breakpoints.down(670)]: {
      width: 10,
      height: 18,
      marginTop: 12,
    },
    [theme.breakpoints.down(600)]: {
      width: 20,
      height: 28,
      marginTop: 12,
    },
  },
  buttonText: {
    color: "white",
    fontSize: 13,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    textTransform:"capitalize",
    [theme.breakpoints.down(1290)]: {
      fontSize: 12,
    },
    [theme.breakpoints.down(1150)]: {
      fontSize: 10.5,
    },
    [theme.breakpoints.down(960)]: {
      fontSize: 11,
    },
    [theme.breakpoints.down(670)]: {
      fontSize: 9.5,
      display:"none"
    },
  },
  buttonArrow: {
    color: "white",
    display:"none",
    [theme.breakpoints.down(670)]: {
        display:"block",
        paddingTop:8
    },
  },

}));

export default QuotesStyle;
