import React, { Fragment, useState } from "react";
import CardAutopaqueteStyle from "./CardAutopaqueteStyle";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import DetailGuideModal from "./DetailGuideModal";

const CardAutopaquete = (props) => {
  const {
    service,
    deliveryDay,
    total,
    isLiber = true,
    url,
    isSelected,
    indice,
    handleClick,
    company,
    extra_weight_cost,
    inter_zone_cost,
    guide_cost,
    textButton,
  } = props;
  const classes = CardAutopaqueteStyle();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={4}
        xl={3}
        className={classes.contentCard}
        key={indice}
      >
        <Box
          key={indice}
          className={clsx(
            classes.containerCard,
            isSelected
              ? classes.containerColorSelected
              : isLiber
              ? classes.containerColorLiber
              : classes.containerColorThird
          )}
        >
          <img src={url} alt="logo" className={classes.image} />

          <Box className={classes.containerBody}>
            <Box className={classes.containerTitle}>
              <Typography className={classes.title}>{service}</Typography>
            </Box>
            <Typography className={classes.deliveryText}>
              Entrega estimada
            </Typography>
            <Typography className={classes.dayText}>{deliveryDay}</Typography>
            <Typography className={classes.amount}>$ {total}.00</Typography>
            <Button
              className={classes.buttonDetails}
              onClick={() => setOpen(true)}
            >
              <Typography className={classes.textDetails}>
                Ver detalles...
              </Typography>
            </Button>
            <Button
              variant="contained"
              className={classes.button}
              disabled={isSelected}
              onClick={handleClick}
            >
              <Typography className={classes.buttonText}>
                {textButton}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Grid>
      <DetailGuideModal
        open={open}
        handleClose={handleClose}
        guideCost={inter_zone_cost + guide_cost}
        service={service}
        extraWeightCost={extra_weight_cost}
        shippingInsuranceCost={0}
        company={company}
        total={total}
        showShippingInsurance={true}
      />
    </Fragment>
  );
};

export default CardAutopaquete;
