import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";
import { MAIN_COLORS } from "../../../constants/theme/colors";

const PaymentOfGuidesStyle = makeStyles((theme) => ({
  title: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    fontSize: 20,
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 40,
    alignItems: "center",
    [theme.breakpoints.down(1300)]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down(600)]: {
      marginTop: 20,
    },
  },
  radioButtons: {
    marginTop: 30,
  },
  containerPrice: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 40,
    [theme.breakpoints.down(1300)]: {
      marginTop: 30,
    },
    [theme.breakpoints.down(1000)]: {
      marginTop: 25,
    },
  },
  containerButtons: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    marginRight: 40,
    [theme.breakpoints.down(450)]: {
      justifyContent: "space-between",
      marginRight: 20,
    },
  },
  text: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 16,
    marginRight: 40,
    textAlign: "left",
    display: "block",
    width: "100%",
    [theme.breakpoints.down(1300)]: {
      marginLeft: 40,
      fontSize: 15,
    },
    [theme.breakpoints.down(1000)]: {
      fontSize: 13.5,
    },
    [theme.breakpoints.down(750)]: {
      width: "90% !important",
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 12.5,
    },
  },
  radioButtonText: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 16,
    [theme.breakpoints.down(1300)]: {
      fontSize: 15,
    },
    [theme.breakpoints.down(1000)]: {
      fontSize: 13.5,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 12.5,
    },
  },
  textPrice: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 20,
    [theme.breakpoints.down(1300)]: {
      fontSize: 18,
    },
    [theme.breakpoints.down(1000)]: {
      fontSize: 16,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 14,
    },
  },
  price: {
    color: "#fff",
    fontSize: 20,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    padding: "8px 80px",
    background: "rgba(248, 130, 46, 1)",
    borderRadius: 10,
    marginTop: 10,
    [theme.breakpoints.down(1300)]: {
      padding: "6px 60px",
      fontSize: 18,
    },
    [theme.breakpoints.down(1000)]: {
      padding: "5px 50px",
      fontSize: 16,
    },
    [theme.breakpoints.down(600)]: {
      padding: "3px 40px",
      fontSize: 14.5,
    },
  },
  containerImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 40,
    marginTop: 30,
    marginBottom: 30,
    [theme.breakpoints.down(450)]: {
      marginRight: 20,
    },
  },
  img: {
    width: 300,
    height: 300,
  },
  imgPayCredits: {
    [theme.breakpoints.down(1300)]: {
      width: 500,
      height: 334,
    },
    [theme.breakpoints.down(1300)]: {
      width: 450,
      height: 284,
    },
    [theme.breakpoints.down(1000)]: {
      width: 350,
      height: 250,
    },
    [theme.breakpoints.down(600)]: {
      width: 270,
      height: 180,
    },
    [theme.breakpoints.down(450)]: {
      width: 220,
      height: 150,
    },
  },
  backButton: {
    height: 45,
    width: 195,
    backgroundColor: MAIN_COLORS.WHITE,
    // color: MAIN_COLORS.BLACK,
    borderRadius: 10,
    boxShadow: "none",
    border: "1px solid",
    borderColor: MAIN_COLORS.MAIN_RED_20_OPACITY,
    textTransform: "none",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 15,
    color: MAIN_COLORS.MAIN_RED,
    marginRight: 50,
    "&:hover": {
      backgroundColor: MAIN_COLORS.WHITE,
      boxShadow: "none",
      border: "1px solid",
      borderColor: MAIN_COLORS.MAIN_RED,
    },
    [theme.breakpoints.down("lg")]: {
      height: 40,
      width: 150,
      fontSize: 12,
    },
    [theme.breakpoints.down(859)]: {
      height: 35,
      width: 120,
      fontSize: 12,
    },
    [theme.breakpoints.down(450)]: {
      marginRight: 30,
      width: 100,
    },
  },
  payButton: {
    height: 45,
    width: 195,
    backgroundColor: MAIN_COLORS.MAIN_RED,
    borderRadius: 10,
    textTransform: "none",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 15,
    color: MAIN_COLORS.WHITE,
    [theme.breakpoints.down("lg")]: {
      height: 40,
      width: 150,
      fontSize: 12,
    },
    [theme.breakpoints.down(859)]: {
      height: 35,
      width: 120,
      fontSize: 12,
    },
    "&:hover": {
      backgroundColor: MAIN_COLORS.MAIN_RED_BLACK,
    },
    [theme.breakpoints.down(450)]: {
      width: 100,
    },
  },
  WTSPButton: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: 45,
    width: 195,
    backgroundColor: MAIN_COLORS.WHITE,
    borderRadius: 10,
    textTransform: "none",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.1)",
    fontSize: 15,
    color: MAIN_COLORS.BLACK,
    textDecoration: "none",
    [theme.breakpoints.down("lg")]: {
      height: 40,
      width: 150,
      fontSize: 12,
    },
    [theme.breakpoints.down(859)]: {
      height: 35,
      width: 120,
      fontSize: 12,
    },
    "&:hover": {
      backgroundColor: "#EAE5E5",
    },
  },
  titleModal: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 24,
    marginTop: 30,
  },
  textModal: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 18,
    color: "#5A5151",
    margin: "60px 0px",
  },
  containerButtonsModal: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  selectDirection: {
    width: 200,
    marginTop: 20,
    marginRight: 30,
    [theme.breakpoints.down(600)]: {
      width: 165,
    },
  },
  inputDescription: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 12,
    marginBottom: 5,
    marginLeft: 5,
    [theme.breakpoints.down(600)]: {
      fontSize: 10,
    },
  },
  smallTextField: {
    width: 180,
    marginTop: 20,
    [theme.breakpoints.down(600)]: {
      width: 125,
    },
  },
  styleSelect: {
    height: 30,
    fontSize: 14,
    "& .MuiSelect-root": {
      padding: 7,
      borderRadius: 10,
      [theme.breakpoints.down(600)]: {
        padding: 6,
      },
    },
    [theme.breakpoints.down(600)]: {
      height: 28,
      fontSize: 12.5,
    },
  },
  inputTextFile: {
    height: 10,
    [theme.breakpoints.down(600)]: {
      height: 8,
      fontSize: 12.5,
    },
  },
}));

export default PaymentOfGuidesStyle;
