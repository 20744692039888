import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../../constants/fontConsts";
import { MAIN_COLORS } from "../../../../constants/theme/colors";

const MainAddressModalStyle = makeStyles((theme) => ({
  content: {
    width: 810,
    height: 300,
    background: "white",
    borderRadius: 10,
    boxShadow: 24,
    paddingLeft: 20,
    paddingRight: 20,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(859)]: {
      width: "90%",
    },
    [theme.breakpoints.down(600)]: {
      height: 250,
    },
    [theme.breakpoints.down(500)]: {
      height: 230,
    },
  },
  containerText: {
    height: 230,
    marginTop: 20,
    [theme.breakpoints.down(600)]: {
      height: 180,
    },
    [theme.breakpoints.down(500)]: {
      marginTop: 15,
      height: 160,
    },
  },
  title: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 24,
    [theme.breakpoints.down(600)]: {
      fontSize: 20,
    },
    [theme.breakpoints.down(500)]: {
      fontSize: 18,
    },
  },
  text: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 18,
    color: "#5A5151",
    margin: "45px 0px",
    [theme.breakpoints.down(600)]: {
      fontSize: 16,
      marginTop: 30,
      marginBottom: 70,
    },
    [theme.breakpoints.down(500)]: {
      fontSize: 15,
    },
  },
  containerButtons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "end",
    height: 60,
    marginBottom: 20,
    [theme.breakpoints.down(600)]: {
      marginBottom: 10,
      height: 40,
    },
    [theme.breakpoints.down(500)]: {
      marginBottom: 15,
      height: 40,
    },
  },
  backButton: {
    height: 40,
    width: 130,
    backgroundColor: MAIN_COLORS.WHITE,
    borderRadius: 10,
    boxShadow: "none",
    border: "1px solid",
    borderColor: MAIN_COLORS.MAIN_RED_20_OPACITY,
    textTransform: "none",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 15,
    color: MAIN_COLORS.MAIN_RED,
    marginRight: 35,
    "&:hover": {
      backgroundColor: MAIN_COLORS.WHITE,
      boxShadow: "none",
      border: "1px solid",
      borderColor: MAIN_COLORS.MAIN_RED,
    },
    [theme.breakpoints.down(600)]: {
      height: 35,
      width: 120,
      fontSize: 12,
    },
    [theme.breakpoints.down(500)]: {
      height: 33,
      width: 110,
      fontSize: 11.5,
    },
  },
  continue: {
    height: 40,
    width: 130,
    backgroundColor: MAIN_COLORS.MAIN_RED,
    borderRadius: 10,
    boxShadow: "none",
    textTransform: "none",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 15,
    color: MAIN_COLORS.WHITE,
    "&:hover": {
      opacity: 0.8,
      backgroundColor: MAIN_COLORS.MAIN_RED,
    },
    [theme.breakpoints.down(600)]: {
      height: 35,
      width: 120,
      fontSize: 12,
    },
    [theme.breakpoints.down(500)]: {
      height: 33,
      width: 110,
      fontSize: 11.5,
    },
  },
}));
export default MainAddressModalStyle;
