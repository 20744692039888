import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../../constants/fontConsts";
import { MAIN_COLORS } from "../../../../constants/theme/colors";

const DownloadQRModalStyles = makeStyles((theme) => ({
  content: {
    maxWidth: 687,
    maxHeight: 350,
    width: "90%",
    height: "90%",
    overflow: "auto",
    background: "white",
    borderRadius: 30,
    padding: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down(700)]: {
      maxHeight: 300,
      maxWidth: 500,
    },
  },
  title: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    fontSize: 22,
    marginBottom: 30,
    textAlign: "center",
    [theme.breakpoints.down(700)]: {
      fontSize: 18,
    },
    [theme.breakpoints.down(550)]: {
      fontSize: 16,
    },
  },
  img: {
    width: 120,
    height: 120,
    marginBottom: 30,
    [theme.breakpoints.down(700)]: {
      width: 90,
      height: 90,
    },
    [theme.breakpoints.down(550)]: {
      width: 80,
      height: 80,
    },
  },
  textButton: {
    fontSize: 15,
    color: MAIN_COLORS.WHITE,
    [theme.breakpoints.down(700)]: {
      fontSize: 13,
    },
    [theme.breakpoints.down(550)]: {
      fontSize: 12,
    },
  },
  button: {
    height: 40,
    width: 200,
    backgroundColor: "#D15353",
    borderRadius: 10,
    boxShadow: "none",
    border: "1px solid",
    borderColor: MAIN_COLORS.MAIN_RED_20_OPACITY,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#D15353",
      boxShadow: "none",
      opacity: 0.7,
    },
    [theme.breakpoints.down(700)]: {
      height: 35,
      width: 170,
    },
    [theme.breakpoints.down(550)]: {
      height: 33,
      width: 160,
    },
  },
}));
export default DownloadQRModalStyles;
