import { makeStyles } from "@material-ui/core";
import { MAIN_COLORS } from "../../../constants/theme/colors";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const MercadoPagoCheckoutStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  button: {
    width: "80%",
    height: 45,
    borderRadius: 10,
    backgroundColor: MAIN_COLORS.MAIN_RED_BLACK,
    color: MAIN_COLORS.WHITE,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    textTransform: "none",
    marginTop: 50,
    "&:hover": {
      backgroundColor: MAIN_COLORS.MAIN_RED,
    },
  },
}));
export default MercadoPagoCheckoutStyles;
