import React, { Fragment, useState } from "react";
import ModalComponent from "../../../../components/sharedComponents/ModalComponent";
import { Box, Button, Typography } from "@material-ui/core";
import PaymentSummaryModalStyles from "./PaymentSummaryModalStyles";
import imgMaseta from "../../../../assets/img/dashboard/img-maseta.svg";
import TrustedUserModal from "../TrustedUserModal";
import Loader from "../../../Loader";
import {
  selectUserInformation,
  setCredits,
} from "../../../../store/reducers/user/UserInformationSlice";
import { useSelector, useDispatch } from "react-redux";
import { generateQRCode } from "../../../../api/ordersApi";
import InsufficientCreditsModal from "../InsufficientCreditsModal";
import { notification } from "../../../../utils/notification";
import { v4 as uuidv4 } from "uuid";
import DownloadQR from "../DownloadQRModal";
import useLogout from "../../../../hooks/useLogout";

const PaymentSummaryModal = (props) => {
  const {
    handleClose,
    open,
    setOpen,
    selectedRows,
    setSelectedRows,
    setUpdateData,
    cost,
    originLocation,
  } = props;

  const classes = PaymentSummaryModalStyles();
  const [logout] = useLogout();

  const userInformation = useSelector(selectUserInformation);

  const dispatch = useDispatch();

  const [openInsufficientCredits, setOpenInsufficientCredits] = useState(false);
  const [openTrustedUser, setOpenTrustedUser] = useState(false);
  const [openDownloadQR, setOpenDownloadQR] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [urlQR, setUrlQR] = useState("");

  const handleCloseInsufficientCredits = () => {
    setOpenInsufficientCredits(false);
  };

  const handleOpenTrustedUser = () => {
    setOpen(false);
    setOpenTrustedUser(true);
  };

  const handleCloseTrustedUser = () => {
    setOpenTrustedUser(false);
  };

  const handleCloseDownloadQR = () => {
    setOpenDownloadQR(false);
  };

  const handleNormalUser = () => {
    setOpen(false);
    handleCreateGuides("pagada");
  };

  const handleCreateGuides = (value) => {
    setShowLoader(true);

    const body = {
      batch: {
        list_id_guides: selectedRows,
        total_cost_batch: cost,
        payment_status: value,
        delivery_option: "domicilio",
        id_location_origin: originLocation,
      },
    };

    generateQRCode(body)
      .then((res) => {
        if (res.data.status_Code === 201) {
          dispatch(setCredits(res.data.new_total_credits));
          setUrlQR(res.data.url_pdf_batch);
          setSelectedRows([]);
          setShowLoader(false);
          setUpdateData(uuidv4());
          setOpenDownloadQR(true);
        } else if (res.data.status_Code === 401) {
          setShowLoader(false);
          notification("La sesión ha caducado");
          logout();
        } else if (
          res.data.status_Code === 200 &&
          res.data.status_Message === "insufficient credits"
        ) {
          setShowLoader(false);
          setOpenInsufficientCredits(true);
        } else {
          setShowLoader(false);
          notification("Ocurrió un error al generar el código de recolección");
        }
      })
      .catch((err) => {
        setShowLoader(false);
        notification("Ocurrió un error al generar el código de recolección");
      });
  };

  const body = (
    <Box className={classes.content}>
      <Box className={classes.containerHeader}>
        <Typography className={classes.textHeader}>
          Guías seleccionadas
        </Typography>
        <img src={imgMaseta} className={classes.image} alt="imagenMaseta" />
      </Box>
      <Box className={classes.containerBody}>
        <Typography className={classes.textTitle}>
          Guías seleccionadas
        </Typography>
        <Typography className={classes.textNumber}>
          {selectedRows.length} guías
        </Typography>
        <Typography className={classes.textTitle}>Créditos</Typography>
        <Typography className={classes.textNumber}>
          ${" "}
          {userInformation.credits
            ? userInformation.credits.toLocaleString("en")
            : 0}{" "}
          MXN
        </Typography>
        <Typography
          className={`${classes.textTitle} ${classes.marginTextTotal}`}
        >
          Costo de recolección
        </Typography>
        <Typography className={classes.textTotalAmount}>
          $ {cost} MXN
        </Typography>
      </Box>
      <Box className={classes.containerFooter}>
        {userInformation.trust_business && cost > 0 ? (
          <Button
            variant="outlined"
            className={classes.buttonPay}
            onClick={handleOpenTrustedUser}
          >
            <Typography className={classes.textButtonPay}>Continuar</Typography>
          </Button>
        ) : (
          <Button
            variant="outlined"
            className={classes.buttonPay}
            onClick={handleNormalUser}
          >
            <Typography className={classes.textButtonPay}>Continuar</Typography>
          </Button>
        )}
      </Box>
    </Box>
  );

  return (
    <Fragment>
      <Loader
        showLoader={showLoader}
        text="Generando código de recolección..."
      />

      <ModalComponent open={open} handleClose={handleClose} body={body} />
      <TrustedUserModal
        open={openTrustedUser}
        handleClose={handleCloseTrustedUser}
        handleCreateGuides={handleCreateGuides}
      />
      <InsufficientCreditsModal
        open={openInsufficientCredits}
        handleClose={handleCloseInsufficientCredits}
      />

      <DownloadQR
        open={openDownloadQR}
        handleClose={handleCloseDownloadQR}
        urlQR={urlQR}
      />
    </Fragment>
  );
};

export default PaymentSummaryModal;
