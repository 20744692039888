import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const ChangeStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    background:
      "linear-gradient(128.31deg, #FFEDD5 13.79%, #FFFFFF 42.87%, #FFFFFF 58.53%, #DBE1F9 86.33%)",
    width: "100%",
    height: "100vh",
    paddingTop: 60,
    "@media (max-height: 750px)": {
      paddingTop: 0,
    },
  },
  title: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 110,
    color: "rgba(0, 0, 0, 0.8)",
    "@media (max-height: 750px)": {
      fontSize: 80,
    },
  },
  text: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 20,
    color: "rgba(0, 0, 0, 0.5)",
    "@media (max-height: 750px)": {
      fontSize: 18,
    },
  },

  botonInicio: {
    position: "relative",
    top: -60,
    color: "rgba(3, 3, 3, 0.45)",
    "@media (max-height: 750px)": {
      top: 0,
    },
  },
  imgChange: {
    width: 350,
    height: 300,
    "@media (max-height: 750px)": {
      width: 280,
      height: 230,
    },
  },
  button: {
    width: 200,
    height: 50,
    background: "#F8822E !important",
    borderRadius: "10px !important",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25) !important",
    color: "#fff !important",
    margin: "30px 50px !important",
    "&:hover": {
      opacity: "0.6 !important",
    },
  },
  icon: {},
}));

export default ChangeStyle;
