import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectUserPassOnboardingProcess } from "../../store/reducers/user/UserInformationSlice";
import ProfileInformationModal from "../perfil/InfoPerfil/ProfileInformationModal";
import ModalContainer from "../sharedComponents/ModalContainer";

const OnBoardingProcessModal = () => {
  const userPassOnboardingProcess = useSelector(selectUserPassOnboardingProcess);
  const [open, setOpen] = useState(!userPassOnboardingProcess);
  const handleClose = () => setOpen(false);

  return (
    <ModalContainer
      open={open}
      body={<ProfileInformationModal handleClose={handleClose} />}
    />
  );
};

export default OnBoardingProcessModal;
