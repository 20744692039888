import React from "react";
import AppRouter from "./router/AppRouter";
import { Toaster } from "react-hot-toast";
import UnsavedChangesProvider from "./context/UnsavedChangesProvider";

export default function App() {
  return (
    <>
      <UnsavedChangesProvider>
        <AppRouter />
      </UnsavedChangesProvider>
      <Toaster />
    </>
  );
}
