import React, { Fragment } from "react";
import ModalComponent from "../../../../../components/sharedComponents/ModalComponent";
import { Box, Button, Typography } from "@material-ui/core";
import ErrorsModalStyles from "./ErrorsModalStyles";

const ErrorsModal = (props) => {
  const { open, handleClose, message } = props;

  const classes = ErrorsModalStyles();

  const body = (
    <Box className={classes.content}>
      <Box className={classes.contentText}>
        <Typography className={classes.title}>Nota:</Typography>
        <Typography className={classes.text}>{message}</Typography>
      </Box>
      <Box className={classes.contentButton}>
        <Button
          variant="contained"
          className={classes.button}
          onClick={handleClose}
        >
          <Typography className={classes.textButton}>Cerrar</Typography>
        </Button>
      </Box>
    </Box>
  );

  return (
    <Fragment>
      <ModalComponent
        open={open}
        handleClose={handleClose}
        body={body}
        showButton={false}
      />
    </Fragment>
  );
};

export default ErrorsModal;
