import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS, POPPINS_FONTS } from "../../../constants/fontConsts";

const TargetFlipStyle = makeStyles((theme) => ({
  card: {
    position: "relative",
    width: 230,
    height: 350,
    "&:hover $front": {
      transform: "perspective(600px) rotateY(180deg)",
    },
    "&:hover $back": {
      transform: "perspective(600px) rotateY(360deg)",
    },
    [theme.breakpoints.down("md")]: {
      width: 200,
      height: 250,
      marginTop:20
    },
  },
  face: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backfaceVisibility: "hidden",
    borderRadius: 10,
    overflow: "hidden",
    transition: ".5s",
  },
  front: {
    transform: "perspective(600px) rotateY(0deg)",
    boxShadow: "0 5px 10px #000",
  },
  imagen: {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  textFront: {
    position: "absolute",
    color: "#fff",
    textAlign: "start",
    fontFamily: POPPINS_FONTS.POPPINS_BOLD,
    fontSize: 20,
    padding: 10,
    paddingRight: 40,
    whiteSpace: "pre-line",
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
      paddingRight: 40,
    },
  },
  back: {
    transform: "perspective(600px) rotateY(180deg)",
    background: "#D15252",
    padding: "15px",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    boxShadow: "0 5px 10px #000",
  },
  textBack: {
    fontFamily: MONTSERRAT_FONTS,
    fontSize: 20,
  },
}));

export default TargetFlipStyle;
