import React from 'react'
import OverlayImagesStyle from './OverlayImage.style'

const OverlayImage = (props) => {
const classes = OverlayImagesStyle();
  return (
    <div className={classes.containerImages}>
        <img src={props.background} className={classes.fondo} alt="BackGroudn" />
        <img src={props.image} className={classes.image} alt="segunda imagen" />
    </div>
  )
}

export default OverlayImage;