import React, { useEffect, useState } from "react";
import { generateHMACSHA256Base64 } from "../../../utils/checkout";
import { formatDate } from "../../../utils/time";
import { Box, Button, TextField } from "@material-ui/core";
import CheckoutStyles from "./checkoutStyles";
import ModalComponent from "../../sharedComponents/ModalComponent";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  resetPaymentDetails,
  setCommission,
  setCreditsAmount,
  setPaymentMethod,
  setSubtotal,
  setTotal,
} from "../../../store/reducers/paymentDetail/paymentDetailsSlice";
import { setLoadingInfo } from "../../../store/reducers/Loading/LoadingSlice";
const { REACT_APP_BASE_URL } = process.env;

const FiservCheckoutForm = (props) => {
  const { amount, closeOptionsModal, paymentDetails } = props;

  const classes = CheckoutStyles();
  const [hash, setHash] = useState(null);
  const [today, setToday] = useState(null);
  const [openIframeModal, setOpenIframeModal] = useState(false);
  const uriIframe = `${REACT_APP_BASE_URL}creacionGuias`;
  const dispatch = useDispatch();

  const urlSuccess =
    "https://7lztc3m8r4.execute-api.us-east-1.amazonaws.com/prod/payments/fiserv/payment-success";
  const UrlFailed =
    "https://7lztc3m8r4.execute-api.us-east-1.amazonaws.com/prod/payments/fiserv/payment-success";

  useEffect(() => {
    const stringForExtendedHash = `${amount}|combinedpage|484|HMACSHA256|${uriIframe}|${urlSuccess}|${urlSuccess}|62990041|America/Mexico_City|${today}|sale`;
    const secretKey = "D?h6tx3\\qa";
    const hashExtended = generateHMACSHA256Base64(
      stringForExtendedHash,
      secretKey
    );
    setHash(hashExtended);
    setToday(formatDate());
  }, [today, amount]);

  useEffect(() => {
    dispatch(setTotal(paymentDetails.total));
    dispatch(setCommission(parseFloat(paymentDetails.comission)));
    dispatch(setSubtotal(paymentDetails.totalWithComission));
    dispatch(setCreditsAmount(parseInt(paymentDetails.creditsAmount)));
    dispatch(setPaymentMethod("Tarjeta"));

    return () => {
      dispatch(resetPaymentDetails());
      dispatch(setLoadingInfo(false));
    };
  }, [paymentDetails]);

  const navigate = useNavigate();
  useEffect(() => {
    const handleMessage = (event) => {
      if (
        event.origin !==
        "https://7lztc3m8r4.execute-api.us-east-1.amazonaws.com"
      )
        return;

      const url = new URL(event.data);
      const status = url.searchParams.get("status");
      // console.log({
      //   paymentId,
      //   status,
      //   paymentVerification,
      //   paymentProcess,
      //   url,
      //   pathname,
      // });

      if (status === "approved") {
        setOpenIframeModal(false);
        navigate(`${url.pathname}${url.search}`, { replace: true });
        closeOptionsModal();
      }
      if (status === "failed") {
        setOpenIframeModal(false);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const IframeBody = (
    <Box
      style={{
        backgroundColor: "#fff",
        height: 800,
        width: 800,
        borderRadius: 10,
        borderColor: "none",
      }}
    >
      <iframe title="fiserv-checkout" name="myFrame" style={{ height: "100%", width: "100%" }}></iframe>
    </Box>
  );

  return (
    <>
      <form
        method="POST"
        target="myFrame"
        action="https://test.ipg-online.com/connect/gateway/processing"
        className={classes.form}
      >
        <TextField
          type="hidden"
          name="chargetotal"
          value={amount}
          className={classes.hiddenInput}
        />
        <TextField
          type="hidden"
          name="checkoutoption"
          value="combinedpage"
          className={classes.hiddenInput}
        />
        <TextField
          type="hidden"
          name="currency"
          value="484"
          className={classes.hiddenInput}
        />
        <TextField
          type="hidden"
          name="hash_algorithm"
          value="HMACSHA256"
          className={classes.hiddenInput}
        />
        <TextField
          type="hidden"
          name="hashExtended"
          value={hash || ""}
          className={classes.hiddenInput}
        />
        <TextField
          type="hidden"
          name="responseFailURL"
          value={urlSuccess}
          className={classes.hiddenInput}
        />
        <TextField
          type="hidden"
          name="responseSuccessURL"
          value={UrlFailed}
          className={classes.hiddenInput}
        />
        <TextField
          type="hidden"
          name="storename"
          value="62990041"
          className={classes.hiddenInput}
        />
        <TextField
          type="hidden"
          name="timezone"
          value="America/Mexico_City"
          className={classes.hiddenInput}
        />
        <TextField type="hidden" name="parentUri" value={uriIframe} />
        <TextField
          type="hidden"
          name="txndatetime"
          value={today}
          className={classes.hiddenInput}
        />
        <TextField
          type="hidden"
          name="txntype"
          value="sale"
          className={classes.hiddenInput}
        />
        <Box className={classes.buttonContainer}>
          <Button
            disabled={!amount}
            type="submit"
            variant="contained"
            className={classes.button}
            onClick={() => setOpenIframeModal(true)}
          >
            Agregar Créditos
          </Button>
        </Box>
      </form>
      <ModalComponent
        open={openIframeModal}
        handleClose={() => setOpenIframeModal(false)}
        body={IframeBody}
        showButton={false}
      />
    </>
  );
};

export default FiservCheckoutForm;

//2024:06:04-13:44:41
//5579220000000012
