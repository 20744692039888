import { Box, Typography } from '@material-ui/core'
import React from 'react'
import imgPeso from '../../../assets/img/dashboard/shipmentsInformation/img-peso.svg'
import imgMedidas from '../../../assets/img/dashboard/shipmentsInformation/img-medidas.svg'
import imgFecha from '../../../assets/img/dashboard/shipmentsInformation/img-calendario.svg'
import imgNombre from '../../../assets/img/dashboard/shipmentsInformation/img-nombre.svg'
import imgTelefono from '../../../assets/img/dashboard/shipmentsInformation/img-telefono.svg'
import imgDireccion from '../../../assets/img/dashboard/shipmentsInformation/img-localizacion.svg'
import imgReferencia from '../../../assets/img/dashboard/shipmentsInformation/img-referencia.svg'
import InformationStyle from './InformationStyle'



const Information = () => {

const classes = InformationStyle();
  return (
    <Box>
        <Typography className={classes.title}>Informacion del envió</Typography>
        <Box>
            <Typography className={classes.subTitle}>Detalles del paquete</Typography>
            <Box className={classes.containerImage}>
                <img src={imgPeso} alt="imagen de vascula" className={classes.imagen}/>
                <Typography className={classes.description} >Peso: {}</Typography>
            </Box>
            <Box className={classes.containerImage}>
                <img src={imgMedidas} alt=" imagen de regla" className={classes.imagen}/>
                <Typography className={classes.description} >Medidas: {}</Typography>
            </Box>
            <Box className={classes.containerImage}>
                <img src={imgFecha} alt=" imagen de calendario" className={classes.imagen}/>
                <Typography className={classes.description} >Fecha de entrega programada: {}</Typography>
            </Box>
        </Box>
        <Box>
            <Typography className={classes.subTitle}>Destinatario</Typography>
            <Box className={classes.containerImage}>
                <img src={imgNombre} alt="imagen de credencial" className={classes.imagen}/>
                <Typography className={classes.description} >Nombre: {}</Typography>
            </Box>
            <Box className={classes.containerImage}>
                <img src={imgTelefono} alt=" imagen de teléfono" className={classes.imagen}/>
                <Typography className={classes.description} >Teléfono: {}</Typography>
            </Box>
            <Box className={classes.containerImage}>
                <img src={imgDireccion} alt=" imagen de ubicaciónn" className={classes.imagen}/>
                <Typography className={classes.description} >Direccion: {}</Typography>
            </Box>
            <Box className={classes.containerImage}>
                <img src={imgReferencia} alt="imagen de ubicación" className={classes.imagen}/>
                <Typography className={classes.description} >Referencias: {}</Typography>
            </Box>
        </Box>
    </Box>
  )
}

export default Information