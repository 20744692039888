import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";
import { MAIN_COLORS } from "../../../constants/theme/colors";

const FormBottomButtonStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingRight: 30,
    marginTop: 30,
    [theme.breakpoints.down(859)]: {
      paddingRight: 10,
    },
  },
  buttonsContainer: {
    float: "right",
  },
  backButton: {
    height: 45,
    width: 195,
    backgroundColor: MAIN_COLORS.WHITE,
    // color: MAIN_COLORS.BLACK,
    borderRadius: 10,
    boxShadow: "none",
    border: "1px solid",
    borderColor: MAIN_COLORS.MAIN_RED_20_OPACITY,
    textTransform: "none",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 15,
    color: MAIN_COLORS.MAIN_RED,
    marginRight: 50,
    "&:hover": {
      backgroundColor: MAIN_COLORS.WHITE,
      boxShadow: "none",
      border: "1px solid",
      borderColor: MAIN_COLORS.MAIN_RED,
    },
    [theme.breakpoints.down("lg")]: {
      height: 40,
      width: 150,
      fontSize: 12,
    },
    [theme.breakpoints.down(859)]: {
      height: 35,
      width: 120,
      fontSize: 12,
    },
    [theme.breakpoints.down(450)]: {
      marginRight: "20px !important",
    },
    [theme.breakpoints.down(400)]: {
      width: "100px !important",
    },
  },
  nextButton: {
    height: 45,
    width: 195,
    backgroundColor: MAIN_COLORS.MAIN_RED,
    borderRadius: 10,
    textTransform: "none",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 15,
    color: MAIN_COLORS.WHITE,
    [theme.breakpoints.down("lg")]: {
      height: 40,
      width: 150,
      fontSize: 12,
    },
    [theme.breakpoints.down(859)]: {
      height: 35,
      width: 120,
      fontSize: 12,
    },
    [theme.breakpoints.down(400)]: {
      width: "100px !important",
      marginRight: "20px !important",
    },
    "&:hover": {
      backgroundColor: MAIN_COLORS.MAIN_RED_BLACK,
    },
  },
}));
export default FormBottomButtonStyles;
