import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import logoLiber from "../../assets/img/LogoNavbar.svg";
import FooterStyles from "./FooterStyles";
import { linksPlataform, officialDocs, socialMedia } from "./data";

const Footer = () => {
  const classes = FooterStyles();

  return (
    <Box className={classes.mainContainer}>
      <Grid container className={classes.contentContainer}>
        <Grid item xs={12} sm={2} md={2} className={classes.footerLogo}>
          <img src={logoLiber} alt="logoLiber" className={classes.logoLiber} />
        </Grid>
        <Grid item xs={6} sm={4} md={2} className={classes.centerContent}>
          <Box>
            <Typography className={classes.footerHeader}>Links</Typography>
            {linksPlataform.map((link, index) => (
              <Typography key={index} className={classes.sectionItems}>
                <a href={link.url} className={classes.a}>
                  {link.text}
                </a>
              </Typography>
            ))}
          </Box>
        </Grid>
        <Grid item xs={6} sm={4} md={3} className={classes.centerContent}>
          <Box>
            <Typography className={classes.footerHeader}>
              Secciones e Informacion
            </Typography>
            {officialDocs.map((doc, index) => (
              <Typography key={index} className={classes.sectionItems}>
                <a
                  href={doc.url}
                  target="_blank"
                  download="Ejemplo cuestionario Guia3"
                  rel="noreferrer"
                  className={classes.a}
                >
                  {doc.text}
                </a>
              </Typography>
            ))}
          </Box>
        </Grid>
        <Grid item xs={6} sm={4} md={2} className={classes.followUsContainer}>
          <Typography align="center" className={classes.footerHeader}>
            Siguenos
          </Typography>
          <Box className={classes.socialMediaIconsContainer}>
            {socialMedia.map((s, index) => (
              <a key={index} href={s.url} target="_blank" rel="noreferrer">
                <img
                  src={s.icon}
                  alt="soc-med"
                  className={classes.socialMediaIcons}
                />
              </a>
            ))}
          </Box>
        </Grid>
        <Grid item xs={6} sm={4} md={1}></Grid>
        <Grid item xs={12} sm={4} md={2} className={classes.addressContainer}>
          <Typography className={classes.footerHeaderContact}>
            Contacto
          </Typography>
          <Box>
            <Typography className={classes.addressInfo}>
              C. Horacio Gaytán 1290 Paseos del Sol, 45070 Zapopan, Jal.
            </Typography>
            <Typography className={classes.addressInfo}>
              Teléfono <br />
              3325481250
            </Typography>
            <Typography className={classes.addressInfo}>
              contacto@gmail.com
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
