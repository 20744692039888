import axios from "axios";
import { getCookie } from "../utils/sessionCookie";
const { REACT_APP_LIBER_API_URL } = process.env;

export const getCredits = () => {
  const request = {
    method: "POST",
    url: `${REACT_APP_LIBER_API_URL}/business/get-credits-history`,
    headers: {
      "content-type": "application/json",
      auth_Token: getCookie("accessToken"),
    },
  };

  return axios(request);
};
