import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../constants/routesConstants";
import { getCookie, removeCookie } from "../../utils/sessionCookie";
import { useDispatch } from "react-redux";
import {
  resetBusinessInformation,
  resetBusinessLocationsInformation,
} from "../../store/reducers/business/BusinessInformationSlice";
import {
  resetUserInformation,
  setUserIsAuth,
} from "../../store/reducers/user/UserInformationSlice";

const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch();

  const accessToken = getCookie("accessToken");

  const handleLogout = () => {
    removeCookie("role");
    removeCookie("accessToken");
    dispatch(resetBusinessInformation());
    dispatch(resetBusinessLocationsInformation());
    dispatch(resetUserInformation());
    dispatch(setUserIsAuth(false));
  };
  // const isOnboardingCompleted = useSelector(selectUserPassOnboardingProcess);

  if (!accessToken) {
    handleLogout();
    return <Navigate to={ROUTES_CONSTANTS.INICIAR_SESION} />;
  }

  //TODO Hacer validar si el proceso de onboarding ya fue pasado,
  // de lo contrario, regresarlo a la pagina iniciao "creacionDeGuias"

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
