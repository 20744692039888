import { makeStyles } from "@material-ui/core";
import { POPPINS_FONTS } from "../../../../constants/fontConsts";

const TitleAndDescriptionStyle = makeStyles((theme) => ({
    
    containerText:{
        display:"flex",
        alignItems:"start",
        justifyContent:"center",
        flexDirection:"column",
        padding:"60px 230px",
        color:"#fff",
        [theme.breakpoints.down(1111,"lg")]:{
            padding:"30px 50px",
        },
        
    },        
    titleText:{
        textAlign:"start",
        fontFamily: POPPINS_FONTS.POPPINS_BOLD,
        fontSize:40,
        [theme.breakpoints.down(1111,"lg")]:{
            fontSize:20,
        },
    },
   
}))
export default TitleAndDescriptionStyle;