import { makeStyles } from "@material-ui/core";

const ViewPdfModalStyle = makeStyles((theme) => ({
  container: {
    height: "80%",
    width: "80%",
    visibility: "visible",
    [theme.breakpoints.down(600)]: {
      height: "60%",
    },
  },
}));

export default ViewPdfModalStyle;
