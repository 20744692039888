import axios from "axios";
const { REACT_APP_LIBER_API_URL } = process.env;

export const loginUser = (payload) => {
  const request = {
    method: "POST",
    data: payload,
    body: JSON.stringify(payload),
    url: `${REACT_APP_LIBER_API_URL}/auth/login`,
    headers: {
      "content-type": "application/json",
    },
  };

  return axios(request);
};
