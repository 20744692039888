import { Box, Grid } from "@material-ui/core";
import React from "react";
import DrawerDash from "../../components/DrawerDash";
import ImageLink from "../../components/perfil/ImageLink";
import InfoPerfil from "../../components/perfil/InfoPerfil";
import PerfilStyle from "../Perfil/PerfilStyle";
const Perfil = () => {
  const classes = PerfilStyle();
  return (
    <div>
      <DrawerDash>
        <Box className={classes.container}></Box>
        <Grid container className={classes.content}>
          <Grid item xl={4} lg={4} md={12} sm={12} className={classes.content}>
            <ImageLink />
          </Grid>
          <Grid item xl={8} lg={8} md={12} sm={12}>
            <InfoPerfil />
          </Grid>
        </Grid>
      </DrawerDash>
    </div>
  );
};

export default Perfil;
