export const overrides = {
  MuiOutlinedInput: {
    root: {
      "&$focused $notchedOutline": {
        borderColor: `rgba(0, 0, 0, 0.23)`,
        borderWidth: 1,
      },
    },
    notchedOutline: {
      borderRadius: 10,
    },
  },
  MuiTextField: {
    root: {
      "&$focused $notchedOutline": {
        borderColor: `rgba(0, 0, 0, 0.23)`,
        borderWidth: 1,
      },
    },
    notchedOutline: {
      borderRadius: 10,
    },
  },
};
