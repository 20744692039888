import axios from "axios";
import { getCookie } from "../utils/sessionCookie";
const { REACT_APP_LIBER_API_URL } = process.env;

export const AddCreditsToAccount = (payload) => {
  const request = {
    method: "POST",
    url: `${REACT_APP_LIBER_API_URL}/business/add-credits`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
      auth_token: getCookie("accessToken"),
    },
  };

  return axios(request);
};

// {
//     "payment_method": "Mercado Pago",
//     "credits_amount": 35,
//     "order_id": "XYZ123",
//     "total": 45,
//     "subtotal": 35,
//     "commission": 10,
//     "payment_status": "approved",
//     "payment_verification": true
// }
