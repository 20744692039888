import { Box, Grid, Typography } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import FeeChooseStyles from "./FeeChooseStyles";
import FormBottomButtons from "../FormBottomButtons";
import CardAutopaquete from "../../../components/sharedComponents/CardAutopaquete";
import { getRates, validateLiber } from "../../../api/guidesFormApi";
import { notification } from "../../../utils/notification";
import { Skeleton } from "@material-ui/lab";
import NotRows from "../../../assets/img/orders/no-results.png";

const FeeChoose = (props) => {
  const {
    handleClickBackStep,
    handleClickNextStep,
    selectedLocation,
    recipient_info,
    setFeeChoose,
    setRates,
    rates,
    setSelected,
    selected,
    postalCodeSelected,
    setPostalCodeSelected,
    setToCollect,
    setAmountReceivable,
    setAmountCorrect,
    packageInformation,
    setPostalCodeOriginSelected,
    postalCodeOriginSelected,
    setPackageSelected,
    packageSelected,
    verifyLiber,
  } = props;
  const classes = FeeChooseStyles();

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    async function getAllRates() {
      if (
        postalCodeSelected !== recipient_info.postal_code ||
        postalCodeOriginSelected !== selectedLocation.postal_code ||
        packageSelected.long_product !== packageInformation.long_product ||
        packageSelected.broad_product !== packageInformation.broad_product ||
        packageSelected.high_product !== packageInformation.high_product ||
        packageSelected.weigth_product !== packageInformation.weigth_product
      ) {
        setLoader(true);
        setSelected("");

        let extra_weight = 0,
          extra_weight_cost = 0,
          inter_zone_cost = 0,
          is_option_express = false,
          is_option_liber = false;

        if (verifyLiber) {
          let payloadValidatedLiber = {
            postal_code_origin: selectedLocation.postal_code,
            postal_code_destiny: recipient_info.postal_code,
            package_weight:
              packageInformation.weigth_product >
              packageInformation.volumetric_weigth
                ? packageInformation.weigth_product
                : packageInformation.volumetric_weigth,
          };

          await validateLiber(payloadValidatedLiber)
            .then((res) => {
              if (
                res.data.status_Code === 200 &&
                res.data.status_Message === "reachable postal codes"
              ) {
                extra_weight = res.data.extra_weight;
                extra_weight_cost = res.data.extra_weight_cost;
                inter_zone_cost = res.data.inter_zone_cost;
                is_option_express = res.data.is_option_express;
                is_option_liber = res.data.is_option_liber;
              } else {
                extra_weight = 0;
                extra_weight_cost = 0;
                inter_zone_cost = 0;
                is_option_express = false;
                is_option_liber = false;
              }
            })
            .catch((err) => {
              extra_weight = 0;
              extra_weight_cost = 0;
              inter_zone_cost = 0;
              is_option_express = false;
              is_option_liber = false;
            });
        }

        let payload = {
          postal_code_origin: selectedLocation.postal_code,
          postal_code_destiny: recipient_info.postal_code,
          is_option_liber: is_option_liber,
          is_option_express: is_option_express,
          inter_zone_cost: inter_zone_cost,
          extra_weight: extra_weight,
          extra_weight_cost: extra_weight_cost,
          info_package: {
            package_height: packageInformation.high_product,
            package_width: packageInformation.broad_product,
            package_depth: packageInformation.long_product,
            package_weight: packageInformation.weigth_product,
            package_value: 0,
          },
        };

        getRates(payload)
          .then((res) => {
            if (
              res.data.status_Code === 200 &&
              res.data.status_Message === "there are rates"
            ) {
              setRates(res.data.rates_list);
              setFeeChoose((prevState) => ({
                ...prevState,
                idPostalCode: res.data.id_postal_code_destiny,
              }));
            } else {
              setRates([]);
            }
            setPostalCodeSelected(recipient_info.postal_code);
            setPostalCodeOriginSelected(selectedLocation.postal_code);
            setPackageSelected({
              long_product: packageInformation.long_product,
              broad_product: packageInformation.broad_product,
              high_product: packageInformation.high_product,
              weigth_product: packageInformation.weigth_product,
            });
          })
          .catch((err) => {
            notification("Ocurrió un error al recuperar las opciones de envío");
            setRates([]);
            setPostalCodeSelected("");
          })
          .finally(() => {
            setLoader(false);
          });
      }
    }

    getAllRates();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (value) => {
    const {
      shipping_code,
      shipping_services,
      subtotal_rate,
      total_rate,
      shipping_company,
      extra_weight,
      extra_weight_cost,
      inter_zone_cost,
      guide_cost,
    } = value;

    setSelected(shipping_code);

    setFeeChoose((prevState) => ({
      ...prevState,
      tipo_envio:
        shipping_company === "LIBER"
          ? shipping_services.toLowerCase()
          : shipping_code,
      subtotal: subtotal_rate,
      total: total_rate,
      company: shipping_company,
      extra_weight: extra_weight,
      extra_weight_cost: extra_weight_cost,
      inter_zone_cost: inter_zone_cost,
      guide_cost: guide_cost,
    }));

    setToCollect("NONE");
    setAmountReceivable(0);
    setAmountCorrect(false);
  };

  return (
    <Fragment>
      {!loader && (
        <Box className={classes.contentButtons}>
          <FormBottomButtons
            disableNext={selected !== "" ? false : true}
            handleNext={rates.length > 0 ? handleClickNextStep : undefined}
            handleBack={handleClickBackStep}
          />
        </Box>
      )}
      <Box>
        {loader ? (
          <Skeleton animation="wave" className={classes.skeleton} />
        ) : rates.length > 0 ? (
          <Fragment>
            <Typography className={classes.headerTitle}>
              Selecciona la paquetería para el envío de tu paquete{" "}
            </Typography>
            <Grid container className={classes.container}>
              {rates.map((value, indice) => {
                return (
                  <CardAutopaquete
                    key={indice}
                    indice={indice}
                    company={value.shipping_company}
                    service={value.shipping_code
                      .substring(
                        value.shipping_company.length + 1,
                        value.shipping_code.length
                      )
                      .replaceAll("-", " ")
                      .replaceAll("_", " ")}
                    deliveryDay={value.delivery_days}
                    total={value.total_rate}
                    isLiber={value.shipping_company === "LIBER" ? true : false}
                    url={value.service_img}
                    isSelected={selected === value.shipping_code ? true : false}
                    handleClick={() => handleClick(value)}
                    extra_weight={value.extra_weight}
                    extra_weight_cost={value.extra_weight_cost}
                    inter_zone_cost={value.inter_zone_cost}
                    guide_cost={value.guide_cost}
                    textButton={"Seleccionar"}
                  />
                );
              })}
            </Grid>
          </Fragment>
        ) : (
          <Box className={classes.containerNoRecords}>
            <img
              src={NotRows}
              alt="Sin registros"
              className={classes.imageNoRecords}
            />
            <Typography className={classes.textNoRecords}>
              No se encontraron tarifas disponibles.
            </Typography>
          </Box>
        )}
      </Box>
    </Fragment>
  );
};

export default FeeChoose;
