import { makeStyles } from "@material-ui/core";
import {MONTSERRAT_FONTS, POPPINS_FONTS} from "../../constants/fontConsts"
const TrackingStyle = makeStyles((theme) =>({

    container:{
        paddingTop:140,
        background:"linear-gradient(10.79deg, #FFFFFF 2.97%, #EFF1FC 59.78%, #FFFFFF 97.12%)",
        height:"100%",
        paddingBottom:60
    },
    topSection:{
        display: "flex",
        alignItems:"center",
        justifyContent:"center",
        flexDirection:"column",
        paddingBottom:20
    },
  
    inputTracking:{
        backgroundColor: "white",
        borderRadius: "15px !important", 
        width:500,
        [theme.breakpoints.down("md")]:{
            maxWidth:400,
            marginBottom:10
        },
        [theme.breakpoints.down("sm")]:{
            maxWidth:350,
        },
        [theme.breakpoints.down("xs")]:{
            maxWidth:300,
        }
    },
    LefthSection:{
        display:"flex",
        flexDirection:"column",
        alignItems:"start",
        padding:"0px 60px",

    },
    title:{
        fontFamily: POPPINS_FONTS.POPPINS_BOLD,
        fontSize:40,
        marginBottom:50,
        textAlign:"center",
        [theme.breakpoints.down("md")]:{
            fontSize:30,
            marginBottom:20,
        },
    },
    RigthSection:{
        display:"flex",
        flexDirection:"column",
        alignItems:"start",
        justifyContent:"start",
        background: "#fff",
        padding:40,
        borderRadius: 20,
        height:450,
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)"
    },
    containerInputBoton:{
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down('md')]:{
            flexDirection:"column",
            alignItems:"center",
            justifyContent:"center"
        }
    },
    botonTracking:{
        backgroundColor: "#F8822E !important", 
        color: "#fff !important",
        fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
        fontSize: 20, 
        marginLeft:"20px !important",
        width:150,
        height:50,
        borderRadius:"10px !important",
        textTransform:"capitalize",
        [theme.breakpoints.down("md")]:{
            width:130,
            height:40,
            fontSize: 18,
        }
    },
    botonTrackingDisable:{
        backgroundColor: "rgba(0, 0, 0, 0.25) !important", 
        color: "#fff !important",
        fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
        fontSize: 20, 
        marginLeft:"20px !important",
        width:150,
        height:50,
        borderRadius:"10px !important",
        textTransform:"capitalize",
        [theme.breakpoints.down("md")]:{
            width:130,
            height:40,
            fontSize: 18,
        }
    },
    containerImage:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        flexDirection:"column",
    },
    imageTracking:{
        width:"100%",
        maxWidth:300,
    },
    imageStatusPackage:{
        width:"100%",
        maxWidth:300,
        [theme.breakpoints.down("sm")]:{
            width:250
        }
    },
    textIndication:{
        fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
        fontSize: 20,
        textAlign:"center",
        color:"rgba(0, 0, 0, 0.4)"
    },
    timelineArea:{
        width:400,
        borderLeft:"2px solid #000",
        padding:20,
        borderRadius:"2px 10px 10px 2px",
        background:"#F5F5FA",
        boxShadow:"0px 4px 20px 0px rgba(0, 0, 0, 0.25)",
        [theme.breakpoints.down("md")]:{
            width:350,
        },
        [theme.breakpoints.down("sm")]:{
            width:300,
        }
    },
    history:{
        backgroundColor: "#E8505B",
        padding:"10px 25px",
        fontSize:16,
        borderRadius:5,
        lineHeight: "1.7",
        position:"relative",
        height:"auto",
        marginBottom:10,
        '&::before': {
            content: '""',
            display:"block",
            width:"10px",
            height:"10px",
            borderRadius: "50%",
            backgroundColor:"#4F769B",
            border: "3px solid #fff",
            position: "absolute",
            top: "10px",
            left: "-30px"
        },
        '&::after': {
            content: '""',
            width: "0px",
            height: "0px",
            borderStyle:"solid",
            borderColor: "transparent #ddd transparent transparent",
            borderWidth: "8px",
            position: "absolute",
            left: "-17px",
            top: "10px"
        },
        [theme.breakpoints.down("md")]:{
            
        }
    },

    statusDate:{
        color:"#fff",
        fontSize:14,
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
        [theme.breakpoints.down("md")]:{
            fontSize:12,
        }
    },
    titleHistory:{
        textAlign:"center",
        fontSize:20,
        fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
        marginTop:30

    },
    // New History
    containerResponse:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        marginTop:50,
        [theme.breakpoints.down('md')]:{
            flexDirection:"column"
        }
    
    },
    containerHistory:{
        display:"flex",
        justifyContent:"space-around",
        width:"100%",
        maxWidth:775,
        background:"#fff",
        boxShadow:"0px 4px 24px 0px rgba(199, 201, 204, 0.20);",
        borderRadius:2,
        height:"auto",
        [theme.breakpoints.down('md')]:{
            width:"90%",
        },
        [theme.breakpoints.down(670)]:{
           flexDirection:"column",
           justifyContent:"center",
           alignItems:"center"
        }
      
    },
    containerInformation:{
        display:"flex",
        justifyContent:"start",
        alignItems:"start",
        flexDirection:"column",
        marginLeft:30,
        width:"50%",
        [theme.breakpoints.down("md")]:{
            justifyContent:"space-around",
            alignItems:"start",
            marginBottom:0,
        },
        [theme.breakpoints.down("sm")]:{
            width:"100%",
            marginLeft:100
        },
   
    },
    containerInformationHistory:{
        display:"flex",
        flexDirection:"column",
        width:"50%",
        marginLeft:30,
        marginTop:30,
        [theme.breakpoints.down("sm")]:{
            width:"100%",
            marginLeft:100
        },
    },
    timeline: {
        width: '100%',
        maxWidth: 600,
        padding: '0px 0px',
        position: 'relative',        
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 5,
          left: `calc(28% + 15px)`,
          bottom: 70,
          width: 4,
          background: '#DDCDCD',
          [theme.breakpoints.down('lg')]:{
            left: `calc(28% + 15px)`,
          },
      
        },
        '&:after': {
          content: '""',
          display: 'table',
          clear: 'both',
        },
      },
      entry: {
        clear: 'both',
        textAlign: 'left',
        position: 'relative',
      },
      titleTimeLine: {
        marginBottom: '0.5em',
        float: 'left',
        width: '33%',
        paddingRight: 30,
        textAlign: 'left',
        position: 'relative',
        '&:before': {
          content: '""',
          position: 'absolute',
          width: 8,
          height: 8,
          border: '4px solid rgba(223, 46, 56, 1)',
          backgroundColor: '#fff',
          borderRadius: '100%',
          top: '15%',
          right: -8,
          zIndex: 99,
          [theme.breakpoints.down(768)]:{
            right: -13,
            top: '13%',
          },
          [theme.breakpoints.down(670)]:{
            right: 5,
            top: '13%',
          },
          [theme.breakpoints.down(610)]:{
            right: 4,
            top: '13%',
          },
          [theme.breakpoints.down(590)]:{
            right: 1,
            top: '13%',
          },
          [theme.breakpoints.down(520)]:{
            right: 0,
            top: '13%',
          },
          [theme.breakpoints.down(490)]:{
            right: -3,
            top: '13%',
          },
          [theme.breakpoints.down(440)]:{
            right: -6,
            top: '13%',
          },
          [theme.breakpoints.down(380)]:{
            right: -7,
            top: '13%',
          },
          [theme.breakpoints.down(340)]:{
            right: -8,
            top: '13%',
          },
        }
      },
      body: {
        margin: '0 0 3em',
        float: 'right',
        width: '66%',
        paddingLeft: 30,
      },
      statusPackage:{
        color:"#000",
        fontSize:10,
        fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
        [theme.breakpoints.down("md")]:{
            fontSize:10
        },
        [theme.breakpoints.down("sm")]:{
            width:100
        }
    },
    dateHistory:{
        fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
        fontSize:10
    },
    hourHistory:{
        fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
        fontSize:7
    },
    descriptionStatusPackage:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
        fontSize:6,
        [theme.breakpoints.down("sm")]:{
            width:100
        }

    },
    hr:{
        border:"none",
        width:3,
        borderLeft:"2px solid #D4C6C6",
        height:450,
        marginTop:30,
        [theme.breakpoints.down("sm")]:{
            display:"none"
        }
    },
    textInfo:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_BOLD,
        fontSize:16,
        margin:"40px 0px 36px 0px"
    },
    textHistoryIndication:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
        fontSize:14,
        marginBottom:8
    },
    textHistoryStatus:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_BOLD,
        fontSize:24,
        marginBottom:30
    },
    textHistory:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
        fontSize:14,
        marginBottom:30
    }
}))

export default TrackingStyle;