import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const TargetFaqsStyle = makeStyles((theme) => ({
  container: {
    width: 350,
    maxHeight: 360,
    height: 340,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginBottom: 40,
    background: "rgba(255, 255, 255, 0.48)",
    borderRadius: 15,
  },
  pregunta: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 20,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  respuesta: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 15,
    textAlign: "justify",
    padding: "25px 15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
    },
  },
  image: {
    paddingTop: 20,
    marginTop: 20,
  },
}));

export default TargetFaqsStyle;
