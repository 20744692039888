import { Box, Link } from "@material-ui/core";
import React from "react";
import imgExcel from "../../../assets/img/dashboard/img-Excel.svg";
import DownloadExcelStyle from "./DownloadExcelStyle";

const DownloadExcel = () => {
  const classes = DownloadExcelStyle();

  return (
    <div className={classes.container}>
      <div className={classes.containerLinkErrors}>
        <Link
          href="https://assets-liber.s3.amazonaws.com/docs/Catalogo+de+errores.pdf"
          underline="hover"
          target="_blank"
          rel="noreferrer"
          className={classes.linkErrors}
        >
          Consultar catálogo de errores
        </Link>
      </div>
      <Box className={classes.box}>
        <Link
          className={classes.containerLink}
          href="https://assets-liber.s3.amazonaws.com/docs/Generacion+de+guias.xlsx"
          download="FormatoGuiasLiber"
        >
          <img
            src={imgExcel}
            className={classes.imagen}
            alt="imagen de excel"
          />
          <label className={classes.label}>Descargar</label>
        </Link>
      </Box>
    </div>
  );
};

export default DownloadExcel;
