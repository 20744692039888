import { Grid, Typography } from "@material-ui/core";
import React from "react";
import DrawerDash from "../../components/DrawerDash";
import MassDeliveryStyle from "./MassDeliveryStyle";
import TabsDelivery from "../../components/massDelivery/TabsDelivery";
import Timer from "../../components/massDelivery/Timer";

const MassDelivery = () => {
  const classes = MassDeliveryStyle();

  return (
    <DrawerDash>
      <Grid container className={classes.containerTitle}>
        <Grid item xs={12} sm={12} md={7}>
          <Typography className={classes.title}>
            Creación de guía masiva
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={5} className={classes.clock}>
          <Timer classes={classes} />
        </Grid>
      </Grid>
      <TabsDelivery />
    </DrawerDash>
  );
};

export default MassDelivery;
