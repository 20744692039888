import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const TabsDeliveryStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: "0px 1px 0px 0px rgba(0,0,0,0.2)",
    display: "flex",
    alignItems: "center",
    marginBottom: 25,
    height: 40,
    minHeight: 0,
    justifyContent: "space-around",
  },
  tabs: {
    width: "100%",
    height: 40,
    minHeight: 0,
  },
  tab: {
    height: 40,
    minHeight: 0,
    fontSize: 13,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px !important",
    },
    [theme.breakpoints.down(590)]: {
      fontSize: "11px !important",
    },
    [theme.breakpoints.down(480)]: {
      fontSize: "9.5px !important",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "8.5px !important",
    },
    "@media (max-height: 760px)": {
      fontSize: 12,
    },
  },
  img: {
    margin: 0,
    marginRight: 10,
    width: 25,
    [theme.breakpoints.down("sm")]: {
      width: 20,
    },
    [theme.breakpoints.down(590)]: {
      width: "17px !important",
      marginRight: 8,
    },
    [theme.breakpoints.down(480)]: {
      width: "14px !important",
      marginRight: 4,
    },
    [theme.breakpoints.down(400)]: {
      width: "12px !important",
      marginRight: 3,
    },
    "@media (max-height: 760px)": {
      width: 20,
    },
  },
  labelContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    flexDirection: "row",
  },
  tabsHeader: {
    display: "flex",
    justifyContent: "space-around",
  },
}));

export default TabsDeliveryStyles;
