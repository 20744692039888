import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { Typography } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiStepConnector-alternativeLabel": {
      top: 20,
      left: `calc(-50% + 24px)`,
      right: `calc(50% + 24px)`,
      [theme.breakpoints.down(660)]: {
        display: "none",
      },
    },
    [theme.breakpoints.down(660)]: {
      display: "block",
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  labelText: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 12,
    color: "rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  alternativeLabel: {
    marginTop: 30,
  },
  stepStyle: {
    [theme.breakpoints.down(660)]: {
      float: "left",
      width: "50%",
      clear: "both",
    },
  },
  stepStyleImpar: {
    [theme.breakpoints.down(660)]: {
      marginBottom: 20,
    },
  },
}));

export default function CustomStepper(props) {
  const { steps, activeStep, children } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        // className={classes.alternativeLabel}
        classes={{ root: classes.root }}
      >
        {steps.map((step) => (
          <Step
            key={step.id}
            className={
              step.id === 1 || step.id === 3 || step.id === 5
                ? classes.stepStyle
                : classes.stepStyleImpar
            }
          >
            {step.icon ? (
              <StepLabel
                StepIconComponent={
                  step.id === activeStep ? step.selectedIcon : step.icon
                }
              >
                <Typography className={classes.labelText}>
                  {step.label}
                </Typography>
              </StepLabel>
            ) : (
              <StepLabel>
                <Typography className={classes.labelText}>
                  {step.label}
                </Typography>
              </StepLabel>
            )}
          </Step>
        ))}
      </Stepper>
      <div>{children}</div>
    </div>
  );
}
