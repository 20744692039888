import { makeStyles } from "@material-ui/core/styles";

const ContainerCenterContentStyles = makeStyles({
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
});

export default ContainerCenterContentStyles;
