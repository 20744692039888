import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const UploadExcelStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 40,
    flexDirection: "column",
    /* min-height: 980px*/
    "@media (min-height: 980px) and (max-width: 650px)": {
      marginTop: 15,
    },
    "@media (min-height: 980px) and (max-width: 470px)": {
      marginTop: 10,
    },

    /* max-height: 979px*/
    "@media (max-height: 979px) and (max-width: 600px)": {
      marginTop: 15,
    },
    "@media (max-height: 979px) and (max-width: 470px)": {
      marginTop: 10,
    },

    /* max-height: 840px*/
    "@media (max-height: 840px) and (min-width: 769px)": {
      marginTop: 20,
    },
    "@media (max-height: 840px) and (max-width: 768px)": {
      marginTop: 15,
    },
    "@media (max-height: 840px) and (max-width: 470px)": {
      marginTop: 10,
    },

    /* max-height: 740px*/
    "@media (max-height: 740px) and (min-width: 769px)": {
      marginTop: 15,
    },
    "@media (max-height: 740px) and (max-width: 768px)": {
      marginTop: 10,
    },

    /* max-height: 640px*/
    "@media (max-height: 640px) and (min-width: 769px)": {
      marginTop: 13,
    },
    "@media (max-height: 640px) and (max-width: 768px)": {
      marginTop: 8,
    },
  },
  containerForm: {
    width: 600,
    height: 300,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px dashed black",
    borderRadius: 10,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.6,
    },
    /* min-height: 980px*/
    "@media (min-height: 980px) and (max-width: 950px)": {
      height: 250,
      width: 500,
    },
    "@media (min-height: 980px) and (max-width: 650px)": {
      width: 350,
      height: 200,
    },
    "@media (min-height: 980px) and (max-width: 470px)": {
      width: 280,
      height: 150,
    },

    /* max-height: 979px*/
    "@media (max-height: 979px) and (min-width: 601px)": {
      height: 200,
      width: 500,
    },
    "@media (max-height: 979px) and (max-width: 600px)": {
      width: 350,
      height: 200,
    },
    "@media (max-height: 979px) and (max-width: 470px)": {
      width: 280,
      height: 150,
    },

    /* max-height: 840px*/
    "@media (max-height: 840px) and (min-width: 769px)": {
      width: 450,
      height: 170,
    },
    "@media (max-height: 840px) and (max-width: 768px)": {
      width: 350,
      height: 170,
    },
    "@media (max-height: 840px) and (max-width: 470px)": {
      width: 280,
      height: 150,
    },

    /* max-height: 740px*/
    "@media (max-height: 740px) and (min-width: 769px)": {
      width: 400,
      height: 140,
    },
    "@media (max-height: 740px) and (max-width: 768px)": {
      width: 300,
      height: 125,
    },
    "@media (max-height: 740px) and (max-width: 470px)": {
      width: 250,
      height: 130,
    },

    /* max-height: 640px*/
    "@media (max-height: 640px) and (min-width: 769px)": {
      width: 380,
      height: 120,
    },
    "@media (max-height: 640px) and (max-width: 768px)": {
      width: 280,
      height: 100,
    },
    "@media (max-height: 640px) and (max-width: 470px)": {
      width: 230,
      height: 90,
    },
  },
  img: {
    /* min-height: 980px */
    "@media (min-height: 980px) and (max-width: 650px)": {
      width: 45,
      height: 45,
    },
    "@media (min-height: 980px) and (max-width: 470px)": {
      width: 40,
      height: 40,
    },

    /* max-height: 979px */
    "@media (max-height: 979px) and (max-width: 600px)": {
      width: 45,
      height: 45,
    },
    "@media (max-height: 979px) and (max-width: 470px)": {
      width: 40,
      height: 40,
    },

    /* max-height: 840px */
    "@media (max-height: 840px) and (max-width: 768px)": {
      width: 45,
      height: 45,
    },
    "@media (max-height: 840px) and (max-width: 470px)": {
      width: 35,
      height: 35,
    },

    /* max-height: 740px */
    "@media (max-height: 740px) and (min-width: 769px)": {
      width: 40,
      height: 40,
    },
    "@media (max-height: 740px) and (max-width: 768px)": {
      width: 35,
      height: 35,
    },
    "@media (max-height: 740px) and (max-width: 470px)": {
      width: 30,
      height: 30,
    },

    /* max-height: 640px*/
    "@media (max-height: 640px) and (min-width: 769px)": {
      width: 38,
      height: 38,
    },
    "@media (max-height: 640px) and (max-width: 768px)": {
      width: 30,
      height: 30,
    },
    "@media (max-height: 640px) and (max-width: 470px)": {
      width: 25,
      height: 25,
    },
  },
  text: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 15,
    color: "rgba(0, 0, 0, 0.76)",
    marginTop: 20,
    /* min-height: 980px */
    "@media (min-height: 980px) and (max-width: 650px)": {
      fontSize: 13,
      marginTop: 15,
    },
    "@media (min-height: 980px) and (max-width: 470px)": {
      fontSize: 11,
      marginTop: 15,
    },

    /* max-height: 979px */
    "@media (max-height: 979px) and (max-width: 600px)": {
      fontSize: 13,
      marginTop: 15,
    },
    "@media (max-height: 979px) and (max-width: 470px)": {
      fontSize: 11,
      marginTop: 15,
    },

    /* max-height: 840px */
    "@media (max-height: 840px) and (max-width: 768px)": {
      fontSize: 13,
      marginTop: 15,
    },
    "@media (max-height: 840px) and (max-width: 470px)": {
      fontSize: 11,
      marginTop: 15,
    },

    /* max-height: 740px */
    "@media (max-height: 740px) and (min-width: 769px)": {
      fontSize: 13,
      marginTop: 15,
    },
    "@media (max-height: 740px) and (max-width: 768px)": {
      fontSize: 11,
      marginTop: 15,
    },
    "@media (max-height: 740px) and (max-width: 470px)": {
      fontSize: 10,
      marginTop: 15,
    },

    /* max-height: 640px*/
    "@media (max-height: 640px) and (min-width: 769px)": {
      fontSize: 12,
      marginTop: 13,
    },
    "@media (max-height: 640px) and (max-width: 768px)": {
      fontSize: 10.5,
      marginTop: 10,
    },
    "@media (max-height: 640px) and (max-width: 470px)": {
      fontSize: 9,
      marginTop: 8,
    },
  },
  containerUploadFile: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  input: {
    opacity: 0,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
  containerButtons: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: 600,
    /* min-height: 981px */
    "@media (min-height: 981px) and (max-width: 950px)": {
      width: 500,
      marginTop: 10,
    },
    "@media (min-height: 980px) and (max-width: 650px)": {
      width: 350,
    },
    "@media (min-height: 980px) and (max-width: 470px)": {
      width: 280,
    },

    /* max-height: 979px */
    "@media (max-height: 979px) and (min-width: 601px)": {
      width: 500,
      marginTop: 10,
    },
    "@media (max-height: 979px) and (max-width: 600px)": {
      width: 350,
    },
    "@media (max-height: 979px) and (max-width: 470px)": {
      width: 280,
    },

    /* max-height: 840px */
    "@media (max-height: 840px) and (min-width: 769px)": {
      width: 450,
    },
    "@media (max-height: 840px) and (max-width: 768px)": {
      width: 350,
    },
    "@media (max-height: 840px) and (max-width: 470px)": {
      width: 280,
    },

    /* max-height: 740px */
    "@media (max-height: 740px) and (min-width: 769px)": {
      width: 400,
    },
    "@media (max-height: 740px) and (max-width: 768px)": {
      width: 300,
    },
    "@media (max-height: 740px) and (max-width: 470px)": {
      width: 250,
    },

    /* max-height: 640px*/
    "@media (max-height: 640px) and (min-width: 769px)": {
      width: 380,
    },
    "@media (max-height: 640px) and (max-width: 768px)": {
      width: 280,
    },
    "@media (max-height: 640px) and (max-width: 470px)": {
      width: 230,
    },
  },
  textButtom: {
    color: "#fff",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 15,
    textTransform: "capitalize",
    /* min-height: 980px */
    "@media (min-height: 980px) and (max-width: 650px)": {
      fontSize: 13,
    },
    "@media (min-height: 980px) and (max-width: 470px)": {
      fontSize: 12,
    },

    /* max-height: 979px */
    "@media (max-height: 979px) and (max-width: 600px)": {
      fontSize: 13,
    },
    "@media (max-height: 979px) and (max-width: 470px)": {
      fontSize: 12,
    },

    /* max-height: 840px */
    "@media (max-height: 840px) and (max-width: 768px)": {
      fontSize: 13,
    },
    "@media (max-height: 840px) and (max-width: 470px)": {
      fontSize: 12,
    },

    /* max-height: 740px */
    "@media (max-height: 740px) and (min-width: 769px)": {
      fontSize: 13,
    },
    "@media (max-height: 740px) and (max-width: 768px)": {
      fontSize: 12,
    },
    "@media (max-height: 740px) and (max-width: 470px)": {
      fontSize: 10,
    },

    /* max-height: 640px*/
    "@media (max-height: 640px) and (min-width: 769px)": {
      fontSize: 12,
    },
    "@media (max-height: 640px) and (max-width: 768px)": {
      fontSize: 10.5,
    },
    "@media (max-height: 640px) and (max-width: 470px)": {
      fontSize: 9
    },
  },
  button: {
    background: "#D15252",
    width: 250,
    height: 50,
    marginTop: 50,
    borderRadius: 10,
    marginLeft: "auto",
    marginRight: "auto",
    "&:hover": {
      opacity: 0.7,
      background: "#D15252 !important",
    },
    "&.Mui-disabled": {
      background: "#adabab !important",
      color: "white",
    },
    /* min-height: 980px */
    "@media (min-height: 980px) and (max-width: 950px)": {
      marginTop: 20,
      height: 50,
      width: 200,
    },
    "@media (min-height: 980px) and (max-width: 650px)": {
      marginTop: 15,
      height: 40,
      width: 150,
    },
    "@media (min-height: 980px) and (max-width: 470px)": {
      marginTop: 5,
      height: 30,
      width: 120,
    },

    /* max-height: 979px */
    "@media (max-height: 979px) and (min-width: 601px)": {
      marginTop: 20,
      height: 40,
      width: 200,
    },
    "@media (max-height: 979px) and (max-width: 600px)": {
      marginTop: 15,
      height: 40,
      width: 150,
    },
    "@media (max-height: 979px) and (max-width: 470px)": {
      marginTop: 15,
      height: 30,
      width: 120,
    },

    /* max-height: 840px */
    "@media (max-height: 840px) and (max-width: 768px)": {
      marginTop: 15,
      height: 40,
      width: 150,
    },
    "@media (max-height: 840px) and (max-width: 470px)": {
      marginTop: 15,
      height: 30,
      width: 120,
    },

    /* max-height: 740px */
    "@media (max-height: 740px) and (min-width: 769px)": {
      width: 150,
      height: 35,
      fontSize: 13,
      marginTop: 10,
    },
    "@media (max-height: 740px) and (max-width: 768px)": {
      marginTop: 15,
      height: 30,
      width: 120,
    },
    "@media (max-height: 740px) and (max-width: 470px)": {
      marginTop: 10,
      height: 25,
      width: 105,
    },

    /* max-height: 640px*/
    "@media (max-height: 640px) and (min-width: 769px)": {
      width: 130,
      height: 30,
      marginTop: 10,
    },
    "@media (max-height: 640px) and (max-width: 768px)": {
      width: 110,
      height: 26,
      marginTop: 10,
    },
    "@media (max-height: 640px) and (max-width: 470px)": {
      marginTop: 10,
      height: 25,
      width: 98,
    },
  },
}));
export default UploadExcelStyle;