export const AUTH_MESSAGES = {
  VALID_USER: "valid user",
  REGISTER_USER: "user was added",
  NONEXISTENT_USER: "Sin Nombre",
  INFO_WAS_ADD: "info was added",
  USER_ALREADY_EXIST: "email exists",
  INFO_COMPLETE: "info complete",
  POSTAL_CODE_VALID : "postal code is validated",
  GUIDES_WAS_ADDED: "guides was added",
  ENOUGH_CREDITS: "enough credits",
  LOCATION_ADD: "location was added",
  LOCATION_UPDATE: "location was updated",
  GET_RATES: "there are rates"
};

export const MESSAGES_STATUS_PACKAGE ={
  RECIBIDO_POR_LIBER: "Sin recolectar",
  PEDIDO_TRANSITO: "Pedido en transito",
  EN_CAMINO: "En camino",
  ENTREGADO: "Entregado"
}
export const ALERT_MESSAGES = {

}
