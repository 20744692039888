import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import FaqsStyle from "./FAQs.style";
import TargetFaqs from "../../components/faqs/TargetFaqs";
import Layout from "../../components/Layout/Layout";
import Preguntas from "./preguntas";

const Faqs = () => {
  const classes = FaqsStyle();
  return (
    <div>
      <Layout>
        <Box className={classes.containers}>
          <Box className={classes.textHeaderContainer}>
            <Typography className={classes.title}>
              Preguntas Frecuentes
            </Typography>
            <Typography className={classes.description}>
              Todas las preguntas y dudas que tengas acerca de nuestro servicio
            </Typography>
          </Box>
          <Grid container className={classes.container}>
            {Preguntas.map((item, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  lg={4}
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TargetFaqs
                    imagen={item.imagen}
                    pregunta={item.pregunta}
                    respuesta={item.respuesta}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Layout>
    </div>
  );
};

export default Faqs;
