import { makeStyles } from "@material-ui/core";

const SuccessAnimationStyles = makeStyles((theme) => ({
  checkmark: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    display: "block",
    strokeWidth: "2",
    stroke: "#4bb71b",
    strokeMiterlimit: "10",
    boxShadow: "inset 0px 0px 0px #4bb71b",
    animation:
      "$fill .4s ease-in-out .4s forwards, $scale .3s ease-in-out .9s both",
    position: "relative",
    top: "5px",
    right: "5px",
    margin: "0 auto",
  },
  checkmarkCircle: {
    strokeDasharray: "166",
    strokeDashoffset: "166",
    strokeWidth: "2",
    strokeMiterlimit: "10",
    stroke: "#4bb71b",
    fill: "#fff",
    animation: "$stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards",
  },
  checkmarkCheck: {
    transformOrigin: "50% 50%",
    strokeDasharray: "48",
    strokeDashoffset: "48",
    animation: "$stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards",
  },
  "@keyframes stroke": {
    "100%": {
      strokeDashoffset: 0,
    },
  },
  "@keyframes scale": {
    "0%, 100%": {
      transform: "none",
    },
    "50%": {
      transform: "scale3d(1.1, 1.1, 1)",
    },
  },
  "@keyframes fill": {
    "100%": {
      boxShadow: "inset 0px 0px 0px 30px #4bb71b",
    },
  },

  [theme.breakpoints.down("md")]: {
    checkmark: {
      width: "50px",
      height: "50px",
    },
  },
  [theme.breakpoints.between("md", "lg")]: {
    checkmark: {
      width: "70px",
      height: "70px",
    },
  },
}));

export default SuccessAnimationStyles;
