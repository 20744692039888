import React from "react";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import AddEditAddressModalStyle from "./AddEditAddressModalStyle";
import ModalComponent from "../../../sharedComponents/ModalComponent";
import imgAddress from "../../../../assets/img/dashboard/img-address.svg";
import Map from "../../../sharedComponents/Map";
import { GENERAL_CONSTANTS } from "../../../../constants/credentials";

const AddEditAddressModal = (props) => {
  const {
    open,
    handleClose,
    flagAdd,
    newDirections,
    handleChangeNewDirection,
    handleCloseModalAddDireccion,
    disableButton,
    verifyCode,
    updateDirectionFuntion,
    handleValidatePostalCode,
    postalCode,
    idPostalCode,
    location,
    setLocation,
  } = props;

  const classes = AddEditAddressModalStyle();

  const body = (
    <Box className={classes.container}>
      <Box className={classes.containerTitleModal}>
        <img src={imgAddress} className={classes.imgAddress} alt="" />
        {flagAdd ? (
          <Typography id="modal-modal-title" className={classes.titleModal}>
            Agregar dirección
          </Typography>
        ) : (
          <Typography id="modal-modal-title" className={classes.titleModal}>
            Editar dirección{" "}
          </Typography>
        )}
      </Box>
      <Box component="form" className={classes.containerForm}>
        <Box className={classes.containerAlias}>
          <Box className={classes.containerInputText}>
            <Typography className={classes.labelModal}>
              Alias <strong style={{ color: "red" }}>*</strong>
            </Typography>
            <TextField
              className={classes.inputAliasInput}
              id="outlined-size-small"
              variant="outlined"
              size="small"
              type="text"
              placeholder="Casa, Negocio, Tienda"
              name="alias"
              disabled={flagAdd ? false : true}
              value={newDirections.alias}
              onChange={handleChangeNewDirection}
              fullWidth
              inputProps={{
                className: classes.inputTextFile,
              }}
            />
          </Box>
        </Box>
        <Box className={classes.containerInputModal}>
          <Box className={classes.containerInputText}>
            <Typography className={classes.labelModal}>
              Calle <strong style={{ color: "red" }}>*</strong>
            </Typography>
            <TextField
              className={classes.textText}
              id="outlined-size-small"
              variant="outlined"
              size="small"
              type="text"
              name="streetName"
              value={newDirections.streetName}
              onChange={handleChangeNewDirection}
              fullWidth
              inputProps={{
                className: classes.inputTextFile,
              }}
            />
          </Box>
          <Box className={classes.containerInputText}>
            <Typography className={classes.labelModal}>
              Colonia <strong style={{ color: "red" }}>*</strong>
            </Typography>
            <TextField
              className={classes.textText}
              id="outlined-size-small"
              variant="outlined"
              size="small"
              type="text"
              name="colony"
              value={newDirections.colony}
              onChange={handleChangeNewDirection}
              fullWidth
              inputProps={{
                className: classes.inputTextFile,
              }}
            />
          </Box>
        </Box>
        <Box className={classes.containerInputModal}>
          <Box className={classes.containerInputText}>
            <Typography className={classes.labelModal}>
              Número Externo <strong style={{ color: "red" }}>*</strong>
            </Typography>
            <TextField
              className={classes.textTextNum}
              id="outlined-size-small"
              variant="outlined"
              size="small"
              name="numExt"
              value={newDirections.numExt}
              onChange={handleChangeNewDirection}
              fullWidth
              inputProps={{
                className: classes.inputTextFile,
                maxLength: 5,
              }}
            />
          </Box>
          <Box className={classes.containerInputText}>
            <Typography className={classes.labelModal}>
              Número Interno
            </Typography>
            <TextField
              className={classes.textTextNum}
              id="outlined-size-small"
              variant="outlined"
              size="small"
              name="numInt"
              value={newDirections.numInt}
              onChange={handleChangeNewDirection}
              fullWidth
              inputProps={{
                className: classes.inputTextFile,
                maxLength: 5,
              }}
            />
          </Box>
          <Box className={classes.containerInputText}>
            <Typography className={classes.labelModal}>
              Codigo Postal <strong style={{ color: "red" }}>*</strong>
            </Typography>
            <TextField
              className={classes.textTextNum}
              id="outlined-size-small"
              variant="outlined"
              size="small"
              name="postalCode"
              value={newDirections.postalCode}
              onChange={handleChangeNewDirection}
              error={!verifyCode && newDirections.postalCode !== ""}
              helperText={
                !verifyCode &&
                newDirections.postalCode !== "" &&
                "Ingresa un código postal válido"
              }
              fullWidth
              inputProps={{
                className: classes.inputTextFile,
                maxLength: 5,
              }}
            />
          </Box>
        </Box>
        <Box className={classes.containerInputModal}>
          <Box className={classes.containerInputText}>
            <Typography className={classes.labelModal}>
              Ciudad <strong style={{ color: "red" }}>*</strong>
            </Typography>
            <TextField
              className={classes.textText}
              id="outlined-size-small"
              variant="outlined"
              size="small"
              type="text"
              name="city"
              value={newDirections.city}
              onChange={handleChangeNewDirection}
              fullWidth
              inputProps={{
                className: classes.inputTextFile,
              }}
            />
          </Box>
          <Box className={classes.containerInputText}>
            <Typography className={classes.labelModal}>
              Estado <strong style={{ color: "red" }}>*</strong>
            </Typography>
            <TextField
              className={classes.textText}
              id="outlined-size-small"
              variant="outlined"
              size="small"
              type="text"
              name="state"
              value={newDirections.state}
              onChange={handleChangeNewDirection}
              fullWidth
              inputProps={{
                className: classes.inputTextFile,
              }}
            />
          </Box>
        </Box>
        <Box className={classes.containerAlias}>
          <Box className={classes.containerInputText}>
            <Typography className={classes.labelModal}>Referencias:</Typography>
            <TextField
              className={classes.textReferences}
              id="outlined-size-small"
              variant="outlined"
              size="small"
              type="text"
              placeholder="Afuera esta un arbol"
              name="references"
              value={newDirections.references}
              onChange={handleChangeNewDirection}
              fullWidth
              inputProps={{
                className: classes.inputTextFile,
                maxLength: 20,
              }}
            />
          </Box>
        </Box>
        <Box className={classes.containerAlias}>
          <Box className={classes.containerInputText}>
            <Typography className={classes.labelModal}>
              Ubicación del domicilio:
            </Typography>
            <Map
              googleMapURL={GENERAL_CONSTANTS.URL_API_MAPS}
              containerElement={<Box className={classes.contentMap} />}
              mapElement={<Box className={classes.map} />}
              loadingElement={<p>cargando...</p>}
              setLocation={setLocation}
              location={location}
              getCurrentPosition={flagAdd}
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.containerButtonsModal}>
        <Button
          variant="contained"
          className={classes.backButton}
          onClick={handleCloseModalAddDireccion}
        >
          {" "}
          Cancelar{" "}
        </Button>
        {flagAdd ? (
          <Button
            disabled={!verifyCode || disableButton}
            variant="contained"
            className={classes.addButton}
            type="submit"
            onClick={handleValidatePostalCode}
          >
            Agregar
          </Button>
        ) : (
          <Button
            disabled={!verifyCode || disableButton}
            variant="contained"
            className={classes.addButton}
            type="submit"
            onClick={() =>
              newDirections.postalCode === postalCode
                ? updateDirectionFuntion(idPostalCode)
                : handleValidatePostalCode()
            }
          >
            Actualizar
          </Button>
        )}
      </Box>
    </Box>
  );

  return (
    <ModalComponent
      open={open}
      handleClose={handleClose}
      body={body}
      showButton={false}
    />
  );
};

export default AddEditAddressModal;
