import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const RowInformationStyle = makeStyles((theme)=>({
    table: {
        minWidth: 650,
      },
      textHead:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
        fontSize:15,
        color:"rgba(0, 0, 0, 0.5)"
      },
      textBody:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
        fontSize:15
      },
      head:{
        background:"rgba(204, 204, 204, 0.07)"
      }
}))

export default RowInformationStyle;