import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import CardsDecription from "../../components/about/CardsDescription";
import Layout from "../../components/Layout/Layout";
import AboutStyle from "./About.style";
import eficaz from "../../assets/img/about/cardsIcons/eficaz.png";
import secure from "../../assets/img/about/cardsIcons/secure.png";
import trust from "../../assets/img/about/cardsIcons/confiable.png";
import reloj from "../../assets/img/about/cardsIcons/reloj.png";
import mision from "../../assets/img/about/misionIconAbout.svg";
import vision from "../../assets/img/about/visionIconAbout.svg";
import objective from "../../assets/img/about/objAboutIcon.svg";
//return images from aws
const imageName = (imgName) =>
  `https://assets-liber.s3.amazonaws.com/images/logosClientes/${imgName}`;

const About = () => {
  const classes = AboutStyle();
  const clientsLogos = [
    { clientId: 1, logo: imageName("bellaly.jpeg"), alt: "bellaly-icn" },
    { clientId: 2, logo: imageName("Cuev.png"), alt: "cuev-icn" },
    { clientId: 3, logo: imageName("gummy.jpeg"), alt: "gummy-icn" },
    { clientId: 4, logo: imageName("hair.jpeg"), alt: "hair-icn" },
    { clientId: 5, logo: imageName("hilorama.jpeg"), alt: "hilo-icn" },
  ];

  const cardItems = [
    {
      id: 1,
      icon: eficaz,
      title: "Eficacia",
      description:
        "Con nuestro servicio podrás enviar o recibir todos tus productos de una manera rápida y segura, siempre brindando un servicio de calidad.",
    },
    {
      id: 2,
      icon: secure,
      title: "Seguridad",
      description:
        "Tu envío es nuestra prioridad. Con personal de confianza y un sistema de rastreo avanzado, aseguramos la entrega segura de tu paquete.",
    },
    {
      id: 3,
      icon: trust,
      title: "Confiabilidad",
      description:
        "La confianza es pieza clave en nuestra empresa, sé parte de nuestros clientes de confianza y goza los beneficios.",
    },
    {
      id: 4,
      icon: reloj,
      title: "Flexibilidad",
      description:
        "Cada envío es único en Liber. Ofrecemos opciones personalizadas y flexibles para satisfacer tus necesidades específicas de paquetería.",
    },
  ];

  const cardObjectivesItems = [
    {
      id: 1,
      icon: mision,
      title: "Misión",
      desc: " Ofrecer soluciones de envío ágiles y eficientes a nuestros clientes, adaptándonos a sus necesidades.",
    },
    {
      id: 2,
      icon: vision,
      title: "Visión",
      desc: "Convertirnos en el líder indiscutible en soluciones logísticas de envío en la región.",
    },
    {
      id: 3,
      icon: objective,
      title: "Objetivo",
      desc: "Expandir nuestro alcance a nivel nacional e internacional mediante la formación de alianzas estratégicas",
    },
  ];

  return (
    <div>
      <Layout>
        <Typography className={classes.headerText}>
          Conoce nuestra trayectoria y compromiso en{" "}
          <span className={classes.hightLightText}> soluciones de envíos</span>
        </Typography>
        <Box className={classes.container}>
          <Typography align="center" className={classes.offerTitle}>
            Nuestros Valores
          </Typography>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.objectivesContainer}
          >
            {cardItems.map((card, index) => (
              <Grid
                key={index}
                item
                lg={6}
                className={classes.gridItemContainer}
              >
                <CardsDecription
                  icon={card.icon}
                  title={card.title}
                  description={card.description}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box className={classes.contentContainer}>
          <Typography align="center" className={classes.ourHistory}>
            Nuestra historia
          </Typography>
          <Typography align="center" className={classes.ourHistorySubtitle}>
            ¿Cómo comenzamos?
          </Typography>

          <Box className={classes.ourHistoryContainer}>
            <Typography className={classes.ourHistoryFirstP}>
              Hace tres años, en la ciudad de Guadalajara, un grupo de
              emprendedores con amplia experiencia en el sector logístico
              decidió crear una empresa de última milla. Su objetivo era ofrecer
              soluciones de envío ágiles y eficientes a los habitantes de la
              ciudad y sus alrededores en un momento en que el comercio
              electrónico estaba en pleno auge.
            </Typography>
            <Typography className={classes.ourHistorySecondP}>
              Desde el primer día, la empresa se enfocó en brindar un servicio
              personalizado y de alta calidad a sus clientes. Este enfoque le
              permitió ganar rápidamente la confianza del mercado. Con el paso
              del tiempo, la empresa amplió su alcance y actualmente cubre toda
              la zona metropolitana de Guadalajara, así como las áreas
              circundantes. Además, ofrece diferentes tipos de envíos, desde
              paquetes pequeños hasta grandes cargas, adaptándose a las
              necesidades de sus clientes.
            </Typography>
          </Box>

          <Box className={classes.cardsContainer}>
            <Grid container>
              {cardObjectivesItems.map((item, index) => (
                <Grid key={index} item xs={12} md={4}>
                  <Box className={classes.objectivesSection}>
                    <Typography className={classes.headerCard}>
                      {item.title}
                    </Typography>
                    <img
                      alt={`item-${item.title}`}
                      src={item.icon}
                      className={classes.cardIcon}
                    />
                    <Typography
                      align="center"
                      className={classes.cardDescription}
                    >
                      {item.desc}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box className={classes.clientsContainer}>
            <Typography className={classes.ourClientsTitle} align="center">
              Nuestros clientes
            </Typography>
            <Box className={classes.clientsLogosContainer}>
              {clientsLogos.map((client, index) => (
                <Box key={index} className={classes.centerLogo}>
                  <img
                    src={client.logo}
                    alt={client.alt}
                    className={classes.clientImagesLogos}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Layout>
    </div>
  );
};

export default About;
