import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const InformationStyle = makeStyles((theme) => ({
    title:{
        fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
        fontSize:15,
        margin:"15px 0px"
    },
    subTitle:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_BOLD,
        fontSize:10,
        marginBottom:20,
       
    },
    description:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
        marginLeft:12,
    },
    containerImage:{
        display:"flex",
        marginBottom:15,
        alignItems:"center"
    },
    imagen:{
        width:15,
        height:15
    }
}))
export default InformationStyle