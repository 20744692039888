import axios from "axios";
const { REACT_APP_LIBER_API_URL, REACT_APP_LIBER_HOST_URL } = process.env;


export const getCredits = (accessToken) => {
    const request = {
      host: REACT_APP_LIBER_HOST_URL,
      method: "GET",
      url: `${REACT_APP_LIBER_API_URL}/business/get-credits`,
      headers: {
        "content-type": "application/json",
        auth_Token: accessToken,
      },
    };
  
    return axios(request);
  };
  