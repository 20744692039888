import React from "react";
import BalanceStyle from "./BalanceStyle";
import DrawerDash from "../../components/DrawerDash";
import { Typography } from "@material-ui/core";
import ListBalance from "../../components/balance/ListBalance";

const Balance = () => {
  const classes = BalanceStyle();

  return (
    <DrawerDash>
      <Typography
        className={classes.title}
        variant="h5"
        id="tableTitle"
        component="div"
      >
        Historial de Saldo
      </Typography>
      <ListBalance />
    </DrawerDash>
  );
};

export default Balance;
