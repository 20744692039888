import React, { Fragment, useState, useEffect } from "react";
import {
  TableRow,
  TableCell,
  Paper,
  TableContainer,
  Table,
  TableBody,
  Typography,
} from "@material-ui/core";
import { getBalance } from "../../../api/balanceApi";
import { notification } from "../../../utils/notification";
import TableSkeleton from "../../sharedComponents/TableSkeleton";
import TableHeader from "./TableHeader";
import useLogout from "../../../hooks/useLogout";
import NotFoundRows from "../../sharedComponents/NotFoundRows";
import ListBalanceStyle from "./ListBalanceStyle";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

const ListBalance = () => {
  const classes = ListBalanceStyle();
  const [logout] = useLogout();

  const [loading, setLoading] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [actualBalance, setActualBalance] = useState(0);

  useEffect(() => {
    setLoading(true);

    getBalance()
      .then((res) => {
        if (res.data.status_Code === 200) {
          setActualBalance(res.data.actual_balance);
          setRowsData(res.data.balance_history);
        } else if (res.data.status_Code === 401) {
          notification("La sesión ha caducado", "error");
          logout();
        } else {
          notification("Ocurrió un error al recuperas las guías", "error");
        }
      })
      .catch((err) => {
        notification(
          "Error de conexión, no se pudieron recuperar las guías",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Typography
        className={classes.textAmount}
        variant="h5"
        id="tableTitle"
        component="div"
      >
        $ {actualBalance}
      </Typography>
      <Typography
        className={classes.textMovements}
        id="tableTitle"
        component="div"
      >
        Movimientos
      </Typography>
      <div className={classes.content}>
        <Paper className={classes.container}>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={"small"}
              aria-label="enhanced table"
            >
              <TableHeader />
              <TableBody>
                {!loading ? (
                  rowsData.length > 0 ? (
                    rowsData.map((row, index) => {
                      return (
                        <TableRow tabIndex={-1} key={row.id_guide}>
                          <TableCell align="left" className={classes.text}>
                            {row.type_case === "Aumento" ? (
                              <AddIcon
                                style={{ color: "#056705ab", fontSize: 20 }}
                              />
                            ) : (
                              <RemoveIcon
                                style={{ color: "#ff000070", fontSize: 20 }}
                              />
                            )}
                          </TableCell>
                          <TableCell align="left" className={classes.text}>
                            {row.amount}
                          </TableCell>
                          <TableCell align="left" className={classes.text}>
                            {row.description_case}
                          </TableCell>
                          <TableCell align="left" className={classes.text}>
                            {row.before_balance}
                          </TableCell>
                          <TableCell align="left" className={classes.text}>
                            {row.after_balance}
                          </TableCell>
                          <TableCell align="left" className={classes.text}>
                            {row.register_date}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <NotFoundRows
                      colSpan={7}
                      text="No se encontraron movimientos"
                    />
                  )
                ) : (
                  <TableSkeleton rowsNum={5} columnsNum={7} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </Fragment>
  );
};

export default ListBalance;
