import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const ImageLinkStyle = makeStyles((theme) => ({
  container: {
    background: "rgba(246, 255, 252, 1)",
    width: "100%",
    height: "100vh",
    borderRadius: "0px 100px 0px 0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "-22px",
    maxWidth: "320px",
    [theme.breakpoints.down("lg")]: {
      height: "100%",
      width: "100%",
      maxWidth: "100%",
    },
    [theme.breakpoints.down(600)]: {
      marginRight: -20,
    },
  },
  containerTextButton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 25,
    textAlign: "center",
    padding: "0px 20px",
    marginTop: 70,
    marginBottom: 35,
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      textAlign: "center",
      marginTop: 40,
      marginBottom: 20,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 18,
      marginBottom: 0,
    },
  },
  imagen: {
    width: "100%",
    height: 300,
    maxWidth: "300px",
    [theme.breakpoints.down(960)]: {
      display: "none",
    },
    [theme.breakpoints.down(600)]: {
      display: "block",
      width: 150,
      height: 140,
    },
  },
}));
export default ImageLinkStyle;
