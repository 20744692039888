import { removeCookie } from "../utils/sessionCookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  resetBusinessInformation,
  resetBusinessLocationsInformation,
} from "../store/reducers/business/BusinessInformationSlice";
import {
  resetUserInformation,
  setUserIsAuth,
} from "../store/reducers/user/UserInformationSlice";
import { ROUTES_CONSTANTS } from "../constants/routesConstants";

const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    removeCookie("role");
    removeCookie("accessToken");
    dispatch(resetBusinessInformation());
    dispatch(resetBusinessLocationsInformation());
    dispatch(resetUserInformation());
    dispatch(setUserIsAuth(false));
    navigate(ROUTES_CONSTANTS.INICIAR_SESION);
  };

  return [logout];
};

export default useLogout;
