import React from "react";
import DrawerDash from "../../components/DrawerDash";
import { Typography } from "@material-ui/core";
import ShipmentsStyle from "./ShipmentsStyle";
import DataFilterShipment from "../../components/shipments/DataFilterShipment";

const Shipments = () => {
  const classes = ShipmentsStyle();

  return (
    <div>
      <DrawerDash>
        <Typography
          className={classes.title}
          variant="h5"
          id="tableTitle"
          component="div"
        >
          Historial de guías Liber
        </Typography>
        <DataFilterShipment />
      </DrawerDash>
    </div>
  );
};

export default Shipments;
