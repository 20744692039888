import { makeStyles } from "@material-ui/core";
import { MAIN_COLORS, SHADOWS } from "../../constants/theme/colors";

const CreationGuidesFormStyle = makeStyles((theme) => ({
  mainContainer: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    width: "100%",
    [theme.breakpoints.down(770)]: {
      background: MAIN_COLORS.WHITE_SMOKE,
    },
  },
  stepperFormContainer: {
    backgroundColor: MAIN_COLORS.WHITE_SMOKE,
    boxShadow: SHADOWS.SH1,
    width: "90%",
    height: "auto",
    borderRadius: 10,
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingBottom: 50,
  },
  stepperContainter: {
    height: 150,
    width: "90%",
    backgroundColor: MAIN_COLORS.WHITE,
    borderRadius: 5,
    marginTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
    //position: "sticky",
    top: 80,
    zIndex: 999,
    [theme.breakpoints.down(1020)]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.down(770)]: {
      width: "100%",
    },
  },
  formContainer: {
    width: "90%",
    height: "auto",
    backgroundColor: MAIN_COLORS.WHITE,
    marginTop: 25,
    borderRadius: 10,
    paddingLeft: 70,
    paddingTop: 44,
    paddingBottom: 50,
    [theme.breakpoints.down(1210)]: {
      paddingLeft: 30,
    },
    [theme.breakpoints.down(770)]: {
      width: "100%",
    },
    [theme.breakpoints.down(660)]: {
      marginTop: 200,
    },
    [theme.breakpoints.down(500)]: {
      marginTop: 220,
    },
  },
}));
export default CreationGuidesFormStyle;
