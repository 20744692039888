import axios from "axios";
const { REACT_APP_LIBER_API_URL, REACT_APP_LIBER_HOST_URL } = process.env;


export const registerUser = (email, password) => {
  const payload = {
      "user_Email" : email,
      "password" : password,
  };
  const request = {
    host: REACT_APP_LIBER_HOST_URL,
    method: "POST",
    data: payload,
    body: JSON.stringify(payload),
    url: `${REACT_APP_LIBER_API_URL}/auth/signup`,
    headers: {
      "content-type": "application/json",   
    },
  };

  return axios(request);
};