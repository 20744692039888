import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";
import { MAIN_COLORS } from "../../../constants/theme/colors";
const PackageFormInformationStyles = makeStyles((theme) => ({
  headerTitle: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 18,
  },
  subheaderTitle: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 12,
    color: MAIN_COLORS.BLACK_MEDIUM_OPACITY,
  },
  eyeInformationcontainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(1230)]: {
      paddingLeft: 20,
    },
    [theme.breakpoints.down(768)]: {
      marginTop: 30,
      paddingLeft: 0,
    },
  },
  input: {
    [theme.breakpoints.down(1150)]: {
      height: 30,
      padding: "6px 14px",
      fontSize: 13.5,
    },
    [theme.breakpoints.down(670)]: {
      height: 25,
      padding: "6px 14px",
      fontSize: 12,
    },
  },
  containerInformation: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 10,
    marginLeft: 10,
    paddingRight: 50,
    [theme.breakpoints.down(1230)]: {
      paddingRight: 30,
    },
  },
  packageDimentionsHeader: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 15,
    marginTop: 35,
    marginBottom: 24,
  },
  typeProductInput: {
    maxWidth: 450,
    width: "100%",
    paddingRight: 30,
  },
  inputDescription: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 10,
    marginBottom: 10,
  },
  errorProduct: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 12,
    marginBottom: 5,
    width: "100%",
    color: "red",
    [theme.breakpoints.down(700)]: {
      fontSize: 11,
      width: "90%",
    },
  },
  measuresInputFields: {
    width: 420 / 3,
    marginRight: 20,
    marginTop: 15,
    [theme.breakpoints.down(670)]: {
      width: "90%",
    },
  },
  measuresHeader: {
    marginTop: 30,
  },
  imagePackage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(670)]: {
      marginTop: 30,
      marginBottom: 20,
    },
  },
  selectDirection: {
    width: 280,
    marginTop: 35,
    [theme.breakpoints.down(460)]: {
      width: 230,
      marginTop: 25,
    },
    [theme.breakpoints.down(380)]: {
      width: 200,
    },
  },
  optionAutocomplete: {
    [theme.breakpoints.down(1150)]: {
      fontSize: 13.5,
    },
    [theme.breakpoints.down(670)]: {
      fontSize: 12.5,
    },
  },
  inputAutocomplete: {
    height: 20,
    padding: "10px 14px",
    [theme.breakpoints.down(1150)]: {
      padding: "6px 14px",
      fontSize: 13.5,
      height: 20,
    },
  },
  labelAutocomplete: {
    [theme.breakpoints.down(1150)]: {
      padding: 0,
      fontSize: 13.5,
    },
    [theme.breakpoints.down(670)]: {
      fontSize: 12.5,
    },
  },
  descriptionText: {
    [theme.breakpoints.down("xs")]: {
      marginTop: 20,
    },
  },
}));
export default PackageFormInformationStyles;
