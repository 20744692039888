import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorAnimation from "../../../components/sharedComponents/ErrorAnimation";
import PaymentErrorStyles from "./PaymentErrorStyles";

const PaymentError = () => {
  const classes = PaymentErrorStyles();
  const [seconds, ] = useState(50);
  const navigate = useNavigate();

  //QueryParams
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();

  //   const collectionId = query.get("collection_id");
  //   const collectionStatus = query.get("collection_status");
  //   const paymentId = query.get("payment_id");
  const status = query.get("status");
  //   const externalReference = query.get("external_reference");
  //   const paymentType = query.get("payment_type");
  //   const merchantOrderId = query.get("merchant_order_id");
  //   const preferenceId = query.get("preference_id");
  //   const siteId = query.get("site_id");
  //   const processingMode = query.get("processing_mode");
  //   const merchantAccountId = query.get("merchant_account_id");

  // if (!status) navigate(ROUTES_CONSTANTS.CREACION_DE_GUIAS, { replace: true });

  // useEffect(() => {
  //   const countdown = setInterval(() => {
  //     setSeconds((prevSeconds) => {
  //       if (prevSeconds === 1) {
  //         clearInterval(countdown);
  //         navigate(ROUTES_CONSTANTS.CREACION_DE_GUIAS);
  //       }
  //       return prevSeconds - 1;
  //     });
  //   }, 1000);

  //   return () => clearInterval(countdown);
  // }, [navigate]);

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.messageContainer}>
          <Typography className={classes.paymentSuccessHeader}>
            Hubo un error al efectuar tú pago
          </Typography>
          <ErrorAnimation />
          <Typography variant="caption" className={classes.redirectMessage}>
            Serás redireccionado en {seconds} segundo{seconds !== 1 && "s"}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default PaymentError;

//
