import { Box, Button, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import ContainerStarContentStyle from "./ContainerStartContent.style";
import ImageMain from "../../../../assets/img/MainPrincipal.svg";
import { useNavigate } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../../../constants/routesConstants";

const ContainerStarContent = () => {
  const classes = ContainerStarContentStyle();
  const navigate = useNavigate();

  const [codeTracking, setCodeTracking] = useState("");

  const handleGetTrakingCode = (e) => {
    setCodeTracking(e.target.value);
  };

  const handleTrakingCode = () => {
    navigate(ROUTES_CONSTANTS.RASTREO, { state: codeTracking });
  };

  return (
    <div>
      <Box className={classes.root}>
        <Box className={classes.containerText}>
          <Typography className={classes.title}>
            Entrega comprometida <br /> a tiempo
          </Typography>
          <Typography className={classes.slogan}>
            Confiable, rápida y siempre a tiempo para llevar tus envíos con
            cuidado y garantizar la satisfacción de nuestros clientes
          </Typography>
        </Box>
        <Box className={classes.containerInputs}>
          <TextField
            variant="outlined"
            value={codeTracking}
            placeholder="N° de Guía"
            onChange={handleGetTrakingCode}
            type="text"
            className={classes.inputFile}
          />
          <Button
            type="button"
            onClick={handleTrakingCode}
            className={classes.buttonCircle}
          >
            Rastrear
          </Button>
        </Box>
        <Box className={classes.containerImg}>
          <img
            className={classes.imgMain}
            src={ImageMain}
            alt="Descripción de la imagen"
          />
        </Box>
      </Box>
    </div>
  );
};

export default ContainerStarContent;
