import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const DataFilterLotsStyle = makeStyles((theme) => ({
  text: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    [theme.breakpoints.down(1150)]: {
      fontSize: 12,
    },
    [theme.breakpoints.down(700)]: {
      fontSize: 11,
    },
  },
  textHeader: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    [theme.breakpoints.down(1150)]: {
      fontSize: 12.5,
    },
    [theme.breakpoints.down(700)]: {
      fontSize: 11.5,
    },
    [theme.breakpoints.down(600)]: {
      padding: "6px 6px 6px 6px",
    },
  },
  image: {
    width: 35,
    height: 35,
    [theme.breakpoints.down(1150)]: {
      width: 30,
      height: 30,
    },
    [theme.breakpoints.down(700)]: {
      width: 23,
      height: 23,
    },
  },
  icon: {
    color: "black",
    width: 20,
    height: 20,
    [theme.breakpoints.down(700)]: {
      width: 17,
      height: 17,
    },
  },
  bgGray: {
    background: "#eeeeee87",
    [theme.breakpoints.down(600)]: {
      padding: "3px 6px 3px 6px",
    },
  },
  bgWhite: {
    background: "white",
    [theme.breakpoints.down(600)]: {
      padding: "3px 6px 3px 6px",
    },
  },
  container: {
    [theme.breakpoints.down(450)]: {
      width: 360,
    },
    [theme.breakpoints.down(420)]: {
      width: 330,
    },
    [theme.breakpoints.down(390)]: {
      width: 316,
    },
  },
}));

export default DataFilterLotsStyle;
