import { makeStyles } from "@material-ui/core";

const TableSkeletonStyle = makeStyles((theme) => ({
  bgGray: {
    background: "#eeeeee87",
  },
  bgWhite: {
    background: "white",
  },
}));

export default TableSkeletonStyle;