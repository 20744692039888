import React, { Fragment, useState } from "react";
import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DrawerDashStyle from "./DrawerDash.style";
import { Box, Collapse, Hidden, Link } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import logo from "../../assets/img/dashboard/img-liberLogo.svg";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import ExposureOutlinedIcon from "@material-ui/icons/ExposureOutlined";
import NoteAddOutlinedIcon from "@material-ui/icons/NoteAddOutlined";
import AttachMoney from "@material-ui/icons/AttachMoney";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import MarkunreadMailboxOutlinedIcon from "@material-ui/icons/MarkunreadMailboxOutlined";
import SyncProblemOutlinedIcon from "@material-ui/icons/SyncProblemOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { removeCookie } from "../../utils/sessionCookie";
import { useNavigate } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../constants/routesConstants";
import { useDispatch, useSelector } from "react-redux";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
import BallotIcon from "@material-ui/icons/BallotOutlined";
import HistoryIcon from "@material-ui/icons/History";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import {
  resetBusinessInformation,
  resetBusinessLocationsInformation,
  selectBusinessInformation,
} from "../../store/reducers/business/BusinessInformationSlice";
import {
  resetUserInformation,
  selectUserInformation,
  selectUserPassOnboardingProcess,
  setCredits,
  setUserIsAuth,
} from "../../store/reducers/user/UserInformationSlice";
import { getGreeting } from "../../utils/time";
// import imgWhatsApp from "../../assets/img/imgWhatsApp.svg"
import imgCards from "../../assets/img/imgCards.svg";
// import imgCreditsLogo from "../../assets/img/imgCreditsLogo.svg";
import RefreshIcon from "@material-ui/icons/Refresh";
import useUnsavedChanges from "../../hooks/useUnsavedChanges";
import { getCredits } from "../../api/getCredits";
import Loader from "../Loader";
import { notification } from "../../utils/notification";
import CreditsModal from "./CreditsModal";
import LocalShippingIcon from "@material-ui/icons/LocalShippingOutlined";
// import imgCaluladora from "../../assets/img/dashboard/calculadora.svg";

const DrawerDash = ({ children }) => {
  const classes = DrawerDashStyle();
  const theme = useTheme();

  const { disableDirty, isDirty } = useUnsavedChanges();

  const [open, setOpen] = useState(false);
  const greeting = getGreeting();
  //navigation
  const navigate = useNavigate();
  //redux
  const dispatch = useDispatch();
  const userPassOnboardinProcess = useSelector(selectUserPassOnboardingProcess);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    openList && setOpenList(!openList);
    setOpen(false);
  };
  const [openList, setOpenList] = useState(false);
  const [openListHistory, setOpenListHistory] = useState(false);
  const [openListFinance, setOpenListFinance] = useState(false);
  const [openM, setOpenM] = useState(false);
  const handleOpenM = () => {
    setOpen(false);
    setOpenM(true);
  };
  const handleCloseM = () => {
    setOpenM(false)
    localStorage.removeItem("paymentState")
  };

  const handleClickOpenList = () => {
    !open && setOpen(!open);
    setOpenList(!openList);
  };

  const handleClickOpenListHistory = () => {
    !open && setOpen(!open);
    setOpenListHistory(!openListHistory);
  };

  const handleClickOpenFinances = () => {
    !open && setOpen(!open);
    setOpenListFinance(!openListFinance);
  };

  const handleLogout = () => {
    removeCookie("role");
    removeCookie("accessToken");
    dispatch(resetBusinessInformation());
    dispatch(resetBusinessLocationsInformation());
    dispatch(resetUserInformation());
    dispatch(setUserIsAuth(false));
    navigate(ROUTES_CONSTANTS.INICIAR_SESION);
  };
  const trustedUser = useSelector(selectUserInformation);
  const accessToken = trustedUser.auth_token;
  // Modal Loadding
  const [openModalLoadding, setOpenModalLoadding] = useState(false);
  const handleGetCredits = () => {
    setOpenModalLoadding(true);
    setTimeout(() => {
      getCredits(accessToken).then((res) => {
        if (res.data.status_Code === 200) {
          const credits = res.data.credits;
          dispatch(setCredits(credits));
          setOpenModalLoadding(false);
          notification("Se actualizo su saldo correctamente", "success");
        }
      });
    }, 500);
  };

  const handleClickPrompt = (route) => {
    if (isDirty) {
      let response = window.confirm(
        "Es posible que los cambios que implementaste no se puedan guardar."
      );

      if (!response) {
        return false;
      } else {
        disableDirty();
        navigate(route, { replace: true });
      }
    } else {
      navigate(route, { replace: true });
    }
  };

  let fullname;
  const userInformation = useSelector(selectBusinessInformation);
  const userCredits = useSelector(selectUserInformation);

  if (
    userInformation?.user_name === undefined ||
    userInformation?.paternal_lastname === undefined
  ) {
    fullname = "bienvenido de nuevo";
  } else {
    fullname = `${userInformation?.user_name}`;
  }
  // const style = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   width: 770,
  //   height: 300,
  //   bgcolor: "background.paper",
  //   borderRadius: 10,
  //   boxShadow: 24,
  //   p: 4,
  //   display: "flex",
  //   flexDirection: "column",
  //   [theme.breakpoints.down(859)]: {
  //     height: 350,
  //     width: 500,
  //     fontSize: 12,
  //   },
  //   [theme.breakpoints.down(520)]: {
  //     height: 320,
  //     width: 400,
  //     fontSize: 12,
  //   },
  // };
  // const whatsappLink = "https://api.whatsapp.com/send?phone=3325481250";

  let dataAppBar = (
    <Toolbar style={{ background: "#fff", color: "#000", height: 80 }}>
      <Hidden lgUp>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: open,
          })}
        >
          <MenuIcon />
        </IconButton>
      </Hidden>
      <Box className={classes.contentCredits}>
        <Box>
          <Typography variant="h6" noWrap className={classes.helloTitle}>
            {!userPassOnboardinProcess ? "¡Hola!" : `¡Hola ${fullname}! 👋`}
          </Typography>
          <Typography variant="h6" noWrap className={classes.welcomeBack}>
            {greeting}
          </Typography>
        </Box>
        <Hidden mdDown>
          <Box className={classes.containerCredits}>
            <img src={imgCards} alt="" className={classes.iconWallet} />
            <Typography className={classes.textCredits}>
              $
              {userCredits.credits
                ? userCredits.credits.toLocaleString("en")
                : 0}{" "}
              MXN
            </Typography>
            <IconButton
              onClick={handleGetCredits}
              title="Actualizar saldo"
              className={classes.buttonUpdateCredits}
            >
              <RefreshIcon style={{ fontSize: 17 }} />
            </IconButton>
          </Box>
        </Hidden>
      </Box>
    </Toolbar>
  );

  let dataDrawer = (
    <Fragment>
      <div className={classes.containerLogo}>
        <img src={logo} alt="imgLogo" className={classes.imgLogo} />
        <div className={classes.toolbar}>
          <Hidden lgUp>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </Hidden>
        </div>
      </div>

      <List>
        <ListItem
          button
          onClick={() => handleClickPrompt(ROUTES_CONSTANTS.PERFIL)}
        >
          <ListItemIcon title="Perfil" className={classes.iconDrawer}>
            <AccountCircleOutlinedIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            className={classes.textIcon}
            primary="Mi perfil"
          />
        </ListItem>
        <Hidden lgUp>
          <ListItem>
            <ListItemIcon className={classes.iconDrawer}>
              <img src={imgCards} alt="" />
            </ListItemIcon>
            <Typography className={classes.textCreditsDrawer}>
              $
              {userCredits.credits
                ? userCredits.credits.toLocaleString("en")
                : 0}{" "}
              MXN
            </Typography>
            <IconButton
              onClick={() => {
                setOpen(false);
                setTimeout(() => {
                  handleGetCredits();
                }, 500);
              }}
              title="Actualizar saldo"
              className={classes.buttonUpdateCredits}
            >
              <RefreshIcon style={{ fontSize: 17 }} />
            </IconButton>
          </ListItem>
        </Hidden>

        <ListItem
          button
          onClick={() =>
            handleClickPrompt(ROUTES_CONSTANTS.COTIZACION_DASHBOARD)
          }
        >
          <ListItemIcon title="Cotizar envío" className={classes.iconDrawer}>
            <ExposureOutlinedIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            className={classes.textIcon}
            primary="Cotizar envío"
          />
        </ListItem>

        <ListItem button onClick={handleClickOpenList}>
          <ListItemIcon title="Guias" className={classes.iconDrawer}>
            <AssignmentOutlinedIcon onClick={handleClickOpenList} />
          </ListItemIcon>
          <ListItemText
            disableTypography
            className={classes.textIcon}
            primary="Guias"
          />
          {openList ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openList} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              onClick={() =>
                handleClickPrompt(ROUTES_CONSTANTS.CREACION_DE_GUIAS)
              }
            >
              <ListItemIcon
                title="Creación de guias"
                className={classes.iconDrawer}
              >
                <NoteAddOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={classes.textIconCollapse}
                primary="Creación de guias"
              />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => handleClickPrompt(ROUTES_CONSTANTS.ORDENES)}
            >
              <ListItemIcon
                title="Recolección Liber"
                className={classes.iconDrawer}
              >
                <ExposureOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={classes.textIconCollapse}
                primary="Recolección Liber"
              />
            </ListItem>
            <Link
              href="https://forms.gle/EcomvfMKPtQp38ZR8"
              target="_blank"
              className={classes.buttonLink}
            >
              <ListItemIcon
                title="Recolección Terceros"
                className={classes.iconDrawer}
              >
                <MarkunreadMailboxOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={classes.textIconCollapse}
                primary="Recolección Terceros"
              />
            </Link>

            <ListItem
              button
              className={classes.nested}
              onClick={() => handleClickPrompt(ROUTES_CONSTANTS.LOTS)}
            >
              <ListItemIcon
                title="Lotes Pendientes"
                className={classes.iconDrawer}
              >
                <BallotIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={classes.textIconCollapse}
                primary="Lotes Pendientes"
              />
            </ListItem>

            <Link
              href="https://forms.gle/2o5tJS36vr7ZUQMr9"
              target="_blank"
              className={classes.buttonLink}
            >
              <ListItemIcon
                title="Reembolso de Guias"
                className={classes.iconDrawer}
              >
                <SyncProblemOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={classes.textIconCollapse}
                primary="Reembolso de Guias"
              />
            </Link>
          </List>
        </Collapse>
        <ListItem button onClick={handleClickOpenListHistory}>
          <ListItemIcon
            title="Historial de Guías"
            className={classes.iconDrawer}
          >
            <HistoryIcon onClick={handleClickOpenListHistory} />
          </ListItemIcon>
          <ListItemText
            disableTypography
            className={classes.textIcon}
            primary="Historial de Guías"
          />
          {openListHistory ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openListHistory} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              onClick={() => handleClickPrompt(ROUTES_CONSTANTS.MIS_ENVIOS)}
            >
              <ListItemIcon title="Liber" className={classes.iconDrawer}>
                <MotorcycleIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={classes.textIconCollapse}
                primary="Liber"
              />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => handleClickPrompt(ROUTES_CONSTANTS.THIRD_ORDENES)}
            >
              <ListItemIcon title="Terceros" className={classes.iconDrawer}>
                <LocalShippingIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={classes.textIconCollapse}
                primary="Terceros"
              />
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={handleClickOpenFinances}>
          <ListItemIcon title="Finanzas" className={classes.iconDrawer}>
            <MonetizationOnIcon onClick={handleClickOpenFinances} />
          </ListItemIcon>
          <ListItemText
            disableTypography
            className={classes.textIcon}
            primary="Finanzas"
          />
          {openListFinance ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openListFinance} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} onClick={handleOpenM}>
              <ListItemIcon
                title="Añadir Créditos"
                className={classes.iconDrawer}
              >
                <AttachMoney />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={classes.textIconCollapse}
                primary="Añadir Créditos"
              />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => handleClickPrompt(ROUTES_CONSTANTS.BALANCE)}
            >
              <ListItemIcon title="Saldo" className={classes.iconDrawer}>
                <AccountBalanceWalletOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={classes.textIconCollapse}
                primary="Saldo"
              />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              onClick={() => handleClickPrompt(ROUTES_CONSTANTS.CREDITS)}
            >
              <ListItemIcon title="Créditos" className={classes.iconDrawer}>
                <img src={imgCards} alt="" className={classes.iconWallet} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={classes.textIconCollapse}
                primary="Créditos"
              />
            </ListItem>
            <Link
              href="https://forms.gle/BUY15fueEtu5w4nR6"
              target="_blank"
              className={classes.buttonLink}
            >
              <ListItemIcon
                title="Retiro de Efectivo"
                className={classes.iconDrawer}
              >
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={classes.textIconCollapse}
                primary="Retiro de Efectivo"
              />
            </Link>
          </List>
        </Collapse>

        <ListItem button>
          <Link
            href="https://forms.gle/8dq2LaogMJLUPhUw5"
            target="_blank"
            className={classes.buttonLinkReport}
          >
            <ListItemIcon
              title="Reporte/Sugerencia"
              className={classes.iconDrawer}
            >
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText
              disableTypography
              className={classes.textIcon}
              primary="Reporte/Sugerencia"
            />
          </Link>
        </ListItem>

        <ListItem
          button
          onClick={() => {
            if (isDirty) {
              let response = window.confirm(
                "Es posible que los cambios que implementaste no se puedan guardar."
              );

              if (!response) {
                return false;
              } else {
                disableDirty();
                handleLogout();
              }
            } else {
              handleLogout();
            }
          }}
        >
          <ListItemIcon title="Cerrar sesión" className={classes.iconDrawer}>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            className={classes.textIcon}
            primary="Cerrar sesión"
          />
        </ListItem>
      </List>
      <CreditsModal open={openM} handleClose={handleCloseM} />
    </Fragment>
  );

  return (
    <div className={classes.root}>
      <Hidden lgUp>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          {dataAppBar}
        </AppBar>
      </Hidden>
      <Hidden mdDown>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, classes.appBarShift)}
        >
          {dataAppBar}
        </AppBar>
      </Hidden>

      <Hidden lgUp>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          {dataDrawer}
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, classes.drawerOpen)}
          classes={{
            paper: clsx(classes.drawerOpen),
          }}
        >
          {dataDrawer}
        </Drawer>
      </Hidden>
      <Loader showLoader={openModalLoadding} text="Actualizando creditos..." />
      <main className={classes.content}>{children}</main>
    </div>
  );
};
export default DrawerDash;
