import React, { Fragment } from "react";
import ModalComponent from "../../sharedComponents/ModalComponent";
import PaymentOptionsContainer from "../PaymentOptionsContainer";

const CreditsModal = (props) => {
  const { open, handleClose } = props;

  // const whatsappLink = "https://api.whatsapp.com/send?phone=3325481250";

  return (
    <Fragment>
      <ModalComponent
        open={open}
        handleClose={handleClose}
        body={<PaymentOptionsContainer handleClose={handleClose} />}
        showButton={false}
      />
    </Fragment>
  );
};

export default CreditsModal;
