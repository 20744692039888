import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../../constants/fontConsts";

const TabShippingTypeStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: 50,
  },
  tableContainer: {
    maxWidth: "90%",
    width: "auto",
    maxHeight: "90%",
    height: "auto",
    overflow: "auto",
    [theme.breakpoints.down(450)]: {
      width: 320,
    },
    [theme.breakpoints.down(380)]: {
      width: 300,
    },
  },
  headers: {
    background: "#dedede",
    padding: 10,
  },
  textHeader: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    "@media (max-width: 1300px)": {
      fontSize: 12,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "11px !important",
    },
  },
  textRowError: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    borderLeft: "1px solid  #e0e0e0",
    background: "#ff000087",
    color: "white",
    padding: 10,
    "@media (max-width: 1300px)": {
      fontSize: 12,
    },
  },
  textRow: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    borderLeft: "1px solid  #e0e0e0",
    padding: 10,
    "@media (max-width: 1300px)": {
      fontSize: 12,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "11px !important",
    },
  },
  textButtomGuide: {
    color: "#fff",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 15,
    textTransform: "capitalize",
    "@media (max-height: 670px)": {
      fontSize: 13,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "14px !important",
    },
  },
  button: {
    background: "#D15252",
    width: 200,
    height: 45,
    marginTop: 40,
    marginBottom: 30,
    borderRadius: 10,
    marginLeft: "auto",
    marginRight: "auto",
    "&:hover": {
      opacity: 0.7,
      background: "#D15252 !important",
    },
    "&.Mui-disabled": {
      background: "#adabab !important",
      color: "white",
    },
    [theme.breakpoints.down(600)]: {
      width: 150,
      height: 38,
    },
  },
  textAmoutTotal: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    borderLeft: "1px solid  #e0e0e0",
    padding: 10,
    textAlign: "end",
    fontSize: 15,
    "@media (max-width: 1300px)": {
      fontSize: 13,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "12px !important",
    },
  },
}));
export default TabShippingTypeStyles;
