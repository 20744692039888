import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS, POPPINS_FONTS } from "../../../constants/fontConsts";

const FormLoginStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "rgba(204, 63, 63, 0.9) !important",
    borderRadius: "100px 0px",
    width: "100%",
    height: "100vh",
    [theme.breakpoints.down(960)]: {
      borderRadius: 0,
    },
  },
  title: {
    color: "#fff",
    fontFamily: POPPINS_FONTS.POPPINS_BOLD,
    fontSize: 48,
    [theme.breakpoints.down(960)]: {
      fontSize: 30,
    },
    "@media (max-height: 750px)": {
      fontSize: 35,
    },
  },
  text: {
    color: "#fff",
    textAlign: "center",
    padding: "20px 120px",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 20,
    [theme.breakpoints.down(960)]: {
      fontSize: 16,
      padding: "20px 20px",
    },
    "@media (max-height: 750px)": {
      fontSize: 16,
    },
  },
  input: {
    width: 400,
    height: 55,
    background: "#FFF0DF",
    border: "1px solid #FFF0DF",
    borderRadius: 15,
    margin: "15px 0px",
    textAlign: "start",
    padding: 20,
    "&:focus": {
      outline: "none",
      backgroundColor: "#ffff",
      border: "2px solid #FFF0DF",
    },
  },
  text2: {
    color: "#fff",
    display: "flex",
    justifyContent: "start",
  },
  buttonInline: {
    background: "none",
    padding: 0,
    fontSize: 16,
    color: "#ff",
    "&:hover": {
      background: "none",
    },
  },
  containerCheck: {
    padding: "10px 70px",
    color: "#fff",
    maxWidth: 600,
  },
  checkbox: {
    fill: "#fff",
  },
  forgotPassword: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 12,
    color: "#fff",
  },
  textOlv: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 15,
    color: "#D9D9D9",
    paddingTop: 10,
  },
  buttonCrear: {
    width: 200,
    height: 50,
    background: "#FFA284 !important",
    borderRadius: "10px !important",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    color: "#fff !important",
    marginTop: "30px !important",
    "&:hover": {
      backgroundColor: "#ffff !important",
      color: "#F8822E !important",
    },
    "&.Mui-disabled": {
      background: "#bfbdbd !important",
      color: "white",
    },
  },
  icon: {
    color: "rgba(0, 0, 0, 0.45)",
  },
  inputComponent: {
    borderRadius: 15,
    width: 400,
    textAlign: "start",
    height: 55,
    backgroundColor: "#FFF0DF",
    margin: "10px 0px",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 15,
    [theme.breakpoints.down(960)]: {
      width: 320,
    },
    "@media (max-height: 750px)": {
      width: 300,
      height: 45,
    },
  },
  errorLabel: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 10,
    color: "#fff",
  },
  createNewAccountLabel: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    color: "#fff",
    fontSize: 14,
    cursor: "pointer",
  },
  root: {
    color: "#fff !important",
    "&$checked": {
      color: "#fff !important",
    },
  },
  checked: {},
  botonInicio: {
    color: "white",
    marginBottom: 20,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
  },
}));
export default FormLoginStyle;
