import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import DrawerDash from "../../components/DrawerDash";
import QuotesDashboardStyle from "./QuotesDashboardStyle";
import { notification } from "../../utils/notification";
import {
  parcelRates,
  verifyPostalCode,
  validateLiber,
} from "../../api/quotesApi";
import { AUTH_MESSAGES } from "../../constants/messages";
import Loader from "../../components/Loader";
import { ROUTES_CONSTANTS } from "../../constants/routesConstants";
import { useNavigate } from "react-router-dom";
import Card from "../../components/quotesDashboard/Card";

const QuotesDashboard = () => {
  const navigate = useNavigate();
  const classes = QuotesDashboardStyle();

  const [codigoPostalOrigen, setCodigoPostalOrigen] = useState("");
  const [codigoPostalDestino, setCodigoPostalDestino] = useState("");
  const [dataOrigin, setDataOrigin] = useState({
    estado: "",
    ciudad: "",
  });
  const [dataDestino, setDataDestino] = useState({
    estado: "",
    ciudad: "",
  });
  const [openModalLoadding, setOpenModalLoadding] = useState(false);

  const [medidas, setMedidas] = useState({
    alto: "",
    largo: "",
    ancho: "",
    peso: "",
    pesoVolumetrico: 0,
  });

  const handleChangeInputPostalCodeOrigin = (e) => {
    const inputText = e.target.value;
    if (/^\d*$/.test(inputText)) {
      setCodigoPostalOrigen(inputText);
    }
  };
  const handleChangeInputPostalCodeDestino = (e) => {
    const inputText = e.target.value;
    if (/^\d*$/.test(inputText)) {
      setCodigoPostalDestino(inputText);
    }
  };

  const [ratesList, setRatesList] = useState([]);

  const handleChangeInputs = (e) => {
    const { name, value } = e.target;
    setMedidas((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  if ((medidas.largo * medidas.ancho * medidas.alto) / 5000 <= medidas.peso) {
    medidas.pesoVolumetrico = medidas.peso;
  } else {
    medidas.pesoVolumetrico = medidas.pesoVolumetrico =
      (medidas.largo * medidas.ancho * medidas.alto) / 5000;
  }

  const handleCotizacion = async () => {
    setOpenModalLoadding(true);
    const payloadOrigen = {
      postal_code: codigoPostalOrigen,
    };
    const payloadDestino = {
      postal_code: codigoPostalDestino,
    };

    let extra_weight = 0,
      extra_weight_cost = 0,
      inter_zone_cost = 0,
      is_option_express = false,
      is_option_liber = false;

    if (medidas.ancho <= 80 && medidas.alto <= 80 && medidas.largo <= 80) {
      let payloadValidatedLiber = {
        postal_code_origin: codigoPostalOrigen,
        postal_code_destiny: codigoPostalDestino,
        package_weight:
          Math.ceil(medidas.pesoVolumetrico) > Math.ceil(medidas.peso)
            ? Math.ceil(medidas.pesoVolumetrico)
            : Math.ceil(medidas.peso),
      };

      await validateLiber(payloadValidatedLiber)
        .then((res) => {
          if (
            res.data.status_Code === 200 &&
            res.data.status_Message === "reachable postal codes"
          ) {
            extra_weight = res.data.extra_weight;
            extra_weight_cost = res.data.extra_weight_cost;
            inter_zone_cost = res.data.inter_zone_cost;
            is_option_express = res.data.is_option_express;
            is_option_liber = res.data.is_option_liber;
          } else {
            extra_weight = 0;
            extra_weight_cost = 0;
            inter_zone_cost = 0;
            is_option_express = false;
            is_option_liber = false;
          }
        })
        .catch((err) => {
          extra_weight = 0;
          extra_weight_cost = 0;
          inter_zone_cost = 0;
          is_option_express = false;
          is_option_liber = false;
        });
    }

    const payloadGetRates = {
      postal_code_origin: codigoPostalOrigen,
      postal_code_destiny: codigoPostalDestino,
      is_option_liber: is_option_liber,
      is_option_express: is_option_express,
      inter_zone_cost: inter_zone_cost,
      extra_weight: extra_weight,
      extra_weight_cost: extra_weight_cost,
      info_package: {
        package_height: Math.ceil(medidas.alto),
        package_width: Math.ceil(medidas.ancho),
        package_depth: Math.ceil(medidas.largo),
        package_weight: Math.ceil(medidas.peso),
        package_value: 0,
      },
    };

    verifyPostalCode(payloadOrigen).then((res) => {
      if (
        res.data.status_Code === 200 &&
        res.data.status_Message === "postal_code_accepted"
      ) {
        const { city, state } = res.data.info_postal_code;
        setDataOrigin({
          estado: state,
          ciudad: city,
        });
      }
    });
    verifyPostalCode(payloadDestino).then((res) => {
      if (
        res.data.status_Code === 200 &&
        res.data.status_Message === "postal_code_accepted"
      ) {
        const { city, state } = res.data.info_postal_code;
        setDataDestino({
          estado: state,
          ciudad: city,
        });
      }
    });

    parcelRates(payloadGetRates)
      .then((res) => {
        if (
          res.data.status_Code === 200 &&
          res.data.status_Message === AUTH_MESSAGES.GET_RATES
        ) {
          setRatesList(res.data.rates_list);
          scrollIntoResultSection();
        } else {
          setRatesList([]);
          notification(
            "No se encontraron opciones de envío disponibles",
            "success"
          );
        }
      })
      .catch((err) => {
        notification("Ocurrió un error al recuperar las opciones de envío");
        setRatesList([]);
      })
      .finally(() => {
        setOpenModalLoadding(false);
      });
  };
  const disableButtton =
    medidas.largo === "" ||
    medidas.ancho === "" ||
    medidas.alto === "" ||
    medidas.peso === "" ||
    medidas.pesoVolumetrico === "" ||
    codigoPostalOrigen === "" ||
    codigoPostalOrigen.length !== 5 ||
    codigoPostalDestino === "" ||
    codigoPostalDestino.length !== 5;

  const handleButtonClick = () => {
    navigate(ROUTES_CONSTANTS.FORM_CREAR_GUIA);
  };

  const scrollIntoResultSection = () => {
    const resultSection = document.getElementById("resultSection");

    if (resultSection) {
      resultSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <DrawerDash>
        <Box className={classes.container}>
          <Typography className={classes.title}>
            Cotiza las tárifas de tus envíos
          </Typography>

          <Box className={classes.containerCards}>
            {/* Origen */}
            <Box className={classes.cardOrigen}>
              <Typography className={classes.titleCard}>
                Información de origen
              </Typography>
              <TextField
                placeholder="Código Postal"
                name="codigoPostalOrigen"
                id="input-with-icon-textfield"
                variant="outlined"
                className={classes.inputCp}
                onChange={handleChangeInputPostalCodeOrigin}
                InputProps={{
                  style: {
                    fontSize: "13px",
                    textAlign: "center",
                    width: "148px",
                    height: "33px",
                  }, // Tamaño de fuente deseado
                }}
              />
              <Typography className={classes.estado}>
                Estado:{" "}
                <span className={classes.estadoRes}>{dataOrigin.estado}</span>{" "}
              </Typography>
              <Typography className={classes.estado}>
                Ciudad:{" "}
                <span className={classes.estadoRes}>{dataOrigin.ciudad}</span>{" "}
              </Typography>
            </Box>
            {/* Destino */}
            <Box className={classes.cardDestino}>
              <Typography className={classes.titleCard}>
                Información de destino
              </Typography>
              <TextField
                placeholder="Código Postal"
                name="codigoPostalDestino"
                id="input-with-icon-textfield"
                variant="outlined"
                className={classes.inputCp}
                onChange={handleChangeInputPostalCodeDestino}
                InputProps={{
                  style: {
                    fontSize: "13px",
                    textAlign: "center",
                    width: "148px",
                    height: "33px",
                  }, // Tamaño de fuente deseado
                  maxLength: 5,
                }}
              />
              <Typography className={classes.estado}>
                Estado:{" "}
                <span className={classes.estadoRes}>{dataDestino.estado}</span>{" "}
              </Typography>
              <Typography className={classes.estado}>
                Ciudad:{" "}
                <span className={classes.estadoRes}>{dataDestino.ciudad}</span>{" "}
              </Typography>
            </Box>
            {/* Medidas */}
            <Box className={classes.cardInfoPaquete}>
              <Typography className={classes.titleCard}>
                Información del paquete
              </Typography>
              {/* Dimensiones */}
              <Box className={classes.cardDimentions}>
                <Box className={classes.containerInputCard}>
                  <Typography className={classes.textInputCard}>
                    Largo
                  </Typography>
                  <TextField
                    type="number"
                    name="largo"
                    id="input-with-icon-textfield"
                    variant="outlined"
                    className={classes.inputMedida}
                    value={medidas.largo}
                    onChange={handleChangeInputs}
                    InputProps={{
                      style: {
                        fontSize: "12px",
                        textAlign: "center",
                        width: "70px",
                        height: "33px",
                      }, // Tamaño de fuente deseado
                    }}
                  />
                </Box>
                <Box className={classes.containerInputCard}>
                  <Typography className={classes.textInputCard}>
                    Alto
                  </Typography>
                  <TextField
                    type="number"
                    name="alto"
                    id="input-with-icon-textfield"
                    variant="outlined"
                    className={classes.inputMedida}
                    value={medidas.alto}
                    onChange={handleChangeInputs}
                    InputProps={{
                      style: {
                        fontSize: "12px",
                        textAlign: "center",
                        width: "70px",
                        height: "33px",
                      }, // Tamaño de fuente deseado
                    }}
                  />
                </Box>
                <Box className={classes.containerInputCard}>
                  <Typography className={classes.textInputCard}>
                    Ancho
                  </Typography>
                  <TextField
                    type="number"
                    name="ancho"
                    id="input-with-icon-textfield"
                    variant="outlined"
                    className={classes.inputMedida}
                    value={medidas.ancho}
                    onChange={handleChangeInputs}
                    InputProps={{
                      style: {
                        fontSize: "12px",
                        textAlign: "center",
                        width: "70px",
                        height: "33px",
                      }, // Tamaño de fuente deseado
                    }}
                  />
                </Box>
              </Box>
              {/* Pesos */}
              <Box className={classes.cardProducts}>
                <Box className={classes.containerInputCard}>
                  <Typography className={classes.textInputCard}>
                    Peso
                  </Typography>
                  <TextField
                    name="peso"
                    id="input-with-icon-textfield"
                    variant="outlined"
                    className={classes.inputMedida}
                    value={medidas.peso}
                    onChange={handleChangeInputs}
                    InputProps={{
                      style: {
                        fontSize: "12px",
                        textAlign: "center",
                        width: "114px",
                        height: "33px",
                      }, // Tamaño de fuente deseado
                    }}
                  />
                </Box>
                <Box className={classes.containerInputCard}>
                  <Typography className={classes.textInputCard}>
                    Peso a cotizar
                  </Typography>
                  <TextField
                    type="number"
                    name="pesoVolumetrico"
                    id="input-with-icon-textfield"
                    variant="outlined"
                    className={classes.inputMedida}
                    value={medidas.pesoVolumetrico}
                    onChange={handleChangeInputs}
                    disabled={true}
                    InputProps={{
                      style: {
                        fontSize: "12px",
                        textAlign: "center",
                        width: "114px",
                        height: "33px",
                      },
                      min: 0, // Establece la longitud mínima del texto
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.containerButton}>
            <Button
              variant="contained"
              className={classes.boton}
              disabled={disableButtton}
              onClick={handleCotizacion}
            >
              Cotizar
            </Button>
          </Box>

          <Grid container className={classes.containerTargets}>
            {ratesList.map((rate, index) => (
              <Card
                index={index}
                rate={rate}
                handleButtonClick={handleButtonClick}
              />
            ))}
          </Grid>
        </Box>

        {/* Modal Loadding */}
        <Loader showLoader={openModalLoadding} text="Generando cotización..." />
      </DrawerDash>
    </div>
  );
};

export default QuotesDashboard;
