import { Box, Typography } from '@material-ui/core'
import React from 'react'
import AddresseSyle from './Adresse.style'

const Addresse = (props) => {

const classes = AddresseSyle();

const {name, street, intNumber, extNumber, colony, city, state, postalCode, locationReferences} = props
  return (
    <Box className={classes.container}>
        <Typography className={classes.title}>Destino</Typography>
        <Box className={classes.informationAddesse}>
            <Typography  className={classes.textName}>{name}</Typography>
            <Typography  className={classes.text}>{street}, {colony}, {"Ext # " + extNumber} {intNumber? <>, Int # {intNumber}</> : ''}</Typography>
            <Typography  className={classes.text}>CP: {postalCode}</Typography>
            <Typography  className={classes.text}>{city} {state}</Typography>
            <Typography  className={classes.textRef}>{locationReferences? locationReferences: ''}</Typography>
        </Box>
    </Box>
  )
}

export default Addresse