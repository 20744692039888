import React, { Fragment, useEffect, useState } from "react";
import { AccessTimeOutlined } from "@material-ui/icons";
import { Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../../constants/routesConstants";
import { notification } from "../../../utils/notification";
import useUnsavedChanges from "../../../hooks/useUnsavedChanges";

const Timer = (props) => {
  const { classes } = props;

  const navigate = useNavigate();

  const { disableDirty } = useUnsavedChanges();

  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(10);

  useEffect(() => {
    if (minutes === 0 && seconds === 0) {
      notification("Se acabó el tiempo");
      disableDirty(false);
      setTimeout(() => {
        navigate(ROUTES_CONSTANTS.ORDENES, { replace: true });
      }, 2000);
    } else {
      let timer = setInterval(() => {
        setSeconds(seconds - 1);

        if (seconds === 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  });

  return (
    <Fragment>
      <AccessTimeOutlined className={classes.iconClock} />
      <Typography className={classes.clockText}>
        Genera las guías en:{" "}
        <strong className={classes.timeColor}>{`${
          minutes >= 0 && minutes < 10 ? "0" + minutes : minutes
        }:${seconds >= 0 && seconds < 10 ? "0" + seconds : seconds}`}</strong>
      </Typography>
    </Fragment>
  );
};

export default Timer;
