import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../constants/fontConsts";

const MassDeliveryStyle = makeStyles((theme) => ({
  containerTitle: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
  },
  title: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 20,
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      fontSize: 16,
    },
    "@media (max-height: 640px) and (min-width: 670px)": {
      fontSize: 17.5,
    },
    "@media (max-height: 640px) and (max-width: 768px)": {
      fontSize: 15,
    },
  },
  clock: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "start",
      marginBottom: 20,
    },
  },
  clockText: {
    fontSize: 14,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    color: "#717171",
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
    },
    "@media (max-height: 640px) and (max-width: 768px)": {
      fontSize: 12,
    },
  },
  iconClock: {
    color: "#717171",
    marginRight: 5,
    [theme.breakpoints.down("sm")]: {
      width: 22,
    },
    "@media (max-height: 640px) and (max-width: 768px)": {
      width: 18,
    }
  },
  timeColor: {
    color: "#D15252",
  },
}));

export default MassDeliveryStyle;
