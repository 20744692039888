import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const CardStyle = makeStyles((theme) => ({
  containerResultTarget: {
    width: 253,
    height: 365,
    background: "#fff",
    borderRadius: 10,
    boxShadow: "0px 1px 12px 0px rgba(255, 0, 0, 0.25)",
    marginBottom: 30,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(1290)]: {
      width: 240,
      height: 310,
      alignItems: "center",
    },
    [theme.breakpoints.down(1150)]: {
      width: 220,
      height: 290,
    },
    [theme.breakpoints.down(1100)]: {
      width: 200,
      height: 290,
    },
    [theme.breakpoints.down(1010)]: {
      width: 190,
      height: 290,
    },
    [theme.breakpoints.down(800)]: {
      width: 200,
      height: 280,
    },
    [theme.breakpoints.down(768)]: {
      width: 440,
      height: 160,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-evenly",
      paddingTop: 18,
    },
    [theme.breakpoints.down("xs")]: {
      width: 328,
      height: 120,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-evenly",
      paddingTop: 18,
    },
  },
  containerResultTargetIsLiber: {
    width: 253,
    height: 365,
    background: "#fff",
    borderRadius: 10,
    boxShadow: "0px 1px 12px 0px #00ff0a80",
    marginBottom: 30,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(1290)]: {
      width: 240,
      height: 310,
      alignItems: "center",
    },
    [theme.breakpoints.down(1150)]: {
      width: 220,
      height: 290,
    },
    [theme.breakpoints.down(1100)]: {
      width: 200,
      height: 290,
    },
    [theme.breakpoints.down(1010)]: {
      width: 190,
      height: 290,
    },
    [theme.breakpoints.down(800)]: {
      width: 200,
      height: 280,
    },
    [theme.breakpoints.down(768)]: {
      width: 440,
      height: 160,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-evenly",
      paddingTop: 18,
    },
    [theme.breakpoints.down("xs")]: {
      width: 328,
      height: 120,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-evenly",
      paddingTop: 18,
    },
  },
  imagenTarget: {
    width: "100%",
    height: 90,
    borderRadius: "10px 10px 0px 0px",
    objectFit: "cover",
    [theme.breakpoints.down(1290)]: {
      height: 70,
    },
    [theme.breakpoints.down("sm")]: {
      width: 100,
      height: "80%",
      objectFit: "fill",
      borderRadius: "10px 10px 10px 10px",
      marginTop: "-20px",
    },
  },
  containerTargetBody: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down(600)]: {
      marginTop: "-40px",
    },
  },
  containerTitle: {
    display: "flex",
    alignItems: "baseline",
  },
  titleTarget: {
    fontSize: 18,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    textAlign: "center",
    marginTop: 14,
    [theme.breakpoints.down(1590)]: {
      fontSize: 18,
    },
    [theme.breakpoints.down(1290)]: {
      fontSize: 18,
    },
    [theme.breakpoints.down(1150)]: {
      fontSize: 15.5,
    },
    [theme.breakpoints.down(960)]: {
      fontSize: 16,
    },
    [theme.breakpoints.down(768)]: {
      marginTop: 0,
      fontSize: 15,
    },
    [theme.breakpoints.down(670)]: {
      fontSize: 10,
    },
    [theme.breakpoints.down(600)]: {
      marginTop: 23,
    },
  },
  deliveryText: {
    fontSize: 19,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    marginTop: 20,
    color: "rgba(0, 0, 0, 0.67)",
    [theme.breakpoints.down(1590)]: {
      fontSize: 16,
    },
    [theme.breakpoints.down(1290)]: {
      fontSize: 14,
      marginTop: 10,
    },
    [theme.breakpoints.down(1150)]: {
      fontSize: 12.5,
    },
    [theme.breakpoints.down(960)]: {
      fontSize: 13,
      marginTop: 10,
    },
    [theme.breakpoints.down(670)]: {
      fontSize: 10.5,
      marginTop: 5,
    },
  },
  dayText: {
    fontSize: 16,
    marginTop: 10,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    color: "rgba(0, 0, 0, 0.67)",
    [theme.breakpoints.down(1590)]: {
      fontSize: 13,
    },
    [theme.breakpoints.down(1290)]: {
      fontSize: 12,
    },
    [theme.breakpoints.down(670)]: {
      fontSize: 9.5,
    },
  },
  amount: {
    fontSize: 23,
    marginTop: 12,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    [theme.breakpoints.down(1290)]: {
      fontSize: 16,
      marginTop: 10,
    },
    [theme.breakpoints.down(960)]: {
      fontSize: 15.5,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 12.5,
    },
  },
  buttonSelection: {
    background: "#DF2E38",
    width: 130,
    height: 35,
    borderRadius: 50,
    marginTop: 18,
    marginBottom: 10,
    [theme.breakpoints.down(1290)]: {
      width: 115,
      height: 33,
    },
    [theme.breakpoints.down(1150)]: {
      width: 100,
      height: 30,
    },
    [theme.breakpoints.down(960)]: {
      width: 110,
      height: 28,
      marginTop: 12,
    },
    [theme.breakpoints.down(670)]: {
      width: 20,
      height: 28,
      marginTop: 12,
    },
  },
  buttonText: {
    color: "white",
    fontSize: 13,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    textTransform: "capitalize",
    [theme.breakpoints.down(1290)]: {
      fontSize: 12,
    },
    [theme.breakpoints.down(1150)]: {
      fontSize: 10.5,
    },
    [theme.breakpoints.down(960)]: {
      fontSize: 11,
    },
    [theme.breakpoints.down(670)]: {
      fontSize: 9.5,
      display: "none",
    },
  },
  buttonArrow: {
    color: "white",
    display: "none",
    [theme.breakpoints.down(670)]: {
      display: "block",
      paddingTop: 8,
    },
  },
  buttonDetails: {
    margin: 0,
    padding: 0,
    marginTop: 15,
    [theme.breakpoints.down(1290)]: {
      marginTop: 10,
    },
  },
  textDetails: {
    fontSize: 12,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    textTransform: "capitalize",
    [theme.breakpoints.down(1590)]: {
      fontSize: 11,
    },
    [theme.breakpoints.down(1290)]: {
      fontSize: 10,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 9,
    },
  },
  contentButtons: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down(768)]: {
      flexDirection: "column",
    },
  },
}));
export default CardStyle;
