import axios from "axios";
const { REACT_APP_LIBER_API_URL } = process.env;

export const parcelRates = (payload) => {
  const request = {
    method: "POST",
    url: `${REACT_APP_LIBER_API_URL}/autopaquete/parcel-rates`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
    },
  };

  return axios(request);
};

export const verifyPostalCode = (payload) => {
  const request = {
    method: "POST",
    url: `${REACT_APP_LIBER_API_URL}/zones/get-info-postal-code`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
    },
  };

  return axios(request);
};

export const validateLiber = (payload) => {
  const request = {
    method: "POST",
    url: `${REACT_APP_LIBER_API_URL}/autopaquete/pre-rates`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
    },
  };

  return axios(request);
};
