import React, { Fragment, useState } from "react";
import UploadExcelStyle from "./UploadExcelStyle";
import { Box, Button, Typography } from "@material-ui/core";
import imgSubirArchivo from "../../../assets/img/dashboard/img-subirExcel.svg";
import * as XLSX from "xlsx";
import ModalReviewExcel from "../../../components/massDelivery/ModalReviewExcel";
import { verifyDataTrustedUser } from "./Functions";
import { TYPE_FILE } from "../../../constants/fileFormats";
import { verifyPostalCode, getLocation } from "../../../api/massDeliveryApi";
import { headers } from "./Headers";
import useLogout from "../../../hooks/useLogout";
import { notification } from "../../../utils/notification";
import Loader from "../../Loader";

const UploadExcel = (props) => {
  const {
    handleTabs,
    handleTabExcel,
    setInfoExcel,
    setFileBase64,
    disableTabs,
    state,
    setState,
    error,
    setError,
    selectedLocation,
    hidePersonalInformation,
  } = props;

  const [logout] = useLogout();

  const [showLoader, setShowLoader] = useState(false);

  const classes = UploadExcelStyle();

  const getRows = async () => {
    let sheet = state.woorksheets[0],
      rowsExcel = XLSX.utils.sheet_to_row_object_array(sheet.data);

    let newRows = [];

    setShowLoader(true);
    await Promise.all(
      rowsExcel.map(async (row) => {
        let payload = { postal_code: row["CP"] };

        let claves = Object.keys(row);

        for (let i = 0; i < claves.length; i++) {
          if (headers.includes(claves[i])) {
            return await verifyPostalCode(payload)
              .then(async (res) => {
                if (res.data.status_Code === 200 && res.data.validated) {
                  let payloadLocation = {
                    street: row["Calle"],
                    street_number: row["Numero_Exterior"],
                    city: row["Ciudad"],
                    state: row["Estado"],
                    colony: row["Colonia"],
                    postal_code: row["CP"],
                  };

                  let latitude = 0;
                  let longitude = 0;

                  await getLocation(payloadLocation)
                    .then((resLocation) => {
                      if (
                        resLocation.data.status_Code === 200 &&
                        resLocation.data.status_Message ===
                          "Longitude y latitude exists"
                      ) {
                        latitude = resLocation.data.latitude;
                        longitude = resLocation.data.longitude;
                      } else if (resLocation.data.status_Code === 401) {
                        notification("La sesión ha caducado");
                        logout();
                      }
                    })
                    .catch((err) => {});

                  newRows.push({
                    ...row,
                    valid_cp: true,
                    guide_cost: res.data.cost,
                    id_postal_code: res.data.id_postal_code,
                    differentZone:
                      selectedLocation.zoneName !== res.data.zone_name
                        ? true
                        : false,
                    costDifferentZone:
                      selectedLocation.zoneName !== res.data.zone_name
                        ? res.data.different_cost
                        : 0,
                    actualLimitWeight: res.data.actual_limit_weight,
                    extraWeightCost: res.data.extra_weight_cost,
                    maxWeight: res.data.max_weight,
                    latitude: latitude,
                    longitude: longitude,
                  });
                } else {
                  newRows.push({ ...row, valid_cp: false });
                }
              })
              .catch((err) => {
                newRows.push({ ...row, valid_cp: false });
              });
          }
        }
      })
    );

    setShowLoader(false);

    state.rows = newRows;
  };

  const getHeaders = () => {
    let sheet = state.woorksheets[0];
    state.headers = [];
    for (let key in sheet.data) {
      let regEx = new RegExp("^(\\w)(1){1}$");
      if (regEx.test(key) === true) {
        state.headers.push(sheet.data[key].v);
      }
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (e) => {
        let base64 = e.target.result;
        let dataFileFormat = base64.split(",");
        resolve(dataFileFormat[1]);
      };
    });
  };

  const readExcel = async (e, isFileButton) => {
    e.preventDefault();

    let excel =
      isFileButton === true ? e.target.files[0] : e.dataTransfer.files[0];
    let listWorksheet = [];

    if (excel) {
      if (excel.type === TYPE_FILE.EXCEL) {
        let file = await convertBase64(excel);

        setFileBase64(file);

        let reader = new FileReader();
        reader.readAsArrayBuffer(excel);
        reader.onloadend = async (e) => {
          let data = new Uint8Array(e.target.result);
          let excelRead = XLSX.read(data, { type: "array" });
          if (excelRead.SheetNames[0]) {
            listWorksheet.push({
              data: excelRead.Sheets[excelRead.SheetNames[0]],
              name: excelRead.SheetNames[0],
            });
          }

          state.woorksheets = listWorksheet;

          getHeaders();
          await getRows();
          setState({
            woorksheets: state.woorksheets,
            rows: state.rows,
            headers: state.headers,
            status: true,
          });

          verifyDataTrustedUser(
            state,
            setError,
            setInfoExcel,
            selectedLocation.id_location,
            hidePersonalInformation
          );
          disableTabs();
        };
      } else {
        setState({
          woorksheets: [],
          rows: [],
          headers: [],
          status: false,
        });
      }
    } else {
      setState({
        woorksheets: [],
        rows: [],
        headers: [],
        status: false,
      });
    }
  };

  const handleClick = () => {
    handleTabs("", 2);
    handleTabExcel();
  };

  return (
    <Fragment>
      <Loader showLoader={showLoader} text="Cargando información..." />
      <Box className={classes.container}>
        <Box className={classes.containerForm}>
          <Box
            className={classes.containerUploadFile}
            onDrop={(e) => readExcel(e, false)}
          >
            <img src={imgSubirArchivo} alt="" className={classes.img} />
            <Typography className={classes.text}>
              Coloca o selecciona el archivo de excel aquí
            </Typography>
            <input
              type="file"
              className={classes.input}
              accept={TYPE_FILE.EXCEL}
              name="excel"
              onChange={(e) => readExcel(e, true)}
              onClick={(e) => (e.target.value = null)}
            />
          </Box>
        </Box>
        <Box className={classes.containerButtons}>
          <Button
            className={classes.button}
            disabled={error}
            onClick={handleClick}
          >
            <Typography className={classes.textButtom}>Continuar</Typography>
          </Button>
          <ModalReviewExcel
            state={state}
            disabled={(error && state.rows.length > 0) || !error ? false : true}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default UploadExcel;
