import imagen1 from "../../assets/img/faqs/imageFaqs1.svg";
import imagen2 from "../../assets/img/faqs/imageFaqs2.svg";
import imagen3 from "../../assets/img/faqs/imageFaqs3.svg";
import imagen4 from "../../assets/img/faqs/imageFaqs4.svg";
import imagen5 from "../../assets/img/faqs/imageFaqs5.svg";
import imagen6 from "../../assets/img/faqs/imageFaqs6.svg";
import imagen7 from "../../assets/img/faqs/imageFaqs7.svg";
import imagen8 from "../../assets/img/faqs/imageFaqs8.svg";
import imagen9 from "../../assets/img/faqs/imageFaqs9.svg";
import imagen10 from "../../assets/img/faqs/imageFaqs10.svg";
import imagen11 from "../../assets/img/faqs/imageFaqs11.svg";

const url =
  "https://www.google.com/maps/d/u/0/viewer?hl=es&ll=20.60708668794172%2C-103.25683094356155&z=11&mid=1v2c8tJTvPIqcgcSc2O49sMGuRabmMGdp";

const Preguntas = [
  {
    pregunta: "¿Cuál es la forma de trabajo de Liber?",
    respuesta:
      "Liber es una empresa de última milla, la cuál se encarga de realizar envíos dentro de diferentes zonas de la ciudad de Guadalajara Jalisco.",
    imagen: imagen1,
  },
  {
    pregunta:
      "¿Los pedidos se envían al destinatario el mismo dìa que se recolectan?",
    respuesta:
      "Ofrecemos 2 tipos de envío, el envío express y el estandar. Cada uno de estos depende de cuestiones como la zona de entrega, la hora de entrega, entre otros.",
    imagen: imagen2,
  },
  {
    pregunta: "¿A partir de cuantos envíos se aplica oferta?",
    respuesta:
      "El día de hoy, contamos con recolección gratis apartir de 4 paquetes",
    imagen: imagen3,
  },
  {
    pregunta: "¿Las tarifas de envió pueden variar segun el dìa?",
    respuesta: (
      <>
        {" "}
        No, la tarifa de envió es igual todos los días de la semana (Sin contar
        los domingos no laborales).{" "}
        <a href={url} target="_blank" rel="noreferrer">
          {" "}
          En este enlace{" "}
        </a>{" "}
        podras ver el precio de envio por recolección solo con seleccionar la
        zona en el mapa{" "}
      </>
    ),
    imagen: imagen4,
  },
  {
    pregunta: "¿A que zonas de Guadalajara no se realizan recolecciones?",
    respuesta:
      "El día de hoy los envíos se realizan a la mayor parte de la zona metropolitana de Guadalaja, las zonas extendidas como Tapalpa, Tequila y sus alrededores, aún no estan en servicio.",
    imagen: imagen5,
  },
  {
    pregunta:
      "¿Cómo se asigna el tipo de transporte dependiendo el producto que se envia?",
    respuesta:
      "El día de hoy tenemos una limitación de 20KG volumetricos por paquete, y hasta 100KG volumetricos por todo y todos son recolectados por motocicletas.",
    imagen: imagen6,
  },
  {
    pregunta:
      "¿Existen beneficios para negocios que envien en cantidad y frecuentemente?",
    respuesta:
      "Si, todos aquellos negocios que son clientes frecuentes, pasan a formar parte de los clientes de confianza, los cuales tienen beneficios como pagar las guias a la hora de recolección o que tus clientes las paguen contra entrega, descuentos en envios , preferencia de entregas, asi como publicidad en nuestra pagina web",
    imagen: imagen7,
  },
  {
    pregunta: "¿Cuánto tarda en ser entregado mi pedido?",
    respuesta:
      "Si tu guía ha sido generado como express, el tiempo estimado 1-4 horas mientras que los enviós estandar son Programados el mismo dia o al dia hábil siguiente",
    imagen: imagen8,
  },
  {
    pregunta: "¿Cómo puedo optimizar el llenado de mis guias multiples?",
    respuesta:
      "El sistema te proporciona un ejemplo completo de los campos que debes de llenar, así mismo, te permite válidar si todos los campos que has ingresado están correctos, o no.",
    imagen: imagen9,
  },
  {
    pregunta: "¿Qué restricciones aplican en los paquetes a enviar?",
    respuesta:
      "Los paquetes no deben de exceder a más de 20KG volumétricos, así mismo, no puedes enviar un lote con más de 100KG volumétricos como total.",
    imagen: imagen10,
  },
  {
    pregunta: "¿El pago se puede realizar en el momento de la recolección?",
    respuesta:
      "Si eres un cliente de confianza, podrás emitir el pago de tus guías a la hora de la recolección, mientras que al no ser parte, tendrás que efectuar el pago en la plataforma.",
    imagen: imagen11,
  },
];
export default Preguntas;
