import React from "react";
import Navbar from "../Navbar";
import { Box } from "@material-ui/core";
import Footer from "../Footer";
const Layout = ({ children }) => {
  return (
    <Box>
      <Navbar />
      <main>{children}</main>
      <Footer />
    </Box>
  );
};

export default Layout;
