import { Box, Typography } from '@material-ui/core'
import React from 'react'
import SenderStyle from './Sender.style'

const Sender = (props) => {

const classes = SenderStyle();
const {name, cp, city, state, streetName, colony, extNumber, intNumber } = props

  return (
    <Box className={classes.container}>
        <Typography className={classes.title} >Origen</Typography>
        <Box className={classes.informationSender}>
            <Typography className={classes.textName}>{name} </Typography>
            {/* <Typography className={classes.text}>Teléfono: {phone}</Typography> */}
            <Typography className={classes.text}>Dirección: {streetName} {extNumber}  {intNumber} {colony} {cp} </Typography>
            {/* <Typography className={classes.text}>CP: {cp}</Typography> */}
            <Typography className={classes.text}>{city} {state}</Typography>
            {/* <Typography className={classes.text}>Estado: </Typography> */}
        </Box>
    </Box>
  )
} 

export default Sender