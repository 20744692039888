import { Box, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import ChangeSection from "../../components/RegisterLogin/ChangeSection";
import FormLogin from "../../components/RegisterLogin/FormLogin";
import imgMoto from "../../assets/img/imgMoto.svg";
import { ROUTES_CONSTANTS } from "../../constants/routesConstants";

const LoginPage = () => {
  const theme = useTheme();
  const showLeftSide = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <Box>
      <Grid container>
        {showLeftSide && (
          <Grid item lg={6}>
            <ChangeSection
              titleButton="Crear cuenta"
              img={imgMoto}
              slogan="Crea tu cuenta y forma parte de nosotros"
              redirectTo={ROUTES_CONSTANTS.REGISTRO}
            />
          </Grid>
        )}
        <Grid item xs={12} md={12} lg={6}>
          <FormLogin />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginPage;
