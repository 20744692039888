import { makeStyles } from "@material-ui/core";
import {
  MONTSERRAT_FONTS,
  POPPINS_FONTS,
} from "../../../../constants/fontConsts";

const TitleAndDescriptionStyle = makeStyles((theme) => ({
  containerText: {
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0px 100px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 40px",
    },
  },
  titleText: {
    textAlign: "justify",
    fontFamily: POPPINS_FONTS.POPPINS_BOLD,
    fontSize: 40,
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
      textAlign: "center",
    },
  },
  descriptionText: {
    marginTop: 30,
    textAlign: "left",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 25,
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
}));
export default TitleAndDescriptionStyle;
