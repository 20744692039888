import React, { Fragment } from "react";
import ModalComponent from "../../../../components/sharedComponents/ModalComponent";
import { Box, Button, Typography } from "@material-ui/core";
import DetailGuideModalStyles from "./DetailGuideModalStyles";

const DetailGuideModal = (props) => {
  const {
    open,
    handleClose,
    service,
    guideCost,
    extraWeightCost,
    shippingInsuranceCost,
    company,
    total,
    showShippingInsurance = false,
  } = props;

  const classes = DetailGuideModalStyles();

  const body = (
    <Box className={classes.content}>
      <Box className={classes.contentText}>
        <Typography className={classes.title}>
          Detalles de la guía {company} {service}
        </Typography>
        <Typography className={classes.text}>
          Costo de la guía:{" "}
          <Typography className={classes.textNumber}>$ {guideCost}</Typography>
        </Typography>
        {extraWeightCost > 0 && (
          <Typography className={classes.text}>
            Costo extra por peso:{" "}
            <Typography className={classes.textNumber}>
              $ {extraWeightCost}
            </Typography>
          </Typography>
        )}
        {shippingInsuranceCost > 0 ? (
          <Typography className={classes.text}>
            Costo extra por seguro de envío:{" "}
            <Typography className={classes.textNumber}>
              $ {shippingInsuranceCost}
            </Typography>
          </Typography>
        ) : (
          showShippingInsurance && (
            <Typography className={classes.text}>
              Costo extra por seguro de envío:{" "}
              <Typography className={classes.textNumber}>N/A</Typography>
            </Typography>
          )
        )}
        <Typography className={classes.text}>
          Costo total:
          <Typography className={classes.textNumber}>$ {total}</Typography>
        </Typography>
      </Box>
      <Box className={classes.contentButton}>
        <Button
          variant="contained"
          className={classes.button}
          onClick={handleClose}
        >
          <Typography className={classes.textButton}>Cerrar</Typography>
        </Button>
      </Box>
    </Box>
  );

  return (
    <Fragment>
      <ModalComponent
        open={open}
        handleClose={handleClose}
        body={body}
        showButton={false}
      />
    </Fragment>
  );
};

export default DetailGuideModal;
