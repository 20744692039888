import React from "react";
import ModalComponent from "../../../sharedComponents/ModalComponent";
import ViewPdfModalStyle from "./ViewPdfModalStyle";

const ViewPdfModal = (props) => {
  const { open, handleClose, pdf } = props;
  const classes = ViewPdfModalStyle();

  const body = (
    <iframe
      title="Guía"
      className={classes.container}
      src={`data:application/pdf;base64,${pdf}`}
    ></iframe>
  );

  return <ModalComponent open={open} handleClose={handleClose} body={body} />;
};

export default ViewPdfModal;
