import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../constants/fontConsts";

const ThirdOrdersStyle = makeStyles((theme) => ({
  title: {
    marginTop: 10,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    marginBottom: 20,
    [theme.breakpoints.down(700)]: {
      fontSize: 18,
    },
  },
}));

export default ThirdOrdersStyle;
