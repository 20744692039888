import { Modal } from "@material-ui/core";
import React, { Fragment } from "react";
import ModalComponentStyles from "./ModalComponent.styles";
import { Button } from "@material-ui/core";

const ModalComponent = (props) => {
  const {
    handleClose,
    open,
    body,
    hideBackdrop = true,
    showButton = true,
  } = props;
  const classes = ModalComponentStyles();

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="title-modal"
        aria-describedby="simple-modal-description"
        className={classes.modal}
        hideBackdrop={hideBackdrop}
      >
        <Fragment>
          {showButton && (
            <Button
              onClick={handleClose}
              className={classes.buttonClose}
              variant="outlined"
              size="small"
            >
              X
            </Button>
          )}
          {body}
        </Fragment>
      </Modal>
    </div>
  );
};

export default ModalComponent;
