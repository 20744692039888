import { Box, Typography } from '@material-ui/core';
import React from 'react'
import TitleAndDescriptionStyle from './TitleAndDecription.Style'

const TitleAndDescription = (props) => {
const classes = TitleAndDescriptionStyle();

  return (
      <Box className={classes.containerText}>
          <Typography variant='h4'className={classes.titleText}>{props.title}</Typography>
          <Typography variant='body1' className={classes.descriptionText}>
              {props.description}
          </Typography>
      </Box>
  )
}

export default TitleAndDescription