import React from "react";
import { TableCell, TableHead, TableRow } from "@material-ui/core";
import { headers } from "../headers";
import TableHeaderStyle from "./TableHeaderStyle";

const TableHeader = () => {
  const classes = TableHeaderStyle();

  return (
    <TableHead>
      <TableRow>
        {headers.map((headCell) => (
          <TableCell key={headCell.id} className={classes.text}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
