import { TablePagination } from "@material-ui/core";
import React from "react";
import CustomPaginationStyle from "./CustomPaginationStyle";

const CustomPagination = ({
  count,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
}) => {
  const classes = CustomPaginationStyle();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      classes={{
        root: classes.text,
        caption: classes.text,

        actions: classes.icon,
      }}
    />
  );
};

export default CustomPagination;
