import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  guidesInformation: {},
  customerInformation: {},
};


export const GuidesCustomerSlice = createSlice({
    name: "business",
    initialState,
    reducers: {
      setGuidesInformation(state, { payload }) {
        state.guidesInformation = payload;
      },
      setCustomerInformation(state, { payload }) {
        state.customerInformation = payload;
      },
   
    },
  });

export const  {setCustomerInformation, customerInformation} = GuidesCustomerSlice.actions;

export default GuidesCustomerSlice.reducer;