import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const DimentionsPackagesStyle = makeStyles((theme) => ({
    title:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_BOLD,
        fontSize:20
    },
    subTitle:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
        fontSize:15,
        marginBottom:15
    },
    simpleText:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
        fontSize:15, 
    },
    containerInputs:{
        paddingRight:100,
        marginTop:15
    },
    containerInputProducto:{
        display:"flex", 
        alignItems:"center"
    },
    inputProducto:{
        display:"flex", 
        flexDirection:"column"
    },
    label:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
        fontSize:10,
        marginBottom:10
    },
    containerBoxTresInputs:{
        display:"flex", 
        flexDirection:"column", 
        marginTop:15
    },
    boxTresInputs:{
        display:"flex", 
        alignItems:"center", 
        justifyContent:"space-between", 
        paddingRight:18,
    },
    gridContainer:{
        marginTop:15
    },
    resultado:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_BOLD,
        fontSize:25,
        backgroundColor:"#F8822E",
        borderRadius:10,
        color:"#fff",
        width:250,
        padding:"5px 0px",
        textAlign:"center",
        marginBottom:20
    },
    boxTextResults:{
        display:"flex", 
        justifyContent:"space-between",
        margin:"10px 0px"
    },
    textBold:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_BOLD,
        fontSize:15
    },
    textGris:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
        fontSize:15, 
        color:"rgba(0, 0, 0, 0.5)"
    }
    
}))
export default DimentionsPackagesStyle