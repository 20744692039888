import { notification } from "../../../../utils/notification";
import moment from "moment-timezone";
import { headers } from "../Headers";

export const verifyDataTrustedUser = async (
  state,
  setError,
  setInfoExcel,
  id_location,
  hidePersonalInformation
) => {
  let flagError = false,
    message = "",
    getGuides = [],
    sum = 0,
    getTotalGuides = 0,
    errorCounter = 0;

  await Promise.all(
    state.rows.map(async (row) => {
      let extraWeightCost = 0,
        extraWeight = 0,
        extraWeightCostVolumetric = 0,
        extraWeightVolumetric = 0,
        newExtraWeight = 0,
        newExtraWeightCost = 0;

      await Promise.all(
        state.headers.map(async (header) => {
          if (
            header === "Por_Cobrar" &&
            row[header].toLowerCase() === "si" &&
            row["Total_Por_Cobrar"] !== undefined &&
            !isNaN(row["Total_Por_Cobrar"])
          ) {
            sum += row["Total_Por_Cobrar"];
          }

          if (header === "Alto" || header === "Largo" || header === "Ancho") {
            if (
              validateRow(header, {
                alto: row["Alto"],
                largo: row["Largo"],
                ancho: row["Ancho"],
              })
            ) {
              errorCounter++;
              flagError = true;
              message =
                header === "Alto"
                  ? "La altura del producto es incorrecta"
                  : header === "Largo"
                  ? "El largo del producto es incorrecto"
                  : "La anchura del producto es incorrecta";
            } else {
              if (
                (row.Largo * row.Ancho * row.Alto) / 5000 >
                row.actualLimitWeight
              ) {
                let totalExtraWeightVolumetric = 0;
                totalExtraWeightVolumetric = Math.ceil(
                  (row.Largo * row.Ancho * row.Alto) / 5000 -
                    row.actualLimitWeight
                );

                extraWeightVolumetric = totalExtraWeightVolumetric;
                extraWeightCostVolumetric =
                  totalExtraWeightVolumetric * row.extraWeightCost;
              }
            }
          }

          if (header === "Total_Por_Cobrar") {
            if (
              validateRow(header, {
                por_cobrar: row["Por_Cobrar"],
                total_por_cobrar: row["Total_Por_Cobrar"],
              })
            ) {
              errorCounter++;
              flagError = true;
              message =
                "El Total_Por_Cobrar es incorrecto o se encuentra vacío";
            }
          }

          if (header === "Peso") {
            if (
              validateRow(header, {
                peso: row["Peso"],
                maxWeight: row.maxWeight,
              })
            ) {
              errorCounter++;
              flagError = true;
              message = "El Peso es incorrecto o se encuentra vacío";
            } else {
              if (row.Peso > row.actualLimitWeight) {
                let totalExtraWeight = 0;
                totalExtraWeight = Math.ceil(row.Peso - row.actualLimitWeight);

                extraWeight = totalExtraWeight;
                extraWeightCost = totalExtraWeight * row.extraWeightCost;
              }
            }
          }

          if (
            header !== "CP" &&
            header !== "Alto" &&
            header !== "Largo" &&
            header !== "Ancho" &&
            header !== "Total_Por_Cobrar" &&
            header !== "Peso"
          ) {
            if (validateRow(header, row[header])) {
              errorCounter++;
              flagError = true;
              message =
                header === "Producto" ||
                header === "Nombre" ||
                header === "Telefono" ||
                header === "Numero_Exterior" ||
                header === "Estado" ||
                header === "Por_Cobrar"
                  ? "El campo de Por_Cobrar es incorrecto o se encuentra vacío"
                  : `La ${header} es incorrecta o se encuentra vacía`;
            }
          }

          if (header === "CP") {
            if (row.valid_cp) {
              getTotalGuides += row.guide_cost;
            } else {
              errorCounter++;
              flagError = true;
              message = "El código postal es incorrecto";
            }
          }
        })
      );

      if (row.Peso > (row.Largo * row.Ancho * row.Alto) / 5000) {
        getTotalGuides +=
          (row.costDifferentZone ? row.costDifferentZone : 0) + extraWeightCost;
        newExtraWeight = extraWeight;
        newExtraWeightCost = extraWeightCost;
      } else {
        getTotalGuides +=
          (row.costDifferentZone ? row.costDifferentZone : 0) +
          extraWeightCostVolumetric;
        newExtraWeight = extraWeightVolumetric;
        newExtraWeightCost = extraWeightCostVolumetric;
      }

      getGuides.push({
        guide_info: {
          id_location: id_location,
          id_zone: 1,
          guide_cost: row.guide_cost ? row.guide_cost : 0,
          shipping_type: "estandar",
          payment_status: "",
          collection_cost: 0,
          package_content: row.Producto ? row.Producto : "",
          content_status: row.Por_Cobrar.toLowerCase() === "si" ? false : true,
          content_price:
            row.Por_Cobrar.toLowerCase() === "si" ? row.Total_Por_Cobrar : 0,
          package_height: row.Alto ? row.Alto : 0,
          package_length: row.Largo ? row.Largo : 0,
          package_width: row.Ancho ? row.Ancho : 0,
          package_weight: row.Peso ? Math.ceil(row.Peso) : 0,
          volumetric_weight: Math.ceil(
            (row.Largo * row.Ancho * row.Alto) / 5000
          ),
          estimated_delivery_date: moment()
            .tz("America/Mexico_City")
            .format("yyyy-MM-DD"),
          extra_weight: newExtraWeight,
          extra_weight_cost: newExtraWeightCost,
          inter_zone_cost: row.costDifferentZone ? row.costDifferentZone : 0,
        },
        recipient_info: {
          full_name: row.Nombre ? row.Nombre : "",
          telephone_number: row.Telefono ? row.Telefono : "",
          state: row.Estado ? row.Estado : "",
          city: row.Ciudad ? row.Ciudad : "",
          colony: row.Colonia ? row.Colonia : "",
          street_name: row.Calle ? row.Calle : "",
          ext_number: row.Numero_Exterior ? row.Numero_Exterior : "",
          int_number: row.Numero_Interior ? row.Numero_Interior : "",
          id_postal_code: row.id_postal_code ? row.id_postal_code : 0,
          location_references: row.Referencias ? row.Referencias : "",
          latitude: row.latitude,
          longitude: row.longitude,
        },
      });
    })
  );

  if (state.headers.length !== headers.length) {
    flagError = true;
    message = "Los nombres de las columnas son incorrectos";
  }

  if (state.rows.length > 20) {
    flagError = true;
    message = "El archivo contiene más de las 20 guías permitidas";
  }

  if (state.rows.length === 0) {
    flagError = true;
    message = "El archivo no contiene información";
  }

  if (flagError) {
    errorCounter > 1
      ? notification("La información del archivo de excel es incorrecta")
      : notification(message);

    setInfoExcel({
      totalGuides: 0,
      guides: [],
      positiveBalance: 0,
      amountTotalGuides: 0,
      totalGuidesExpress: 0,
      totalGuidesEstandar: 0,
    });
  } else {
    notification("La información se cargo correctamente", "success");
    setInfoExcel({
      totalGuides: state.rows.length,
      guides: getGuides,
      positiveBalance: sum,
      amountTotalGuides: getTotalGuides,
    });
  }
  //console.log(getGuides);

  setError(flagError);
};

export const validateRow = (header, value) => {
  let flagValidation = false;

  if (headers.indexOf(header) === -1) {
    flagValidation = true;
  }

  if (
    (header === "Producto" ||
      header === "Nombre" ||
      header === "Calle" ||
      header === "Numero_Exterior" ||
      header === "Colonia" ||
      header === "Ciudad" ||
      header === "Estado") &&
    value === undefined
  ) {
    flagValidation = true;
  }

  if (
    header === "Cantidad" &&
    (value === undefined || isNaN(value) || value < 1)
  ) {
    flagValidation = true;
  }

  if (
    header === "Alto" &&
    (isNaN(value.alto) ||
      value.alto <= 0 ||
      value.alto > 80 ||
      value.alto === undefined)
  ) {
    flagValidation = true;
  } else if (
    header === "Alto" &&
    (value.largo * value.ancho * value.alto) / 5000 > 25
  ) {
    flagValidation = true;
  }

  if (
    header === "Largo" &&
    (isNaN(value.largo) ||
      value.largo <= 0 ||
      value.largo > 80 ||
      value.largo === undefined)
  ) {
    flagValidation = true;
  } else if (
    header === "Largo" &&
    (value.largo * value.ancho * value.alto) / 5000 > 25
  ) {
    flagValidation = true;
  }

  if (
    header === "Ancho" &&
    (isNaN(value.ancho) ||
      value.ancho <= 0 ||
      value.ancho > 80 ||
      value.ancho === undefined)
  ) {
    flagValidation = true;
  } else if (
    header === "Ancho" &&
    (value.largo * value.ancho * value.alto) / 5000 > 25
  ) {
    flagValidation = true;
  }

  if (
    header === "Peso" &&
    (isNaN(value.peso) || value.peso > value.maxWeight || value.peso <= 0)
  ) {
    flagValidation = true;
  }

  if (
    header === "Telefono" &&
    (isNaN(value) || value.toString().length !== 10)
  ) {
    flagValidation = true;
  }

  if (
    header === "Pagado" &&
    (value === undefined || ["SI", "NO"].indexOf(value) === -1)
  ) {
    flagValidation = true;
  }

  if (header === "Total_Por_Cobrar") {
    if (value.por_cobrar.toLowerCase() === "si") {
      if (
        value.total_por_cobrar === undefined ||
        isNaN(value.total_por_cobrar) ||
        value.total_por_cobrar <= 0
      ) {
        flagValidation = true;
      }
    }
  }

  return flagValidation;
};
