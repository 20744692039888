import React, { Fragment, useState, useEffect } from "react";
import {
  TableRow,
  TableCell,
  Paper,
  TableContainer,
  Table,
  TableBody,
  Typography,
  Box,
  Button,
  Hidden,
} from "@material-ui/core";
import { getThirdOrders } from "../../../api/thirdOrdersApi";
import { notification } from "../../../utils/notification";
import TableSkeleton from "../../sharedComponents/TableSkeleton";
import CustomPagination from "../../sharedComponents/CustomPagination";
import TableHeader from "./TableHeader";
import DataFilterOrderStyle from "./DataFilterOrderStyle";
import clsx from "clsx";
import NotRows from "../../../assets/img/orders/no-results.png";
import useLogout from "../../../hooks/useLogout";
import ViewPdfModal from "./ViewPdfModal";
import VisibilityIcon from "@material-ui/icons/Visibility";

const DataFilterOrder = () => {
  const classes = DataFilterOrderStyle();
  const [logout] = useLogout();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [pdf, setPdf] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setLoading(true);

    getThirdOrders()
      .then((res) => {
        if (res.data.status_Code === 200) {
          setRowsData(res.data.shippings);
        } else if (res.data.status_Code === 401) {
          notification("La sesión ha caducado", "error");
          logout();
        } else {
          notification("Ocurrió un error al recuperas las ordenes", "error");
        }
      })
      .catch((err) => {
        notification("Ocurrió un error al recuperas las ordenes", "error");
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <div className={classes.content}>
        <Paper className={classes.container}>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={"small"}
              aria-label="enhanced table"
            >
              <TableHeader />
              <TableBody>
                {!loading ? (
                  rowsData.length > 0 ? (
                    rowsData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id_third_guide}
                            style={{ background: "none" }}
                          >
                            <TableCell
                              align="left"
                              className={clsx(
                                classes.text,
                                index % 2 ? classes.bgWhite : classes.bgGray
                              )}
                            >
                              {row.shipping_label_tracking_id}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={clsx(
                                classes.text,
                                index % 2 ? classes.bgWhite : classes.bgGray
                              )}
                            >
                              {row.recipient}
                            </TableCell>

                            <TableCell
                              align="left"
                              className={clsx(
                                classes.text,
                                index % 2 ? classes.bgWhite : classes.bgGray
                              )}
                            >
                              {row.shipping_company}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={clsx(
                                classes.text,
                                index % 2 ? classes.bgWhite : classes.bgGray
                              )}
                            >
                              {row.shipping_code}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={clsx(
                                classes.text,
                                index % 2 ? classes.bgWhite : classes.bgGray
                              )}
                            >
                              {row.total}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={clsx(
                                classes.text,
                                index % 2 ? classes.bgWhite : classes.bgGray
                              )}
                            >
                              {row.register_date}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={
                                index % 2 ? classes.bgWhite : classes.bgGray
                              }
                            >
                              <Hidden xsDown>
                                <Button
                                  onClick={() => {
                                    setPdf(row.base_pdf);
                                    setOpen(true);
                                  }}
                                  className={classes.containerIcon}
                                >
                                  <VisibilityIcon className={classes.icon} />
                                </Button>
                              </Hidden>
                              <Hidden smUp>
                                <a
                                  href={`data:application/pdf;base64,${row.base_pdf}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className={classes.containerIcon}
                                  title="PDF"
                                  download={true}
                                >
                                  <VisibilityIcon className={classes.icon} />
                                </a>
                              </Hidden>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9}>
                        <Box className={classes.containerNoRecords}>
                          <img
                            src={NotRows}
                            alt="Sin registros"
                            className={classes.imageNoRecords}
                          />
                          <Typography className={classes.textNoRecords}>
                            No se encontraron guías
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableSkeleton rowsNum={5} columnsNum={7} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            count={rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Paper>
      </div>
      <ViewPdfModal open={open} handleClose={handleClose} pdf={pdf} />
    </Fragment>
  );
};

export default DataFilterOrder;
