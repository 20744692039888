import { makeStyles } from "@material-ui/core";
import {
  MONTSERRAT_FONTS,
  POPPINS_FONTS,
} from "../../../../constants/fontConsts";

const CircleAndDescriptionStyle = makeStyles((theme) => ({
  mainContainer: {
    paddingLeft: 40,
    paddingBottom:60
  },
  containerBox: {
    padding: "0px 40px",
  },
  container: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    marginTop: 10,
  },
  titleContainer: {
    marginBottom: 30,
    fontFamily: POPPINS_FONTS.POPPINS_BOLD,
    fontSize: 40,
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
  },
  title: {
    textAlign: "start",
    fontFamily: POPPINS_FONTS.POPPINS_SEMIBOLD,
    fontSize: 25,
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
    },
  },
  description: {
    textAlign: "start",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 25,
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  // imageContainer: {
  //   border: 1,
  //   marginRight: 10,
  // },
  imageIcon: {
    border: "2px solid #DA2424",
    padding: 20,
    margin: 10,
    borderRadius: 50,
  },
  imageIconSmall: {
    border: "2px solid #DA2424",
    padding: 20,
    margin: 10,
    borderRadius: 50,
  },

  containerGroup: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  containerText: {
    display: "flex",
    flexDirection: "column",
  },
  text: {
    padding: "10px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 30px",
    },
  },
  imageGroup: {
    marginTop:40,
    [theme.breakpoints.down("sm")]: {
      width: 100,
      height: 600,
      marginTop:0,
    },
    [theme.breakpoints.down("xs")]: {
      width: 110,
      height: 600,
      marginTop:0,
    },
  },
}));
export default CircleAndDescriptionStyle;
