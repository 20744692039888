import React from "react";
import DrawerDash from "../../components/DrawerDash";
import { Typography } from "@material-ui/core";
import LotsStyle from "./LotsStyle";
import DataFilterShipment from "../../components/lots/DataFilterLots";

const Lots = () => {
  const classes = LotsStyle();

  return (
    <div>
      <DrawerDash>
        <Typography
          className={classes.title}
          variant="h5"
          id="tableTitle"
          component="div"
        >
          Lotes pendientes
        </Typography>
        <DataFilterShipment />
      </DrawerDash>
    </div>
  );
};

export default Lots;
