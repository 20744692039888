import React from "react";
import DrawerDash from "../../components/DrawerDash";
import DataFilterOrder from "../../components/thirdOrdes/DataFilterOrder";
import { Typography } from "@material-ui/core";
import ThirdOrdersStyle from "./ThirdOrdersStyle";

export default function ThirdOrders() {
  const classes = ThirdOrdersStyle();

  return (
    <DrawerDash>
      <Typography
        className={classes.title}
        variant="h5"
        id="tableTitle"
        component="div"
      >
        Historial de guías de Terceros
      </Typography>
      <DataFilterOrder />
    </DrawerDash>
  );
}
