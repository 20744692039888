import { Box, TableCell, TableRow, Typography } from "@material-ui/core";
import React from "react";
import NotRows from "../../../assets/img/orders/no-results.png";
import NotFoundRowsStyle from "./NotFoundRowsStyle";

const NotFoundRows = (props) => {
  const { colSpan, text = "No se encontraron guías" } = props;

  const classes = NotFoundRowsStyle();

  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <Box className={classes.containerNoRecords}>
          <img
            src={NotRows}
            alt="Sin registros"
            className={classes.imageNoRecords}
          />
          <Typography className={classes.textNoRecords}>{text}</Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default NotFoundRows;
