import { Box, Button, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import Addresse from "../../components/tracking/Addressee";
import PackageData from "../../components/tracking/PackageData";
import Sender from "../../components/tracking/Sender";
import TrackingStyle from "./Tracking.style";
import { searchPackage } from "../../api/trackingApi";
import imgTracking from "../../assets/img/tracking/imageTracking.svg";
import { notification } from "../../utils/notification";
import Loader from "../../components/Loader";
import { useLocation } from "react-router-dom";

const Tracking = () => {
  const classes = TrackingStyle();

  const { state } = useLocation();

  const [codeTracking, setCodeTracking] = useState("");
  // Modal Loadding
  const [openModalLoadding, setOpenModalLoadding] = useState(false);

  const [userInfo, setUserInfo] = useState({});
  const [recipientInfo, setRecipientInfo] = useState({});
  const [packageInfo, setPackageInfo] = useState({});
  const [guidesHistory, setGuidesHistory] = useState([]);
  const [packageFound, setPackageFound] = useState(false);

  useEffect(() => {
    if (state) {
      window.history.replaceState({}, "");
      setCodeTracking(state);
      handleTrakingCode(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTrakingCode = (myTrackingCode) => {
    setOpenModalLoadding(true);
    searchPackage(myTrackingCode)
      .then((res) => {
        const result = res.data;
        if (
          result.status_Code === 200 &&
          result.status_Message === "package found"
        ) {
          setUserInfo(res.data.tracking_info.user_info);
          setRecipientInfo(res.data.tracking_info.recipent_info);
          setPackageInfo(res.data.tracking_info.package_info);
          setGuidesHistory(res.data.tracking_info.guides_history);
          setPackageFound(true);
          setOpenModalLoadding(false);
        } else {
          setPackageFound(false);
          notification("El código de rastreo es incorrecto ", "error");
          setOpenModalLoadding(false);
        }
      })
      .catch((err) => {
        setPackageFound(false);
        notification("Ocurrió un error al rastrear el código", "error");
        setOpenModalLoadding(false);
      });
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleGetTrakingCode = (e) => {
    let valueInput = e.target.value;

    setCodeTracking(valueInput);

    valueInput.length === 10
      ? setIsButtonDisabled(false)
      : setIsButtonDisabled(true);
  };

  return (
    <div>
      <Layout
        style={{
          background:
            "linear-gradient(10.79deg, #FFFFFF 2.97%, #EFF1FC 59.78%, #FFFFFF 97.12%)",
        }}
      >
        <Box className={classes.container}>
          <Box className={classes.topSection}>
            <Typography className={classes.title}>
              Rastrea tu paquete
            </Typography>
            <Box className={classes.containerInputBoton}>
              <TextField
                variant="outlined"
                placeholder={"N° Guía"}
                onChange={handleGetTrakingCode}
                className={classes.inputTracking}
                value={codeTracking}
              />
              <Button
                type="button"
                variant="contained"
                onClick={() => handleTrakingCode(codeTracking)}
                className={
                  isButtonDisabled
                    ? classes.botonTrackingDisable
                    : classes.botonTracking
                }
                disabled={isButtonDisabled}
              >
                Rastrear
              </Button>
            </Box>
          </Box>
          {packageFound ? (
            <>
              <Box className={classes.containerResponse}>
                <Box className={classes.containerHistory}>
                  <Box className={classes.containerInformation}>
                    <Typography className={classes.textInfo}>
                      Informacion del paquete
                    </Typography>
                    <Sender
                      name={userInfo.sender}
                      phone={userInfo.telephone_number}
                      cp={userInfo.postal_code}
                      city={userInfo.city}
                      state={userInfo.state}
                      streetName={userInfo.street_name}
                      colony={userInfo.colony}
                      extNumber={userInfo.ext_number}
                      intNumber={userInfo.int_number}
                    />
                    <Addresse
                      name={recipientInfo.full_name}
                      phone={recipientInfo.telephone_number}
                      street={recipientInfo.street_name}
                      intNumber={recipientInfo.int_number}
                      extNumber={recipientInfo.ext_number}
                      colony={recipientInfo.colony}
                      city={recipientInfo.city}
                      state={recipientInfo.state}
                      postalCode={recipientInfo.postal_code}
                      locationReferences={recipientInfo.location_references}
                    />
                    <PackageData
                      packageWeight={packageInfo.package_weight}
                      packageHeigt={packageInfo.package_height}
                      packageLength={packageInfo.package_length}
                      packageWidth={packageInfo.package_width}
                      destiny_zone={packageInfo.destiny_zone}
                      destiny_area={packageInfo.destiny_area}
                      destiny_sector={packageInfo.destiny_sector}
                      destiny_subsector={packageInfo.destiny_subsector}
                    />
                  </Box>
                  <hr className={classes.hr} />
                  <Box className={classes.containerInformationHistory}>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "start",
                        flexDirection: "column",
                      }}
                    >
                      <Typography className={classes.textHistoryIndication}>
                        Estado del paquete
                      </Typography>
                      <Typography className={classes.textHistoryStatus}>
                        {guidesHistory[guidesHistory.length - 1].status}
                      </Typography>
                      <Typography className={classes.textHistory}>
                        Historial del paquete
                      </Typography>
                    </Box>
                    <Box className={classes.timeline}>
                      {guidesHistory.map((history, index) => (
                        <Box key={index} className={classes.entry}>
                          <Box className={classes.titleTimeLine}>
                            <Typography className={classes.dateHistory}>
                              {history.date}{" "}
                            </Typography>
                          </Box>
                          <Box className={classes.body}>
                            <Typography className={classes.statusPackage}>
                              {history.status}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <Box className={classes.containerImage}>
              <Typography className={classes.textIndication}>
                Ingresa tu número de guía
              </Typography>
              <img
                src={imgTracking}
                alt="imagen de recibido"
                className={classes.imageTracking}
              />
            </Box>
          )}
        </Box>

        {/* Modal Loadding */}
        <Loader showLoader={openModalLoadding} text="Buscando guía..." />
      </Layout>
    </div>
  );
};

export default Tracking;
