import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../../constants/fontConsts";

const TableHeaderStyle = makeStyles((theme) => ({
  text: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    [theme.breakpoints.down(1150)]: {
      fontSize: 12.5,
    },
    [theme.breakpoints.down(700)]: {
      fontSize: 11.5,
    },
    [theme.breakpoints.down(600)]: {
      padding: "6px 6px 6px 6px",
    },
  },
}));

export default TableHeaderStyle;
