import { Tab, Tabs, AppBar } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import DownloadExcel from "../DownloadExcel";
import UploadExcel from "../UploadExcel";
import TabsDeliveryStyles from "./TabsDeliveryStyles";
import imgSubirArchivo from "../../../assets/img/dashboard/img-Excel.svg";
import imgEntregaEstandar from "../../../assets/img/dashboard/img-entregaEstandar.svg";
import imgAddress from "../../../assets/img/dashboard/img-address.svg";
import TabShippingType from "./TabShippingType";
import TabOriginAddress from "./TabOriginAddress";
import useUnsavedChanges from "../../../hooks/useUnsavedChanges";

const TabsDelivery = () => {
  const classes = TabsDeliveryStyles();

  const { propmt, enableDirty, disableDirty } = useUnsavedChanges();

  const [value, setValue] = useState(0);
  const [fileBase64, setFileBase64] = useState(true);

  const [disableExcel, setDisableExcel] = useState(true);
  const [disableShipment, setDisableShipment] = useState(true);

  //Upload Excel
  const [error, setError] = useState(true);

  const [infoExcel, setInfoExcel] = useState({
    totalGuides: 0,
    guides: [],
    positiveBalance: 0,
    amountTotalGuides: 0,
    totalGuidesExpress: 0,
    totalGuidesEstandar: 0,
  });

  const [state, setState] = useState({
    woorksheets: [],
    rows: [],
    headers: [],
    status: false,
  });

  //Address
  const [selectedLocation, setSelectedLocation] = useState({
    alias: "",
    city: "",
    colony: "",
    ext_number: "",
    id_location: "",
    int_number: "",
    location_references: "",
    postal_code: "",
    principal: "",
    state: "",
    street_name: "",
    zoneName: "",
  });

  const [hidePersonalInformation, setHidePersonalInformation] = useState(false);

  const handleTabs = (e, val) => {
    setValue(val);
  };

  const handleTabAddress = () => {
    setDisableExcel(false);
  };

  const disableTabsAddress = () => {
    setDisableExcel(true);
    setDisableShipment(true);
  };

  const handleTabExcel = () => {
    setDisableShipment(false);
  };

  const disableTabs = () => {
    setDisableShipment(true);
  };

  return (
    <Fragment>
      {propmt}
      <AppBar position="static" color="transparent" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleTabs}
          indicatorColor="secondary"
          textColor="primary"
          className={classes.tabs}
          classes={{ flexContainer: classes.tabsHeader }}
        >
          <Tab
            icon={<img src={imgAddress} className={classes.img} alt="envio" />}
            classes={{
              wrapper: classes.labelContainer,
            }}
            label="Domicilio"
            className={classes.tab}
          />

          <Tab
            icon={
              <img src={imgSubirArchivo} className={classes.img} alt="file" />
            }
            label="Archivo de Excel"
            disabled={disableExcel}
            classes={{
              wrapper: classes.labelContainer,
            }}
            className={classes.tab}
          />
          <Tab
            icon={
              <img
                src={imgEntregaEstandar}
                className={classes.img}
                alt="envio"
              />
            }
            label="Tipo de Envio"
            disabled={disableShipment}
            classes={{
              wrapper: classes.labelContainer,
            }}
            className={classes.tab}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <TabOriginAddress
          handleTabs={handleTabs}
          handleTabAddress={handleTabAddress}
          setSelectedLocation={setSelectedLocation}
          selectedLocation={selectedLocation}
          hidePersonalInformation={hidePersonalInformation}
          setHidePersonalInformation={setHidePersonalInformation}
          disableTabsAddress={disableTabsAddress}
          enableDirty={enableDirty}
          setError={setError}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DownloadExcel />
        <UploadExcel
          handleTabs={handleTabs}
          handleTabExcel={handleTabExcel}
          setInfoExcel={setInfoExcel}
          setFileBase64={setFileBase64}
          disableTabs={disableTabs}
          state={state}
          setState={setState}
          error={error}
          setError={setError}
          selectedLocation={selectedLocation}
          hidePersonalInformation={hidePersonalInformation}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TabShippingType
          infoExcel={infoExcel}
          fileBase64={fileBase64}
          disableDirty={disableDirty}
          hidePersonalInformation={hidePersonalInformation}
        />
      </TabPanel>
    </Fragment>
  );
};

export default TabsDelivery;

const TabPanel = (props) => {
  const { children, value, index } = props;
  return value === index && children;
};
