import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const ImageStatusPackageStyle = makeStyles((theme)=>({
    container:{
        display: "flex",   
        alignItems:"center",
        justifyContent:"center"
    },
    containerImageText:{
        margin:20,
    },
    containerStatus:{
        border: "2px solid #373334",
        display: "flex",
        justifyContent:"center",
        alignItems:"center",
        background:"rgba(77, 74, 74, 0.06);",
        padding:25,
        borderRadius:50
    },
    containerStatusSelect:{
        border: "2px solid #373334",
        display: "flex",
        justifyContent:"center",
        alignItems:"center",
        background:"rgba(77, 74, 74, 0.4)",
        padding:25,
        borderRadius:50
    },
    image:{
        
    },
    textImage:{
        fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
        fontSize:10,
        textAlign:"center",
        marginTop:10
    },
    textUpdate:{
        fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
        fontSize:16,
        marginTop:10
    },
    separador:{
        marginBottom: 30
    }
}))

export default ImageStatusPackageStyle;