import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import ChangeStyle from "./Change.style";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import { ROUTES_CONSTANTS } from "../../../constants/routesConstants";
import { useNavigate } from "react-router-dom";
const ChangeSection = (props) => {
  const { titleButton, img, slogan, redirectTo } = props;
  const navigate = useNavigate();
  const handleRedirectTo = (route) => {
    navigate(route);
  };

  const classes = ChangeStyle();

  return (
    <Box className={classes.container}>
      <Button
        onClick={() => {
          handleRedirectTo(ROUTES_CONSTANTS.LANDING);
        }}
        startIcon={<HomeOutlinedIcon />}
        className={classes.botonInicio}
      >
        Ir al inicio
      </Button>

      <Typography className={classes.title}>Liber</Typography>
      <Typography className={classes.text}>{slogan}</Typography>

      <Button
        className={classes.button}
        onClick={() => {
          handleRedirectTo(redirectTo);
        }}
      >
        {titleButton}
      </Button>
      <img
        src={img}
        className={classes.imgChange}
        alt="imagen de moto de liber"
      />
    </Box>
  );
};

export default ChangeSection;
