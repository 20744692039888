import React from 'react'
import Layout from '../../components/Layout/Layout'
import { Box, Typography } from '@material-ui/core'
import ShippingZonesStyles from './ShippingZonesStyle'
import { URLCONST } from '../../constants/routesConstants'

const ShippingZones = () => {

const classes = ShippingZonesStyles();
  return (
    <div>
        <Layout>
            
            <Box className={classes.container}>
              <Typography className={classes.headerText}>Zonas de envío</Typography>
              <Typography className={classes.textAlign}>  
                Estas son las zonas de envio de LIBER, puedes 
                seleccionar la sección a donde desear realizar tu envió y podrás ver la cotizacionde 
                costo de recolección.
              </Typography>
              <Box className={classes.containerMap}>
                  <iframe title='mapsLiber' src={URLCONST.IFRAME_MAPS_CLIENTS} className={classes.iframeMaps}></iframe>
              </Box>
            </Box>
          
        </Layout>
    </div>
  )
}

export default ShippingZones