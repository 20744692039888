import { Box, Typography } from '@material-ui/core'
import React from 'react'
import ImageStatusPackageStyle from './ImageStatusStyle'
import imageRecibido from "../../../assets/img/tracking/imageRecibido.svg"
import imageTransito from "../../../assets/img/tracking/imageTransito.svg"
import imageCamino from "../../../assets/img/tracking/imageCamino.svg"
import imageEntregado from "../../../assets/img/tracking/imageEntregado.svg"
import imageSecuencia from "../../../assets/img/tracking/imageSecuencia.svg"
import { MESSAGES_STATUS_PACKAGE } from '../../../constants/messages'
// import datos from '../data'



// const data = datos
// const statusPackage = data[0].body.info_Paquete.Estatus_Paquete

const ImageStatusPackage = (props) => {
const {statusPackage, lastUpdate} = props
const classes = ImageStatusPackageStyle();  
// const statusPackage1 = 'en 1'
  return (
    <div>

    <Box className={classes.container}>
      <Box className={classes.containerImageText}>
        <Box className={statusPackage === MESSAGES_STATUS_PACKAGE.RECIBIDO_POR_LIBER ? classes.containerStatusSelect : classes.containerStatus }>
          <img src={imageRecibido} alt="imagen de recibido" className={classes.image} />
        </Box>
        <Typography className={classes.textImage}>Recibido por Liber</Typography>
      </Box>

      <img src={imageSecuencia} alt="imagen de secuencia" className={classes.separador} />

      <Box className={classes.containerImageText}>
        <Box className={statusPackage === MESSAGES_STATUS_PACKAGE.PEDIDO_TRANSITO ? classes.containerStatusSelect : classes.containerStatus }>
          <img src={imageTransito} alt="imagen de transito" className={classes.image} />
        </Box>
        <Typography className={classes.textImage}>Pedido en transito</Typography>
      </Box>

      <img src={imageSecuencia} alt="imagen de secuencia" className={classes.separador} />

      <Box className={classes.containerImageText}>
        <Box className={statusPackage === MESSAGES_STATUS_PACKAGE.EN_CAMINO ? classes.containerStatusSelect : classes.containerStatus }>
          <img src={imageCamino} alt="imagen de en camino" className={classes.image} />
        </Box>
        <Typography className={classes.textImage}>En camino</Typography>
      </Box>

      <img src={imageSecuencia} alt="imagen de secuencia" className={classes.separador} />
      
      <Box className={classes.containerImageText}>
        <Box className={statusPackage === MESSAGES_STATUS_PACKAGE.ENTREGADO ? classes.containerStatusSelect : classes.containerStatus }>
          <img src={imageEntregado} alt="imagen de entregado" className={classes.image} />
        </Box>
        <Typography className={classes.textImage}>Pedido Entregado</Typography>
      </Box>
    </Box>
    <Box>
      <Typography className={classes.textUpdate}>Ultima actualización: {lastUpdate}</Typography>
    </Box>
    </div>

  )
}

export default ImageStatusPackage