import React from "react";
import DrawerDash from "../../components/DrawerDash";
import DataFilterOrder from "../../components/orders/DataFilterOrder";
import { Typography } from "@material-ui/core";
import OrdersStyle from "./OrdersStyle";

export default function Orders() {
  const classes = OrdersStyle();

  return (
    <DrawerDash>
      <Typography
        className={classes.title}
        variant="h5"
        id="tableTitle"
        component="div"
      >
        Generar recolección Liber
      </Typography>
      <DataFilterOrder />
    </DrawerDash>
  );
}
