import { Box, Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import ImageAndLinkStyle from "./ImageAndLinkStyle";

const ImageAndLink = (props) => {
  const classes = ImageAndLinkStyle();

  const {
    img,
    title,
    //titleText,
    text,
    textButton,
    disabled,
    handleClick,
    isInitialPosition = false,
  } = props;

  return (
    <Grid
      container
      className={
        isInitialPosition
          ? `${classes.container}`
          : `${classes.container} ${classes.marginContainer}`
      }
    >
      <Grid
        item
        className={classes.containerFile}
        xs={12}
        sm={12}
        md={4}
        lg={4}
      >
        <Typography className={classes.title}>{title}</Typography>
        <Box className={classes.containerImage}>
          <img
            src={img}
            alt="img modo generar guia"
            className={classes.image}
          />
        </Box>
      </Grid>
      <Grid
        item
        className={classes.containerText}
        xs={12}
        sm={12}
        md={5}
        lg={4}
      >
        {/* <Typography className={classes.subTitle}>{titleText}</Typography> */}
        <Typography className={classes.text}>{text}</Typography>
      </Grid>
      <Grid
        item
        className={classes.containerButton}
        xs={12}
        sm={12}
        md={3}
        lg={4}
      >
        <Button
          variant="contained"
          className={classes.button}
          disabled={disabled}
          onClick={handleClick}
        >
          <Typography className={classes.textButton}>{textButton}</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default ImageAndLink;
