import { Box, Typography } from "@material-ui/core";
import React from "react";
import CardDecriptionStyle from "./CardsDescription.style";

const CardsDecription = (props) => {
  const { icon, title, description } = props;
  const classes = CardDecriptionStyle();

  return (
    <Box className={classes.container}>
      <img className={classes.image} src={icon} alt="imagen" />
      <Typography variant="h6" className={classes.title}>{title}</Typography>
      <Typography className={classes.description}>{description}</Typography>
    </Box>
  );
};

export default CardsDecription;
