import React from "react";
import { Box } from "@material-ui/core/";
import centeredContentStyles from "./CenteredContent.styles";

const CenteredContent = (props) => {
  const { children } = props;
  const classes = centeredContentStyles();
  return <Box className={classes.mainContainer}>{children}</Box>;
};

export default CenteredContent;
