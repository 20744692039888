import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";
import { MAIN_COLORS } from "../../../constants/theme/colors";

const InfoPerfilStyle = makeStyles((theme) => ({
  container: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  containerInput: {
    marginBottom: 20,
    [theme.breakpoints.down(600)]: {
      marginBottom: 15,
    },
  },
  swith: {
    marginLeft: 0,
  },
  switchText: {
    fontSize: 14,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    [theme.breakpoints.down(600)]: {
      fontSize: 12.5,
    },
  },
  containerLabels: {
    [theme.breakpoints.down(670)]: {
      width: "100%",
    },
  },
  label: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 15,
    textAlign: "start",
    marginBottom: 10,
    [theme.breakpoints.down(600)]: {
      fontSize: 12.5,
      marginBottom: 5,
    },
  },
  containerInfo: {
    [theme.breakpoints.down(960)]: {
      display: "flex",
      maxWidth: 800,
      justifyContent: "space-between",
      marginTop: 20,
    },
  },
  imagen: {
    width: 300,
    marginRight: 20,
    [theme.breakpoints.up(960)]: {
      display: "none",
    },
    [theme.breakpoints.down(800)]: {
      width: 250,
    },
    [theme.breakpoints.down(750)]: {
      width: 200,
      marginRight: 10,
    },
    [theme.breakpoints.down(650)]: {
      width: 180,
      marginRight: 0,
    },
    [theme.breakpoints.down(600)]: {
      display: "none",
    },
  },
  inputNombre: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 15,
    border: "1px solid rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    textAlign: "start",
    background: "#fff",
    padding: "10px",
    color: "rgba(0, 0, 0, 1)",
    width: 400,
    height: 45,
    [theme.breakpoints.down("lg")]: {
      height: 35,
      width: 300,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 13,
      height: 30,
      width: "100%",
    },
  },
  inputComun: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 15,
    border: "1px solid rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    textAlign: "start",
    background: "#fff",
    padding: "10px",
    color: "rgba(0, 0, 0, 1)",
    width: 300,
    height: 45,
    [theme.breakpoints.down("lg")]: {
      height: 35,
      width: 250,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 13,
      height: 30,
      width: "100%",
    },
  },
  textDivider: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 15,
    color: "rgba(0, 0, 0, 0.5)",
  },
  textAndButton: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  divider: {
    margin: "20px 0px 0px 0px",
    color: "rgba(0, 0, 0, 0.25)",
    padding: "0px 40px",
  },
  buttonAddDirection: {
    height: 50,
    width: 200,
    backgroundColor: MAIN_COLORS.MAIN_RED,
    borderRadius: 10,
    border: "0px solid #fff",
    textTransform: "none",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 15,
    color: MAIN_COLORS.WHITE,
    cursor: "pointer",
    [theme.breakpoints.down("lg")]: {
      height: 40,
      width: 170,
      fontSize: 12,
    },
    [theme.breakpoints.down(859)]: {
      height: 35,
      width: 160,
      fontSize: 12,
    },
    [theme.breakpoints.down(600)]: {
      height: 30,
      width: 150,
      fontSize: 11,
    },
    "&:hover": {
      backgroundColor: MAIN_COLORS.MAIN_RED_BLACK,
    },
  },
  containerDireccionesText: {
    padding: 0,
    paddingRight: 10,
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid rgba(0, 0, 0, 0.25)",
    borderRadius: 10,
    width: "100%",
    marginTop: 20,
  },
  containerDireccionesTextPrincipal: {
    padding: 0,
    paddingRight: 10,
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid rgba(0, 0, 0, 0.25)",
    borderRadius: 10,
    width: "100%",
    marginTop: 20,
    boxShadow: "1px 1px 10px #D15252",
  },
  textDirections: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 15,
    marginRight: 10,
    padding: 10,
    width: "100%",
    cursor: "pointer",
    [theme.breakpoints.down(1100)]: {
      fontSize: 14,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 12.5,
    },
  },
  textDirectionsMain: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 15,
    marginRight: 10,
    padding: 10,
    width: "100%",
    [theme.breakpoints.down(1100)]: {
      fontSize: 14,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 12.5,
    },
  },
  textDirectionNote: {
    marginTop: 15,
    fontSize: 15,
    [theme.breakpoints.down(700)]: {
      fontSize: 14,
    },
    [theme.breakpoints.down(550)]: {
      fontSize: 12.5,
    },
  },
  alias: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 15,
    marginRight: 5,
    [theme.breakpoints.down(1100)]: {
      fontSize: 14,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 12.5,
    },
  },
  buttonEdit: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.5)",
    border: "none",
    padding: 0,
    [theme.breakpoints.down(1100)]: {
      fontSize: 13,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 12,
    },
  },
  containerButtonModalAddDirection: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  backButton: {
    height: 40,
    width: 130,
    backgroundColor: MAIN_COLORS.WHITE,
    borderRadius: 10,
    boxShadow: "none",
    border: "1px solid",
    borderColor: MAIN_COLORS.MAIN_RED_20_OPACITY,
    textTransform: "none",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 15,
    color: MAIN_COLORS.MAIN_RED,
    marginRight: 30,
    "&:hover": {
      backgroundColor: MAIN_COLORS.WHITE,
      boxShadow: "none",
      border: "1px solid",
      borderColor: MAIN_COLORS.MAIN_RED,
    },
  },
  continue: {
    height: 40,
    width: 130,
    backgroundColor: MAIN_COLORS.MAIN_RED,
    borderRadius: 10,
    boxShadow: "none",
    textTransform: "none",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 15,
    color: MAIN_COLORS.WHITE,
    marginRight: 20,
    "&:hover": {
      opacity: 0.8,
      backgroundColor: MAIN_COLORS.MAIN_RED,
    },
  },
}));
export default InfoPerfilStyle;
