import { filter } from "lodash";

export const dynamicFilter = (text, rows) => {
  let filterRows = [];
  let newRows = [];

  filterRows = filter(rows, (r) => {
    newRows = [];
    Object.values(r).forEach((value, indice) => {
      if (indice !== 0 && !Array.isArray(value)) {
        newRows.push(value);
      }
    });
    return JSON.stringify(newRows).toLowerCase().includes(text.toLowerCase());
  });

  return filterRows;
};
