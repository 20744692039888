import React, { useEffect } from "react";
import { Box, Button } from "@material-ui/core";
import MercadoPagoCheckoutStyles from "./MercadoPagoCheckoutStyles";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsLoadingInfo,
  setLoadingInfo,
} from "../../../store/reducers/Loading/LoadingSlice";
import {
  resetPaymentDetails,
  setCommission,
  setCreditsAmount,
  setPaymentMethod,
  setSubtotal,
  setTotal,
} from "../../../store/reducers/paymentDetail/paymentDetailsSlice";

const { REACT_APP_MERCADO_PAGO_PK, REACT_APP_LIBER_API_URL } = process.env;

const MercadoPagoCheckout = (props) => {
  const { amount, paymentDetails } = props;
  const classes = MercadoPagoCheckoutStyles();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoadingInfo);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://sdk.mercadopago.com/js/v2";
    script.async = true;
    script.onload = () => {
      console.log("MercadoPago SDK loaded");
    };
    script.onerror = () => {
      console.error("Error loading MercadoPago SDK");
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    dispatch(setTotal(paymentDetails.total));
    dispatch(setCommission(parseFloat(paymentDetails.comission)));
    dispatch(setSubtotal(paymentDetails.totalWithComission));
    dispatch(setCreditsAmount(parseInt(paymentDetails.creditsAmount)));
    dispatch(setPaymentMethod("Mercado Pago"));

    return () => {
      dispatch(resetPaymentDetails());
    };
  }, [paymentDetails, dispatch]);

  const handleMercadoPago = async () => {
    try {
      dispatch(setLoadingInfo(true));

      const response = await fetch(
        `${REACT_APP_LIBER_API_URL}/payments/mercado-pago`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            title: "Recarga de Créditos",
            quantity: 1,
            unit_price: parseFloat(amount),
          }),
        }
      );

      const data = await response.json();

      if (!window.MercadoPago) {
        console.error("MercadoPago SDK not loaded");
        return;
      }

      const mp = new window.MercadoPago(REACT_APP_MERCADO_PAGO_PK, {
        locale: "es-MX",
      });
      dispatch(setLoadingInfo(false));

      mp.checkout({
        preference: {
          id: data.body.id,
        },
        autoOpen: true,
      });

      dispatch(setLoadingInfo(false));
    } catch (error) {
      dispatch(setLoadingInfo(false));
      console.error("Error al crear la preferencia de pago", error);
    }
  };

  return (
    <Box className={classes.mainContainer}>
      <Button
        onClick={handleMercadoPago}
        variant="contained"
        className={classes.button}
        disabled={!amount || isLoading}
      >
        Agregar Créditos
      </Button>
    </Box>
  );
};

export default MercadoPagoCheckout;
