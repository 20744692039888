import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const TypesPackagesStyle = makeStyles((theme)=> ({
    containerEye:{
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        background:"#F6FFFC",
        padding:"10px 20px", 
        marginBottom:20,
        width:470
    },
    textEye:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
        fontSize:10,
        marginLeft:15
    },
    containerInformation:{
        border: "2px solid #DFDFDF",
        padding:20
    },
    titleContainer:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
        fontSize:15
    },
    containerSeparado:{
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between"
    },
    containerSeparadoAbajo:{
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
        marginTop:20
    },
    textContainerSeparado:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
        fontSize:12
    },
    titleText:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
        fontSize:10,
        color:"rgba(0, 0, 0, 0.25)"
    },
    descriptionText:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
        fontSize:15,
    }
}))
export default TypesPackagesStyle