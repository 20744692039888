import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const AddresseSyle = makeStyles((theme)=>({
    container:{
        display:"flex",
        flexDirection:"column",
        alignItems:"start",
        marginBottom:20
    },
    title:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_BOLD,
        fontSize:14,
        color:"rgba(0, 0, 0, 0.7)",
        margin: "10px 0px 10px 0px",
        [theme.breakpoints.down("md")]:{
            fontSize:18
        }
    },
    informationAddesse:{
        display:"flex",
        flexDirection:"column",
        alignItems:"start",
    },
    text:{
        fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
        fontSize: 12,
    },
    textName:{
        fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
        fontSize: 12,
        marginBottom:5,
        [theme.breakpoints.down("md")]:{
            fontSize:15
        },
    },
    textRef:{
        fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
        fontSize: 16,
        maxWidth:300, 
        [theme.breakpoints.down("md")]:{
            fontSize:15,
            maxWidth:240, 
        },
    }

}));
export default AddresseSyle;