import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import thunk from "redux-thunk";

//Slices
import UserInformationSlice from "./reducers/user/UserInformationSlice";
import BusinessInformationSlice from "./reducers/business/BusinessInformationSlice";
import TrackingInformationSlice from "./reducers/tracking/TrackingInformationSlice";
import CustomerSlice from "./reducers/customer/CustomerSlice";
import OrdersSlice from "./reducers/orders/OrdersSlice";
import LoadingSlice from "./reducers/Loading/LoadingSlice";
import PaymentDetailsSlice from "./reducers/paymentDetail/paymentDetailsSlice";
const persistConfig = {
  key: "liber",
  storage,
};

const reducers = combineReducers({
  user: UserInformationSlice,
  business: BusinessInformationSlice,
  tracking: TrackingInformationSlice,
  customer: CustomerSlice,
  orders: OrdersSlice,
  loading: LoadingSlice,
  payments: PaymentDetailsSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const persist = persistedReducer;

export default configureStore({
  reducer: persist,
  middleware: [thunk],
});
