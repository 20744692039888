// import { Box, Button, Typography } from '@material-ui/core'
import { Box, Typography } from "@material-ui/core";
import React from "react";
import imgPerfil from "../../../assets/img/dashboard/img-dashPerfil.svg";
import ImageLinkStyle from "../ImageLink/ImageLinkStyle";

const ImageLink = () => {
  const classes = ImageLinkStyle();
  return (
    <div>
      <Box className={classes.container}>
        <Box className={classes.containerTextButton}>
          <Typography className={classes.text}>
            Bienvenido a tu sitio ideal
          </Typography>
        </Box>
        <img src={imgPerfil} alt="imgPerfil" className={classes.imagen} />
      </Box>
    </div>
  );
};

export default ImageLink;
