import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Fade,
  CircularProgress,
} from "@material-ui/core";
import {
  AccountCircle,
  Lock,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import React, { useState } from "react";
import { loginUser } from "../../../api/authApi";
import FormLoginStyle from "./FormLogin.style";
import { setCookie } from "../../../utils/sessionCookie";
import { useNavigate } from "react-router-dom";
import { AUTH_MESSAGES } from "../../../constants/messages";
import { useDispatch } from "react-redux";
import {
  setUserInformation,
  setUserIsAuth,
} from "../../../store/reducers/user/UserInformationSlice";
import { ROUTES_CONSTANTS } from "../../../constants/routesConstants";
import { getInfoNegocio } from "../../../api/userApi";
import {
  setBusinessCompleteInformation,
  setBusinessLocationsInformation,
} from "../../../store/reducers/business/BusinessInformationSlice";
import { notification } from "../../../utils/notification";
import { validateEmail } from "../../../utils/validations";
import { encryptPassword } from "../../../utils/auth";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";

const FormLogin = () => {
  const classes = FormLoginStyle();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checked, setChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isValidEmail, setIsValidEmail] = useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const [showLoader, setShowLoader] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleCreateNewAccount = () => navigate(ROUTES_CONSTANTS.REGISTRO);

  const handleRedirectTo = (route) => {
    navigate(route);
  };

  const handleEmail = (e) => {
    let email = e.target.value.trim();
    const isValid = validateEmail(email);

    if (isValid) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
    setEmail(email);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = () => {
    setShowLoader(true);

    const payload = {
      new_user_email: email,
      new_user_password: encryptPassword(password),
      enviroment: "web",
    };

    loginUser(payload)
      .then((resUser) => {
        if (
          resUser.data.status_Code === 200 &&
          resUser.data.status_Message === AUTH_MESSAGES.VALID_USER
        ) {
          const accessToken = resUser.data.body.auth_token;
          getInfoNegocio(accessToken)
            .then((res) => {
              setCookie("accessToken", accessToken);
              dispatch(setUserInformation(resUser.data.body));
              dispatch(setBusinessCompleteInformation(res.data.business_Info));
              dispatch(
                setBusinessLocationsInformation(res.data.business_Locations)
              );
              dispatch(setUserIsAuth(true));
              setShowLoader(false);
              navigate(ROUTES_CONSTANTS.CREACION_DE_GUIAS);
            })
            .catch((err) => {
              setShowLoader(false);
              notification(
                "No se pudo obtener la información del usuario",
                "error"
              );
            });
        } else {
          setShowLoader(false);
          notification("El usuario o la contraseña son incorrectos", "error");
        }
      })
      .catch((err) => {
        setShowLoader(false);
        notification("El usuario o la contraseña son incorrectos", "error");
      });
  };

  return (
    <Box className={classes.container}>
      <Button
        onClick={() => {
          handleRedirectTo(ROUTES_CONSTANTS.LANDING);
        }}
        startIcon={<HomeOutlinedIcon />}
        className={classes.botonInicio}
      >
        Ir al inicio
      </Button>
      <Typography className={classes.title}>Iniciar Sesión</Typography>
      <Typography className={classes.text}>
        Por favor, ingresa tu usuario y contraseña.
      </Typography>
      <TextField
        type="text"
        variant="outlined"
        placeholder="Correo"
        name="correo"
        value={email}
        onChange={(e) => handleEmail(e)}
        helperText={
          email !== "" &&
          !isValidEmail && (
            <Typography className={classes.errorLabel}>
              Ingresa un correo válido
            </Typography>
          )
        }
        InputProps={{
          className: classes.inputComponent,
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircle className={classes.icon} />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        variant="outlined"
        placeholder="Contraseña"
        name="contrasena"
        value={password}
        onChange={handlePassword}
        type={showPassword ? "text" : "password"}
        InputProps={{
          className: classes.inputComponent,
          startAdornment: (
            <InputAdornment position="start">
              <Lock className={classes.icon} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box>
        <FormControlLabel
          className={classes.containerCheck}
          control={
            <Checkbox
              label="Session"
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "Checkbox" }}
              classes={{
                root: classes.root,
                checked: classes.checked,
              }}
            />
          }
          label="Recordar mi sesión"
        />
        <Typography align="center" className={classes.forgotPassword}>
          No tengo cuenta{" "}
          <span
            className={classes.createNewAccountLabel}
            onClick={handleCreateNewAccount}
          >
            {" "}
            crear una{" "}
          </span>
        </Typography>
      </Box>
      <Button
        type="button"
        onClick={handleLogin}
        className={classes.buttonCrear}
        disabled={!isValidEmail}
      >
        {showLoader ? "Iniciando" : "Iniciar Sesión"}
        {showLoader && (
          <Box style={{ height: 30, marginLeft: 20 }}>
            <Fade in={showLoader} unmountOnExit>
              <CircularProgress style={{ width: 30, height: 30 }} />
            </Fade>
          </Box>
        )}
      </Button>
    </Box>
  );
};

export default FormLogin;
