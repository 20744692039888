import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";
import { MAIN_COLORS } from "../../../constants/theme/colors";

const ErrorAutopaqueteModalStyles = makeStyles((theme) => ({
  content: {
    maxWidth: 687,
    maxHeight: 350,
    width: "90%",
    height: "90%",
    overflow: "auto",
    background: "white",
    borderRadius: 30,
    padding: 40,
    [theme.breakpoints.down(600)]: {
      maxHeight: 300,
    },
  },
  contentText: {
    height: 230,
    [theme.breakpoints.down(600)]: {
      height: 180,
    },
  },
  title: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 24,
    [theme.breakpoints.down(900)]: {
      fontSize: 21,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 18,
    },
    [theme.breakpoints.down(450)]: {
      fontSize: 16,
    },
    [theme.breakpoints.down(400)]: {
      fontSize: 15,
    },
  },
  text: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 18,
    color: "#5A5151",
    marginTop: 30,
    [theme.breakpoints.down(900)]: {
      fontSize: 16,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 14,
      marginTop: 20,
    },
    [theme.breakpoints.down(450)]: {
      fontSize: 13,
      marginTop: 15,
    },
  },
  textButton: {
    fontSize: 15,
    color: MAIN_COLORS.WHITE,
    [theme.breakpoints.down(600)]: {
      fontSize: 13,
    },
  },
  contentButton: {
    display: "flex",
    justifyContent: "end",
  },
  button: {
    height: 40,
    width: 150,
    backgroundColor: "#D15353",
    borderRadius: 10,
    boxShadow: "none",
    border: "1px solid",
    borderColor: MAIN_COLORS.MAIN_RED_20_OPACITY,
    textTransform: "none",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    "&:hover": {
      backgroundColor: "#D15353",
      boxShadow: "none",
      opacity: 0.7,
    },
    [theme.breakpoints.down(600)]: {
      height: 33,
      width: 110,
      marginTop: 5,
    },
  },
}));
export default ErrorAutopaqueteModalStyles;
