import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS, POPPINS_FONTS } from "../../../constants/fontConsts";

const ListBalanceStyle = makeStyles((theme) => ({
  textAmount: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_EXTRABOLD,
    fontSize: 24,
    textAlign: "center",
    marginBottom: 20,
  },
  textMovements: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 18,
    marginBottom: 7,
  },
  text: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    [theme.breakpoints.down(1150)]: {
      fontSize: 12,
    },
    [theme.breakpoints.down(700)]: {
      fontSize: 11,
    },
  },
  image: {
    width: 35,
    height: 35,
    [theme.breakpoints.down(1150)]: {
      width: 30,
      height: 30,
    },
    [theme.breakpoints.down(700)]: {
      width: 23,
      height: 23,
    },
  },
  containerIcon: {
    background: "#d15252",
    width: 25,
    height: 25,
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    color: "white",
    width: 20,
    height: 20,
    [theme.breakpoints.down(700)]: {
      width: 17,
      height: 17,
    },
  },
  containerIconTermica: {
    background: "#474747f7",
    width: 25,
    height: 25,
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconTermica: {
    width: 18,
    height: 17,
    [theme.breakpoints.down(700)]: {
      width: 17,
      height: 16,
    },
  },
  bgGray: {
    background: "#eeeeee87",
    [theme.breakpoints.down(600)]: {
      padding: "3px 6px 3px 6px",
    },
  },
  bgWhite: {
    background: "white",
    [theme.breakpoints.down(600)]: {
      padding: "3px 6px 3px 6px",
    },
  },
  content: {},
  container: {
    width: "100%",
    boxShadow: "none",
    /* [theme.breakpoints.down(1060)]: {
      maxWidth: 680,
    },
    [theme.breakpoints.down(960)]: {
      maxWidth: 750,
    },
    [theme.breakpoints.down(880)]: {
      maxWidth: 680,
    },
    [theme.breakpoints.down(800)]: {
      width: 600,
    },
    [theme.breakpoints.down(700)]: {
      width: 570,
    },
    [theme.breakpoints.down(630)]: {
      width: 540,
    },
    [theme.breakpoints.down(600)]: {
      width: 520,
    },
    [theme.breakpoints.down(580)]: {
      width: 490,
    },
    [theme.breakpoints.down(550)]: {
      width: 460,
    },
    [theme.breakpoints.down(520)]: {
      width: 430,
    },
    [theme.breakpoints.down(490)]: {
      width: 400,
    },
    [theme.breakpoints.down(460)]: {
      width: 380,
    },
    [theme.breakpoints.down(440)]: {
      width: 365,
    },
    [theme.breakpoints.down(425)]: {
      width: 350,
    },
    [theme.breakpoints.down(410)]: {
      width: 335,
    },
    [theme.breakpoints.down(395)]: {
      width: 320,
    },
    [theme.breakpoints.down(380)]: {
      width: 305,
    }, */
  },
  densePadding: {
    marginTop: 20,
  },
  containerButton: {
    display: "flex",
    justifyContent: "center",
    width: "60px",
    alignItems: "center",
  },
  containerButtons: {
    display: "flex",
    justifyContent: "space-between",
    width: "60px",
    alignItems: "center",
  },
  containerAddress: {
    width: 160,
  },

  filter: {
    width: "100%",
    display: "flex",
    alignItems: "start",
  },
  filterInput: {
    backgroundColor: "white",
    width: 250,
    borderRadius: 15,
    textAlign: "start",
    height: 30,
    marginBottom: 20,
    marginRight: 30,
    marginTop: 10,
    background: "fff",
    fontFamily: POPPINS_FONTS.POPPINS_REGULAR,
    fontSize: 15,
  },
  iconFilter: {
    color: "#ACABAB",
    height: 20,
    width: 20,
  },
  greenCircle: {
    color: "#49AF40",
    height: 13,
    width: 13,
    marginRight: 3,
  },
  redCircle: {
    color: "#CB412E",
    height: 13,
    width: 13,
    marginRight: 3,
  },
  contentStatus: {
    display: "flex",
    alignItems: "center",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
  },
  switch: {
    fontSize: 15,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    [theme.breakpoints.down(600)]: {
      fontSize: 13.5,
    },
  },
}));

export default ListBalanceStyle;
