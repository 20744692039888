import { makeStyles } from "@material-ui/core";

const CustomPaginationStyle = makeStyles((theme) => ({
  text: {
    [theme.breakpoints.down(700)]: {
      fontSize: 10,
      overflow: "hidden"
    },
  },
}));

export default CustomPaginationStyle;
