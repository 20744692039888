import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  payment_details: {
    payment_method: null,
    credits_amount: 0,
    order_id: null,
    total: 0,
    subtotal: 0,
    commission: 0,
    payment_status: null,
    payment_verification: null,
  },
};

export const paymentDetailsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    setPaymentDetails(state, { payload }) {
      state.payment_details.payment_status = payload.payment_status;
      state.payment_details.payment_verification = payload.payment_verification;
      state.payment_details.order_id = payload.order_id;
    },
    setPaymentMethod(state, { payload }) {
      state.payment_details.payment_method = payload;
    },
    setTotal(state, { payload }) {
      state.payment_details.total = payload;
    },
    setSubtotal(state, { payload }) {
      state.payment_details.subtotal = payload;
      },
      setCommission(state, { payload }) {
      state.payment_details.commission = payload;
    },
    setCreditsAmount(state, { payload }) {
      state.payment_details.credits_amount = payload;
    },
    resetPaymentDetails(state) {
      state.payment_details = initialState.payment_details;
    },
  },
});

export const {
  setFee,
  setPaymentDetails,
  setPaymentMethod,
  setTotal,
  setSubtotal,
  setCommission,
  setCreditsAmount,
  resetPaymentDetails,
} = paymentDetailsSlice.actions;

export default paymentDetailsSlice.reducer;

// selectors
export const selectPaymentDetailsInfo = (state) =>
  state.payments ? state.payments.payment_details : "";
export const selectAllPaymentDetails = (state) =>
  state.payments ? state.payments : initialState;
