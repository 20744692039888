import axios from "axios";
import { getCookie } from "../utils/sessionCookie";
const { REACT_APP_LIBER_API_URL } = process.env;

export const getOrders = () => {
  const request = {
    method: "GET",
    url: `${REACT_APP_LIBER_API_URL}/business/get-guides-orders`,
    headers: {
      "content-type": "application/json",
      auth_Token: getCookie("accessToken"),
    },
  };

  return axios(request);
};

export const generateQRCode = (payload) => {
  const request = {
    method: "POST",
    data: payload,
    url: `${REACT_APP_LIBER_API_URL}/batchs/add-collection-batch`,
    headers: {
      "content-type": "application/json",
      auth_Token: getCookie("accessToken"),
    },
  };

  return axios(request);
};

export const verifyPostalCode = (payload) => {
  const request = {
    method: "POST",
    url: `${REACT_APP_LIBER_API_URL}/zones/check-existence-postal-code`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
    },
  };

  return axios(request);
};
