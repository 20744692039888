import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import TabOriginAddressStyles from "./TabOriginAddressStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelector } from "react-redux";
import { selectBusinessLocations } from "../../../../store/reducers/business/BusinessInformationSlice";
import { verifyPostalCode } from "../../../../api/massDeliveryApi";
import ErrorsModal from "./ErrorModal";

const TabOriginAddress = (props) => {
  const {
    handleTabs,
    handleTabAddress,
    setSelectedLocation,
    selectedLocation,
    disableTabsAddress,
    enableDirty,
    setError,
  } = props;

  const classes = TabOriginAddressStyles();
  const businessLocations = useSelector(selectBusinessLocations);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [validatePC, setValidatePC] = useState(false);
  const aliases = businessLocations.map((obj) => obj.alias);
  const [primLoc] = businessLocations.filter((loc) => loc.principal);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (selectedLocation.id_location !== "") {
      setSelectedLocation({
        alias: selectedLocation.alias,
        city: selectedLocation.city,
        colony: selectedLocation.colony,
        ext_number: selectedLocation.ext_number,
        id_location: selectedLocation.id_location,
        int_number: selectedLocation.int_number,
        location_references: selectedLocation.location_references,
        postal_code: selectedLocation.postal_code,
        principal: selectedLocation.principal,
        state: selectedLocation.state,
        street_name: selectedLocation.street_name,
        zoneName: selectedLocation.zoneName,
      });

      setValidatePC(true);
    } else if (aliases.length > 0) {
      setSelectedLocation({
        alias: primLoc.alias,
        city: primLoc.city,
        colony: primLoc.colony,
        ext_number: primLoc.ext_number,
        id_location: primLoc.id_location,
        int_number: primLoc.int_number,
        location_references: primLoc.location_references,
        postal_code: primLoc.postal_code,
        principal: primLoc.principal,
        state: primLoc.state,
        street_name: primLoc.street_name,
        zoneName: "",
      });

      validatePostalCode(primLoc.postal_code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validatePostalCode = (pc) => {
    let payload = { postal_code: pc };

    verifyPostalCode(payload)
      .then((res) => {
        if (
          res.data.status_Code === 200 &&
          res.data.status_Message === "postal code is validated"
        ) {
          setValidatePC(true);
          setSelectedLocation((prevState) => ({
            ...prevState,
            zoneName: res.data.zone_name,
          }));
        } else {
          setValidatePC(false);
          setMessage(
            "Por el momento el código postal para la dirección seleccionada no se encuentra dentro de nuestras zonas con servicio, por lo que deberá seleccionar otra dirección para seguir con el proceso."
          );
          setOpen(true);
          setSelectedLocation((prevState) => ({
            ...prevState,
            zoneName: "",
          }));
        }
      })
      .catch((err) => {
        setValidatePC(false);
        setMessage(
          "Por el momento el código postal para la dirección seleccionada no se encuentra dentro de nuestras zonas con servicio, por lo que deberá seleccionar otra dirección para seguir con el proceso."
        );
        setOpen(true);
        setSelectedLocation((prevState) => ({
          ...prevState,
          zoneName: "",
        }));
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAliasDropdownChange = async (e) => {
    const field = e.target.value;
    const location = await businessLocations.filter(
      (location) => location.alias === field
    );

    const {
      alias,
      city,
      colony,
      ext_number,
      id_location,
      int_number,
      location_references,
      postal_code,
      principal,
      state,
      street_name,
    } = location[0];

    setSelectedLocation({
      alias,
      city,
      colony,
      ext_number,
      id_location,
      int_number,
      location_references,
      postal_code,
      principal,
      state,
      street_name,
    });

    validatePostalCode(postal_code);
    disableTabsAddress();
    setError(true);
    enableDirty();
  };

  const handleClick = () => {
    handleTabs("", 1);
    handleTabAddress();
    enableDirty();
  };

  return (
    <Fragment>
      <Grid container className={classes.mainContainer}>
        <Grid item xs={12}>
          <Typography className={classes.container}>
            Selecciona la dirección de origen
          </Typography>
        </Grid>
        {/* Mostramos este drowpdown unicamente si hay más de una dirección */}
        {aliases.length > 0 && (
          <Fragment>
            <Grid item xs={12}>
              <FormControl className={classes.selectDirection}>
                <Select
                  variant="outlined"
                  onChange={(e) => {
                    handleAliasDropdownChange(e);
                  }}
                  IconComponent={ExpandMoreIcon}
                  className={classes.selectInput}
                  defaultValue={
                    selectedLocation.alias !== ""
                      ? selectedLocation.alias
                      : primLoc.alias
                  }
                >
                  {aliases.map((alias, index) => (
                    <MenuItem
                      key={index}
                      value={alias}
                      className={classes.selectItem}
                    >
                      {alias}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Fragment>
        )}
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          className={classes.mainFormContainer}
        >
          <FormControl className={classes.largeTextField}>
            <Typography className={classes.inputDescription}>
              Colonia
            </Typography>
            <TextField
              variant="outlined"
              value={selectedLocation.colony}
              disabled
              inputProps={{
                className: classes.inputTextFile,
              }}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          className={classes.mainFormContainer}
        >
          <FormControl className={classes.largeTextField}>
            <Typography className={classes.inputDescription}>
              {" "}
              Calle{" "}
            </Typography>
            <TextField
              variant="outlined"
              value={selectedLocation.street_name}
              disabled
              inputProps={{
                className: classes.inputTextFile,
              }}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          className={classes.mainFormContainer}
        >
          <FormControl className={classes.numberField}>
            <Typography className={classes.inputDescription}>
              Código Postal
            </Typography>
            <TextField
              variant="outlined"
              value={selectedLocation.postal_code}
              disabled
              inputProps={{
                className: classes.inputTextFile,
              }}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          className={classes.mainFormContainer}
        >
          <FormControl className={classes.numberField}>
            <Typography className={classes.inputDescription}>
              Núm. Externo
            </Typography>
            <TextField
              variant="outlined"
              value={selectedLocation.ext_number}
              disabled
              inputProps={{
                className: classes.inputTextFile,
              }}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          className={classes.mainFormContainer}
        >
          <FormControl className={classes.numberField}>
            <Typography className={classes.inputDescription}>
              Núm. Interno
            </Typography>
            <TextField
              variant="outlined"
              value={selectedLocation.int_number}
              disabled
              inputProps={{
                className: classes.inputTextFile,
              }}
            />
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          className={classes.mainFormContainer}
        >
          <FormControl className={classes.mediumTextField}>
            <Typography className={classes.inputDescription}>
              {" "}
              Ciudad{" "}
            </Typography>
            <TextField
              variant="outlined"
              value={selectedLocation.city}
              disabled
              inputProps={{
                className: classes.inputTextFile,
              }}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          className={classes.mainFormContainer}
        >
          <FormControl className={classes.largeTextField}>
            <Typography className={classes.inputDescription}>
              {" "}
              Estado{" "}
            </Typography>
            <TextField
              variant="outlined"
              value={selectedLocation.state}
              disabled
              inputProps={{
                className: classes.inputTextFile,
              }}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          className={classes.mainFormContainer}
        >
          <FormControl className={classes.largeTextField}>
            <Typography className={classes.inputDescription}>
              Referencias del domiclio
            </Typography>
            <TextField
              variant="outlined"
              multiline
              value={selectedLocation.location_references}
              disabled
              className={classes.textFieldMultiline}
              inputProps={{
                className: classes.inputTextFieldMultiline,
              }}
            />
          </FormControl>
        </Grid>
        <Button
          variant="outlined"
          className={classes.button}
          onClick={handleClick}
          disabled={!validatePC}
        >
          Continuar
        </Button>
      </Grid>
      <ErrorsModal open={open} message={message} handleClose={handleClose} />
    </Fragment>
  );
};

export default TabOriginAddress;
