import { Box, Button, Hidden, Typography } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import imgFlecha from "../../../assets/img/flechaCotizar.svg";
import CardStyle from "./CardStyle";
import DetailGuideModal from "../../sharedComponents/CardAutopaquete/DetailGuideModal";

const Card = (props) => {
  const { index, rate, handleButtonClick } = props;

  const classes = CardStyle();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Box
        key={index}
        className={
          rate.shipping_company === "LIBER"
            ? classes.containerResultTargetIsLiber
            : classes.containerResultTarget
        }
        id="resultSection"
      >
        <img src={rate.service_img} alt="" className={classes.imagenTarget} />
        <Box className={classes.containerTargetBody}>
          <Box className={classes.containerTitle}>
            <Typography className={classes.titleTarget}>
              {rate.shipping_code
                .substring(
                  rate.shipping_company.length + 1,
                  rate.shipping_code.length
                )
                .replaceAll("-", " ")
                .replaceAll("_", " ")}
            </Typography>
          </Box>
          <Typography className={classes.deliveryText}>
            Entrega estimada
          </Typography>
          <Typography className={classes.dayText}>
            {rate.delivery_days}
          </Typography>

          <Typography className={classes.amount}>
            ${rate.total_rate}.00
          </Typography>
        </Box>
        <Hidden smDown>
          <Button
            className={classes.buttonDetails}
            onClick={() => setOpen(true)}
          >
            <Typography className={classes.textDetails}>
              Ver detalles...
            </Typography>
          </Button>
        </Hidden>
        <Box className={classes.contentButtons}>
          <Hidden mdUp>
            <Button
              className={classes.buttonDetails}
              onClick={() => setOpen(true)}
            >
              <Typography className={classes.textDetails}>
                Ver detalles...
              </Typography>
            </Button>
          </Hidden>
          <Button
            variant="contained"
            className={classes.buttonSelection}
            onClick={handleButtonClick}
          >
            <Typography className={classes.buttonText}>Obtener</Typography>
            <Typography className={classes.buttonArrow}>
              <img src={imgFlecha} alt="" />
            </Typography>
          </Button>
        </Box>
      </Box>
      <DetailGuideModal
        open={open}
        handleClose={handleClose}
        guideCost={rate.inter_zone_cost + rate.guide_cost}
        service={rate.shipping_code
          .substring(
            rate.shipping_company.length + 1,
            rate.shipping_code.length
          )
          .replaceAll("-", " ")
          .replaceAll("_", " ")}
        extraWeightCost={rate.extra_weight_cost}
        shippingInsuranceCost={0}
        company={rate.shipping_company}
        total={rate.total_rate}
      />
    </Fragment>
  );
};

export default Card;
