import { Box,  Grid,  IconButton, TextField, Tooltip, Typography } from '@material-ui/core'
import React from 'react'
import DimentionsPackagesStyle from './DimentionsPackagesStyle'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import imageBoxDimensiones from "../../../assets/img/dashboard/img-boxDimensiones.svg"

const DimentionsPackages = () => {
const classes = DimentionsPackagesStyle();
const textTooltip = `Es importante tomar medidas de seguridad para tu producto, asegúrate de que su embalaje lo proteja de la manera adecuada para evitar problemas con su transporte`
  return (
    <Box>
      <Typography className={classes.title}>Dimensiones y tipo de producto</Typography>
      <Typography className={classes.subTitle}>Medidas, peso y contenido del paquete</Typography>
      <Typography className={classes.simpleText}>Dimensiones del paquete</Typography>
      <Box className={classes.containerInputs}>
          {/* Input Tipo Producto */}
          <Box className={classes.containerInputProducto}>
            <Box className={classes.inputProducto}>
              <p className={classes.label}>Tipo de producto*</p>
              <TextField
                id="outlined-basic" 
                variant='outlined'
                focused={false}
                inputProps={{
                  style: {
                    borderRadius: 15,
                    textAlign: "start",
                    background: "#fff",
                    height:12,
                    minWidth:280,
                  },
                }}/>
            </Box>
            <Tooltip title={textTooltip} placement="top-start">
              <IconButton aria-label="delete">
                <HelpOutlineIcon style={{fontSize:20}} />
              </IconButton>
            </Tooltip>
          </Box>
          {/* container inputs 3 dimensiones */}
          <Box className={classes.containerBoxTresInputs}>
              <Typography className={classes.label}>Medidas(cm)*</Typography>
              <Box className={classes.boxTresInputs}>
                <TextField
                  id="outlined-basic" 
                  placeholder='Largo'
                  variant='outlined'
                  focused={false}
                  inputProps={{
                    style: {
                      borderRadius: 15,
                      textAlign: "center",
                      background: "#fff",
                      height:10,
                      width:60
                    },
                  }}/>
                <TextField
                  id="outlined-basic" 
                  placeholder='Ancho'
                  variant='outlined'
                  focused={false}
                  inputProps={{
                    style: {
                      borderRadius: 15,
                      textAlign: "center",
                      background: "#fff",
                      height:10,
                      width:60
                    },
                  }}/>
                <TextField
                  id="outlined-basic" 
                  placeholder='Alto'
                  variant='outlined'
                  focused={false}
                  inputProps={{
                    style: {
                      borderRadius: 15,
                      textAlign: "center",
                      background: "#fff",
                      height:10,
                      width:60
                    },
                  }}/>
              </Box>
          </Box>
          {/* container inputs Pesos e imagen */}
          <Grid container className={classes.gridContainer}>
            <Grid item md={4}>
                  <Box>
                  <p className={classes.label}>Peso (Kg)*</p>
                  <TextField
                    id="outlined-basic" 
                    variant='outlined'
                    focused={false}
                    inputProps={{
                      style: {
                        borderRadius: 15,
                        textAlign: "start",
                        background: "#fff",
                        height:12,
                        width:60,
                      },
                    }}/>
                  </Box>
                  <Box style={{marginTop:15}}>
                  <p className={classes.label}>Peso Volumétrico*</p>
                  <TextField
                    id="outlined-basic" 
                    variant='outlined'
                    focused={false}
                    inputProps={{
                      style: {
                        borderRadius: 15,
                        textAlign: "start",
                        background: "#fff",
                        height:12,
                        width:60,
                      },
                    }}/>
                  </Box>
            </Grid>
            <Grid item md={8} style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                  <img src={imageBoxDimensiones} alt="" />
            </Grid>
          </Grid>   
      </Box>
      {/* container resultados */}
      <Box style={{display:"flex", justifyContent:"center", marginTop:30}}>
        <Typography className={classes.resultado}>$0.00 MXN</Typography>
      </Box>
        <Box style={{padding:"0px 50px", marginTop:15}}>
          <Box className={classes.boxTextResults}>
            <Typography className={classes.simpleText}>Tarifa base</Typography>
            <Typography className={classes.simpleText}>$0.00 MXN</Typography>
          </Box>
          <Box className={classes.boxTextResults}>
            <Typography className={classes.simpleText}>Recargo por recolección</Typography>
            <Typography className={classes.textGris}>$0.00 MXN</Typography>
          </Box>
          <Box className={classes.boxTextResults}>
            <Typography className={classes.textBold}>Total Estimado</Typography>
            <Typography className={classes.textBold}>$0.00 MXN</Typography>
          </Box>
        </Box>
  
    </Box>
  )
}

export default DimentionsPackages