import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const TrustedUserModalStyles = makeStyles((theme) => ({
  content: {
    maxWidth: 687,
    maxHeight: 557,
    width: "90%",
    height: "90%",
    overflow: "auto",
    background: "white",
    borderRadius: 30,
    [theme.breakpoints.down("md")]: {
      maxWidth: 500,
      maxHeight: 450,
    },
    [theme.breakpoints.down(600)]: {
      maxWidth: "420px !important",
      maxHeight: "350px !important",
    },
    "@media (max-height: 700px)": {
      maxWidth: 500,
      maxHeight: 450,
    },
  },
  containerHeader: {
    background: "rgba(246, 255, 252, 1)",
    height: 173,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      height: 130,
    },
    [theme.breakpoints.down(600)]: {
      height: "100px !important",
    },
    "@media (max-height: 700px)": {
      height: 130,
    },
  },
  containerBody: {
    height: "calc(557px - (173px + 100px))",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      height: "calc(450px - (130px + 75px))",
    },
    [theme.breakpoints.down(600)]: {
      height: "calc(350px - (100px + 65px)) !important",
    },
    "@media (max-height: 700px)": {
      height: "calc(450px - (130px + 75px))",
    },
  },
  containerFooter: {
    height: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderTop: "1px solid rgba(0, 0, 0, 0.25)",
    [theme.breakpoints.down("md")]: {
      height: 75,
    },
    [theme.breakpoints.down(600)]: {
      height: "65px !important",
    },
    "@media (max-height: 700px)": {
      height: 75,
    },
  },
  containerRadioButton: {
    marginLeft: 60,
    [theme.breakpoints.down(600)]: {
      marginLeft: "40px !important",
    },
  },
  image: {
    width: 25,
    height: 49,
    marginLeft: 10,
    position: "absolute",
    [theme.breakpoints.down("md")]: {
      width: 20,
      height: 40,
    },
    [theme.breakpoints.down(600)]: {
      width: "18px !important",
      height: "35px !important",
    },
    [theme.breakpoints.down(450)]: {
      display: "none !important",
    },
    "@media (max-height: 700px)": {
      width: 20,
      height: 40,
    },
  },
  formControl: {
    marginBottom: 20,
  },
  textHeader: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 25,
    color: "#000",
    fontWeight: 500,
    [theme.breakpoints.down("md")]: {
      width: 400,
      fontSize: 17,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "16px !important",
    },
    [theme.breakpoints.down(450)]: {
      width: "340px !important",
      fontSize: "15px !important",
    },
    [theme.breakpoints.down(400)]: {
      width: "300px !important",
    },
    "@media (max-height: 700px)": {
      width: 400,
      fontSize: 17,
    },
  },
  textRadioButton: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 20,
    color: "#000",
    [theme.breakpoints.down("md")]: {
      fontSize: 17,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "15.5px !important",
    },
    [theme.breakpoints.down(450)]: {
      fontSize: "14px !important",
    },
    "@media (max-height: 700px)": {
      fontSize: 17,
    },
  },
  textButton: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    color: "white",
    fontSize: 20,
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "14px !important",
    },
    "@media (max-height: 700px)": {
      fontSize: 15,
    },
  },
  button: {
    background: "#D15252",
    borderRadius: "10px",
    width: 176,
    height: 50,
    color: "white",
    border: "none",
    "&:hover": {
      opacity: 0.7,
      background: "#D15252 !important",
    },
    "&.Mui-disabled": {
      background: "#adabab !important",
      color: "white",
    },
    [theme.breakpoints.down("md")]: {
      width: 130,
      height: 40,
    },
    [theme.breakpoints.down(600)]: {
      width: "120px !important",
      height: "35px !important",
    },
    "@media (max-height: 700px)": {
      width: 130,
      height: 40,
    },
  },
}));
export default TrustedUserModalStyles;
