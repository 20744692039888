import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Input,
  Typography,
} from "@material-ui/core";
import PaymentOptionCard from "../../sharedComponents/PaymentOptionCard";
import PaymentOptionsStyle from "./PaymentOptionsStyle";
import CloseIcon from "@material-ui/icons/Close";
import mpLogo from "../../../assets/icons/MPIcon.png";
// import tarjetaIcon from "../../../assets/icons/tarjeta.svg";
import saldoIcon from "../../../assets/icons/saldo.svg";
import transferIcon from "../../../assets/icons/transaccion.svg";
import FiservCheckoutForm from "../../payments/FiservCheckoutForm";
import MercadoPagoCheckout from "../../payments/MercadoPagoCheckout";
import {
  getMercadoPagoComission,
  getFiservTotalWithCommission,
  getOrderIDByUuid,
} from "../../../utils/checkout";
import { useDispatch, useSelector } from "react-redux";
// import {
//   setCommission,
//   setPaymentMethod,
//   setSubtotal,
// } from "../../../store/reducers/paymentDetail/paymentDetailsSlice";
import {
  selectCurrentUserBalance,
  selectUserHasBalanceAmount,
  setCredits,
  setCurrentBalanceForUser,
} from "../../../store/reducers/user/UserInformationSlice";
import { AddCreditsToAccount } from "../../../api/checkoutApi";
import { notification } from "../../../utils/notification";
import { selectIsLoadingInfo, setLoadingInfo } from "../../../store/reducers/Loading/LoadingSlice";
const PaymentOptionsContainer = (props) => {
  const { handleClose } = props;
  const classes = PaymentOptionsStyle();
  const dispatch = useDispatch();
  // Recuperar el estado inicial del localStorage si existe
  const initialState = JSON.parse(localStorage.getItem("paymentState")) || {
    selectSaldo: false,
    selectTransfer: false,
    selectMerPago: true,
    selectTarget: false,
    currentSelectedOption: "MercadoPago",
  };

  const [state, setState] = useState(initialState);
  const [creditsAmount, setCreditsAmount] = useState("");
  const [totalWithComission, setTotalWithComission] = useState(0);
  const [comission, setComission] = useState(0);
  const [total, setTotal] = useState(0);
  const userHasBalance = useSelector(selectUserHasBalanceAmount);
  const currentUserBalance = useSelector(selectCurrentUserBalance);
  const isLoading = useSelector(selectIsLoadingInfo)
  useEffect(() => {
    localStorage.setItem("paymentState", JSON.stringify(state));
    return () => {
      localStorage.removeItem("paymentState");
    };
  }, [state]);

  const transactionPercentages = () => {
    switch (state.currentSelectedOption) {
      case "Saldo":
        return "no cobra comisión ";
      case "Transfer":
        return "cobra $5 de comisión ";
      case "MercadoPago":
        return "cobra un 4.42% + 4.64 ";
      case "Tarjeta":
        return "cobra un 2.2% + 16% IVA ";
      default:
        return "cobra un 2.2% + 16% IVA ";
    }
  };

  const resetCredistAmount = () => {
    setCreditsAmount("");
    setComission(0);
    setTotalWithComission(0);
  };
  const handleOptionChange = (option) => {
    let newState = {
      selectSaldo: false,
      selectTransfer: false,
      selectMerPago: false,
      selectTarget: false,
      currentSelectedOption: option,
    };

    switch (option) {
      case "Saldo":
        newState.selectSaldo = true;
        resetCredistAmount();
        break;
      case "Transfer":
        newState.selectTransfer = true;
        resetCredistAmount();
        break;
      case "MercadoPago":
        newState.selectMerPago = true;
        resetCredistAmount();
        break;
      case "Tarjeta":
        newState.selectTarget = true;
        resetCredistAmount();
        break;
      default:
        newState.selectTarget = true;
        break;
    }

    setState(newState);
  };

  const isTransferSelected = state.selectTransfer;
  const paymentOptionsItems = [
    {
      item: "merPago",
      icon: mpLogo,
      altDesc: "MercPagoLogo",
      isSelected: state.selectMerPago,
      handleSelect: () => handleOptionChange("MercadoPago"),
    },
    // {
    //   item: "target",
    //   icon: tarjetaIcon,
    //   altDesc: "pagotarjeta",
    //   text: "Tarjeta",
    //   isSelected: state.selectTarget,
    //   handleSelect: () => handleOptionChange("Tarjeta"),
    // },
    {
      item: "Saldo",
      icon: saldoIcon,
      altDesc: "pagoSaldo",
      text: "Saldo",
      isSelected: state.selectSaldo,
      handleSelect: () => handleOptionChange("Saldo"),
    },
    {
      item: "Transferencia",
      icon: transferIcon,
      altDesc: "pagoTransferencia",
      text: "Transferencia",
      isSelected: state.selectTransfer,
      handleSelect: () => handleOptionChange("Transfer"),
    },
  ];
  const handleChangeAmountValue = (event) => {
    const val = event.target.value;
    // Solo permite números mayores que 0
    if (/^[1-9]\d*$/.test(val) || val === "") {
      setCreditsAmount(val);
      if (val !== "") {
        const total = parseInt(val, 10);
        setTotal(total);
        let optionComission;
        let totalAndcommission;
        switch (state.currentSelectedOption) {
          case "MercadoPago":
            optionComission = getMercadoPagoComission(total);
            let parsedCommission = parseFloat(optionComission.toFixed(2));
            setComission(parsedCommission.toFixed(2));
            totalAndcommission = total + parsedCommission;
            setTotalWithComission(totalAndcommission);
            break;
          case "Tarjeta":
            optionComission = getFiservTotalWithCommission(total);
            let commission = parseFloat(optionComission.toFixed(2));
            setComission(commission);
            totalAndcommission = total + commission;
            setTotalWithComission(totalAndcommission);
            break;
          case "Saldo":
            // totalAndcommission = total + commission;
            // setTotalWithComission(totalAndcommission);
            break;
          default:
            // paymentMethodPercentage = 0;
            break;
        }
      } else {
        setTotalWithComission(0);
      }
    }
  };

  const handleAddCreditsWithSaldo = () => {
    dispatch(setLoadingInfo(true));

    const orderID = getOrderIDByUuid();
    const paymentSaldoInformation = {
      payment_method: "Saldo",
      credits_amount: parseInt(creditsAmount),
      order_id: orderID,
      total: parseFloat(creditsAmount),
      subtotal: parseFloat(creditsAmount),
      commission: 0,
      payment_status: "approved",
      payment_verification: true,
    };
    if (parseInt(creditsAmount) > currentUserBalance) {
      notification("No cuentas con el saldo suficiente", "error");
      dispatch(setLoadingInfo(false));
    } else {
      dispatch(setLoadingInfo(true));

      AddCreditsToAccount(paymentSaldoInformation).then((res) => {
        setLoadingInfo(true);
        if (res.data.status_Code === 201) {
          dispatch(setCredits(res.data.new_total_credits));
          dispatch(setCurrentBalanceForUser(res.data.new_total_balance));
          notification("Se actualizo su saldo correctamente", "success");
          setLoadingInfo(false);
          handleClose();
          dispatch(setLoadingInfo(false));
        } else {
          notification(
            "Hubo un error al intentar agregar sus créditos",
            "error"
          );
          dispatch(setLoadingInfo(false));
        }
      });
    }
  };

  return (
    <Box className={classes.content}>
      <IconButton className={classes.closeButton} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <Typography id="modal-modal-title" className={classes.title}>
        Agregar Créditos
      </Typography>
      <Typography id="modal-modal-description" className={classes.text}>
        Elige el método de pago, define el monto, y agrégalo a tu cuenta. Podrás
        verlo acreditado en un lapso de 1 a 2 horas.
      </Typography>
      <Box className={classes.containerButtons}>
        <Grid container className={classes.gridPaymentOptionsContainer}>
          {paymentOptionsItems.map((opt) => {
            if (opt.item === "Saldo" && !userHasBalance) {
              return null;
            }
            return (
              <Grid
                item
                xs={12}
                md={12}
                lg={6}
                className={classes.gridPaymentOptions}
                key={opt.item}
              >
                {opt.text ? (
                  <PaymentOptionCard
                    icon={opt.icon}
                    altDesc={opt.altDesc}
                    text={opt.text}
                    isSelected={opt.isSelected}
                    handleSelect={opt.handleSelect}
                  />
                ) : (
                  <PaymentOptionCard
                    isSelected={opt.isSelected}
                    handleSelect={opt.handleSelect}
                    icon={opt.icon}
                    altDesc={opt.altDesc}
                  />
                )}
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Typography
        variant="caption"
        align="center"
        className={classes.percentageValues}
      >
        Este método de pago {transactionPercentages()} por transacción
      </Typography>
      <hr className={classes.hrLine} />
      {!isTransferSelected && (
        <Box className={classes.headerCredits}>
          <Typography
            variant="caption"
            align="center"
            className={classes.creditAmount}
          >
            ¿Qué cantidad de créditos quieres agregar?{" "}
          </Typography>
          <Input
            placeholder="100"
            className={classes.creditInput}
            value={creditsAmount}
            onChange={handleChangeAmountValue}
          />
          {userHasBalance && state.selectSaldo && (
            <Typography className={classes.currentBalanceValue}>
              {" "}
              Saldo actual: {currentUserBalance}{" "}
            </Typography>
          )}
        </Box>
      )}
      {isTransferSelected && (
        <Box className={classes.transferContainer}>
          <Typography className={classes.infoBankHeader}>
            {" "}
            Información bancaria para transferencia
          </Typography>
          <Typography className={classes.infoBank}>
            Cuenta: 012320001229976734
          </Typography>
          <Typography className={classes.infoBank}>Banco: BBVA</Typography>
          <Typography className={classes.infoBank}>
            Nombre: Liber paqueteria express SAS
          </Typography>
          <Typography variant="body2" className={classes.infoTransfer}>
            {" "}
            Una vez echo tu deposito, rellena el siguiente formulario para hacer
            el ajuste
            <a
              href="https://forms.gle/diePJhAmFNbK8Ae7A"
              target="_blank"
              rel="noreferrer"
            >
              <br />
              <br /> Rellenar formulario
              {/* {" "}
              33 2548 1250{" "} */}
            </a>
          </Typography>
          <Typography variant="body2" className={classes.infoTransfer}>
            {" "}
            Cualquier duda o soporte, contáctanos por whatsapp
            <a
              href="https://wa.me/5213325481250"
              target="_blank"
              rel="noreferrer"
            >
              <br />
              <br /> 33 2548 1250{" "}
            </a>
          </Typography>
        </Box>
      )}
      {creditsAmount && !isTransferSelected && (
        <>
          <Typography variant="caption" className={classes.paymentDetailHeader}>
            {" "}
            Detalle del depósito
          </Typography>
          <Box className={classes.root}>
            <div className={classes.left}>
              <Typography variant="body1" className={classes.paragraph}>
                Cantidad:
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Comisión
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Total a pagar
              </Typography>
            </div>
            <div className={classes.right}>
              <Typography variant="body1" className={classes.paragraph}>
                ${creditsAmount}
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                ${comission}
              </Typography>
              <Typography
                variant="body1"
                className={classes.totalPrice}
                style={{ color: "red" }}
              >
                ${totalWithComission}
              </Typography>
            </div>
          </Box>
        </>
      )}
      {state.selectTarget ? (
        <FiservCheckoutForm
          amount={totalWithComission}
          closeOptionsModal={handleClose}
          paymentDetails={{
            creditsAmount,
            total,
            totalWithComission,
            comission,
          }}
        />
      ) : null}
      {state.selectMerPago && (
        <MercadoPagoCheckout
          amount={totalWithComission}
          paymentDetails={{
            creditsAmount,
            total,
            totalWithComission,
            comission,
          }}
        />
      )}
      {state.selectSaldo && (
        <Box className={classes.buttonSaldoContainer}>
          <Button
            variant="contained"
            className={classes.buttonSaldo}
            disabled={!creditsAmount || isLoading}
            onClick={handleAddCreditsWithSaldo}
          >
            Agregar Créditos
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default PaymentOptionsContainer;
