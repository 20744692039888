import { Box, Typography } from "@material-ui/core";
import React from "react";
import PackageDataStyle from "./PackageData.style";

const PackageData = (props) => {
  const classes = PackageDataStyle();
  const {
    packageWeight,
    packageHeigt,
    packageLength,
    packageWidth,
    destiny_zone,
    destiny_area,
    destiny_sector,
    destiny_subsector,
  } = props;

  return (
    <Box className={classes.container}>
      <Typography className={classes.titleCard}>Datos del paquete</Typography>
      <Box className={classes.informationPackage}>
        <Typography className={classes.text}> Peso: <span className={classes.span}> {packageWeight}kg </span> </Typography>
        <Typography className={classes.text}>
          Medidas: <span className={classes.span}> {packageHeigt} x {packageLength} x {packageWidth} </span> 
        </Typography>
        <Typography className={classes.text}>
          Zona: <span className={classes.span}> {destiny_zone}</span> 
        </Typography>
        <Typography className={classes.text}>
          Areá: <span className={classes.span}> {destiny_area}</span>
        </Typography>
        <Typography className={classes.text}>
          Sector: <span className={classes.span}> {destiny_sector}</span>
        </Typography>
        <Typography className={classes.text}>
          SubSector: <span className={classes.span}> {destiny_subsector}</span> 
        </Typography>
      </Box>
    </Box>
  );
};

export default PackageData;
