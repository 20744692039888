import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../../constants/fontConsts";
import { MAIN_COLORS } from "../../../../constants/theme/colors";

const AddEditAddressModalStyle = makeStyles((theme) => ({
  container: {
    width: "90%",
    maxWidth: 700,
    maxHeight: 650,
    height: "95%",
    backgroundColor: "#ffffff",
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "@media (max-height: 660px) and (min-width: 600px)": {
      maxHeight: 486,
      maxWidth: 580,
    },
    "@media (min-height: 660px) and (min-width: 600px) and (max-width: 800px)":
      {
        maxHeight: 486,
        maxWidth: 580,
      },
    [theme.breakpoints.down(600)]: {
      height: "95%",
      maxHeight: 750,
    },
  },
  containerForm: {
    marginTop: 10,
    marginBottom: 10,
    maxHeight: 479,
    height: "100%",
    overflow: "auto",
    width: 550,
    "@media (max-height: 660px) and (min-width: 600px)": {
      marginTop: 15,
      marginBottom: 5,
      maxHeight: 325,
      width: 480,
    },
    "@media (min-height: 660px) and (min-width: 600px) and (max-width: 800px)":
      {
        marginTop: 15,
        marginBottom: 5,
        maxHeight: 325,
        width: 480,
      },
    [theme.breakpoints.down(600)]: {
      width: "100%",
      maxHeight: 615,
    },
  },
  containerTitleModal: {
    height: 70,
    width: "100%",
    background: "rgba(246, 255, 252, 1)",
    borderRadius: "10px 10px 0px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-height: 660px) and (min-width: 600px)": {
      height: 60,
    },
    "@media (min-height: 660px) and (min-width: 600px) and (max-width: 800px)":
      {
        height: 60,
      },
    [theme.breakpoints.down(600)]: {
      height: 50,
    },
  },
  imgAddress: {
    width: 25,
    height: 25,
    marginRight: 20,
    "@media (max-height: 660px) and (min-width: 600px)": {
      width: 20,
      height: 20,
    },
    "@media (min-height: 660px) and (min-width: 600px) and (max-width: 800px)":
      {
        width: 20,
        height: 20,
      },
    [theme.breakpoints.down(600)]: {
      width: 18,
      height: 18,
    },
  },
  titleModal: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 22,
    textAlign: "center",
    "@media (max-height: 660px) and (min-width: 600px)": {
      fontSize: 19,
    },
    "@media (min-height: 660px) and (min-width: 600px) and (max-width: 800px)":
      {
        fontSize: 19,
      },
    [theme.breakpoints.down(600)]: {
      fontSize: 17.5,
    },
  },
  inputAliasInput: {
    width: "100%",
    marginBottom: 10,
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
  containerAlias: {
    [theme.breakpoints.down(600)]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  error: {
    marginTop: 10,
    color: "#f50057",
    fontSize: 12,
  },
  labelModal: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 12,
    textAlign: "start",
    marginBottom: 6,
    marginTop: 10,
    "@media (max-height: 660px) and (min-width: 600px)": {
      marginTop: 0,
      fontSize: 11,
    },
    "@media (min-height: 660px) and (min-width: 600px) and (max-width: 800px)":
      {
        marginTop: 0,
        fontSize: 11,
      },
    [theme.breakpoints.down(600)]: {
      fontSize: 11.5,
      marginTop: 0,
      marginBottom: 3,
    },
  },
  textText: {
    width: 260,
    marginBottom: 10,
    "@media (max-height: 660px) and (min-width: 600px)": {
      width: 230,
    },
    "@media (min-height: 660px) and (min-width: 600px) and (max-width: 800px)":
      {
        width: 230,
      },
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
  textTextNum: {
    width: 165,
    marginBottom: 10,
    "@media (max-height: 660px) and (min-width: 600px)": {
      width: 145,
    },
    "@media (min-height: 660px) and (min-width: 600px) and (max-width: 800px)":
      {
        width: 145,
      },
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
  textReferences: {
    width: "100%",
    marginBottom: 10,
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
  inputTextFile: {
    "@media (max-height: 660px) and (min-width: 600px)": {
      height: 20,
      padding: "6px 14px",
      fontSize: 13.5,
    },
    "@media (min-height: 660px) and (min-width: 600px) and (max-width: 800px)":
      {
        height: 20,
        padding: "6px 14px",
        fontSize: 13.5,
      },
    [theme.breakpoints.down(600)]: {
      height: 25,
      padding: "6px 14px",
      fontSize: 13,
    },
  },
  containerInputModal: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down(600)]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  containerInputText: {
    [theme.breakpoints.down(600)]: {
      width: "90%",
    },
  },
  containerButtonsModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 90,
    "@media (max-height: 660px) and (min-width: 600px)": {
      height: 80,
    },
    "@media (min-height: 660px) and (min-width: 600px) and (max-width: 800px)":
      {
        height: 80,
      },
    [theme.breakpoints.down(600)]: {
      width: "100%",
      borderTop: "1px solid #d2d2d2",
      borderRadius: "0px 0px 10px 10px",
    },
  },
  backButton: {
    height: 40,
    width: 180,
    backgroundColor: MAIN_COLORS.WHITE,
    borderRadius: 10,
    boxShadow: "none",
    border: "1px solid",
    borderColor: MAIN_COLORS.MAIN_RED_20_OPACITY,
    textTransform: "none",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 15,
    color: MAIN_COLORS.MAIN_RED,
    marginRight: 50,
    "&:hover": {
      backgroundColor: MAIN_COLORS.WHITE,
      boxShadow: "none",
      border: "1px solid",
      borderColor: MAIN_COLORS.MAIN_RED,
    },
    "@media (max-height: 660px) and (min-width: 600px)": {
      height: 35,
      width: 150,
      fontSize: 13,
    },
    "@media (min-height: 660px) and (min-width: 600px) and (max-width: 800px)":
      {
        height: 35,
        width: 150,
        fontSize: 13,
      },
    [theme.breakpoints.down(600)]: {
      height: 35,
      width: 130,
      fontSize: 13,
    },
    [theme.breakpoints.down(410)]: {
      marginRight: 20,
    },
  },
  addButton: {
    height: 40,
    width: 180,
    backgroundColor: MAIN_COLORS.MAIN_RED,
    borderRadius: 10,
    textTransform: "none",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 15,
    color: MAIN_COLORS.WHITE,
    "&:hover": {
      backgroundColor: MAIN_COLORS.MAIN_RED_BLACK,
    },
    "@media (max-height: 660px) and (min-width: 600px)": {
      height: 35,
      width: 150,
      fontSize: 13,
    },
    "@media (min-height: 660px) and (min-width: 600px) and (max-width: 800px)":
      {
        height: 35,
        width: 150,
        fontSize: 13,
      },
    [theme.breakpoints.down(600)]: {
      height: 35,
      width: 130,
      fontSize: 13,
    },
  },
  contentMap: {
    width: "100%",
    height: 350,
    marginBottom: 20,
  },
  map: {
    height: "100%",
    borderRadius: 20,
  },
}));
export default AddEditAddressModalStyle;
