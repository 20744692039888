import { makeStyles } from "@material-ui/core/styles";
import {
  POPPINS_FONTS,
  MONTSERRAT_FONTS,
} from "../../../../constants/fontConsts";

const ContainerStarContentStyle = makeStyles((theme) => ({
  root: {
    background:
      "linear-gradient(80.43deg, #FFECD4 8.1%, #FFFFFF 46.86%, #FFFFFF 47.2%, #D6DDF8 102.17%)",
    width: "100%",
    height: "100vh",
    [theme.breakpoints.only("md")]: {
      height: "90vh",
    },
    [theme.breakpoints.down("sm")]: {
      height: 620,
    },
  },
  title: {
    fontSize: 44,
    fontFamily: POPPINS_FONTS.POPPINS_EXTRABOLD,
    textAlign: "start",
    [theme.breakpoints.down("md")]: {
      fontSize: 25,
      marginBottom: 15,
    },
  },
  slogan: {
    textAlign: "start",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 20,
    zIndex: 1,
    marginRight: 350,
    [theme.breakpoints.up("md")]: {
      marginRight: 200,
    },
    [theme.breakpoints.only("md")]: {
      marginRight: 200,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      marginRight: 50,
    },
  },
  containerImg: {
    paddingLeft: 295,
    marginTop: "-150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  imgMain: {
    width: 900,
    height: "auto",
    [theme.breakpoints.up("md")]: {
      marginTop: -350,
    },
    [theme.breakpoints.down("md")]:{
      width: 650,
      height: 700,
      marginRight: 200,
      marginTop: -250,
    },
    [theme.breakpoints.down("sm")]: {
      width: 330,
      height: 450,
      marginRight: 250,
      marginTop: -150,
    },
  },
  containerText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    paddingTop: 170,
    paddingLeft: 70,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 50,
    },
  },
  containerInputs: {
    display: "flex",
    width: 800,
    height: 110,
    padding: 4,
    paddingLeft: 20,
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    backgroundColor: "#fff !important",
    borderRadius: 20,
    marginTop: 50,
    filter: "drop-shadow(0px 19px 24px rgba(0, 0, 0, 0.25))",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      height: 80,
    },
  },
  containerIput: {
    display: "flex",
    width: 550,
    height: 63,
    padding: 0,
    paddingLeft: 0,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    border: "1px solid #FFFFFF",
  },
  inputFile: {
    width: "100%",
    height: 55,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    background: 'rgba(233, 237, 252, 0.27)',
    borderRadius: 20,
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      height: 55,
    },
  },
  buttonCircle: {
    margin: 10,
    borderRadius: 10,
    backgroundColor: "#F8822E !important",
    color: "#fff !important",
    width: 140,
    height: 40,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    "&:hover": {
      backgroundColor: "#ffff !important",
      color: "#F8822E !important",
      border: "1px solid #F8822E !important",
    },
  },
}));

export default ContainerStarContentStyle;
