import {Box, Button, Grid, Typography } from '@material-ui/core';
import React from 'react'
import DrawerDash from '../../components/DrawerDash'
import RowInformation from '../../components/shipmentInformation/RowInformation';
import Information from '../../components/shipmentInformation/Infotmation';
import ImageStatusPackage from '../../components/tracking/ImageStatusPackage';
import ShipmentInformationStyle from './ShipmentInformationStyle';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

const ShipmentsInformation = () => {

const classes = ShipmentInformationStyle()
  return (
    <div>
        <DrawerDash>
            <Button
            className={classes.buttonVolver}
            startIcon={<KeyboardReturnIcon className={classes.iconVolver}/>}
            >
                Volver
            </Button>
            <RowInformation/>
            <Grid container className={classes.container}>
                <Grid item md={4}> 
                    <Information/>
                </Grid>
                <Grid item md={8}> 
                    <Typography className={classes.title}>Estado del envio</Typography>
                    <ImageStatusPackage/>
                    <Box className={classes.containerButton}>
                        <Button className={classes.button}>Descargar guía</Button>
                    </Box>
                    
                </Grid>
            </Grid>
        </DrawerDash>
    </div>
  )
}

export default ShipmentsInformation