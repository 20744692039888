import React, { Fragment } from "react";
import ModalComponent from "../../../components/sharedComponents/ModalComponent";
import { Box, Button, Typography } from "@material-ui/core";
import InsufficientCreditsModalStyle from "./InsufficientCreditsModalStyle";
import imgWhatsApp from "../../../assets/img/imgWhatsApp.svg";

const InsufficientCreditsModal = (props) => {
  const { open, handleClose } = props;

  const classes = InsufficientCreditsModalStyle();

  const whatsappLink = "https://api.whatsapp.com/send?phone=3325481250";

  const body = (
    <Box className={classes.content}>
      <Typography id="modal-modal-title" className={classes.title}>
        Creditos Insufientes
      </Typography>
      <Typography id="modal-modal-description" className={classes.text}>
        Puedes ponerte en contacto con el administrador para conseguir
        <br />
        mas creditos
        <br />
        Contacta mediante WhatsApp para mayor información
      </Typography>
      <Box className={classes.containerButtons}>
        <Button
          variant="contained"
          className={classes.backButton}
          onClick={handleClose}
        >
          {" "}
          Regresar{" "}
        </Button>
        <a
          variant="contained"
          href={whatsappLink}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.WTSPButton}
        >
          Contactar
          <img src={imgWhatsApp} alt="" />
        </a>
      </Box>
    </Box>
  );

  return (
    <Fragment>
      <ModalComponent open={open} handleClose={handleClose} body={body} />
    </Fragment>
  );
};

export default InsufficientCreditsModal;
