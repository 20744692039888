import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS, POPPINS_FONTS } from "../../constants/fontConsts";

const AboutStyle = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: 730,
    background: "#E8505B",
    paddingLeft: 50,
    paddingRight: 50,
    marginTop: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderTopLeftRadius: 150,
    [theme.breakpoints.down(1111, "lg")]: {
      height: "100%",
      borderRadius: "0px 150px 0px 0px",
      marginTop: 50,
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: "140px 0px 140px 0px",
    },
  },
  offerTitle: {
    fontFamily: POPPINS_FONTS.POPPINS_BOLD,
    fontSize: 25,
    color: "#fff",
    marginTop: 50,
    [theme.breakpoints.up("lg")]: {
      fontSize: 35,
      marginBottom: 20,
      marginTop: 80,
    },
  },
  gridItemContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontFamily: POPPINS_FONTS.POPPINS_BOLD,
    fontSize: 40,
    textAlign: "start",
    marginTop: 150,
    marginLeft: 80,
    marginRight: 250,
    marginBottom: 120,
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
      marginRight: 30,
      marginLeft: 30,
      marginBottom: 50,
    },
  },
  hightLightText: {
    fontFamily: POPPINS_FONTS.POPPINS_BOLD,
    fontSize: 40,
    color: "#F8822E",
    marginRight: 250,
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
      marginRight: 0,
    },
  },
  ourHistory: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_EXTRABOLD,
    fontSize: 48,
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
    },
  },
  contentContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  ourHistorySubtitle: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 20,
    marginTop: 10,
    marginBottom: 50,
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    },
  },
  ourHistoryContainer: {
    paddingLeft: 100,
    paddingRight: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: 1200,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 70,
      paddingRight: 30,
    },
  },
  ourHistoryFirstP: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 18,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  ourHistorySecondP: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 18,
    marginTop: 20,
    marginBottom: 100,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  objectivesSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  headerCard: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 30,
  },
  cardIcon: {
    marginTop: 20,
  },
  cardDescription: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 14,
    marginTop: 25,
    maxWidth: 350,
    paddingLeft: 30,
    paddingRight: 30,
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 150,
    },
  },
  cardsContainer: {
    maxWidth: 1200,
    marginBottom: 100,
  },
  objectivesContainer: {
    maxWidth: 1000,
  },
  clientsContainer: {
    backgroundColor: "#F3F3F3",
    height: 400,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: 320,
    },
  },
  ourClientsTitle: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 30,
    marginTop: 50,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
  clientsLogosContainer: {
    width: "100%",
    height: 250,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      width: "2px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 2px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: ".5px solid white",
      borderRadius: 10,
    },
    [theme.breakpoints.down("sm")]: {
      height: 180,
    },
  },
  clientImagesLogos: {
    width: 150,
    height: 150,
    marginLeft: 80,
    borderRadius:20,
    [theme.breakpoints.down("sm")]: {
      width: 100,
      height: 100,
    },
  },
  centerLogo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default AboutStyle;
