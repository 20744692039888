import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const FeeChooseStyles = makeStyles((theme) => ({
  contentButtons: {
    width: "100% !important",
    marginBottom: 30,
    float: "inline-end",
    marginTop: -30,
  },
  headerTitle: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 18,
    [theme.breakpoints.down(600)]: {
      fontSize: 16,
    },
  },
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: 20,
    marginLeft: "-20px",
    [theme.breakpoints.down(450)]: {
      marginLeft: "-32px",
      width: "112%",
    },
    [theme.breakpoints.down(380)]: {
      marginLeft: "-34px",
      width: "114%",
    },
  },
  skeleton: {
    background: "#00000014",
    transform: "none",
    width: "95%",
    "@media (min-height: 1000px)": {
      height: 500,
    },
    "@media (min-height: 900px) and (max-height: 1000px)": {
      height: 400,
    },
    "@media (min-height: 700px) and (max-height: 900px)": {
      height: 250,
    },
    "@media (min-height: 600px) and (max-height: 700px)": {
      height: 150,
    },
    "@media (max-height: 600px)": {
      height: 100,
    },
    [theme.breakpoints.up(1210)]: {
      width: "98%",
      marginLeft: "-30px",
    },
    [theme.breakpoints.down(600)]: {
      height: 200,
    },
  },
  containerNoRecords: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginLeft: "-30px",
    marginBottom: 40,
    [theme.breakpoints.down(600)]: {
      marginBottom: 20,
    },
  },
  imageNoRecords: {
    width: 130,
    height: 130,
    marginBottom: 30,
    [theme.breakpoints.down(900)]: {
      width: 100,
      height: 100,
    },
    [theme.breakpoints.down(700)]: {
      width: 90,
      height: 90,
    },
    [theme.breakpoints.down(600)]: {
      width: 70,
      height: 70,
    },
  },
  textNoRecords: {
    fontSize: 15.5,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    textAlign: "center",
    [theme.breakpoints.down(900)]: {
      fontSize: 15,
    },
    [theme.breakpoints.down(700)]: {
      width: "95%",
      fontSize: 14,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 13,
    },
  },
}));
export default FeeChooseStyles;
