import { Box } from "@material-ui/core";
import React from "react";
import DrawerDash from "../../components/DrawerDash";
import ImageAndLink from "../../components/sharedComponents/ImageAndLink";
import imgEXCEL from "../../assets/img/dashboard/img-Excel.svg";
import imgFormulario from "../../assets/img/dashboard/img-Formulario.svg";
import { ROUTES_CONSTANTS } from "../../constants/routesConstants";
import { useNavigate } from "react-router-dom";
import OnBoardingProcessModal from "../../components/OnBoardingProcessModal";

const CreationGuidesLinks = () => {
  const navigate = useNavigate();

  const handleRedirectToCreateGuides = () => {
    navigate(ROUTES_CONSTANTS.FORM_CREAR_GUIA, { replace: true });
  };

  const handleRedirectToMassDelivery = () => {
    navigate(ROUTES_CONSTANTS.GENERAR_GUIAS_MASIVO, { replace: true });
  };

  return (
    <DrawerDash>
      <Box style={{ marginTop: 50 }}>
        <ImageAndLink
          isInitialPosition={true}
          title="Creación de guía individual"
          img={imgFormulario}
          text="A diferencia del excel, esta opción te permite ir de una guía por una guía, esta no cuenta con un limíte de creación de guías."
          textButton="Crear Guia"
          disabled={false}
          handleClick={handleRedirectToCreateGuides}
        />

        <ImageAndLink
          title="Creación de guía masiva (solo liber)"
          img={imgEXCEL}
          text="La opción de generar guías mediante excel, permite realizar una carga masiva ( 20 como máximo ) de guías y crearlas al mismo tiempo."
          textButton="Creación Masiva"
          disabled={false}
          handleClick={handleRedirectToMassDelivery}
        />
      </Box>
      <OnBoardingProcessModal />
    </DrawerDash>
  );
};

export default CreationGuidesLinks;
