import React, { Fragment, useState } from "react";
import ModalComponent from "../../../../components/sharedComponents/ModalComponent";
import { Box, Button, Typography } from "@material-ui/core";
import MainAddressModalStyle from "./MainAddressModalStyle";
import { changeMainAddress } from "../../../../api/guidesFormApi";
import { notification } from "../../../../utils/notification";
import Loader from "../../../Loader";
import useLogout from "../../../../hooks/useLogout";
import { getInfoNegocio } from "../../../../api/userApi";
import { getCookie } from "../../../../utils/sessionCookie";
import { AUTH_MESSAGES } from "../../../../constants/messages";
import { setBusinessLocationsInformation } from "../../../../store/reducers/business/BusinessInformationSlice";
import { useDispatch } from "react-redux";

const MainAddressModal = (props) => {
  const { open, handleClose, alias, locationId } = props;

  const classes = MainAddressModalStyle();

  const dispatch = useDispatch();
  const [logout] = useLogout();

  const [showLoader, setShowLoader] = useState(false);

  const changeAddress = () => {
    handleClose();
    setShowLoader(true);

    const payload = {
      new_id_location: locationId,
    };

    changeMainAddress(payload)
      .then((res) => {
        if (
          res.data.status_Code === 200 &&
          res.data.status_Message === "principal location was updated"
        ) {
          notification(
            "La dirección principal se cambió se actualizó correctamente",
            "success"
          );
          getInfoNegocio(getCookie("accessToken"))
            .then((res) => {
              if (
                res.data.status_Code === 200 &&
                res.data.status_Message === AUTH_MESSAGES.INFO_COMPLETE
              ) {
                setShowLoader(false);
                const newBusinessLocation = res.data.business_Locations;
                dispatch(setBusinessLocationsInformation(newBusinessLocation));
              } else if (res.data.status_Code === 401) {
                setShowLoader(false);
                notification("La sesión ha caducado");
                logout();
              } else {
                setShowLoader(false);
                notification(
                  "Ocurrio un error al recuperar los domicilios en el perfil"
                );
              }
            })
            .catch((err) => {
              setShowLoader(false);
              notification(
                "Ocurrio un error al recuperar los domicilios en el perfil"
              );
            });
        } else if (res.data.status_Code === 401) {
          setShowLoader(false);
          notification("La sesión ha caducado");
          logout();
        } else {
          setShowLoader(false);
          notification("Ocurrio un error al actualizar la dirección");
        }
      })
      .catch((err) => {
        setShowLoader(false);
        notification("Ocurrió un error al guardar la información");
      });
  };

  const body = (
    <Box className={classes.content}>
      <Box className={classes.containerText}>
        <Typography className={classes.title}>
          Cambiar dirección principal
        </Typography>
        <Typography className={classes.text}>
          ¿Estás seguro de que quieres agregar la dirección{" "}
          <strong>{alias}</strong> como la dirección principal?
        </Typography>
      </Box>
      <Box className={classes.containerButtons}>
        <Button
          variant="contained"
          className={classes.backButton}
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          className={classes.continue}
          onClick={changeAddress}
        >
          Continuar
        </Button>
      </Box>
    </Box>
  );

  return (
    <Fragment>
      <ModalComponent
        open={open}
        handleClose={handleClose}
        body={body}
        showButton={false}
      />
      <Loader
        showLoader={showLoader}
        text={"Actualizando dirección principal..."}
      />
    </Fragment>
  );
};

export default MainAddressModal;
