import React, { Fragment, useState } from "react";
import ModalComponent from "../../../../components/sharedComponents/ModalComponent";
import {
  Box,
  Button,
  Radio,
  RadioGroup,
  Typography,
  FormControlLabel,
} from "@material-ui/core";
import TrustedUserModalStyles from "./TrustedUserModalStyles";
import imgMaseta from "../../../../assets/img/dashboard/img-maseta.svg";

const TrustedUserModal = (props) => {
  const { open, handleClose, handleCreateGuides } = props;

  const classes = TrustedUserModalStyles();

  const [payLater, setPayLater] = useState(false);
  const [credits, setCredits] = useState(false);

  const handleClick = () => {
    handleClose();
    if (credits) {
      handleCreateGuides("pagada");
    } else {
      handleCreateGuides("faltante");
    }
  };

  const handleChange = (e) => {
    if (e.target.value === "credits") {
      setCredits(true);
      setPayLater(false);
    } else {
      setCredits(false);
      setPayLater(true);
    }
  };

  const body = (
    <Box className={classes.content}>
      <Box className={classes.containerHeader}>
        <Typography className={classes.textHeader}>
          Tu ya formas parte de nuestros usuarios de confianza. ¡Elige tu método
          de pago!
          <img src={imgMaseta} className={classes.image} alt="imagenMaseta" />
        </Typography>
      </Box>
      <Box className={classes.containerBody}>
        <RadioGroup
          aria-label="payment"
          name="payment"
          className={classes.containerRadioButton}
          onChange={handleChange}
        >
          <FormControlLabel
            value="PayLater"
            checked={payLater}
            control={<Radio style={{ color: "black" }} />}
            className={classes.formControl}
            label={
              <Typography className={classes.textRadioButton}>
                Pagar al momento de la recolección
              </Typography>
            }
          />
          <FormControlLabel
            value="credits"
            checked={credits}
            control={<Radio style={{ color: "black" }} />}
            label={
              <Typography className={classes.textRadioButton}>
                Pagar con creditos
              </Typography>
            }
          />
        </RadioGroup>
      </Box>
      <Box className={classes.containerFooter}>
        <Button
          variant="outlined"
          className={classes.button}
          onClick={handleClick}
          disabled={payLater || credits ? false : true}
        >
          <Typography className={classes.textButton}>Continuar</Typography>
        </Button>
      </Box>
    </Box>
  );

  return (
    <Fragment>
      <ModalComponent open={open} handleClose={handleClose} body={body} />
    </Fragment>
  );
};

export default TrustedUserModal;
