import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ChildFriendlyIcon from '@material-ui/icons/ChildFriendly';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import RowInformationStyle from './RowInformationStyle';

function createData(Vehiculo,NoGuia, Destinatario, Recoleccion, Zona, TipoEnvio, Entrega, Estado, Accion) {
  return {Vehiculo, NoGuia , Destinatario, Recoleccion, Zona, TipoEnvio, Entrega, Estado, Accion};
}

const rows = [
  createData(<ChildFriendlyIcon/>, '8FR0A0181FA', "Paola Govea", "15/03/23", "Zona A", "expres", "15/03/23", "Proceso", <ArrowForwardIcon/>)
];

const RowInformation = () => {
  const classes = RowInformationStyle();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className={classes.head}>
          <TableRow className={classes.textHead}>
            <TableCell></TableCell>
            <TableCell>No. de Guía</TableCell>
            <TableCell>Destinatario</TableCell>
            <TableCell>Recoleccion</TableCell>
            <TableCell>Zona</TableCell>
            <TableCell>Tipo de envio</TableCell>
            <TableCell>Entrega</TableCell>
            <TableCell>Estado</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} className={classes.textBody}>
              <TableCell component="th" scope="row">
                {row.Vehiculo}
              </TableCell>
              <TableCell>{row.NoGuia}</TableCell>
              <TableCell>{row.Destinatario}</TableCell>
              <TableCell>{row.Recoleccion}</TableCell>
              <TableCell>{row.Zona}</TableCell>
              <TableCell>{row.TipoEnvio}</TableCell>
              <TableCell>{row.Entrega}</TableCell>
              <TableCell>{row.Estado}</TableCell>
              <TableCell>{row.Accion}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default RowInformation;