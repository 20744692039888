import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";
import { MAIN_COLORS } from "../../../constants/theme/colors";
const GuideSummaryinformationStyles = makeStyles((theme) => ({
  headerTitle: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 18,
  },
  remitentContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 47,
  },
  remitentHeader: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    fontSize: 13,
  },
  changeLabel: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 10,
    textDecoration: "underline",
    cursor: "pointer",
    [theme.breakpoints.down(1130)]: {
      marginRight: 30,
    },
  },
  userInfoContainer: {
    marginTop: 20,
  },
  headerUserInfoLabel: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 12,
    color: MAIN_COLORS.BLACK_20_OPACITY,
  },
  descriptionUserInfoLabel: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 16,
    [theme.breakpoints.down(600)]: {
      fontSize: 13.5,
      width: "95%",
    },
  },
}));
export default GuideSummaryinformationStyles;
