import { makeStyles } from "@material-ui/core";


const OverlayImagesStyle = makeStyles((theme) =>({
    containerImages:{
        position:"relative",
        width:440,
        height:440,
        [theme.breakpoints.down("md")]:{
            width:220,
            height:220,
        },
    },
    fondo:{
        position:"absolute",
        top:0,
        left:0,
        width:"100%",
        height:"100%",
        zIndex:1
    },
    image:{
        position:"absolute",
        top:50,
        left:60,
        height:328,
        width:250,
        zIndex:2,
        [theme.breakpoints.down("md")]:{
            width:164,
            height:125,
            top:20,
            left:30,
        },
    }
}))

export default OverlayImagesStyle;