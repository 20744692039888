import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import PackageFormInformationStyles from "./PackageFormInformationStyles";
import packageImage from "../../../assets/img/dashboard/img-boxDimensiones.svg";
import FormBottomButtons from "../FormBottomButtons";
import { Autocomplete } from "@material-ui/lab";
import { getPackages } from "../../../api/guidesFormApi";
import { notification } from "../../../utils/notification";
import useLogout from "../../../hooks/useLogout";

const PackageFormInformation = (props) => {
  const {
    handleClickBackStep,
    handleClickNextStep,
    packageInformation,
    setPackageInformation,
    setVerifyLiber,
    setValuePackage,
    valuePackage,
    setPackageData,
    packageData,
  } = props;
  const classes = PackageFormInformationStyles();

  const [logout] = useLogout();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (packageInformation.type_product !== "") {
      setVerifyWeigth(true);
      setVerifyHight(true);
      setVerifyBroad(true);
      setVerifyLong(true);
    }

    if (packageInformation.volumetric_weigth !== "") {
      if (
        (packageInformation.long_product *
          packageInformation.broad_product *
          packageInformation.high_product) /
          5000 <=
        60
      ) {
        setVerifyVolumetricWeigth(true);
      } else {
        setVerifyVolumetricWeigth(false);
      }
    }

    if (packageInformation.type_product === "") {
      getPackages()
        .then((res) => {
          if (res.data.status_Code === 200) {
            setPackageData(res.data.products);
          } else if (res.data.status_Code === 401) {
            notification("La sesión ha caducado", "error");
            logout();
          } else {
            notification("No se pudieron recuperar los destinatarios");
          }
        })
        .catch((err) => {
          notification("No se pudieron recuperar los destinatarios");
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [verifyWeigth, setVerifyWeigth] = useState(false);
  const [verifyVolumetricWeigth, setVerifyVolumetricWeigth] = useState(false);
  const [verifyHight, setVerifyHight] = useState(false);
  const [verifyBroad, setVerifyBroad] = useState(false);
  const [verifyLong, setVerifyLong] = useState(false);

  const disableButtton =
    packageInformation.type_product === "" ||
    packageInformation.long_product === "" ||
    packageInformation.broad_product === "" ||
    packageInformation.high_product === "" ||
    packageInformation.weigth_product === "" ||
    packageInformation.volumetric_weigth === "";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPackageInformation((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "weigth_product") {
      if (value <= 60 && value > 0) {
        setVerifyWeigth(true);
        if (value <= 25) {
          setVerifyLiber(true);
        } else {
          setVerifyLiber(false);
        }
      } else {
        setVerifyWeigth(false);
      }
    }

    if (name === "high_product") {
      if (value <= 0 || value > 120) {
        setVerifyHight(false);
      } else if (
        (packageInformation.long_product *
          packageInformation.broad_product *
          value) /
          5000 <=
        60
      ) {
        setVerifyVolumetricWeigth(true);
        setVerifyHight(true);
      } else {
        setVerifyVolumetricWeigth(false);
        setVerifyHight(true);
      }
    }

    if (name === "broad_product") {
      if (value <= 0 || value > 80) {
        setVerifyBroad(false);
      } else if (
        (packageInformation.long_product *
          value *
          packageInformation.high_product) /
          5000 <=
        60
      ) {
        setVerifyVolumetricWeigth(true);
        setVerifyBroad(true);
      } else {
        setVerifyVolumetricWeigth(false);
        setVerifyBroad(true);
      }
    }

    if (name === "long_product") {
      if (value <= 0 || value > 80) {
        setVerifyLong(false);
      } else if (
        (value *
          packageInformation.broad_product *
          packageInformation.high_product) /
          5000 <=
        60
      ) {
        setVerifyVolumetricWeigth(true);
        setVerifyLong(true);
      } else {
        setVerifyVolumetricWeigth(false);
        setVerifyLong(true);
      }
    }
  };

  packageInformation.volumetric_weigth =
    (packageInformation.long_product *
      packageInformation.broad_product *
      packageInformation.high_product) /
    5000;

  const onChangeOptionIndex = (value) => {
    if (value !== null) {
      setVerifyLong(true);
      setVerifyBroad(true);
      setVerifyHight(true);
      setVerifyWeigth(true);
      setVerifyVolumetricWeigth(true);

      if (
        value.width <= 80 &&
        value.height <= 80 &&
        value.length <= 80 &&
        value.weight <= 25 &&
        (value.length * value.width * value.height) / 5000 <= 25
      ) {
        setVerifyLiber(true);
      } else {
        setVerifyLiber(false);
      }

      setPackageInformation({
        id_package: value.id_concurrent_product,
        type_product: value.product_name,
        long_product: value.length,
        broad_product: value.width,
        high_product: value.height,
        weigth_product: value.weight,
        volumetric_weigth: (value.length * value.width * value.height) / 5000,
        description: value.description,
      });
    } else {
      setVerifyLong(false);
      setVerifyBroad(false);
      setVerifyHight(false);
      setVerifyWeigth(false);
      setVerifyVolumetricWeigth(false);
      setVerifyLiber(false);

      setPackageInformation({
        id_package: "",
        type_product: "",
        long_product: "",
        broad_product: "",
        high_product: "",
        weigth_product: "",
        volumetric_weigth: "",
        description: "",
      });
    }

    setValuePackage(value);
  };

  const handleSavePackage = (e) => {
    setPackageInformation((prevState) => ({
      ...prevState,
      save_package: e.target.checked,
      update_package: false,
    }));
  };

  const handleUpdatePackage = (e) => {
    setPackageInformation((prevState) => ({
      ...prevState,
      save_package: false,
      update_package: e.target.checked,
    }));
  };

  const validations = () => {
    setPackageInformation((prevState) => ({
      ...prevState,
      long_product: Math.ceil(packageInformation.long_product),
      broad_product: Math.ceil(packageInformation.broad_product),
      high_product: Math.ceil(packageInformation.high_product),
      weigth_product: Math.ceil(packageInformation.weigth_product),
      volumetric_weigth: Math.ceil(
        (Math.ceil(packageInformation.long_product) *
          Math.ceil(packageInformation.broad_product) *
          Math.ceil(packageInformation.high_product)) /
          5000
      ),
    }));

    if (
      packageInformation.broad_product <= 80 &&
      packageInformation.high_product <= 80 &&
      packageInformation.long_product <= 80
    ) {
      setVerifyLiber(true);
    } else {
      setVerifyLiber(false);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Box>
          <Typography className={classes.headerTitle}>
            Dimensiones y tipo de producto
          </Typography>
          <Typography className={classes.subheaderTitle}>
            {" "}
            Medidas, peso y contenido del paquete{" "}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box className={classes.eyeInformationcontainer}>
          <Visibility />
          <Typography className={classes.containerInformation}>
            Revisa que las dimensiones de tu paquete sean correctas, ya que si
            detecta un sobrepeso, se generará un costo extra al momento de la
            recolección.
          </Typography>
        </Box>
      </Grid>
      {packageData.length > 0 && (
        <Grid item xs={12}>
          <FormControl className={classes.selectDirection}>
            <Autocomplete
              id="embalaje"
              className={classes.styleAutocomplete}
              options={packageData}
              value={valuePackage}
              getOptionLabel={(option) => `${option.product_name}`}
              renderOption={(option) => (
                <p>
                  {option.product_name}
                  <br />
                  Dimensiones: {option.length}x{option.width}x{option.height} cm
                  <br />
                  Peso: {option.weight} kgs
                </p>
              )}
              onChange={(event, newValue) => onChangeOptionIndex(newValue)}
              classes={{
                option: classes.optionAutocomplete,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Selecciona un empaque"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    className: classes.inputAutocomplete,
                  }}
                  InputLabelProps={{
                    className: classes.labelAutocomplete,
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12} md={12}>
        <Typography className={classes.packageDimentionsHeader}>
          Dimensiones del paquete
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl className={classes.typeProductInput}>
          <Typography className={classes.inputDescription}>
            Tipo de empaque <strong style={{ color: "red" }}>*</strong>
          </Typography>
          <TextField
            variant="outlined"
            value={packageInformation.type_product}
            name="type_product"
            onChange={handleChange}
            inputProps={{
              className: classes.input,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.descriptionText}>
        <FormControl className={classes.typeProductInput}>
          <Typography className={classes.inputDescription}>
            Descripción del contenido
          </Typography>
          <TextField
            variant="outlined"
            value={packageInformation.description}
            name="description"
            onChange={handleChange}
            inputProps={{
              className: classes.input,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.packageDimentionsHeader}>
          Medidas (cm)
        </Typography>
      </Grid>

      <Grid container style={{ maxWidth: 450 }}>
        {(!verifyBroad || !verifyHight || !verifyLong) && (
          <Grid item xs={12}>
            <Typography className={classes.errorProduct}>
              El alto, ancho y largo del producto deben ser mayor a 1cm, el
              largo y ancho deben ser menores o iguales a 80cm, el alto debe ser
              menor o igual a 120cm
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={4}>
          <FormControl className={classes.measuresInputFields}>
            <Typography className={classes.inputDescription}>
              Largo (cm) <strong style={{ color: "red" }}>*</strong>
            </Typography>
            <TextField
              type="number"
              variant="outlined"
              value={packageInformation.long_product}
              name="long_product"
              onChange={handleChange}
              placeholder="ej: 80"
              inputProps={{
                className: classes.input,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl className={classes.measuresInputFields}>
            <Typography className={classes.inputDescription}>
              Ancho (cm) <strong style={{ color: "red" }}>*</strong>
            </Typography>
            <TextField
              type="number"
              variant="outlined"
              value={packageInformation.broad_product}
              name="broad_product"
              onChange={handleChange}
              placeholder="ej: 80"
              inputProps={{
                className: classes.input,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl className={classes.measuresInputFields}>
            <Typography className={classes.inputDescription}>
              Alto (cm) <strong style={{ color: "red" }}>*</strong>
            </Typography>
            <TextField
              type="number"
              variant="outlined"
              value={packageInformation.high_product}
              name="high_product"
              onChange={handleChange}
              placeholder="ej: 50"
              inputProps={{
                className: classes.input,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl className={classes.measuresInputFields}>
            <Typography className={classes.inputDescription}>
              Peso(Kg) <strong style={{ color: "red" }}>*</strong>
            </Typography>
            <TextField
              type="number"
              variant="outlined"
              value={packageInformation.weigth_product}
              error={!verifyWeigth}
              helperText={!verifyWeigth && "El peso debé ser menor a 60 Kg"}
              name="weigth_product"
              onChange={handleChange}
              placeholder="ej: 25"
              inputProps={{
                className: classes.input,
              }}
            />
          </FormControl>
          <FormControl className={classes.measuresInputFields}>
            <Typography className={classes.inputDescription}>
              Peso a Cotizar
            </Typography>
            <TextField
              variant="outlined"
              value={
                packageInformation.volumetric_weigth >
                packageInformation.weigth_product
                  ? packageInformation.volumetric_weigth
                  : packageInformation.weigth_product
              }
              error={!verifyVolumetricWeigth}
              helperText={
                !verifyVolumetricWeigth && "El peso debé ser menor a 60 Kg"
              }
              disabled={true}
              name="volumetricWeigt"
              placeholder="ej: 0"
              inputProps={{
                className: classes.input,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={8} className={classes.imagePackage}>
          <img src={packageImage} alt="package" />
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        {packageInformation.id_package === "" ? (
          <FormControlLabel
            control={
              <Switch
                checked={packageInformation.save_package}
                onChange={handleSavePackage}
                name="guardar"
              />
            }
            labelPlacement="start"
            label={<Typography className={classes.switch}>Guardar</Typography>}
          />
        ) : (
          <FormControlLabel
            control={
              <Switch
                checked={packageInformation.update_package}
                onChange={handleUpdatePackage}
                name="actualizar"
              />
            }
            labelPlacement="start"
            label={
              <Typography className={classes.switch}>Actualizar</Typography>
            }
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <FormBottomButtons
          disableNext={
            !verifyWeigth ||
            !verifyVolumetricWeigth ||
            disableButtton ||
            !verifyBroad ||
            !verifyHight ||
            !verifyLong
          }
          handleNext={() => {
            validations();
            handleClickNextStep();
          }}
          handleBack={handleClickBackStep}
        />
      </Grid>
    </Grid>
  );
};

export default PackageFormInformation;
