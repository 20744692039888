export const MAIN_COLORS = {
  WHITE: "#fff",
  BLACK: "#000",
  BLACK_20_OPACITY: "rgba(0, 0, 0, 0.25)",
  WHITE_SMOKE: "#F5FAFB",
  LIGHT_GRAY: "#D9D9D9",
  EXTRA_LIGHT_GRAY: "#E6E6E6",
  BLACK_MEDIUM_OPACITY: "rgba(0, 0, 0, 0.5)",
  MAIN_RED: "#D15252",
  MAIN_RED_20_OPACITY: "rgba(209, 82, 82, 0.2)",
  MAIN_RED_BLACK: "#C52F2F",
  MAIN_GREY: "#CFC4C4"
};
export const SHADOWS = {
  SH1: "0px 4px 1px -3px rgba(0, 0, 0, 0.1)",
};
