import axios from "axios";
const { REACT_APP_LIBER_API_URL } = process.env;

export const createGuideForm = (payload, accessToken) => {
  const request = {
    method: "POST",
    url: `${REACT_APP_LIBER_API_URL}/guides/create-guides`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
      auth_Token: accessToken,
    },

  };

  return axios(request);
};
export const validateSaleGuide = (payload, accessToken) => {
  const request = {
    method: "POST",
    url: `${REACT_APP_LIBER_API_URL}/business/validate-sale`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
      auth_Token: accessToken,
    },

  };

  return axios(request);
};
