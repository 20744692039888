import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS, POPPINS_FONTS } from "../../constants/fontConsts";

const LandingPageStyle = makeStyles((theme) => ({
  containerMision: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  gdlEnvios: {
    height: 300,
  },
  cardSections: {
    width: "100%",
    background:
      " linear-gradient(181.26deg, #FFF0E5 4.69%, rgba(255, 255, 255, 0) 30.48%)",
    borderRadius: "100px 100px 0px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 600,
    [theme.breakpoints.down("md")]: {
      borderRadius: `10px 10px 0px 0px`,
      paddingBottom: 50,
      paddingTop: 50,
      height:"100%"
    },
  },
  titleCenter: {
    fontFamily: POPPINS_FONTS.POPPINS_BOLD,
    fontSize: 40,
    marginBottom: 10,
    [theme.breakpoints.between("md","lg")]: {
      fontSize: 30,
      marginTop: 200,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 25,
      marginTop: 100,
    },
    
  },
  descriptionText: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 25,
    paddingLeft: 200,
      paddingRight: 200,
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
      paddingLeft: 40,
      paddingRight: 40,
      marginTop: 40,
    },
  },


  containerTarjets: {
    display: "flex",
    alignItems: "space-around",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  containerTipoEnvios: {
    width: "100%",
    height: "100%",
    background: "#E8505B",
    borderRadius: "0px 500px 0px 0px",
    [theme.breakpoints.down(1111, "lg")]: {
      height: "100%",
      borderRadius: "0px 150px 0px 0px",
      marginTop: 50,
    },
  },
  cardsImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection:"column"
  },
  MisionValores: {
    
  },
  ZonasEnvio: {
    width: "100%",
    height: "100%",
    paddingBottom:30,
    background:
      "linear-gradient(256.8deg, rgba(255, 237, 214, 0.5) 29.73%, rgba(255, 255, 255, 0.5) 47.05%, rgba(229, 227, 255, 0.5) 94.74%)",
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
  },
  containerZonasEnvio:{
    display:"flex",
    justifyContent:"space-evenly",
    [theme.breakpoints.down("md")]: {
      justifyContent:"center",
      alignItems:"center",
      flexDirection:"column"
    },
  },
  tablaZonas:{
    width: 500,
    height:790,
    backgroundColor:"#FFFFFF",
    textAlign:"center",
    filter: "drop-shadow(0px 1px 1px #000)",
    borderRadius:5,
    marginBottom:10,
    [theme.breakpoints.down("md")]: {
      width: 450,
      height:680,
    },
    [theme.breakpoints.down("sm")]: {
      width: 300,
      height:650,
    },
  },
  theadZonas1:{
    textAlign:"center",
    backgroundColor: "#EEEEEE",
    fontFamily: POPPINS_FONTS.POPPINS_SEMIBOLD,
    fontSize:20,
    borderBottom:"1px solid rgba(0, 0, 0, 0.1)",
    padding:5,
    [theme.breakpoints.down("sm")]: {
      fontSize:18,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize:16,
    },
  },
  theadZonas:{
    textAlign:"center",
    backgroundColor: "#EEEEEE",
    fontFamily: POPPINS_FONTS.POPPINS_SEMIBOLD,
    fontSize:20,
    borderBottom:"1px solid rgba(0, 0, 0, 0.1)",
    padding:"-10px",
    [theme.breakpoints.down("sm")]: {
      fontSize:18,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize:16,
    },
  },
  borderTabla:{
    border: "1px solid"
  },
  bodyTabla:{
    fontFamily:MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize:16,
    border:"1px solid #ddd ",
    [theme.breakpoints.down("md")]: {
      fontSize:14,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize:12,
    },
  },
  trTable:{
    borderBottom:"1px solid rgba(0, 0, 0, 0.1)"
  },
  textoTabla:{
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM ,
    fontSize:"15px!important", 
    color:"rgba(0, 0, 0, 0.5)!important",
    borderBottom:"1px solid rgba(0, 0, 0, 0.1)",
  },
  buttonZonas: {
    width: 200,
    height: 50,
    background: "#D15252 !important",
    borderRadius: "5px !important",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    color: "#fff !important",
    textTransform:"capitalize",
    "&:hover": {
      backgroundColor: "#ffff !important",
      color: "#D15252 !important",
    },
  },
  containerTextZonas: {
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "justify",
    marginTop: 60,
    padding: "0px 60px",
    [theme.breakpoints.down("md")]: {
      marginTop: 30,
    },
  },
  textTitleZoEnv: {
    textAlign: "center",
    paddingTop: 50,
    fontFamily: POPPINS_FONTS.POPPINS_BOLD,
    fontSize: 40,
    [theme.breakpoints.down("md")]: {
      fontSize: 25,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
  containerImageText: {
    display:"flex",
    alignItems:"center",
    justifyContent:"center"

  },
  textTipoEnvio:{
    textAlign:"start",
    padding:"0px 100px",
    fontFamily:MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize:25,
    [theme.breakpoints.down("md")]:{
        padding:"0px 20px",
        fontSize:12,
    },
  },

  textTitleRutas: {
    textAlign: "center",
    fontFamily: POPPINS_FONTS.POPPINS_BOLD,
    fontSize: 40,
    marginBottom:60,
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
  },
  containerImageZonas: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection:"column"
  },
  imgZonas:{
    
    [theme.breakpoints.up("lg")]: {
      width:500,
      height:500
    },
    [theme.breakpoints.down("md")]: {
      width:400,
      height:400
    },
    [theme.breakpoints.down("sm")]: {
      width:280,
      height:280
    },
  },
  containerTextImageZones:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column",
  },
  textZones:{
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize:20,
    textAlign:"center",
    marginBottom:10,
    [theme.breakpoints.down("sm")]: {
      fontSize:12,
    },
  },
  RutasImageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 110px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 30px",
    },
  },
  LiberTodos: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      margin: "30px 0",
    },
  },
  imgTienda: {
    width: "50%",
    height: "50%",
    marginTop:"-50px",
    [theme.breakpoints.down("md")]: {
      width: "80%",
      height: "80%",
      marginBottom:10
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "100%",
      marginBottom:10
    },
  },
  
}));
export default LandingPageStyle;
