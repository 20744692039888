
export const MONTSERRAT_FONTS = {
    MONTSERRAT_BOLD: "MontserratBold",
    MONTSERRAT_EXTRABOLD: "MontserratExtraBold",
    MONTSERRAT_LIGHT: "MontserrratLight",
    MONTSERRAT_MEDIUM: "MontserratMedium",
    MONTSERRAT_REGULAR: "MontserratRegular",
    MONTSERRAT_SEMIBOLD: "MontserratSemibold"
};


export const POPPINS_FONTS = {
    POPPINS_BOLD: "PoppinsBold",
    POPPINS_SEMIBOLD: "PoppinsSemiBold",
    POPPINS_EXTRABOLD: "PoppinsExtraBold",
    POPPINS_REGULAR: "PoppinsRegular"
};