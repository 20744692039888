export const headers = [
  {
    id: "type",
    numeric: false,
    disablePadding: true,
    label: "Tipo",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "Cantidad",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Descripción",
  },
  {
    id: "previous_Balance",
    numeric: false,
    disablePadding: false,
    label: "Saldo Anterior",
  },
  {
    id: "current_Balance",
    numeric: false,
    disablePadding: false,
    label: "Saldo Actual",
  },
  {
    id: "creation_Date",
    numeric: false,
    disablePadding: false,
    label: "fecha de Registro",
  },
];
