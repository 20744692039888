import React, { Fragment, useState } from "react";
import ModalComponent from "../../../components/sharedComponents/ModalComponent";
import { Box, Button, Typography } from "@material-ui/core";
import PaymentSummaryModalStyles from "./PaymentSummaryModalStyles";
import imgMaseta from "../../../assets/img/dashboard/img-maseta.svg";
import TrustedUserModal from "../TrustedUserModal";
import Loader from "../../Loader";
import { notification } from "../../../utils/notification";
import moment from "moment-timezone";
import { addGuide, addExcel } from "../../../api/massDeliveryApi";
import {
  selectUserInformation,
  setCredits,
} from "../../../store/reducers/user/UserInformationSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../../constants/routesConstants";
import InsufficientCreditsModal from "../InsufficientCreditsModal";
import useLogout from "../../../hooks/useLogout";

const PaymentSummaryModal = (props) => {
  const { infoExcel, fileBase64, handleClose, open, setOpen, disableDirty } =
    props;

  const [logout] = useLogout();

  const classes = PaymentSummaryModalStyles();

  const navigate = useNavigate();

  const userInformation = useSelector(selectUserInformation);

  const dispatch = useDispatch();

  const [openInsufficientCredits, setOpenInsufficientCredits] = useState(false);
  const [openTrustedUser, setOpenTrustedUser] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const handleCloseInsufficientCredits = () => {
    setOpenInsufficientCredits(false);
  };

  const handleOpenTrustedUser = () => {
    setOpen(false);
    setOpenTrustedUser(true);
  };

  const handleCloseTrustedUser = () => {
    setOpenTrustedUser(false);
  };

  const handleNormalUser = () => {
    setOpen(false);
    handleCreateGuides("pagada");
  };

  const handleCreateGuides = (value) => {
    setShowLoader(true);

    const payloadGuide = {
      guides: infoExcel.guides,
      total_cost_guides: infoExcel.amountTotalGuides,
      payment_status: value,
      /* preferences: {
        limited_location: hidePersonalInformation,
        laser_size_required: true,
      }, */
    };
    addGuide(payloadGuide)
      .then((res) => {
        if (res.data.status_Code === 201) {
          const payloadExcel = {
            excel_base64: fileBase64,
            time: moment()
              .tz("America/Mexico_City")
              .format("yyyy-MM-DD H:mm:ss"),
            id_user: userInformation.id_user,
          };
          addExcel(payloadExcel)
            .then((resExcel) => {
              notification("Las guías se crearon correctamente", "success");
              setShowLoader(false);
              disableDirty();
              dispatch(setCredits(res.data.new_total_credits));
              navigate(ROUTES_CONSTANTS.ORDENES, { replace: true });
            })
            .catch((e) => {
              notification("Las guías se crearon correctamente", "success");
              setShowLoader(false);
              disableDirty();
              setTimeout(() => {
                navigate(ROUTES_CONSTANTS.ORDENES, { replace: true });
              }, "1000");
            });
        } else if (res.data.status_Code === 401) {
          setShowLoader(false);
          notification("La sesión ha caducado");
          logout();
        } else if (
          res.data.status_Code === 200 &&
          res.data.status_Message === "insufficient credits"
        ) {
          setShowLoader(false);
          setOpenInsufficientCredits(true);
        } else {
          setShowLoader(false);
          notification("Ocurrió un error al generar las guías");
        }
      })
      .catch((e) => {
        setShowLoader(false);
        notification("Ocurrió un error al generar las guías");
      });
  };

  const body = (
    <Box className={classes.content}>
      <Box className={classes.containerHeader}>
        <Typography className={classes.textHeader}>
          Resumen de las Guías
        </Typography>
        <img src={imgMaseta} className={classes.image} alt="imagenMaseta" />
      </Box>
      <Box className={classes.containerBody}>
        <Typography className={classes.textTitle}>Total de Guías</Typography>
        <Typography className={classes.textNumber}>
          {infoExcel.totalGuides} guías
        </Typography>
        <Typography className={classes.textTitle}>Créditos</Typography>
        <Typography className={classes.textNumber}>
          $ {userInformation.credits.toLocaleString("en")}.00 MXN
        </Typography>
        <Typography
          className={`${classes.textTitle} ${classes.marginTextTotal}`}
        >
          Total estimado
        </Typography>
        <Typography className={classes.textTotalAmount}>
          $ {infoExcel.amountTotalGuides.toLocaleString("en")}.00 MXN
        </Typography>
      </Box>
      <Box className={classes.containerFooter}>
        {userInformation.trust_business ? (
          <Button
            variant="outlined"
            className={classes.buttonPay}
            onClick={handleOpenTrustedUser}
          >
            <Typography className={classes.textButtonPay}>Continuar</Typography>
          </Button>
        ) : (
          <Button
            variant="outlined"
            className={classes.buttonPay}
            onClick={handleNormalUser}
          >
            <Typography className={classes.textButtonPay}>Continuar</Typography>
          </Button>
        )}
      </Box>
    </Box>
  );

  return (
    <Fragment>
      <Loader showLoader={showLoader} text="Generando guías..." />

      <ModalComponent open={open} handleClose={handleClose} body={body} />
      <TrustedUserModal
        open={openTrustedUser}
        handleClose={handleCloseTrustedUser}
        handleCreateGuides={handleCreateGuides}
      />
      <InsufficientCreditsModal
        open={openInsufficientCredits}
        handleClose={handleCloseInsufficientCredits}
      />
    </Fragment>
  );
};

export default PaymentSummaryModal;
