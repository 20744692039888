import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  guide_info: {
    id_location: null,
    guide_cost: null,
    shipping_type: "",
    payment_status: "",
    collection_cost: null,
    package_content: "",
    package_height: null,
    package_length: null,
    package_width: null,
    package_weight: null,
    volumetric_weight: null,
    limited_location: true,
    estimated_delivery_date: "2023-01-01",
  },
  recipient_info: {
    id_postal_code: null,
    full_name: "",
    telephone_number: "",
    state: "",
    city: "",
    colony: "",
    street_name: "",
    ext_number: "",
    int_number: "",
    location_references: "",
  },
};

export const OrdersSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    setFee(state, { payload }) {
      state.fee = payload;
    },
  },
});

export const { setFee } = OrdersSlice.actions;

export default OrdersSlice.reducer;

//selectors
export const selectFee = (state) => (state.orders ? state.orders.fee : "");
