import React, { useState, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import PaymentSuccessStyle from "./PaymentSuccessStyles";
import SuccessAnimation from "../../../components/sharedComponents/SuccessAnimation";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../../constants/routesConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  resetPaymentDetails,
  selectPaymentDetailsInfo,
} from "../../../store/reducers/paymentDetail/paymentDetailsSlice";
import { AddCreditsToAccount } from "../../../api/checkoutApi";
import { setCredits } from "../../../store/reducers/user/UserInformationSlice";
import { notification } from "../../../utils/notification";

const PaymentSuccess = () => {
  const classes = PaymentSuccessStyle();
  const [seconds, setSeconds] = useState(3);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const paymentInformation = useSelector(selectPaymentDetailsInfo);
  //QueryParams
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();

  const paymentId = query.get("payment_id");
  const status = query.get("status");
  const payment_process = query.get("payment_process");
  const payment_verification = query.get("payment_verification");

  useEffect(() => {
    const paymentInfo = {
      payment_method: payment_process === "fiserv" ? "Tarjeta" : "Mercado Pago",
      credits_amount: paymentInformation.total,
      order_id: paymentId,
      total: paymentInformation.total,
      subtotal: paymentInformation.subtotal,
      commission: paymentInformation.commission,
      payment_status: status,
      payment_verification: payment_verification
        ? payment_verification
        : status === "approved",
    };

    AddCreditsToAccount(paymentInfo)
      .then((res) => {
        if (res.data.status_Code === 201) {
          dispatch(setCredits(res.data.new_total_credits));
          notification("Se actualizo su saldo correctamente", "success");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (!status) navigate(ROUTES_CONSTANTS.CREACION_DE_GUIAS, { replace: true });

  useEffect(() => {
    const countdown = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 1) {
          clearInterval(countdown);
          navigate(ROUTES_CONSTANTS.CREACION_DE_GUIAS);
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => {
      clearInterval(countdown);
      dispatch(resetPaymentDetails());
    };
  }, [navigate]);

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.messageContainer}>
          <Typography className={classes.paymentSuccessHeader}>
            Tú pago ha sido realizado con éxito
          </Typography>
          <SuccessAnimation />
          <Typography variant="caption" className={classes.redirectMessage}>
            Serás redireccionado en {seconds} segundo{seconds !== 1 && "s"}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default PaymentSuccess;
