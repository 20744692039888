export const getGreeting = () => {
  const horaActual = new Date();
  const hora = horaActual.getHours();
  if (hora >= 6 && hora < 12) {
    return "¡Buenos días!";
  } else if (hora >= 12 && hora < 18) {
    return "¡Buenas tardes!";
  } else {
    return "¡Buenas noches!";
  }
};

export function formatDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const hours = String(today.getHours()).padStart(2, "0");
  const minutes = String(today.getMinutes()).padStart(2, "0");
  const seconds = String(today.getSeconds()).padStart(2, "0");

  return `${year}:${month}:${day}-${hours}:${minutes}:${seconds}`;
}
