import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../constants/fontConsts";

const NavbarStyle = makeStyles((theme) => ({
  navbarItems: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#545454",
    padding: "0px 30px",
    width: "95%",
    height: 80,
    borderRadius: 13,
    fontSize: 20,
    transform: "translate(-50%)",
    position: "absolute",
    fontWeight: 700,
    top: 20,
    left: "50%",
    zIndex: "100",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    [theme.breakpoints.down(1250)]: {
      width: "100%",
    },
  },
  navbarLogo: {
    width: 200,
    [theme.breakpoints.down(1110)]: {
      width: 150,
    },
  },
  navMenu: {
    display: "grid",
    gridTemplateColumns: "repeat(7,auto)",
    gridGap: 10,
    listStyle: "none",
    alignItems: "center",
  },
  navLinks: {
    textDecoration: "none",
    color: "#545454",
    fontSize: "1.2rem",
    fontWeight: 600,
    padding: "0.7rem 1rem",
    whiteSpace: "nowrap",
    borderRadius: 10,
    "&:hover": {
      backgroundColor: "#5093D0 !important",
      color: "#fff !important",
      borderRadius: 10,
      transition: "all 0.2s ease-in-out",
    },
    [theme.breakpoints.down(1110)]: {
      fontSize: "16px",
    },
  },
  navLinkInicio: {
    marginLeft: 100,
    [theme.breakpoints.down(1250)]: {
      marginLeft: 40,
    },
    [theme.breakpoints.down(1110)]: {
      marginLeft: 20,
    },
  },
  button: {
    padding: "0.7rem 2rem",
    whiteSpace: "nowrap",
    borderRadius: 10,
    fontSize: 20,
    fontWeight: 600,
    border: "none",
    cursor: "pointer",
    background: "#5093D0",
    color: " #ffff",
    transition: "0.2s ease-in-out",
    position: "initial",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#4f769b !important",
      color: "#fff",
    },
    [theme.breakpoints.down(1110)]: {
      fontSize: "16px",
    },
  },
  hamburguerButton: {
    padding: "0.5rem 1rem",
    whiteSpace: "nowrap",
    borderRadius: 10,
    fontSize: 20,
    fontWeight: 600,
    border: "none",
    cursor: "pointer",
    background: "#5093D0",
    color: " #ffff",
    transition: "0.2s ease-in-out",
    position: "initial",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#4f769b !important",
      color: "#fff",
    },
    [theme.breakpoints.down(1110)]: {
      fontSize: "16px",
    },
  },
  styleDrawer: {
    zIndex: "100000 !important",
    background:
      "linear-gradient(121.43deg, #c60007d9 34.1%, #FFFFFF 159.86%, #FFFFFF 47.2%, #D6DDF8 102.17%)",
  },
  elementsDrawer: {
    color: "white",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 16.5,
  },
}));
export default NavbarStyle;
