export const headers = [
  {
    id: "tracking_code",
    numeric: false,
    disablePadding: true,
    label: "No. de Guía",
  },
  {
    id: "recipient",
    numeric: false,
    disablePadding: false,
    label: "Destinatario",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: false,
    label: "Dirección Destino",
  },
  {
    id: "shiping_Type",
    numeric: false,
    disablePadding: false,
    label: "Tipo de envió",
  },
  {
    id: "delibery_Date",
    numeric: false,
    disablePadding: false,
    label: "Fecha de entrega",
  },
  {
    id: "created_Date",
    numeric: false,
    disablePadding: false,
    label: "Fecha de creación",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Estatus",
  },
  {
    id: "guide",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];
