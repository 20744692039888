import { Box, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import ChangeSection from "../../components/RegisterLogin/ChangeSection";
import FormRegister from "../../components/RegisterLogin/FormRegister";
import motocicleta from '../../assets/img/imgMoto.svg'
import { ROUTES_CONSTANTS } from "../../constants/routesConstants";
const RegisterPage = () => {
  const theme = useTheme();
  const showLeftSide = useMediaQuery(theme.breakpoints.up(960));
  return (
    <Box>
      <Grid container>
        {showLeftSide && (
          <Grid item md={7} lg={6}>
            <ChangeSection
              titleButton="Iniciar Sesion"
              img={motocicleta}
              slogan="Si ya tienes una cuenta, ¡Inicia sesión!"
              redirectTo={ROUTES_CONSTANTS.INICIAR_SESION}
            />
          </Grid>
        )}
        <Grid item md={12} lg={6}>
          <FormRegister />
        </Grid>
      </Grid>
    </Box>
  );
};

export default RegisterPage;
