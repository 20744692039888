export const headers = [
  {
    id: "type",
    numeric: false,
    disablePadding: true,
    label: "Tipo",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "Cantidad",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Descripción",
  },
  {
    id: "previous_Credits",
    numeric: false,
    disablePadding: false,
    label: "Créditos Anteriores",
  },
  {
    id: "current_Credits",
    numeric: false,
    disablePadding: false,
    label: "Créditos Actuales",
  },
  {
    id: "creation_Date",
    numeric: false,
    disablePadding: false,
    label: "fecha de Registro",
  },
];
