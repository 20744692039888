import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const ImageAndLinkStyle = makeStyles((theme) => ({
  container: {
    border: "1px solid rgba(0, 0, 0, 0.25)",
    borderRadius: "15px",
    padding: "30px 0px",
    height: 351,
    whidth: 1072,
    [theme.breakpoints.down(1250)]: {
      height: 300,
    },
    [theme.breakpoints.down(1030)]: {
      height: 250,
    },
    [theme.breakpoints.down(768)]: {
      padding: 15,
      maxHeight: 270,
      height: "100%",
    },
    "@media (max-height: 900px)": {
      height: 270,
    },
    "@media (max-width: 767.5px) and (max-height: 900px)": {
      padding: 15,
      maxHeight: 270,
      height: "100%",
    },
    "@media (max-height: 730px)": {
      height: 220,
    },
    "@media (max-height: 630px)": {
      height: 180,
    },
    "@media (max-width: 767.5px) and (max-height: 630px)": {
      height: 230,
    },
  },
  marginContainer: {
    marginTop: 25,
  },
  containerFile: {
    paddingLeft: 21,
    [theme.breakpoints.down(768)]: {
      paddingLeft: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  },
  containerImage: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 60,
    [theme.breakpoints.down(1030)]: {
      marginTop: 30,
    },
    [theme.breakpoints.down(768)]: {
      marginTop: 0,
    },
    "@media (max-height: 900px)": {
      marginTop: 45,
    },
    "@media (max-width: 767.5px) and (max-height: 900px)": {
      marginTop: 0,
    },
    "@media (max-height: 730px)": {
      marginTop: 0,
    },
  },
  image: {
    width: 138,
    height: 138,
    [theme.breakpoints.down(1250)]: {
      width: 100,
      height: 100,
    },
    [theme.breakpoints.down(1030)]: {
      width: 80,
      height: 80,
    },
    [theme.breakpoints.down(768)]: {
      width: 50,
      height: 50,
      marginBottom: 15,
    },
    "@media (max-height: 900px)": {
      width: 100,
      height: 100,
    },
    "@media (max-width: 1029px) and (max-height: 900px)": {
      width: 80,
      height: 80,
    },
    "@media (max-width: 767.5px) and (max-height: 900px)": {
      width: 50,
      height: 50,
      marginBottom: 15,
    },
    "@media (max-width: 767.5px) and (max-height: 730px)": {
      width: 40,
      height: 40,
    },
    "@media (max-height: 630px)": {
      width: 60,
      height: 60,
    },
    "@media (max-width: 767.5px) and (max-height: 630px)": {
      width: 30,
      height: 30,
    },
  },
  containerText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  containerButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15,
  },
  title: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 15,
    marginBottom: 20,
    [theme.breakpoints.down(1030)]: {
      fontSize: 13,
    },
    [theme.breakpoints.down(768)]: {
      fontSize: 12,
      marginBottom: 15,
      textAlign: "center",
    },
    "@media (max-height: 630px)": {
      fontSize: 14,
    },
    "@media (max-width: 1029px) and (max-height: 630px)": {
      fontSize: 13,
    },
    "@media (max-width: 767.5px) and (max-height: 630px)": {
      fontSize: 12,
    },
  },
  subTitle: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 15,
    marginBottom: 20,
    [theme.breakpoints.down(1030)]: {
      fontSize: 13,
    },
    [theme.breakpoints.down(768)]: {
      fontSize: 12,
      marginBottom: 5,
      textAlign: "center",
    },
    "@media (max-height: 630px)": {
      fontSize: 14,
      marginBottom: 10,
    },
    "@media (max-width: 1029px) and (max-height: 630px)": {
      fontSize: 13,
    },
    "@media (max-width: 767.5px) and (max-height: 630px)": {
      fontSize: 12,
    },
  },
  text: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 15,
    [theme.breakpoints.down(1030)]: {
      fontSize: 13,
    },
    [theme.breakpoints.down(768)]: {
      fontSize: 12,
      textAlign: "center",
    },
    "@media (max-height: 630px)": {
      fontSize: 14,
    },
    "@media (max-width: 1029px) and (max-height: 630px)": {
      fontSize: 13,
    },
    "@media (max-width: 767.5px) and (max-height: 630px)": {
      fontSize: 12,
    },
  },
  textButton: {
    color: "#fff",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    textTransform: "capitalize",
    fontSize: 15,
    [theme.breakpoints.down(1030)]: {
      fontSize: 12,
    },
    "@media (max-width: 1029px) and (max-height: 630px)": {
      fontSize: 12,
    },
  },
  button: {
    background: "#D15252",
    height: 50,
    width: 205,
    borderRadius: 10,
    "&:hover": {
      opacity: 0.7,
      background: "#D15252 !important",
    },
    "&.Mui-disabled": {
      background: "#adabab !important",
      color: "white",
    },
    [theme.breakpoints.down(1250)]: {
      width: 175,
      height: 45,
    },
    [theme.breakpoints.down(1030)]: {
      width: 140,
      height: 35,
    },
    "@media (max-width: 767.5px) and (max-height: 730px)": {
      height: 25,
    },
    "@media (max-height: 630px)": {
      width: 180,
      height: 40,
    },
    "@media (max-width: 1029px) and (max-height: 630px)": {
      width: 140,
      height: 35,
    },
    "@media (max-width: 767.5px) and (max-height: 630px)": {
      height: 28,
    },
  },
}));
export default ImageAndLinkStyle;
