import React, { Fragment, useState, useEffect } from "react";
import {
  TableRow,
  TableCell,
  Checkbox,
  Paper,
  TableContainer,
  Table,
  TableBody,
  Typography,
  Box,
} from "@material-ui/core";
import MotoIcon from "../../../assets/img/motoOrdenes.svg";
import PrintIcon from "@material-ui/icons/Print";
import { getOrders } from "../../../api/ordersApi";
import { notification } from "../../../utils/notification";
import TableSkeleton from "../../sharedComponents/TableSkeleton";
import CustomPagination from "../../sharedComponents/CustomPagination";
import TableHeader from "./TableHeader";
import TableToolbar from "./TableToolbar";
import DataFilterOrderStyle from "./DataFilterOrderStyle";
import clsx from "clsx";
import PrintTermica from "../../../assets/img/orders/print-termica.svg";
import NotRows from "../../../assets/img/orders/no-results.png";
import useLogout from "../../../hooks/useLogout";

const DataFilterOrder = () => {
  const classes = DataFilterOrderStyle();
  const [logout] = useLogout();

  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, ] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [updateData, setUpdateData] = useState("");

  const [rowsData, setRowsData] = useState([]);

  useEffect(() => {
    setLoading(true);

    getOrders()
      .then((res) => {
        if (res.data.status_Code === 200) {
          setRowsData(res.data.orders);
        } else if (res.data.status_Code === 401) {
          notification("La sesión ha caducado", "error");
          logout();
        } else {
          notification("Ocurrió un error al recuperas las ordenes", "error");
        }
      })
      .catch((err) => {
        notification("Ocurrió un error al recuperas las ordenes", "error");
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rowsData.map((n) => n.id_guide);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, id_guide) => {
    const selectedIndex = selected.indexOf(id_guide);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id_guide);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id_guide) => selected.indexOf(id_guide) !== -1;

  return (
    <Fragment>
      <div className={classes.content}>
        <Paper className={classes.container}>
          <TableToolbar
            numSelected={selected.length}
            selectedRows={selected}
            setSelectedRows={setSelected}
            rowsData={rowsData}
            setUpdateData={setUpdateData}
          />
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              aria-label="enhanced table"
            >
              <TableHeader
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={rowsData.length}
              />
              <TableBody>
                {!loading ? (
                  rowsData.length > 0 ? (
                    rowsData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id_guide);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id_guide}
                            selected={isItemSelected}
                            style={{ background: "none" }}
                          >
                            <TableCell
                              padding="checkbox"
                              className={
                                index % 2 ? classes.bgWhite : classes.bgGray
                              }
                            >
                              <Checkbox
                                onClick={(event) =>
                                  handleClick(event, row.id_guide)
                                }
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                                style={{ color: "black" }}
                              />
                            </TableCell>
                            <TableCell
                              align="left"
                              className={clsx(
                                classes.text,
                                index % 2 ? classes.bgWhite : classes.bgGray
                              )}
                            >
                              {row.tracking_code}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={
                                index % 2 ? classes.bgWhite : classes.bgGray
                              }
                            >
                              <img
                                src={MotoIcon}
                                alt="moto"
                                className={classes.image}
                              />
                            </TableCell>
                            <TableCell
                              align="left"
                              className={clsx(
                                classes.text,
                                index % 2 ? classes.bgWhite : classes.bgGray
                              )}
                            >
                              {row.info_recipient.fullname}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={clsx(
                                classes.text,
                                index % 2 ? classes.bgWhite : classes.bgGray
                              )}
                            >
                              <div className={classes.containerAddress}>
                                {`${row.info_recipient.street_name} #${
                                  row.info_recipient.ext_number
                                }${
                                  row.info_recipient.int_number === ""
                                    ? ""
                                    : " Interior. " +
                                      row.info_recipient.int_number
                                }, ${row.info_recipient.colony}, ${
                                  row.info_recipient.postal_code
                                }, ${row.info_recipient.city}, ${
                                  row.info_recipient.state
                                }`}
                              </div>
                            </TableCell>
                            <TableCell
                              align="left"
                              className={clsx(
                                classes.text,
                                index % 2 ? classes.bgWhite : classes.bgGray
                              )}
                            >
                              {`Alias: ${row.alias} - ${
                                row.info_origin.street_name
                              } #${row.info_origin.ext_number}${
                                row.info_origin.int_number === ""
                                  ? ""
                                  : " Interior. " +
                                    row.info_origin.int_number
                              }, ${row.info_origin.colony}`}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={clsx(
                                classes.text,
                                index % 2 ? classes.bgWhite : classes.bgGray
                              )}
                            >
                              {row.zone}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={clsx(
                                classes.text,
                                index % 2 ? classes.bgWhite : classes.bgGray
                              )}
                            >
                              {row.package_weight > row.volumetric_weight
                                ? row.package_weight
                                : row.volumetric_weight}{" "}
                              Kg
                            </TableCell>
                            <TableCell
                              align="left"
                              className={clsx(
                                classes.text,
                                index % 2 ? classes.bgWhite : classes.bgGray
                              )}
                            >
                              {row.guide_creation_date}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={
                                index % 2 ? classes.bgWhite : classes.bgGray
                              }
                            >
                              <div
                                className={clsx(
                                  row.urls_pdf_s3.length > 1
                                    ? classes.containerButtons
                                    : classes.containerButton
                                )}
                              >
                                {row.urls_pdf_s3.length > 1 ? (
                                  <Fragment>
                                    <a
                                      href={row.urls_pdf_s3[0]}
                                      target="_blank"
                                      rel="noreferrer"
                                      className={classes.containerIcon}
                                      title="PDF tamaño normal"
                                    >
                                      <PrintIcon className={classes.icon} />
                                    </a>
                                    <a
                                      href={row.urls_pdf_s3[1]}
                                      target="_blank"
                                      rel="noreferrer"
                                      className={classes.containerIconTermica}
                                      title="PDF para impresora térmica"
                                    >
                                      <img
                                        src={PrintTermica}
                                        className={classes.iconTermica}
                                        alt="termica"
                                      />
                                    </a>
                                  </Fragment>
                                ) : (
                                  <a
                                    href={row.urls_pdf_s3[0]}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={classes.containerIcon}
                                    title="PDF tamaño normal"
                                  >
                                    <PrintIcon className={classes.icon} />
                                  </a>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9}>
                        <Box className={classes.containerNoRecords}>
                          <img
                            src={NotRows}
                            alt="Sin registros"
                            className={classes.imageNoRecords}
                          />
                          <Typography className={classes.textNoRecords}>
                            No se encontraron guías
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableSkeleton rowsNum={5} columnsNum={9} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            count={rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Paper>
      </div>
    </Fragment>
  );
};

export default DataFilterOrder;
