import { Box } from "@material-ui/core";
import React, { useState } from "react";
// import AddresseForm from "../../components/createGuidesForm/AddresseForm/Index";
// import SenderForm from "../../components/createGuidesForm/SenderForm";
import DrawerDash from "../../components/DrawerDash";
import CustomStepper from "../../components/sharedComponents/CustomStepper";
// import { MONTSERRAT_FONTS } from "../../constants/fontConsts";
import CreationGuidesFormStyle from "./CreationGuidesFormStyle.js";
//Icons
import { ReactComponent as destinationIcon } from "../../assets/icons/guideFormStepIcons/destinationIcon.svg";
import { ReactComponent as originIcon } from "../../assets/icons/guideFormStepIcons/directionIcon.svg";
import { ReactComponent as packageIcon } from "../../assets/icons/guideFormStepIcons/packageIcon.svg";
import { ReactComponent as summaryIcon } from "../../assets/icons/guideFormStepIcons/summaryIcon.svg";
import { ReactComponent as paymentIcon } from "../../assets/icons/guideFormStepIcons/paymentIcon.svg";
import { ReactComponent as feeIcon } from "../../assets/icons/guideFormStepIcons/feeIcon.svg";
//Selected Icons
import { ReactComponent as destinationSelectedIcon } from "../../assets/icons/guideFormStepIcons/destinationSelectedIcon.svg";
import { ReactComponent as originSelectedIcon } from "../../assets/icons/guideFormStepIcons/directionSelectedIcon.svg";
import { ReactComponent as packageSelectedIcon } from "../../assets/icons/guideFormStepIcons/packageSelectedIcon.svg";
import { ReactComponent as summarySelectedIcon } from "../../assets/icons/guideFormStepIcons/summarySelectedIcon.svg";
import { ReactComponent as paymentSelectedIcon } from "../../assets/icons/guideFormStepIcons/paymentSelectedIcon.svg";
import { ReactComponent as feeIconSelected } from "../../assets/icons/guideFormStepIcons/feeIconSelected.svg";
import OriginInformation from "./OriginInformation";
import DestinationInformation from "./DestinationInformation";
import PackageFormInformation from "./PackageFormInformation";
import GuideSummaryInformation from "./GuideSummaryInformation";
import FeeChoose from "./FeeChoose";
import PymentOfGuides from "./PaymentOfGuides";
import useUnsavedChanges from "../../hooks/useUnsavedChanges";

const CreationGuidesForm = () => {
  const classes = CreationGuidesFormStyle();

  const { propmt, enableDirty, disableDirty } = useUnsavedChanges();

  const stepperSteps = [
    {
      id: 1,
      label: "Dirección de origen",
      icon: originIcon,
      selectedIcon: originSelectedIcon,
    },
    {
      id: 2,
      label: "Información destinatario",
      icon: destinationIcon,
      selectedIcon: destinationSelectedIcon,
    },
    {
      id: 3,
      label: "Información paquete",
      icon: packageIcon,
      selectedIcon: packageSelectedIcon,
    },
    {
      id: 4,
      label: "Resúmen",
      icon: summaryIcon,
      selectedIcon: summarySelectedIcon,
    },
    {
      id: 5,
      label: "Tarifa",
      icon: feeIcon,
      selectedIcon: feeIconSelected,
    },
    {
      id: 6,
      label: "Pago",
      icon: paymentIcon,
      selectedIcon: paymentSelectedIcon,
    },
  ];
  // OriginInformation
  const [selectedLocation, setSelectedLocation] = useState({
    alias: "",
    city: "",
    colony: "",
    ext_number: "",
    id_location: "",
    int_number: "",
    location_references: "",
    postal_code: "",
    principal: "",
    state: "",
    street_name: "",
  });
  const [hidePersonalInformation, setHidePersonalInformation] = useState(false);
  // DestinationInformation
  const [recipient_info, setRecipientInfo] = useState({
    id_recipient: "",
    full_name: "",
    telephone_number: "",
    state: "",
    city: "",
    colony: "",
    street_name: "",
    ext_number: "",
    int_number: "",
    postal_code: "",
    location_references: "",
    latitude: 0,
    longitude: 0,
    save_recipient: false,
    update_recipient: false,
  });

  const [recipientSelected, setRecipientSelected] = useState({
    state: "",
    city: "",
    street_name: "",
    ext_number: "",
    postal_code: "",
    colony: "",
    isSelected: false,
  });

  const [valueRecipient, setValueRecipient] = useState(null);
  const [recipients, setRecipients] = useState([]);
  const [verifyCode, setVerifyCode] = useState(false);
  const [verifyPhone, setVerifyPhone] = useState(false);

  // PackageInformation
  const [packageInformation, setPackageInformation] = useState({
    id_package: "",
    type_product: "",
    long_product: "",
    broad_product: "",
    high_product: "",
    weigth_product: "",
    volumetric_weigth: "",
    description: "",
    save_package: false,
    update_package: false,
  });

  const [verifyLiber, setVerifyLiber] = useState(false);
  const [valuePackage, setValuePackage] = useState(null);
  const [packageData, setPackageData] = useState([]);

  // FeeChoose
  const [feeChoose, setFeeChoose] = useState({
    tipo_envio: "",
    subtotal: 0,
    total: 0,
    idPostalCode: 0,
    company: "",
    extra_weight: 0,
    extra_weight_cost: 0,
    inter_zone_cost: 0,
    guide_cost: 0,
  });

  const [rates, setRates] = useState([]);
  const [selected, setSelected] = useState("");
  const [postalCodeSelected, setPostalCodeSelected] = useState("");
  const [postalCodeOriginSelected, setPostalCodeOriginSelected] = useState("");
  const [packageSelected, setPackageSelected] = useState({
    long_product: "",
    broad_product: "",
    high_product: "",
    weigth_product: "",
  });

  // PymentOfGuides
  const [toCollect, setToCollect] = useState("NONE");
  const [amountReceivable, setAmountReceivable] = useState(0);
  const [amountCorrect, setAmountCorrect] = useState(false);

  const [activeStep, setActiveStep] = useState(1);

  const handleNextStepper = () => {
    switch (activeStep) {
      case 1:
        enableDirty();
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      case 2:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      case 3:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      case 4:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      case 5:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      default:
        break;
    }
  };
  const handleBackStepper = () => {
    if (activeStep > 1) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };
  const handleBackDestination = () => {
    if (activeStep > 1) {
      setActiveStep((prevActiveStep) => prevActiveStep - 2);
    }
  };
  const handleBackOrigin = () => {
    if (activeStep > 1) {
      setActiveStep((prevActiveStep) => prevActiveStep - 3);
    }
  };
  const renderFormComponentByStep = () => {
    switch (activeStep) {
      case 1:
        return (
          <OriginInformation
            enableDirty={enableDirty}
            handleClickNextStep={handleNextStepper}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            hidePersonalInformation={hidePersonalInformation}
            setHidePersonalInformation={setHidePersonalInformation}
          />
        );
      case 2:
        return (
          <DestinationInformation
            handleClickBackStep={handleBackStepper}
            handleClickNextStep={handleNextStepper}
            recipient_info={recipient_info}
            setRecipientInfo={setRecipientInfo}
            recipients={recipients}
            setRecipients={setRecipients}
            valueRecipient={valueRecipient}
            setValueRecipient={setValueRecipient}
            setVerifyCode={setVerifyCode}
            verifyCode={verifyCode}
            verifyPhone={verifyPhone}
            setVerifyPhone={setVerifyPhone}
            recipientSelected={recipientSelected}
            setRecipientSelected={setRecipientSelected}
          />
        );
      case 3:
        return (
          <PackageFormInformation
            handleClickBackStep={handleBackStepper}
            handleClickNextStep={handleNextStepper}
            packageInformation={packageInformation}
            setPackageInformation={setPackageInformation}
            setVerifyLiber={setVerifyLiber}
            setValuePackage={setValuePackage}
            valuePackage={valuePackage}
            setPackageData={setPackageData}
            packageData={packageData}
          />
        );
      case 4:
        return (
          <GuideSummaryInformation
            handleClickBackStep={handleBackStepper}
            handleClickNextStep={handleNextStepper}
            recipient_info={recipient_info}
            selectedLocation={selectedLocation}
            handleBackOrigin={handleBackOrigin}
            handleBackDestination={handleBackDestination}
          />
        );
      case 5:
        return (
          <FeeChoose
            recipient_info={recipient_info}
            selectedLocation={selectedLocation}
            handleClickBackStep={handleBackStepper}
            handleClickNextStep={handleNextStepper}
            setFeeChoose={setFeeChoose}
            setRates={setRates}
            rates={rates}
            setSelected={setSelected}
            selected={selected}
            postalCodeSelected={postalCodeSelected}
            setPostalCodeSelected={setPostalCodeSelected}
            setToCollect={setToCollect}
            setAmountReceivable={setAmountReceivable}
            setAmountCorrect={setAmountCorrect}
            packageInformation={packageInformation}
            setPostalCodeOriginSelected={setPostalCodeOriginSelected}
            postalCodeOriginSelected={postalCodeOriginSelected}
            setPackageSelected={setPackageSelected}
            packageSelected={packageSelected}
            verifyLiber={verifyLiber}
          />
        );
      case 6:
        return (
          <PymentOfGuides
            handleClickBackStep={handleBackStepper}
            handleClickNextStep={handleNextStepper}
            hidePersonalInformation={hidePersonalInformation}
            selectedLocation={selectedLocation}
            recipient_info={recipient_info}
            setRecipientInfo={setRecipientInfo}
            feeChoose={feeChoose}
            packageInformation={packageInformation}
            setPackageInformation={setPackageInformation}
            disableDirty={disableDirty}
            toCollect={toCollect}
            setToCollect={setToCollect}
            amountReceivable={amountReceivable}
            setAmountReceivable={setAmountReceivable}
            amountCorrect={amountCorrect}
            setAmountCorrect={setAmountCorrect}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      {propmt}
      <DrawerDash>
        <Box className={classes.mainContainer}>
          <Box className={classes.stepperFormContainer}>
            {/* Stepper Container */}
            <Box className={classes.stepperContainter}>
              <Box>
                <CustomStepper
                  steps={stepperSteps}
                  handleBack={handleBackStepper}
                  handleNext={handleNextStepper}
                  activeStep={activeStep}
                />
              </Box>
            </Box>
            {/* Stepper Container */}

            {/* Form container */}
            <Box className={classes.formContainer}>
              {renderFormComponentByStep()}
            </Box>
            {/* Form container */}
          </Box>
        </Box>
      </DrawerDash>
    </>
  );
};

export default CreationGuidesForm;
