import React from "react";
import { Checkbox, TableCell, TableHead, TableRow } from "@material-ui/core";
import { headers } from "../headers";
import TableHeaderStyle from "./TableHeaderStyle";

const TableHeader = (props) => {
  const { onSelectAllClick, numSelected, rowCount } = props;

  const classes = TableHeaderStyle();

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
            style={{ color: "black" }}
          />
        </TableCell>
        {headers.map((headCell) => (
          <TableCell key={headCell.id} className={classes.text}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
