import { Box, Typography } from "@material-ui/core";
import React from "react";
import PaymentOptionCardStyle from "./PaymentOptionCardStyle";
const PaymentOptionCard = (props) => {
  const { icon, altDesc, text, isSelected, handleSelect } = props;
  const classes = PaymentOptionCardStyle();
  return (
    <Box
      onClick={handleSelect}
      className={`${classes.paymentOptionContainer} ${
        isSelected ? classes.buttonActive : ""
      }`}
    >
      {text && icon ? (
        <Box className={classes.iconAndTextContainer}>
          <img src={icon} alt={altDesc} className={classes.iconText} />
          <Typography variant="body1" className={classes.textIcon}>
            {" "}
            {text}{" "}
          </Typography>
        </Box>
      ) : (
        <img src={icon} alt={altDesc} />
      )}
    </Box>
  );
};

export default PaymentOptionCard;
