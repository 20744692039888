import React from "react";
import TargetFlipStyle from "./TargetFlipStyle";
import { Typography } from "@material-ui/core";

const TargetFlip = (props) => {
  const classes = TargetFlipStyle();
  const { image, cardTitle, backDescription } = props;
  return (
    <div className={classes.card}>
      <div className={`${classes.face} ${classes.front}`}>
        <img src={image} alt="imagenLiber" className={classes.imagen} />
        <Typography className={classes.textFront}>{cardTitle}</Typography>
      </div>
      <div className={`${classes.face} ${classes.back}`}>
        <Typography className={classes.textBack}>{backDescription}</Typography>
      </div>
    </div>
  );
};

export default TargetFlip;
