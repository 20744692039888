import { Box, Typography } from '@material-ui/core'
import TitleAndDescriptionStyle from './TitleAndDescription.style'
import React from 'react'

const TitleAndDescriptionWithe = () => {

const classes = TitleAndDescriptionStyle();
  return (
    <Box className={classes.containerText}>
        <Typography className={classes.titleText}>Tipos de envio</Typography>
    </Box>
  )
}

export default TitleAndDescriptionWithe