import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../constants/routesConstants";
import { getCookie, removeCookie } from "../../utils/sessionCookie";
import { useDispatch } from "react-redux";
import {
  resetBusinessInformation,
  resetBusinessLocationsInformation,
} from "../../store/reducers/business/BusinessInformationSlice";
import {
  resetUserInformation,
  setUserIsAuth,
} from "../../store/reducers/user/UserInformationSlice";

const PublicRoute = ({ children }) => {
  const dispatch = useDispatch();

  const accessToken = getCookie("accessToken");

  const handleLogout = () => {
    removeCookie("role");
    removeCookie("accessToken");
    dispatch(resetBusinessInformation());
    dispatch(resetBusinessLocationsInformation());
    dispatch(resetUserInformation());
    dispatch(setUserIsAuth(false));
  };

  if (accessToken) {
    return <Navigate to={ROUTES_CONSTANTS.CREACION_DE_GUIAS} />;
  }
  handleLogout();
  return children ? children : <Outlet />;
};

export default PublicRoute;
