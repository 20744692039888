import { Box, Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import Layout from "../../components/Layout/Layout";
import ContainerStarContent from "../../components/landingPage/MainSection/ContainerStartContent";
import CirclesAndInformation from "../../components/landingPage/Mision.Valores/CirclesInformation";
import LandingPageStyle from "./LandingPage.style";
import CardDescription from "../../components/landingPage/TiposEnvio/CardDecription";
import TitleAndDescription from "../../components/landingPage/Mision.Valores/TitleAndDecription";
import TitleAndDescriptionWithe from "../../components/landingPage/TiposEnvio/TitleAndDecriptionWithe";
import OverlayImage from "../../components/landingPage/Rutas/OverlayImage";
import imageMotocicleta from "../../assets/img/imgMotocicleta.svg";
import imageCamion from "../../assets/img/imgCamion.svg";
import imgFondoNaranja from "../../assets/img/imgElipseNaranja.svg";
import imgMisionPaquete from "../../assets/img/imgMisionPaquete.svg";
import imgZonas from "../../assets/img/imgZonas.svg";
import imgTienda from "../../assets/img/imgTienda.svg";
import imageEnviosConfiables from "../../assets/img/imageEnviosConfiables.svg";
import imageRecoleccionGratis from "../../assets/img/imageRecoleccionGratis.svg";
import imageEnviosEconomicos from "../../assets/img/imageEnviosEconomicos.svg";
import imageQueEsLiber from "../../assets/img/imageQueEsLiber.svg";
import imageQueOfreceLiber from "../../assets/img/imageQueOfreceLiber.svg";
import WrapperImageContainer from "../../components/sharedComponents/WrapperImageContainer";
import CenteredContent from "../../components/landingPage/CenteredContent";
import TargetFlip from "../../components/sharedComponents/TargerFlip";
const cardsInfo = [
  {
    id: 1,
    image: imageEnviosConfiables,
    cardTitle: "Envíos Confiables",
    backDescription: "La seguridad de tus paquetes es primordial.",
  },
  {
    id: 2,
    image: imageRecoleccionGratis,
    cardTitle: "Recolección gratis",
    backDescription: "Recolección sin ningún costo a partir de 3 paquetes.",
  },
  {
    id: 3,
    image: imageEnviosEconomicos,
    cardTitle: "Envíos económicos",
    backDescription: "Contamos con envios a partir de $50",
  },
];

const LandingPage = () => {
  const classes = LandingPageStyle();

  return (
    <div>
      <Layout>
        <ContainerStarContent />

        <Box className={classes.gdlEnvios}>
          <Typography align="center" className={classes.titleCenter}>
            Los envíos más baratos en todo Guadalajara
          </Typography>
          <Typography align="center" className={classes.descriptionText}>
            Goza de todos los beneficios que tenemos para ti, cotiza cada uno de
            tus paquetes y realiza tus envíos de manera confiable.
          </Typography>
        </Box>

        <Box className={classes.cardSections}>
          <Grid container justifyContent="center" alignItems="center">
            {cardsInfo.map((card, index) => (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <CenteredContent>
                  <TargetFlip
                    image={card.image}
                    cardTitle={card.cardTitle}
                    backDescription={card.backDescription}
                  />
                </CenteredContent>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box className={classes.wrappersContainer}>
          <WrapperImageContainer
            wrapperTitle="¿Qué es Liber?"
            wrapperDesc="Liber es una empresa de paqueteria, la cuál se encarga de llevar hasta la puerta de la casa de tus clientes, todos sus paquetes de una manera rápida y segura."
            wrapperImage={imageQueEsLiber}
            imagePosition="right"
          />
          <WrapperImageContainer
            wrapperTitle="¿Qué ofrecemos?"
            wrapperDesc="Liber ofrece su servicio de paqueteria, contamos con servicio estandar y express. Recolectamos tus paquetes y los envíamos a tus clientes finales de la manera más eficaz."
            wrapperImage={imageQueOfreceLiber}
          />
        </Box>

        <Box className={classes.MisionValores}>
          <Grid container>
            <Grid item xs={12} md={6} className={classes.containerMision}>
              <TitleAndDescription
                title="Nuestra Misión"
                description=" Nuestra misión es proporcionar soluciones logísticas de última milla confiables y eficientes, para asegurar la entrega oportuna de productos y servicios a nuestros clientes. Nos comprometemos a utilizar tecnología innovadora, procesos optimizados y un equipo altamente capacitado para garantizar una experiencia excepcional tanto para nuestros clientes como para los destinatarios de los envíos. "
              />
              <OverlayImage
                background={imgFondoNaranja}
                image={imgMisionPaquete}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CirclesAndInformation />
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.containerTipoEnvios}>
          <TitleAndDescriptionWithe />
          <Box className={classes.cardsImage}>
            <CardDescription
              image={imageCamion}
              title="Envíos Express"
              text="Enviamos  tu paquete el mismo día haya sido recolectado
              o enviado a nuestra matriz."
              textMin="Los envíos express están basados en la disponibilidad de nuestros repartidores y  son recibidos antes de la 1:00 pm.
              El costo se calcula en $11.00 / km "
              textMinSec="El costo se calcula en $11.00 / km"
            />
            <CardDescription
              image={imageMotocicleta}
              title="Envíos Estándar"
              text="
              Enviamos  tu paquete en el transcurso de los siguientes 
              3 días hábiles después de que este haya sido recolectado o entregado en la matriz."
              textMin="Este tipo de envío puede ser solicitado dentro de los horarios laborales."
              textMinSec="El costo varia según la zona de destino del paquete."
            />
          </Box>
        </Box>
        <Box className={classes.ZonasEnvio}>
          <Typography className={classes.textTitleZoEnv}>
            Zonas de Envio
          </Typography>
          <Box className={classes.containerZonasEnvio}>
            <Box className={classes.containerTextZonas}>
              <table className={classes.tablaZonas}>
                <thead>
                  <td colSpan="2" className={classes.theadZonas1}>
                    Dentro de ZMG
                  </td>
                </thead>
                <tbody className={classes.bodyTabla}>
                  <tr
                    className="borderTr"
                    style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}
                  >
                    <th className={classes.trTable}>Zona</th>
                    <th className={classes.trTable}>Costo</th>
                  </tr>
                  <tr>
                    <td className={classes.trTable}>1- A</td>
                    <td className={classes.trTable}>$50.00 MXN</td>
                  </tr>
                  <tr>
                    <td className={classes.trTable}>1- B</td>
                    <td className={classes.trTable}>$50.00 MXN</td>
                  </tr>
                  <tr>
                    <td className={classes.trTable}>1- C</td>
                    <td className={classes.trTable}>$50.00 MXN</td>
                  </tr>
                  <tr>
                    <td>1- D</td>
                    <td>$50.00 MXN</td>
                  </tr>
                </tbody>

                <thead>
                  <td colSpan="2" className={classes.theadZonas}>
                    Fuera de ZMG
                  </td>
                </thead>
                <tbody className={classes.bodyTabla}>
                  <tr>
                    <th className={classes.trTable}>Zona</th>
                    <th className={classes.trTable}>Costo</th>
                  </tr>
                  <tr>
                    <td className={classes.trTable}>1- A</td>
                    <td className={classes.trTable}>$50.00 MXN</td>
                  </tr>
                  <tr>
                    <td className={classes.trTable}>1- B</td>
                    <td className={classes.trTable}>$50.00 MXN</td>
                  </tr>
                  <tr>
                    <td className={classes.trTable}>1- C</td>
                    <td className={classes.trTable}>$50.00 MXN</td>
                  </tr>
                  <tr>
                    <td>1- D</td>
                    <td>$50.00 MXN</td>
                  </tr>
                </tbody>

                <thead>
                  <td colSpan="2" className={classes.theadZonas}>
                    Zona Extendida
                  </td>
                </thead>
                <tbody className={classes.bodyTabla}>
                  <tr>
                    <td>ZE- 1</td>
                    <td>$120.00 MXN</td>
                  </tr>
                  <tr>
                    <td colSpan="2" className={classes.textoTabla}>
                      Entregas y Recolecciones solo Miércoles de 10:30-5:00pm.
                    </td>
                  </tr>
                  <tr>
                    <td>ZE- 1</td>
                    <td>$120.00 MXN</td>
                  </tr>
                  <tr>
                    <td colSpan="2" className={classes.textoTabla}>
                      Entregas y Recolecciones Solo Viernes de 10:30-5:00pm.
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <Button className={classes.buttonZonas}>
                        Revisar Zonas
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>

            <Box className={classes.containerImageZonas}>
              <img
                src={imgZonas}
                className={classes.imgZonas}
                alt="imagen de zonas de envio"
              />
              <Box className={classes.containerTextImageZones}>
                <Typography className={classes.textZones}>
                  {" "}
                  Nuestro punto de recolección se encuentra ubicado en C. <br />
                  Horacio Gaytán 1290, Paseos del Sol, 45070 Zapopan, Jal.
                </Typography>
                <Typography className={classes.textZones}>
                  Con horario de atención de Lunes a viernes 9:00am a 17:00 pm{" "}
                  <br /> Sábado de 9:30 am a 1:30 pm.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* 
        <Box className={classes.TiposEnvio}>
          <Typography className={classes.textTitleRutas}>Tipos de guías</Typography>
          <SimpleText description="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut ero labore et dolore magna aliqua. Ut enim ad minim veniam, quise."/>
          <Box className={classes.containerImageText}>
            <img src={imagenTiposGuias} alt="imagenTiposEnvio" />
            <ImageBox image={imagenTiposGuias} width={440} height={440} /> 
            <Typography className={classes.textTipoEnvio}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores quisquam saepe modi libero quia nam suscipit quos voluptates quibusdam neque sequi consequuntur repellendus, est accusamus, natus enim labore placeat aliquam.</Typography>
          </Box>
        </Box> */}

        <Box className={classes.LiberTodos}>
          <img
            src={imgTienda}
            className={classes.imgTienda}
            alt="imagen de tienda"
          />
          <TitleAndDescription
            title="Liber es para todos"
            description="Impulsa tu e-commerce. Haz crecer el alcance de tu negocio enviando tus productos a todo el país. Intégranos con tu tienda de forma práctica y sencilla."
          />
        </Box>
      </Layout>
    </div>
  );
};

export default LandingPage;

// eslint-disable-next-line no-lone-blocks
