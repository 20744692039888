import { TableCell, TableRow } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import TableSkeletonStyle from "./TableSkeletonStyle";

const TableSkeleton = ({ rowsNum, columnsNum }) => {
  const classes = TableSkeletonStyle();

  return [...Array(rowsNum)].map((row, index) => (
    <TableRow key={index}>
      {[...Array(columnsNum)].map((column, indexColumn) => (
        <TableCell
          component="th"
          scope="row"
          key={indexColumn}
          className={index % 2 ? classes.bgWhite : classes.bgGray}
        >
          <Skeleton animation="wave" variant="text" />
        </TableCell>
      ))}
    </TableRow>
  ));
};

export default TableSkeleton;
