import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS, POPPINS_FONTS } from "../../../constants/fontConsts";

const wrapperImageStyles = makeStyles((theme) => ({
  mainContainer: {
    marginBottom: 20,
    height: "auto",
    paddingBottom:80,
    [theme.breakpoints.down("md")]: {
      paddingBottom:30,
      paddingLeft:20
    },
  },
  wrapperTitleContainer: {
    marginTop: 50,
    paddingLeft: 50,
    [theme.breakpoints.down("md")]: {
      marginTop: 20,
      paddingLeft: 20,
    },
  },
  wrapperDescriptionContainer: {
    marginTop: 30,
    paddingLeft: 50,
    width: 500,
    [theme.breakpoints.down("md")]: {
      marginTop: 30,
      padding: "0px 20px",
      width: "100%",
    },
  },
  wrapperImage: {
    [theme.breakpoints.down(1111, "lg")]: {
      width: 350,
      height: 350,
    },
  },
  question: {
    fontFamily: POPPINS_FONTS.POPPINS_BOLD,
    fontSize: 40,
    [theme.breakpoints.down("md")]:{
      fontSize: 20,
    },
  },
  description: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 25,
    [theme.breakpoints.only("md")]:{
      fontSize: 20,
    },
    [theme.breakpoints.down("md")]:{
      fontSize: 16,
    },
  },
}));

export default wrapperImageStyles;
