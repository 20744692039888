import axios from "axios";
import { getCookie } from "../utils/sessionCookie";
const { REACT_APP_LIBER_API_URL } = process.env;

export const getShipments = (partial_info) => {
  let payload = {
    partial_info: partial_info,
  };

  const request = {
    method: "POST",
    data: payload,
    url: `${REACT_APP_LIBER_API_URL}/business/get-shipments`,
    headers: {
      "content-type": "application/json",
      auth_Token: getCookie("accessToken"),
    },
  };

  return axios(request);
};
