import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const ModalReviewExcelStyle = makeStyles((theme) => ({
  tableContainer: {
    maxWidth: "90%",
    width: "auto",
    maxHeight: "90%",
    height: "auto",
    overflow: "auto",
  },
  headers: {
    background: "#dedede",
    padding: 10,
  },
  textHeader: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    "@media (max-width: 1300px)": {
      fontSize: 12,
    },
  },
  textRowError: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    borderLeft: "1px solid  #e0e0e0",
    background: "#ff000087",
    color: "white",
    padding: 10,
    "@media (max-width: 1300px)": {
      fontSize: 12,
    },
  },
  textRow: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    borderLeft: "1px solid  #e0e0e0",
    padding: 10,
    "@media (max-width: 1300px)": {
      fontSize: 12,
    },
  },
  button: {
    background: "#D15252",
    color: "#fff",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 15,
    textTransform: "capitalize",
    width: 250,
    height: 50,
    marginTop: 50,
    borderRadius: 10,
    marginLeft: "auto",
    marginRight: "auto",
    "&:hover": {
      opacity: 0.7,
      background: "#D15252 !important",
    },
    "&.Mui-disabled": {
      background: "#adabab !important",
      color: "white",
    },
    /* min-height: 980px*/
    "@media (min-height: 980px) and (max-width: 950px)": {
      marginTop: 20,
      height: 50,
      width: 200,
    },
    "@media (min-height: 980px) and (max-width: 650px)": {
      marginTop: 15,
      height: 40,
      width: 150,
      fontSize: 13,
    },
    "@media (min-height: 980px) and (max-width: 470px)": {
      marginTop: 5,
      height: 30,
      width: 120,
      fontSize: 12,
    },

    /* max-height: 979px*/
    "@media (max-height: 979px) and (min-width: 601px)": {
      marginTop: 20,
      height: 40,
      width: 200,
    },
    "@media (max-height: 979px) and (max-width: 600px)": {
      marginTop: 15,
      height: 40,
      width: 150,
      fontSize: 13,
    },
    "@media (max-height: 979px) and (max-width: 470px)": {
      marginTop: 15,
      height: 30,
      width: 120,
      fontSize: 12,
    },

    /* max-height: 840px*/
    "@media (max-height: 840px) and (max-width: 768px)": {
      marginTop: 15,
      height: 40,
      width: 150,
      fontSize: 13,
    },
    "@media (max-height: 840px) and (max-width: 470px)": {
      marginTop: 15,
      height: 30,
      width: 120,
      fontSize: 12,
    },

    /* max-height: 740px*/
    "@media (max-height: 740px) and (min-width: 769px)": {
      width: 150,
      height: 35,
      marginTop: 10,
      fontSize: 13,
    },
    "@media (max-height: 740px) and (max-width: 768px)": {
      marginTop: 15,
      height: 30,
      width: 120,
      fontSize: 12,
    },
    "@media (max-height: 740px) and (max-width: 470px)": {
      marginTop: 10,
      height: 25,
      width: 105,
      fontSize: 10,
    },

    /* max-height: 640px*/
    "@media (max-height: 640px) and (min-width: 769px)": {
      width: 130,
      height: 30,
      marginTop: 10,
      fontSize: 12,
    },
    "@media (max-height: 640px) and (max-width: 768px)": {
      width: 110,
      height: 26,
      marginTop: 10,
      fontSize: 10.5,
    },
    "@media (max-height: 640px) and (max-width: 470px)": {
      marginTop: 10,
      height: 25,
      width: 98,
      fontSize: 9
    },
  },
}));
export default ModalReviewExcelStyle;
