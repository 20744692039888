import { makeStyles } from "@material-ui/core";

const ModalComponentStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#000000ad",
  },
  buttonClose: {
    top: 0,
    right: 0,
    position: "absolute",
    background: "none",
    border: "none",
    width: 20,
    height: 20,
    fontSize: 20,
    marginTop: 20,
    marginRight: -10,
    color: "white",
    fontWeight: "bold",
    cursor: "pointer",
  },
}));

export default ModalComponentStyles;
