export const headers = [
  "Producto",
  "Cantidad",
  "Nombre",
  "Telefono",
  "Calle",
  "Numero_Exterior",
  "Numero_Interior",
  "Colonia",
  "Ciudad",
  "CP",
  "Estado",
  "Por_Cobrar",
  "Total_Por_Cobrar",
  "Referencias",
  "Alto",
  "Largo",
  "Ancho",
  "Peso",
];