import React, { Fragment } from "react";
import ModalComponent from "../../../sharedComponents/ModalComponent";
import { Box, Typography } from "@material-ui/core";
import DownloadQRModalStyles from "./DownloadQRModalStyles";
import QRCode from "../../../../assets/img/orders/codigoqr.svg";

const DownloadQRModal = (props) => {
  const { open, handleClose, urlQR } = props;

  const classes = DownloadQRModalStyles();

  const body = (
    <Box className={classes.content}>
      <Typography className={classes.title}>
        Código QR generado de manera correcta
      </Typography>
      <img src={QRCode} alt="Código QR" className={classes.img} />
      <a
        href={urlQR}
        target="_blank"
        rel="noreferrer"
        className={classes.button}
        onClick={() => handleClose()}
      >
        <Typography className={classes.textButton}>
          Descargar Código QR
        </Typography>
      </a>
    </Box>
  );

  return (
    <Fragment>
      <ModalComponent open={open} handleClose={handleClose} body={body} />
    </Fragment>
  );
};

export default DownloadQRModal;
