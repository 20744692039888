import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "../pages/About";
import Faqs from "../pages/FAQs";
import LandingPage from "../pages/LandingPage";
import LoginPage from "../pages/LoginPage";
import RegisterPage from "../pages/RegisterPage";
import Tracking from "../pages/Tracking";
import Quotes from "../pages/Quotes";
import Perfil from "../pages/Perfil";
import Shipments from "../pages/Shipments";
import Orders from "../pages/Orders/Index";
import ThirdOrders from "../pages/ThirdOrders/Index";
import CreationGuidesLinks from "../pages/CreationGuidesLinks";
import CreationGuidesForm from "../pages/CreationGuidesForm";
import ShippingType from "../pages/ShippingType";
import DimentionsTypesPackages from "../pages/DimentionsTypesPackages";
import QuotesDashboard from "../pages/QuotesDashboard";
import MassDelivery from "../pages/MassDelivery";
import ShipmentsInformation from "../pages/ShipmentInformation";
import ProtectedRoute from "../components/ProtectedRoute";
import { ROUTES_CONSTANTS } from "../constants/routesConstants";
import PublicRoute from "../components/PublicRoute";
import ShippingZones from "../pages/ShippingZones";
import InMaintenance from "../pages/InMaintenance";
import Lots from "../pages/Lots";
import Balance from "../pages/Balance";
import PaymentSuccess from "../pages/payments/PaymentSuccess";
import PaymentError from "../pages/payments/PaymentError";
import Credits from "../pages/Credits";
const { REACT_APP_IN_MAINTENANCE } = process.env;

const AppRouter = () => {
  return (
    <Router>
      {REACT_APP_IN_MAINTENANCE === "false" ? (
        <Routes>
          <Route element={<PublicRoute />}>
            <Route index element={<LandingPage />} />
            <Route path={ROUTES_CONSTANTS.LANDING} element={<LandingPage />} />
            <Route
              path={ROUTES_CONSTANTS.INICIAR_SESION}
              element={<LoginPage />}
            />
            <Route
              path={ROUTES_CONSTANTS.REGISTRO}
              element={<RegisterPage />}
            />
            <Route path={ROUTES_CONSTANTS.RASTREO} element={<Tracking />} />
            <Route path={ROUTES_CONSTANTS.RASTREOQR} element={<Tracking />} />
            <Route path={ROUTES_CONSTANTS.FAQS} element={<Faqs />} />
            <Route path={ROUTES_CONSTANTS.NOSOTROS} element={<About />} />
            <Route path={ROUTES_CONSTANTS.COTIZACION} element={<Quotes />} />
            <Route path={ROUTES_CONSTANTS.ZONAS} element={<ShippingZones />} />
          </Route>

          <Route element={<ProtectedRoute />}>
            <Route
              path={ROUTES_CONSTANTS.CREACION_DE_GUIAS}
              element={<CreationGuidesLinks />}
            />
            <Route path={ROUTES_CONSTANTS.PERFIL} element={<Perfil />} />
            <Route path={ROUTES_CONSTANTS.MIS_ENVIOS} element={<Shipments />} />
            <Route
              path={ROUTES_CONSTANTS.INFORMACION_ENVIOS}
              element={<ShipmentsInformation />}
            />
            <Route path={ROUTES_CONSTANTS.ORDENES} element={<Orders />} />
            <Route
              path={ROUTES_CONSTANTS.THIRD_ORDENES}
              element={<ThirdOrders />}
            />
            <Route
              path={ROUTES_CONSTANTS.TIPO_ENVIO}
              element={<ShippingType />}
            />
            <Route
              path={ROUTES_CONSTANTS.FORM_CREAR_GUIA}
              element={<CreationGuidesForm />}
            />
            <Route
              path={ROUTES_CONSTANTS.DIMENSIONES_RESUMEN_GUIAS}
              element={<DimentionsTypesPackages />}
            />
            <Route
              path={ROUTES_CONSTANTS.COTIZACION_DASHBOARD}
              element={<QuotesDashboard />}
            />
            <Route
              path={ROUTES_CONSTANTS.GENERAR_GUIAS_MASIVO}
              element={<MassDelivery />}
            />
            <Route path={ROUTES_CONSTANTS.LOTS} element={<Lots />} />
            <Route path={ROUTES_CONSTANTS.BALANCE} element={<Balance />} />
            <Route path={ROUTES_CONSTANTS.CREDITS} element={<Credits />} />
            <Route path={ROUTES_CONSTANTS.LOTS} element={<Lots />} />
            <Route path={ROUTES_CONSTANTS.BALANCE} element={<Balance />} />
            <Route
              path={ROUTES_CONSTANTS.PAYMENT_SUCCESS}
              element={<PaymentSuccess />}
            />
            <Route
              path={ROUTES_CONSTANTS.PAYMENT_FAILED}
              element={<PaymentError />}
            />
          </Route>
        </Routes>
      ) : (
        <Routes>
          <Route index element={<InMaintenance />} />
        </Routes>
      )}
    </Router>
  );
};
export default AppRouter;
