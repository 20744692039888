import { ROUTES_CONSTANTS } from "../../constants/routesConstants";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import InfoIcon from "@material-ui/icons/Info";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import HomeIcon from "@material-ui/icons/Home";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AssignmentIcon from "@material-ui/icons/Assignment";

const MenuItems = [
  {
    title: "Inicio",
    url: ROUTES_CONSTANTS.LANDING,
    icon: <HomeIcon style={{ color: "white" }} />,
  },
  {
    title: "Cotización",
    url: ROUTES_CONSTANTS.COTIZACION,
    icon: <AssignmentIcon style={{ color: "white" }} />,
  },
  {
    title: "Rastreo",
    url: ROUTES_CONSTANTS.RASTREO,
    icon: <LocalShippingIcon style={{ color: "white" }} />,
  },
  {
    title: "FAQ s",
    url: ROUTES_CONSTANTS.FAQS,
    icon: <LiveHelpIcon style={{ color: "white" }} />,
  },
  {
    title: "Nosotros",
    url: ROUTES_CONSTANTS.NOSOTROS,
    icon: <InfoIcon style={{ color: "white" }} />,
  },
  {
    title: "Iniciar Sesión",
    url: ROUTES_CONSTANTS.INICIAR_SESION,
    icon: <AccountCircleIcon style={{ color: "white" }} />,
  },
  /* {
        title: 'Registro',
        url: ROUTES_CONSTANTS.REGISTRO,
        cName: 'nav-links-mobile',
    } */
];
export default MenuItems;
