import { createTheme } from "@material-ui/core";
import { overrides } from "./overrides";
import { themeBreakpoints } from "./themeBreakpoints";

const Theme = createTheme({
  breakpoints: themeBreakpoints,
  overrides:overrides
});

export default Theme;
