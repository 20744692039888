import { makeStyles } from "@material-ui/core";
import {
  MONTSERRAT_FONTS,
  POPPINS_FONTS,
} from "../../../../constants/fontConsts";
import { MAIN_COLORS } from "../../../../constants/theme/colors";

const TabOriginAddressStyles = makeStyles((theme) => ({
  mainContainer: {
    maxWidth: 700,
    display: "flex",
    margin: "auto",
    alignItems: "center",
    marginTop: 40,
  },
  container: {
    fontFamily: POPPINS_FONTS.POPPINS_REGULAR,
    fontSize: 18,
    [theme.breakpoints.down(700)]: {
      fontSize: 16,
      width: "90%",
    },
  },
  mainFormContainer: {
    marginTop: 44,
    [theme.breakpoints.down(1150)]: {
      marginTop: 20,
    },
  },
  input: {
    borderRadius: 15,
    textAlign: "start",
    background: MAIN_COLORS.WHITE,
  },
  textFieldMultiline: {
    width: 400,
    [theme.breakpoints.down(600)]: {
      width: 300,
    },
    [theme.breakpoints.down(460)]: {
      width: 250,
    },
    [theme.breakpoints.down(410)]: {
      width: 230,
    },
    [theme.breakpoints.down(390)]: {
      width: 200,
    },
  },
  inputTextFieldMultiline: {
    [theme.breakpoints.down(1150)]: {
      fontSize: 13.5,
    },
    [theme.breakpoints.down(670)]: {
      fontSize: 12,
    },
  },
  selectDirection: {
    width: 320,
    marginTop: 44,
    [theme.breakpoints.down(700)]: {
      width: 300,
    },
    [theme.breakpoints.down(460)]: {
      width: 250,
      marginTop: 25,
    },
    [theme.breakpoints.down(410)]: {
      width: 230,
    },
    [theme.breakpoints.down(390)]: {
      width: 200,
    },
  },
  error: {
    marginTop: 20,
    color: "#f50057",
  },
  largeTextField: {
    width: 320,
    [theme.breakpoints.down("lg")]: {
      width: 290,
    },
    [theme.breakpoints.down(1120)]: {
      width: 250,
    },
    [theme.breakpoints.down(1030)]: {
      width: 230,
    },
    [theme.breakpoints.down(390)]: {
      width: 200,
    },
  },
  referenciasField: {
    width: 500,
  },
  mediumTextField: {
    width: 260,
    [theme.breakpoints.down("lg")]: {
      width: 240,
    },
    [theme.breakpoints.down(1120)]: {
      width: 200,
    },
    [theme.breakpoints.down(1030)]: {
      width: 180,
    },
  },
  smallTextField: {
    width: 150,
  },
  numberField: {
    width: 110,
  },
  selectInput: {
    "& .MuiSelect-root": {
      [theme.breakpoints.down(1150)]: {
        padding: 10,
        borderRadius: 10,
      },
      [theme.breakpoints.down(670)]: {
        padding: 8,
        borderRadius: 10,
      },
    },
    [theme.breakpoints.down(1150)]: {
      height: 40,
      padding: "0px !important",
      fontSize: 13.5,
    },
    [theme.breakpoints.down(670)]: {
      height: 35,
      padding: "6px 14px",
      fontSize: 12.5,
    },
  },
  selectItem: {
    [theme.breakpoints.down(1150)]: {
      fontSize: 13.5,
    },
    [theme.breakpoints.down(670)]: {
      fontSize: 12.5,
    },
  },
  inputDescription: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 12,
    marginBottom: 16,
    marginLeft: 5,
    [theme.breakpoints.down(1150)]: {
      marginBottom: 5,
    },
  },
  inputTextFile: {
    [theme.breakpoints.down(1150)]: {
      height: 30,
      padding: "6px 14px",
      fontSize: 13.5,
    },
    [theme.breakpoints.down(670)]: {
      height: 26,
      padding: "6px 14px",
      fontSize: 12.5,
    },
  },
  hidePersonalInformationText: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 12,
    marginLeft: 5,
  },
  root: {
    color: MAIN_COLORS.LIGHT_GRAY,
    borderRadius: "50px !important",
    "& .Mui-checked": {
      color: MAIN_COLORS.BLACK,
    },
  },
  checked: {
    color: MAIN_COLORS.BLACK + "!important",
  },
  tooltipText: {
    fontFamily: POPPINS_FONTS.POPPINS_REGULAR,
    fontSize: 15,
  },
  button: {
    background: "#D15252",
    color: "#fff",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 15,
    textTransform: "capitalize",
    width: 200,
    height: 45,
    marginTop: 40,
    marginBottom: 30,
    borderRadius: 10,
    marginLeft: "auto",
    marginRight: "auto",
    "&:hover": {
      opacity: 0.7,
      background: "#D15252 !important",
    },
    "&.Mui-disabled": {
      background: "#adabab !important",
      color: "white",
    },
    [theme.breakpoints.down(600)]: {
      width: 140,
      height: 38,
      fontSize: 14,
    },
  },
}));
export default TabOriginAddressStyles;
