import { Box, Button, Grid, Typography } from '@material-ui/core'
import React from 'react'
import DimentionsPackages from '../../components/dimentionsAndTypesPackages/DimentionsPackages'
import TypesPackages from '../../components/dimentionsAndTypesPackages/TypePackages'
import DrawerDash from '../../components/DrawerDash'
import { MONTSERRAT_FONTS } from '../../constants/fontConsts'
import DimentionsTypesPackagesStyle from './DimentiosTypesPackagesStyle'

const DimentionsTypesPackages = () => {

const classes = DimentionsTypesPackagesStyle()
  return (
    <div>
    <DrawerDash>
        <Typography style={{fontFamily:MONTSERRAT_FONTS.MONTSERRAT_REGULAR,fontSize:15, color:"rgba(0, 0, 0, 0.8)", margin:"10px 15px"}} >Paso 1 de 3 -- Origen y destino</Typography>
        <Grid container className={classes.container}>
            <Grid md={5}>
                <DimentionsPackages/>
            </Grid>
            <Grid md={7}>
                <TypesPackages/>
            </Grid>
        </Grid>
        <Box style={{display:"flex", justifyContent:"end"}}>
            <Button variant='outlined' style={{background:"#D15252", color:"#fff", textTransform:"capitalize", margin:10, padding:"5px 30px"}}>Generar</Button>
        </Box>
    </DrawerDash>
    </div>
  )
}

export default DimentionsTypesPackages