import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS, POPPINS_FONTS } from "../../constants/fontConsts";

const FaqsStyle = makeStyles((theme) => ({
  containers: {
    background:
      "linear-gradient(10.79deg, #FFFFFF 2.97%, #EFF1FC 59.78%, #FFFFFF 97.12%)",
    height: "100%",
    paddingTop: 1,
  },
  container: {
  
  },
  imagenDudas: {
    width: 300,
    height: 300,
  },
  textHeaderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    // margin: "140px 60px 60px 60px ",
    [theme.breakpoints.down("sm")]: {
      marginTop: 150,
      marginLeft: 70,
      marginRight: 50,
    },
  },
  title: {
    fontFamily: POPPINS_FONTS.POPPINS_BOLD,
    fontSize: 40,
    marginBottom: 20,
    marginTop:120,
    marginLeft:70,
    [theme.breakpoints.down("sm")]: {
      fontSize: 25,
      marginLeft:0,
      marginTop:0,
    },
  },
  description: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 20,
    maxWidth:500,
    marginLeft:70,
    marginBottom:80,
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      marginBottom:50,
      maxWidth:350,
      marginLeft:0,
    },
  },
}));

export default FaqsStyle;
