import React, { useState } from "react";
import { Button, Toolbar, Tooltip, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import TableToolbarStyle from "./TableToolbarStyle";
import PaymentSummaryModal from "../PaymentSummaryModal";
import ErrorsModal from "../ErrorsModal";
import { verifyPostalCode } from "../../../../api/ordersApi";

const TableToolbar = (props) => {
  const {
    numSelected,
    selectedRows,
    rowsData,
    setSelectedRows,
    setUpdateData,
  } = props;
  const classes = TableToolbarStyle();

  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [cost, setCost] = useState(0);
  const [originLocation, setOriginLocation] = useState(0);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  const handleClick = () => {
    let idLocation = 0;
    let pcOrigin = 0;
    let error = false;
    let weight = 0;

    selectedRows.forEach((selected) => {
      rowsData.forEach((row) => {
        if (selected === row.id_guide) {
          if (idLocation === 0) {
            idLocation = row.id_location;
            setOriginLocation(row.id_location);
            pcOrigin = row.origin_postal_code;
            weight =
              row.package_weight > row.volumetric_weight
                ? row.package_weight
                : row.volumetric_weight;
          } else if (idLocation === row.id_location) {
            weight +=
              row.package_weight > row.volumetric_weight
                ? row.package_weight
                : row.volumetric_weight;
          } else {
            error = true;
          }
        }
      });
    });

    if (error) {
      setMessageError(
        "La dirección de origen de las guías seleccionadas no es la misma. Para poder generar la recolección deben tener el mismo domicilio origen."
      );
      setOpenError(true);
    } else if (weight > 25) {
      setMessageError(
        `El peso de las guías seleccionadas es de ${weight}kg, el peso máximo permitido es de 25kg para la recolección.`
      );
      setOpenError(true);
    } else {
      const payload = { postal_code: pcOrigin };

      verifyPostalCode(payload)
        .then((res) => {
          if (
            res.data.status_Code === 200 &&
            res.data.status_Message === "postal code is validated"
          ) {
            setCost(selectedRows.length < 3 ? res.data.cost : 0);
            setOpen(true);
          } else {
            setMessageError(
              "Por el momento no contamos con servicio de recolección en su ubicación, por lo que tendrá que dirigirse a una sucursal cercana para hacer entrega de sus guías."
            );
            setOpenError(true);
          }
        })
        .catch((err) => {
          setMessageError(
            "Por el momento no contamos con servicio de recolección en su ubicación, por lo que tendrá que dirigirse a una sucursal cercana para hacer entrega de sus guías."
          );
          setOpenError(true);
        });
    }
  };

  return (
    <>
      {numSelected > 0 && (
        <Toolbar className={classes.highlight}>
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
          <Tooltip title="Delete">
            <Button
              variant="contained"
              endIcon={<AddCircleOutlineIcon />}
              onClick={() => handleClick()}
              className={classes.button}
            >
              Generar Recolección
            </Button>
          </Tooltip>
        </Toolbar>
      )}
      <PaymentSummaryModal
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        setUpdateData={setUpdateData}
        cost={cost}
        originLocation={originLocation}
      />
      <ErrorsModal
        open={openError}
        handleClose={handleCloseError}
        message={messageError}
      />
    </>
  );
};

export default TableToolbar;
