import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const PackageDataStyle = makeStyles((theme) =>({
    container:{
        display:"flex",
        flexDirection:"column",
        alignItems:"start",
        marginBottom:40
    },
    title:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_BOLD,
        fontSize:20,
        margin: "30px 0px 10px 0px",
        [theme.breakpoints.down("md")]:{
            fontSize:18
        },
     
    },
    informationPackage:{
        display:"flex",
        flexDirection:"column",
        alignItems:"start"
    },
    titleCard:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_BOLD,
        fontSize:14,
        color:"rgba(0, 0, 0, 0.7)",
        margin: "10px 0px 10px 0px",
        [theme.breakpoints.down("md")]:{
            fontSize:18
        },
    },
    text:{
        fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
        fontSize: 12,
    },
    span:{
        fontFamily:MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
        fontSize:12
    }
}))
export default PackageDataStyle;