import React, { Fragment, useState, useEffect } from "react";
import {
  TableRow,
  TableCell,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TextField,
  InputAdornment,
  Box,
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core";
import { getShipments } from "../../../api/shipmentsApi";
import { notification } from "../../../utils/notification";
import TableSkeleton from "../../sharedComponents/TableSkeleton";
import CustomPagination from "../../sharedComponents/CustomPagination";
import TableHeader from "./TableHeader";
import DataFilterShipmentStyle from "./DataFilterShipmentStyle";
import clsx from "clsx";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import useLogout from "../../../hooks/useLogout";
import PrintTermica from "../../../assets/img/orders/print-termica.svg";
import PrintIcon from "@material-ui/icons/Print";
import SearchIcon from "@material-ui/icons/Search";
import { dynamicFilter } from "../../../utils/filter";
import NotFoundRows from "../../sharedComponents/NotFoundRows";

const DataFilterShipment = () => {
  const classes = DataFilterShipmentStyle();
  const [logout] = useLogout();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [rowsDataOriginal, setRowsDataOriginal] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const [showAllGuides, setShowAllGuides] = useState(false);

  useEffect(() => {
    setLoading(true);

    getShipments(!showAllGuides)
      .then((res) => {
        console.log(res.data);
        if (res.data.status_Code === 200) {
          setRowsDataOriginal(res.data.shipments);
          setRowsData(res.data.shipments);
        } else if (res.data.status_Code === 401) {
          notification("La sesión ha caducado", "error");
          logout();
        } else {
          notification("Ocurrió un error al recuperas las guías", "error");
        }
      })
      .catch((err) => {
        notification("Ocurrió un error al recuperas las guías", "error");
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAllGuides]);

  const handleChange = (event) => {
    let text = event.target.value;

    let filterRows = dynamicFilter(text, rowsDataOriginal);

    setPage(0);
    setRowsData(filterRows);
  };

  const handleShowGuides = (e) => {
    setShowAllGuides(e.target.checked);
  };

  return (
    <Fragment>
      <TextField
        fullWidth
        variant="outlined"
        id="input-with-icon-textfield"
        placeholder="Buscar"
        className={classes.filter}
        onChange={handleChange}
        InputProps={{
          className: classes.filterInput,
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon className={classes.iconFilter} />
            </InputAdornment>
          ),
        }}
      />
      <FormControlLabel
        control={
          <Switch
            checked={showAllGuides}
            onChange={handleShowGuides}
            name="actualizar"
          />
        }
        style={{
          justifyContent: "left",
          marginBottom: 15,
          marginLeft: 5,
        }}
        labelPlacement="start"
        label={
          <Typography className={classes.switch}>
            Mostrar guías de más de 6 meses
          </Typography>
        }
      />
      <div className={classes.content}>
        <Paper className={classes.container}>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={"small"}
              aria-label="enhanced table"
            >
              <TableHeader />
              <TableBody>
                {!loading ? (
                  rowsData.length > 0 ? (
                    rowsData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id_guide}
                            style={{ background: "none" }}
                          >
                            <TableCell
                              align="left"
                              className={clsx(
                                classes.text,
                                index % 2 ? classes.bgWhite : classes.bgGray
                              )}
                            >
                              {row.tracking_code}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={clsx(
                                classes.text,
                                index % 2 ? classes.bgWhite : classes.bgGray
                              )}
                            >
                              {row.fullname}
                            </TableCell>

                            <TableCell
                              align="left"
                              className={clsx(
                                classes.text,
                                index % 2 ? classes.bgWhite : classes.bgGray
                              )}
                            >
                              <div className={classes.containerAddress}>
                                {`${row.street_name} #${row.ext_number}${
                                  row.int_number === ""
                                    ? ""
                                    : " Int. " + row.int_number
                                }, ${row.colony}, ${59600}, ${row.city}, ${
                                  row.state
                                }`}
                              </div>
                            </TableCell>
                            <TableCell
                              align="left"
                              className={clsx(
                                classes.text,
                                index % 2 ? classes.bgWhite : classes.bgGray
                              )}
                            >
                              {row.shipping_type}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={clsx(
                                classes.text,
                                index % 2 ? classes.bgWhite : classes.bgGray
                              )}
                            >
                              {row.delivery_date}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={clsx(
                                classes.text,
                                index % 2 ? classes.bgWhite : classes.bgGray
                              )}
                            >
                              {row.creation_date}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={clsx(
                                classes.text,
                                index % 2 ? classes.bgWhite : classes.bgGray
                              )}
                            >
                              <Box className={classes.contentStatus}>
                                <FiberManualRecordIcon
                                  className={
                                    row.actual_status === "Entregado"
                                      ? classes.greenCircle
                                      : classes.redCircle
                                  }
                                />
                                {row.actual_status}
                              </Box>
                            </TableCell>
                            <TableCell
                              align="left"
                              className={
                                index % 2 ? classes.bgWhite : classes.bgGray
                              }
                            >
                              <div
                                className={clsx(
                                  row.urls_pdf_s3.length > 1
                                    ? classes.containerButtons
                                    : classes.containerButton
                                )}
                              >
                                {row.urls_pdf_s3.length > 1 ? (
                                  <Fragment>
                                    <a
                                      href={row.urls_pdf_s3[0]}
                                      target="_blank"
                                      rel="noreferrer"
                                      className={classes.containerIcon}
                                      title="PDF tamaño normal"
                                    >
                                      <PrintIcon className={classes.icon} />
                                    </a>
                                    <a
                                      href={row.urls_pdf_s3[1]}
                                      target="_blank"
                                      rel="noreferrer"
                                      className={classes.containerIconTermica}
                                      title="PDF para impresora térmica"
                                    >
                                      <img
                                        src={PrintTermica}
                                        className={classes.iconTermica}
                                        alt="termica"
                                      />
                                    </a>
                                  </Fragment>
                                ) : (
                                  <a
                                    href={row.urls_pdf_s3[0]}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={classes.containerIcon}
                                    title="PDF tamaño normal"
                                  >
                                    <PrintIcon className={classes.icon} />
                                  </a>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <NotFoundRows colSpan={7} />
                  )
                ) : (
                  <TableSkeleton rowsNum={5} columnsNum={7} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            count={rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Paper>
      </div>
    </Fragment>
  );
};

export default DataFilterShipment;
