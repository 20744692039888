import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../../constants/fontConsts";

const CardDescription = makeStyles((theme) => ({
  container: {
    width: 900,
    height: 300,
    background: "#FFFCFC",
    borderRadius: 15,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginBottom: 80,
    [theme.breakpoints.down("md")]: {
      width: "90%",
      height: 300,
      flexDirection:"column",
      justifyContent:"center",
      alignItems:"center",
      padding: "10px 0px"
    },
    [theme.breakpoints.down("sm")]: {
      width:"80%",
      
      
    },
    [theme.breakpoints.only("md")]: {
    },
    [theme.breakpoints.down("sm")]: {

    },
  },
  image: {
    paddingLeft: 30,
    [theme.breakpoints.down("sm")]: {
      height: 80,
      width: 80,
    },
  },
  containerText: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 60px",
  },
  title: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 28,
    marginBottom:20,
    [theme.breakpoints.down("md")]:{
      textAlign:"center"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
    },
  },
  text: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 20,
    [theme.breakpoints.down(1111, "lg")]: {
      fontSize: 12,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
  },
  textMin: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 15,
    marginTop:20,
    [theme.breakpoints.down(1111, "lg")]: {
      fontSize: 10,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
  },
  textMinSec: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 15,
    marginTop:10,
    [theme.breakpoints.down(1111, "lg")]: {
      fontSize: 10,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
      marginBottom:20
    },
  },
}));

export default CardDescription;
