import { makeStyles } from "@material-ui/core";
import { MAIN_COLORS } from "../../../constants/theme/colors";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const CheckoutStyles = makeStyles((theme) => ({
  button: {
    width: "90%",
    height: 45,
    borderRadius: 10,
    backgroundColor: MAIN_COLORS.MAIN_RED_BLACK,
    color: MAIN_COLORS.WHITE,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    textTransform: "none",
    marginTop: 20,
    "&:hover": {
      backgroundColor: MAIN_COLORS.MAIN_RED,
    },
  },
  form: {
    flexDirection: "column",
    gap: theme.spacing(2),
    width: "100%",
    maxWidth: 400,
    margin: "auto",
  },
  hiddenInput: {
    display: "none",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));
export default CheckoutStyles;
