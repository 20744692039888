import React, { Fragment, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Typography,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@material-ui/core";
import TabShippingTypeStyles from "./TabShippingTypeStyles";

import PaymentSummaryModal from "../../PaymentSummaryModal";

const headers = [
  { name: "Producto", id: "package_content" },
  { name: "Nombre", id: "full_name" },
  { name: "Domicilio", id: "address" },
  { name: "Envio", id: "shipping" },
  { name: "Peso extra", id: "extra_weight" },
];

const TabShippingType = (props) => {
  const { infoExcel, fileBase64, disableDirty } = props;

  const classes = TabShippingTypeStyles();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <Fragment>
      <Box className={classes.content}>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers.map((header, index) => {
                  return (
                    <TableCell key={index} className={classes.headers}>
                      <Typography className={classes.textHeader}>
                        {header.name}
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {infoExcel.guides.map((row, indexRow) => {
                return (
                  <TableRow key={indexRow}>
                    <TableCell className={classes.textRow}>
                      {row.guide_info.package_content}
                    </TableCell>
                    <TableCell className={classes.textRow}>
                      {row.recipient_info.full_name}
                    </TableCell>
                    <TableCell className={classes.textRow}>
                      {`${row.recipient_info.street_name} #${
                        row.recipient_info.ext_number
                      }${
                        row.recipient_info.int_number === ""
                          ? ""
                          : " Interior. " + row.recipient_info.int_number
                      }, ${row.recipient_info.colony}, ${
                        row.recipient_info.city
                      }, ${row.recipient_info.state}`}
                    </TableCell>
                    <TableCell className={classes.textRow}>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={row.guide_info.shipping_type}
                          style={{ fontSize: 10 }}
                        >
                          <FormControlLabel
                            value="Estandar"
                            control={<Radio style={{ color: "#d15252" }} />}
                            checked={true}
                            label={
                              <Typography style={{ fontSize: 12 }}>
                                Estandar $
                                {row.guide_info.guide_cost +
                                  row.guide_info.inter_zone_cost}
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </TableCell>
                    <TableCell className={classes.textRow}>
                      ${row.guide_info.extra_weight_cost}
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell className={classes.textRow}></TableCell>
                <TableCell className={classes.textRow}></TableCell>
                <TableCell className={classes.textRow}></TableCell>
                <TableCell className={classes.textAmoutTotal}>Total</TableCell>
                <TableCell className={classes.textAmoutTotal}>
                  ${infoExcel.amountTotalGuides}.00
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          variant="outlined"
          className={classes.button}
          onClick={handleClick}
        >
          <Typography className={classes.textButtomGuide}>
            Generar guías
          </Typography>
        </Button>
      </Box>
      <PaymentSummaryModal
        infoExcel={infoExcel}
        fileBase64={fileBase64}
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        disableDirty={disableDirty}
      />
    </Fragment>
  );
};

export default TabShippingType;
