import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInformation: {
    auth_token: "",
    id_user: null,
    id_role: null,
    role: "",
    info_complete: null,
    privacy_policy: null,
    trust_business: null,
    is_warehouse: null,
    credits: null,
    balance: 0,
    limited_location: null,
    laser_size_required: null,
  },
  isAuth: false,
};

export const UserInformationSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInformation(state, { payload }) {
      state.userInformation = payload;
    },
    setInfoCompleteStatus(state, { payload }) {
      state.userInformation.info_complete = payload;
    },
    setUserIsAuth(state, { payload }) {
      state.isAuth = payload;
    },
    setCredits(state, { payload }) {
      state.userInformation.credits = payload;
    },
    setCurrentBalanceForUser(state, { payload }) {
      state.userInformation.balance = payload;
    },
    setLimitedLocation(state, { payload }) {
      state.userInformation.limited_location = payload;
    },
    setLaserSize(state, { payload }) {
      state.userInformation.laser_size_required = payload;
    },
    resetUserInformation(state) {
      state.userInformation = {};
    },
  },
});

export const {
  setUserInformation,
  setInfoCompleteStatus,
  resetUserInformation,
  setUserIsAuth,
  setCredits,
  setCurrentBalanceForUser,
  setLimitedLocation,
  setLaserSize,
} = UserInformationSlice.actions;

export default UserInformationSlice.reducer;

//selectors
export const selectUserInformation = (state) =>
  state.user ? state.user.userInformation : [];
export const selecteUserIsAuth = (state) =>
  state.user ? state.user.isAuth : [];
export const selectUserPassOnboardingProcess = (state) =>
  state.user.userInformation && state.user.userInformation.info_complete;
export const selectUserHasBalanceAmount = (state) =>
  state.user.userInformation && state.user.userInformation.balance > 0;
export const selectCurrentUserBalance = (state) =>
  state.user.userInformation ? state.user.userInformation.balance : 0;
