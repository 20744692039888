import React from "react";
import CreditsStyle from "./CreditsStyle";
import DrawerDash from "../../components/DrawerDash";
import { Typography } from "@material-ui/core";
import ListCredits from "../../components/credits/ListCredits";

const Credits = () => {
  const classes = CreditsStyle();

  return (
    <DrawerDash>
      <Typography
        className={classes.title}
        variant="h5"
        id="tableTitle"
        component="div"
      >
        Historial de Créditos
      </Typography>
      <ListCredits />
    </DrawerDash>
  );
};

export default Credits;
