import React, { Fragment, useState } from "react";
import ModalComponent from "../../../components/sharedComponents/ModalComponent";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Typography,
  Button,
} from "@material-ui/core";
import ModalReviewExcelStyle from "./ModalReviewExcelStyles";
import { validateRow } from "../UploadExcel/Functions";


const ModalReviewExcel = (props) => {
  const { state, disabled } = props;

  const classes = ModalReviewExcelStyle();

  // const trust_business = useSelector(selectUserInformation).trust_business;

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const tableExcel = state.status && (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {state.headers.map((header, index) => {
              return (
                <TableCell key={index} className={classes.headers}>
                  <Typography className={classes.textHeader}>
                    {header}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {state.rows.map((row, indexRow) => {
            return (
              <TableRow key={indexRow}>
                {state.headers.map((header, indexHeader) => {
                  if (header === "CP" && !row["valid_cp"]) {
                    return (
                      <TableCell
                        key={indexHeader}
                        className={classes.textRowError}
                      >
                        {row[header]}
                      </TableCell>
                    );
                  } else if (
                    header === "Alto" ||
                    header === "Largo" ||
                    header === "Ancho"
                  ) {
                    if (
                      validateRow(header, {
                        alto: row["Alto"],
                        largo: row["Largo"],
                        ancho: row["Ancho"],
                      })
                    ) {
                      return (
                        <TableCell
                          key={indexHeader}
                          className={classes.textRowError}
                        >
                          {row[header]}
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell
                          className={classes.textRow}
                          key={indexHeader}
                        >
                          {row[header]}
                        </TableCell>
                      );
                    }
                  } else if (header === "Total_Por_Cobrar") {
                    if (
                      validateRow(header, {
                        por_cobrar: row["Por_Cobrar"],
                        total_por_cobrar: row["Total_Por_Cobrar"],
                      })
                    ) {
                      return (
                        <TableCell
                          key={indexHeader}
                          className={classes.textRowError}
                        >
                          {row[header]}
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell
                          className={classes.textRow}
                          key={indexHeader}
                        >
                          {row[header]}
                        </TableCell>
                      );
                    }
                  } else if (header === "Peso") {
                    if (
                      validateRow(header, {
                        peso: row["Peso"],
                        maxWeight: row.maxWeight,
                      })
                    ) {
                      return (
                        <TableCell
                          key={indexHeader}
                          className={classes.textRowError}
                        >
                          {row[header] !== undefined ? row[header] + "kg" : ""}
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell
                          className={classes.textRow}
                          key={indexHeader}
                        >
                          {row[header] !== undefined
                            ? Math.ceil(row[header]) + "kg"
                            : ""}
                        </TableCell>
                      );
                    }
                  } else if (validateRow(header, row[header])) {
                    return (
                      <TableCell
                        key={indexHeader}
                        className={classes.textRowError}
                      >
                        {row[header]}
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell className={classes.textRow} key={indexHeader}>
                        {row[header]}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Fragment>
      <Button
        variant="outlined"
        className={classes.button}
        onClick={handleOpen}
        disabled={disabled}
      >
        Revisar Excel
      </Button>
      <ModalComponent open={open} handleClose={handleClose} body={tableExcel} />
    </Fragment>
  );
};

export default ModalReviewExcel;
