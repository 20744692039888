import { Box, Typography } from '@material-ui/core';
import React from 'react'
import CircleAndDescriptionStyle from './CirclesInformation.style'
// import imageConfianza from "../../../../assets/img/imgConfianza.svg"
// import imageColaboracion from "../../../../assets/img/imgColaboracion.svg"
// import imageTransparencia from "../../../../assets/img/imgTransparencia.svg"
import imageValoresGroup from "../../../../assets/img/imageValoreGroup.svg"

const CirclesAndInformation = () => {

const classes = CircleAndDescriptionStyle();

  return (
    <Box className={classes.mainContainer}>
        <Typography className={classes.titleContainer}>Valores que integran Liber</Typography>
        <Box className={classes.containerGroup}>
            <Box className={classes.containerImage}>
                <img className={classes.imageGroup} src={imageValoresGroup} alt="" />
            </Box>
            <Box className={classes.containerText}>
                <Box className={classes.text} >
                    <Typography className={classes.title}>Seguro</Typography>
                    <Typography className={classes.description}> Ten por seguro que tu paquete, así como lo recolectamos, será envíado y recibido por tus clientes. </Typography>
                </Box>
                <Box className={classes.text} >
                    <Typography className={classes.title}>Confiable</Typography>
                    <Typography className={classes.description}>Confía en que todos tus paquetes que envíes o recibas, siempre llegarán hasta la puerta de tu casa.  </Typography>
                </Box>
                <Box className={classes.text} >
                    <Typography className={classes.title}>Rápido</Typography>
                    <Typography className={classes.description}>Contamos con servicios tecnológicos que nos permiten siempre estar a tiempo y forma con tus envíos. </Typography>
                </Box>
            </Box>
        </Box>
    </Box>
    
    // <Box className={classes.containerBox}>
    //     <Typography variant='h4' className={classes.titleContainer}>Valores que integran Liber</Typography>
    //     <Box className={classes.container}>
    //         <Box className={classes.imageContainer}>
    //             <img className={classes.imageIconSmall} src={imageConfianza} alt="" />
    //         </Box>
    //         <Box className={classes.textContainer} >
    //             <Typography  className={classes.title}>Confiable</Typography>
    //             <Typography  className={classes.description}>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</Typography>
    //         </Box>
    //     </Box>
    //     <Box className={classes.container}>
    //         <Box className={classes.imageContainer}>
    //             <img className={classes.imageIcon} src={imageColaboracion} alt="" />
    //         </Box>
    //         <Box className={classes.textContainer} >
    //             <Typography  className={classes.title}>Colaboración</Typography>
    //             <Typography  className={classes.description}>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</Typography>
    //         </Box>
    //     </Box>
    //     <Box className={classes.container}>
    //         <Box className={classes.imageContainer}>
    //             <img className={classes.imageIcon} src={imageTransparencia} alt="" />
    //         </Box>
    //         <Box className={classes.textContainer} >
    //             <Typography  className={classes.title}>Transporte</Typography>
    //             <Typography  className={classes.description}>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</Typography>
    //         </Box>
    //     </Box>
     
    // </Box>
  )
}

export default CirclesAndInformation