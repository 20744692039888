import axios from "axios";
const { REACT_APP_LIBER_API_URL, REACT_APP_LIBER_HOST_URL } = process.env;


export const searchPackage = (trackingCode) => {
  // console.log(`${REACT_APP_LIBER_API_URL}/packages/package-searching?tracking_code=${trackingCode}`)
  const request = {
    host: REACT_APP_LIBER_HOST_URL,
    method: "GET",
    url: `${REACT_APP_LIBER_API_URL}/packages/package-searching?tracking_code=${trackingCode}`,
    headers: {
      "content-type": "application/json",   
    },
  };
  return axios(request);
};