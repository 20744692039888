export const headers = [
  {
    id: "tracking_code",
    numeric: false,
    disablePadding: true,
    label: "No. de Guía",
  },
  {
    id: "recipient_fullname",
    numeric: false,
    disablePadding: false,
    label: "Destinatario",
  },
  {
    id: "company",
    numeric: false,
    disablePadding: false,
    label: "Paquetería",
  },
  {
    id: "service",
    numeric: false,
    disablePadding: false,
    label: "Tipo de Envío",
  },
  {
    id: "cost",
    numeric: false,
    disablePadding: false,
    label: "Costo",
  },
  {
    id: "guide_creation_date",
    numeric: false,
    disablePadding: false,
    label: "Creación",
  },
  {
    id: "guide",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];
