import { makeStyles } from "@material-ui/core";

const NotFoundRowsStyle = makeStyles((theme) => ({
  containerNoRecords: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down(500)]: {
      alignItems: "start",
      marginLeft: 20,
      width: 170,
    },
  },
  imageNoRecords: {
    width: 70,
    height: 70,
    marginBottom: 10,
    [theme.breakpoints.down(500)]: {
      width: 50,
      height: 50,
    },
  },
  textNoRecords: {
    [theme.breakpoints.down(500)]: {
      fontSize: 14.5,
    },
  },
}));

export default NotFoundRowsStyle;
