import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Fade,
  CircularProgress,
} from "@material-ui/core";
import {
  AccountCircle,
  Lock,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import React, { Fragment, useState } from "react";
import FormRegisteStyle from "./FormRegister.style";
import { useNavigate } from "react-router-dom";
import { registerUser } from "../../../api/registerApi";
import { ROUTES_CONSTANTS } from "../../../constants/routesConstants";
import { notification } from "../../../utils/notification";
import LiberLogo from "../../../assets/img/LogoNavbar.svg";
import { encryptPassword } from "../../../utils/auth";

const FormRegister = () => {
  const classes = FormRegisteStyle();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [checkedTYC, setcheckedTYC] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  // const rolUser = "Negocio";
  const handleChangeTYCCheckStatus = (e) => {
    setcheckedTYC(e.target.checked);
    // console.log(e.target.checked);
  };
  const navigate = useNavigate();

  const handleEmail = (e) => {
    let getEmail = e.target.value.trim();
    setEmail(getEmail);

    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(getEmail)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);

    e.target.value.length < 8
      ? setIsValidPassword(false)
      : setIsValidPassword(true);
  };

  const handleRegister = () => {
    setShowLoader(true);
    registerUser(email, encryptPassword(password))
      .then((res) => {
        if (res.data.status_Code === 201) {
          notification("El usuario fue creado con éxito", "success");
          setTimeout(() => {
            setShowLoader(false);
            navigate(ROUTES_CONSTANTS.INICIAR_SESION);
          }, 1000);
        } else if (res.data.status_Code === 200) {
          setShowLoader(false);
          notification("El correo ingresado ya está en uso", "error");
        } else {
          setShowLoader(false);
          notification("El usuario no pudo ser creado", "error");
        }
      })
      .catch((err) => {
        setShowLoader(false);
        notification("El usuario no pudo ser creado", "error");
      });
  };

  const disableButton =
    !isValidEmail || !password || password !== verifyPassword || !checkedTYC
      ? true
      : false;

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  // const handleRedirectToTYC = () => {
  //   navigate(ROUTES_CONSTANTS.TYC);
  // };
  // const handleRedirectToPoliticyPrivacy = () => {
  //   navigate(ROUTES_CONSTANTS.POLITICA_PRIVACIDAD);
  // };

  return (
    <Fragment>
      <Box className={classes.mainContainer}>
        <Box className={classes.formContainer}>
          <Box className={classes.logoContainer}>
            <img src={LiberLogo} alt="liber-lg" className={classes.liberLogo} />
          </Box>
          <Typography className={classes.createAccount} align="center">
            Crear cuenta
          </Typography>
          <Typography className={classes.subText} align="center">
            Ingresa tu correo y contraseña para crear un nuevo perfil
          </Typography>

          <Box className={classes.inputsContainer}>
            <TextField
              type="text"
              variant="outlined"
              placeholder="Correo"
              name="correo"
              value={email}
              onChange={handleEmail}
              autoComplete="off"
              helperText={
                !isValidEmail &&
                email.length > 0 && (
                  <Typography className={classes.errorLabel}>
                    Ingresa un formato de correo válido
                  </Typography>
                )
              }
              InputProps={{
                className: classes.inputComponent,
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle className={classes.icon} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box className={classes.inputsContainer}>
            <TextField
              type={showPassword ? "text" : "password"}
              variant="outlined"
              placeholder="Contraseña"
              name="contraseña"
              value={password}
              onChange={handlePassword}
              helperText={
                !isValidPassword && (
                  <Typography className={classes.errorLabel}>
                    La contraseña debe tener al menos 8 caracteres
                  </Typography>
                )
              }
              InputProps={{
                className: classes.inputComponent,
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock className={classes.icon} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box className={classes.inputsContainer}>
            <TextField
              type={showConfirmPassword ? "text" : "password"}
              variant="outlined"
              placeholder="Confirmar Contraseña"
              name="verificarContraseña"
              value={verifyPassword}
              onChange={(e) => setVerifyPassword(e.target.value)}
              InputProps={{
                className: classes.inputComponent,
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock className={classes.icon} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={
                verifyPassword !== "" &&
                password !== verifyPassword && (
                  <Typography className={classes.errorLabel}>
                    Las contraseñas no conciden
                  </Typography>
                )
              }
            />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box className={classes.containerText}>
              <Typography className={classes.IhaveAccount}>
                Ya tengo una cuenta,{" "}
                <span
                  className={classes.loginLink}
                  onClick={() => {
                    navigate(ROUTES_CONSTANTS.INICIAR_SESION);
                  }}
                >
                  iniciar sesion
                </span>
              </Typography>
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedTYC}
                  onChange={handleChangeTYCCheckStatus}
                  name="termsAndConditionsChecked"
                  classes={{
                    root: classes.root,
                    checked: classes.checked,
                  }}
                />
              }
              className={classes.marginTYC}
              label={
                <Typography className={classes.acceptTYC}>
                  Aceptar{" "}
                  <a
                    href="https://assets-liber.s3.amazonaws.com/docs/legal_docs/Condiciones_de_uso_de_liber_expres.pdf"
                    download
                    target="_blank"
                    rel="noreferrer"
                    className={classes.tycLink}
                  >
                    Términos y Condiciones
                  </a>{" "}
                  y <br />{" "}
                  <a
                    href="https://assets-liber.s3.amazonaws.com/docs/legal_docs/Aviso_de_privacidad.pdf"
                    download
                    target="_blank"
                    rel="noreferrer"
                    className={classes.privacyLink}
                  >
                    Aviso de Privacidad
                  </a>
                </Typography>
              }
            />

            <Button
              type="button"
              onClick={handleRegister}
              className={classes.buttonCrear}
              disabled={disableButton}
            >
              {showLoader ? "Creando.." : "Crear"}
              {showLoader && (
                <Box className={classes.contentLoader}>
                  <Fade in={showLoader} unmountOnExit>
                    <CircularProgress className={classes.loader} />
                  </Fade>
                </Box>
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default FormRegister;
