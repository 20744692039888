import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  businessInformation: {},
  businesLocations: [],
};

export const BusinessInformationSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    setBusinessCompleteInformation(state, { payload }) {
      state.businessInformation = payload;
    },
    setBusinessLocationsInformation(state, { payload }) {
      state.businesLocations = payload;
    },
    resetBusinessLocationsInformation(state) {
      state.businesLocations = [];
    },
    resetBusinessInformation(state) {
      state.businessInformation = {};
    },
  },
});

export const {
  setBusinessCompleteInformation,
  setBusinessLocationsInformation,
  resetBusinessLocationsInformation,
  resetBusinessInformation,
} = BusinessInformationSlice.actions;

export default BusinessInformationSlice.reducer;

//selectors
export const selectBusinessInformation = (state) =>
  state.business ? state.business.businessInformation : [];
export const selectBusinessLocations = (state) =>
  state.business ? state.business.businesLocations : [];
export const verifyIftheUserAlreadyHasBusinessInfo = (state) =>
  state.business.businessInformation &&
  (state.business.businessInformation.user_name !== "" ||
    state.business.businessInformation.paternal_lastname !== "" ||
    state.business.businessInformation.maternal_lastname !== "" ||
    state.business.businessInformation.business_name !== "")
    ? true
    : false;
