import { Box, Typography } from "@material-ui/core";
import React from "react";
import LoaderStyles from "./LoaderStyles";
import ModalComponent from "../../components/sharedComponents/ModalComponent";
import LoaderImage from "../../assets/img/Loader/Loader.svg";

const Loader = (props) => {
  const { text, showLoader } = props;

  const classes = LoaderStyles();

  return (
    <ModalComponent
      showButton={false}
      open={showLoader}
      body={
        <Box className={classes.content}>
          <Box className={classes.container}>
            <img src={LoaderImage} alt="loader" className={classes.img} />
            <Typography className={classes.text}>{text}</Typography>
          </Box>
        </Box>
      }
    />
  );
};

export default Loader;
