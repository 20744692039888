import axios from "axios";
import { getCookie } from "../utils/sessionCookie";
const { REACT_APP_LIBER_API_URL } = process.env;

export const getThirdOrders = () => {
  const request = {
    method: "GET",
    url: `${REACT_APP_LIBER_API_URL}/autopaquete/get-shippings`,
    headers: {
      "content-type": "application/json",
      auth_Token: getCookie("accessToken"),
    },
  };

  return axios(request);
};
