import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../constants/fontConsts";

const LoaderStyles = makeStyles((theme) => ({
  content: {
    width: "80%",
    height: "80%",
    background: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: 10,
    [theme.breakpoints.down(700)]: {
      width: "70%",
      height: "55%",
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  img: {
    [theme.breakpoints.down(700)]: {
      width: 180,
    },
    [theme.breakpoints.down(550)]: {
      width: 150,
    },
  },
  text: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    color: "#000",
    fontSize: 20,
    marginTop: 20,
    animation: `$movimiento 1.5s linear infinite`,
    textAlign: "center",
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.down(700)]: {
      fontSize: 17,
    },
    [theme.breakpoints.down(550)]: {
      fontSize: 16,
    },
  },
  "@keyframes movimiento": {
    "0%": {
      opacity: "0.2",
    },
    "12.5%": {
      opacity: "0.4",
    },
    "25%": {
      opacity: "0.6",
    },
    "37.5%": {
      opacity: "0.8",
    },
    "50%": {
      opacity: "1",
    },
    "62.5%": {
      opacity: "0.8",
    },
    "75%": {
      opacity: "0.6",
    },
    "87.5%": {
      opacity: "0.4",
    },
    "100%": {
      opacity: "0.2",
    },
  },
}));

export default LoaderStyles;
