import { makeStyles } from "@material-ui/core/styles";
import { MONTSERRAT_FONTS } from "../../constants/fontConsts";
import { MAIN_COLORS } from "../../constants/theme/colors";

const InMaintenanceStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  paper: {
    textAlign: "center",
  },
  image: {
    width: "100%",
    maxWidth: 300,
  },
  header: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    color: MAIN_COLORS.BLACK_MEDIUM_OPACITY,
    [theme.breakpoints.down("md")]: {
      fontSize: 25,
    },
  },
  footerText: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    color: MAIN_COLORS.BLACK_MEDIUM_OPACITY,
    fontSize: 15,
    marginTop: 10,

    [theme.breakpoints.down("md")]: {
      fontSize: 15,
      marginTop: 20,
    },
  },
}));
export default InMaintenanceStyles;
