import React, { useState, useEffect, createContext } from "react";

export const UnsavedChangesContext = createContext();

export default function UnsavedChangesProvider({ children }) {
  const [isDirty, setDirty] = useState(false);

  let message = "Changes you made may not be saved?";

  useEffect(() => {
    //Detecting browser closing
    window.onbeforeunload = isDirty && (() => message);

    return () => {
      window.onbeforeunload = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  const propmt = <unstable_usePrompt when={isDirty} message={message} />;

  const enableDirty = () => setDirty(true);

  const disableDirty = () => setDirty(false);

  const contextValue = {
    propmt,
    enableDirty,
    disableDirty,
    isDirty,
  };

  return (
    <UnsavedChangesContext.Provider value={contextValue}>
      {children}
    </UnsavedChangesContext.Provider>
  );
}
