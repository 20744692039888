import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS, POPPINS_FONTS } from "../../constants/fontConsts";
import { MAIN_COLORS } from "../../constants/theme/colors";
const drawerWidth = 240;

const DrawerDashStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    // backgroundColor:"#F8F5EF"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: "#F8F5EF",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing() + 1,
    [theme.breakpoints.up("sm")]: {
      width: 60,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: "#FFF",
    marginTop: 64,
  },
  list: {
    width: "100%",
    maxWidth: drawerWidth,
    backgroundColor: "#F8F5EF",
  },
  nested: {
    paddingLeft: 30,
  },
  buttonLink: {
    textDecoration: "none",
    color: "#50201f",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 30,
    paddingTop: 8,
    paddingBottom: 8,
  },
  buttonLinkReport: {
    textDecoration: "none",
    color: "#50201f",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imgLogo: {
    width: 150,
    height: 150,
    marginBottom: -50,
    paddingBottom: 80,
  },
  containerLogo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "end",
      alignItems: "start",
    },
  },
  textIcon: {
    fontFamily: POPPINS_FONTS.POPPINS_REGULAR,
    fontSize: 16.5,
    [theme.breakpoints.down(1000)]: {
      fontSize: 16,
    },
  },
  textIconCollapse: {
    fontFamily: POPPINS_FONTS.POPPINS_REGULAR,
    fontSize: 15,
    [theme.breakpoints.down(1000)]: {
      fontSize: 14,
    },
  },
  welcomeBack: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 12,
    color: `rgba(0, 0, 0, 0.5)`,
    lineHeight: "18px",
    textAlign: "justify",
    [theme.breakpoints.down(700)]: {
      fontSize: 11,
    },
  },
  helloTitle: {
    fontFamily: POPPINS_FONTS.POPPINS_REGULAR,
    fontSize: 25,
    [theme.breakpoints.down(700)]: {
      fontSize: 18,
      fontFamily: POPPINS_FONTS.POPPINS_SEMIBOLD,
    },
  },
  icon: {
    paddingLeft: 10,
  },
  Modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 770,
    height: 300,
    bgcolor: "background.paper",
    borderRadius: 10,
    boxShadow: 24,
    p: 4,
  },
  titleModal: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 24,
    marginTop: 20,
    [theme.breakpoints.down(600)]: {
      fontSize: 20,
    },
    [theme.breakpoints.down(500)]: {
      fontSize: 18,
    },
  },
  textModal: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 18,
    color: "#5A5151",
    margin: "60px 0px",
    [theme.breakpoints.down(600)]: {
      fontSize: 16,
      marginTop: 40,
      marginBottom: 70,
    },
    [theme.breakpoints.down(500)]: {
      fontSize: 15,
    },
  },
  boxModal: {
    width: 700,
    height: 350,
    background: "white",
    borderRadius: 10,
    boxShadow: 24,
    padding: 20,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(859)]: {
      height: 400,
      width: 500,
      fontSize: 12,
    },
    [theme.breakpoints.down(600)]: {
      height: 380,
      width: 450,
      fontSize: 12,
    },
    [theme.breakpoints.down(500)]: {
      height: 350,
      width: 350,
      fontSize: 12,
    },
  },
  // whatsappButton: {
  //   width: 218,
  //   height: 50,
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   backgroundColor: "#FFFFFF",
  //   color: "#020202",
  //   borderRadius: "10px",
  //   textDecoration: "none",
  //   fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
  //   fontSize: 20,
  //   boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.1)",
  //   marginTop: 25,
  // },
  whatsappButton: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: 45,
    width: 220,
    backgroundColor: MAIN_COLORS.WHITE,
    boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.1)",
    marginTop: 20,
    borderRadius: 10,
    textTransform: "none",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 20,
    color: MAIN_COLORS.BLACK,
    textDecoration: "none",
    [theme.breakpoints.down("lg")]: {
      height: 40,
      width: 150,
      fontSize: 12,
    },
    [theme.breakpoints.down(859)]: {
      height: 35,
      width: 120,
      fontSize: 12,
    },
    "&:hover": {
      backgroundColor: "#EAE5E5",
    },
  },

  iconWhatsApp: {
    marginLeft: 10,
  },
  btnModalEntendido: {
    height: 45,
    width: 195,
    backgroundColor: MAIN_COLORS.MAIN_RED,
    borderRadius: 10,
    textTransform: "none",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 20,
    color: MAIN_COLORS.WHITE,
    [theme.breakpoints.down("lg")]: {
      height: 40,
      width: 150,
      fontSize: 12,
    },
    [theme.breakpoints.down(859)]: {
      height: 35,
      width: 120,
      fontSize: 12,
    },
    "&:hover": {
      backgroundColor: MAIN_COLORS.MAIN_RED_BLACK,
    },
  },
  contentCredits: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  containerCredits: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    minWidth: 180,
    height: 45,
    background: "#F8EFEF",
    borderRadius: 30,
    padding: 15,
  },
  containerCreditsDrawer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    minWidth: 180,
    height: 45,
    borderRadius: 30,
    padding: 15,
  },
  textCredits: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 16,
    marginRight: 10,
  },
  textCreditsDrawer: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 16,
    marginRight: 20,
  },
  iconWallet: {
    marginRight: 10,
  },
  buttonUpdateCredits: {
    background: "#d15252",
    color: "white",
    width: 25,
    height: 25,
  },
  iconDrawer: {
    minWidth: 33,
    [theme.breakpoints.down(859)]: {
      fontSize: 8,
    },
  },
}));
export default DrawerDashStyle;
