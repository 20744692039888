import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  styled,
  useRadioGroup,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import PaymentOfGuidesStyle from "./PaymentOfGuidesStyle";
import imgPayGuide from "../../../assets/img/imgPayGuideForm.svg";
import imgPayGuideRecoletion from "../../../assets/img/imgPayGuideRecoletion.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUserInformation,
  setCredits,
} from "../../../store/reducers/user/UserInformationSlice";
import { selectBusinessInformation } from "../../../store/reducers/business/BusinessInformationSlice";
import { createGuideForm } from "../../../api/createGuideForm";
import { AUTH_MESSAGES } from "../../../constants/messages";
import { useNavigate } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../../constants/routesConstants";
import Loader from "../../../components/Loader";
import { notification } from "../../../utils/notification";
import imgWhatsApp from "../../../assets/img/imgWhatsApp.svg";
import { useTheme } from "@material-ui/core/styles";
import {
  addRecipient,
  updateRecipient,
  createGuideThird,
  addUpdatePackage,
  //getLocation,
} from "../../../api/guidesFormApi";
import useLogout from "../../../hooks/useLogout";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ErrorAutopaqueteModal from "../ErrorAutopaqueteModal";

const PymentOfGuides = (props) => {
  const theme = useTheme();
  const {
    handleClickBackStep,
    feeChoose,
    selectedLocation,
    recipient_info,
    setRecipientInfo,
    packageInformation,
    setPackageInformation,
    disableDirty,
    toCollect,
    setToCollect,
    amountReceivable,
    setAmountReceivable,
    amountCorrect,
    setAmountCorrect,
  } = props;
  const classes = PaymentOfGuidesStyle();
  const [logout] = useLogout();

  // StyleModal

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: 350,
    bgcolor: "background.paper",
    borderRadius: 10,
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(859)]: {
      height: 400,
      width: 500,
      fontSize: 12,
    },
    [theme.breakpoints.down(520)]: {
      height: 420,
      width: 400,
      fontSize: 12,
    },
  };
  const dispatch = useDispatch();
  const trustedUser = useSelector(selectUserInformation);
  const userInformation = useSelector(selectBusinessInformation);
  const navigate = useNavigate();
  const accessToken = trustedUser.auth_token;
  // Modal pago de confianza
  const [openModal, setOpenModal] = useState(false);
  const [openAutopaqueteModal, setOpenAutopaqueteModal] = useState(false);
  const [messageAutopaquete, setMessageAutopaquete] = useState(false);

  const handleCloseAutopaquete = () => setOpenAutopaqueteModal(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  // Modal Error
  const [openModalError, setOpenModalError] = useState(false);
  const handleCloseError = () => setOpenModalError(false);
  // Modal Loadding
  const [openModalLoadding, setOpenModalLoadding] = useState(false);
  // CambioMetodoPago
  const [metodoPago, setMetodoPago] = useState("creditos");
  const [paymentStatus, setPaymentStatus] = useState("pagada");
  // WhatsAPP
  const whatsappLink = "https://api.whatsapp.com/send?phone=3325481250";

  const currentDate = new Date();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const getPayload = () => {
    if (feeChoose.company === "LIBER") {
      return {
        guides: [
          {
            guide_info: {
              id_location: selectedLocation.id_location,
              guide_cost: feeChoose.guide_cost,
              shipping_type: feeChoose.tipo_envio,
              collection_cost: 0,
              package_content: packageInformation.type_product,
              content_status: toCollect === "SI" ? false : true,
              content_price: toCollect === "SI" ? amountReceivable : 0,
              package_height: packageInformation.high_product,
              package_length: packageInformation.long_product,
              package_width: packageInformation.broad_product,
              package_weight: packageInformation.weigth_product,
              volumetric_weight:
                (packageInformation.long_product *
                  packageInformation.broad_product *
                  packageInformation.high_product) /
                5000,
              estimated_delivery_date: currentDate.toDateString(),
              extra_weight: feeChoose.extra_weight,
              extra_weight_cost: feeChoose.extra_weight_cost,
              inter_zone_cost: feeChoose.inter_zone_cost,
            },
            recipient_info: {
              id_postal_code: feeChoose.idPostalCode,
              full_name: recipient_info.full_name,
              telephone_number: recipient_info.telephone_number,
              state: recipient_info.state,
              city: recipient_info.city,
              colony: recipient_info.colony,
              street_name: recipient_info.street_name,
              ext_number: recipient_info.ext_number,
              int_number: recipient_info.int_number,
              location_references: recipient_info.location_references,
              latitude: recipient_info.latitude,
              longitude: recipient_info.longitude,
            },
          },
        ],
        total_cost_guides: feeChoose.total,
        payment_status: paymentStatus,
      };
    } else {
      return {
        shipping_code: feeChoose.tipo_envio,
        total_cost_third_guide: feeChoose.total,
        location_origin: {
          company:
            userInformation.business_name !== ""
              ? userInformation.business_name
              : `${userInformation.user_name} ${userInformation.paternal_lastname} ${userInformation.maternal_lastname}`,
          fullname: `${userInformation.user_name} ${userInformation.paternal_lastname} ${userInformation.maternal_lastname}`,
          telephone_number: userInformation.telephone_number,
          email: "hola@correo.com",
          street_name: selectedLocation.street_name,
          ext_number: selectedLocation.ext_number,
          colony: selectedLocation.colony,
          postal_code: selectedLocation.postal_code,
          references:
            selectedLocation.location_references === ""
              ? "N/A"
              : selectedLocation.location_references,
        },
        location_destiny: {
          company: recipient_info.full_name,
          fullname: recipient_info.full_name,
          telephone_number: recipient_info.telephone_number,
          email: "hola@correo.com",
          street_name: recipient_info.street_name,
          ext_number: recipient_info.ext_number,
          colony: recipient_info.colony,
          postal_code: recipient_info.postal_code,
          references:
            recipient_info.location_references === ""
              ? "N/A"
              : recipient_info.location_references,
        },
        info_package: {
          product_description: packageInformation.type_product,
          package_value: 100,
          insurance: false,
          package_height: packageInformation.high_product,
          package_width: packageInformation.broad_product,
          package_depth: packageInformation.long_product,
          package_weight: packageInformation.weigth_product,
        },
      };
    }
  };

  const handlePackage = async () => {
    let flag = true;

    let payload = {
      is_update: packageInformation.update_package,
      product_info: {
        id_concurrent_product: packageInformation.id_package,
        product_name: packageInformation.type_product,
        description: packageInformation.description,
        height: packageInformation.high_product,
        length: packageInformation.long_product,
        width: packageInformation.broad_product,
        weight: packageInformation.weigth_product,
      },
    };

    await addUpdatePackage(payload)
      .then((res) => {
        if (res.data.status_Code === 201) {
          notification(
            packageInformation.update_package
              ? "El empaque se actualizó de manera correcta"
              : "El empaque se guardó de manera correcta",
            "success"
          );
          if (packageInformation.save_package) {
            setPackageInformation((prevState) => ({
              ...prevState,
              id_package: res.data.new_id_concurrent_product,
              save_package: false,
              update_package: true,
            }));
          }
        } else if (res.data.status_Code === 401) {
          notification("La sesión ha caducado");
          logout();
          flag = false;
        } else {
          notification(
            packageInformation.update_package
              ? "Ocurrió un error al actualizar el empaque"
              : "Ocurrió un error al guardar el empaque"
          );
          flag = false;
        }
      })
      .catch((err) => {
        notification(
          `Error de conexión, no se pudo ${
            packageInformation.update_package ? "actualizar" : "guardar"
          } el empaque, revisa tu conexión de internet`
        );
        flag = false;
      });

    return flag;
  };

  const handleClick = async () => {
    setOpenModalLoadding(true);

    if (packageInformation.update_package || packageInformation.save_package) {
      const response = await handlePackage();

      if (!response) {
        setOpenModalLoadding(false);
        return false;
      }
    }

    let payloadRecipient = {
      concurrent_recipient: {
        id_concurrent_recipient: recipient_info.id_recipient,
        id_postal_code: feeChoose.idPostalCode,
        full_name: recipient_info.full_name,
        telephone_number: recipient_info.telephone_number,
        state: recipient_info.state,
        city: recipient_info.city,
        colony: recipient_info.colony,
        street_name: recipient_info.street_name,
        ext_number: recipient_info.ext_number,
        int_number: recipient_info.int_number,
        references: recipient_info.location_references,
        latitude: recipient_info.latitude,
        longitude: recipient_info.longitude,
      },
    };

    if (recipient_info.save_recipient) {
      addRecipient(payloadRecipient)
        .then((res) => {
          if (res.data.status_Code === 201) {
            notification(
              "El destinatario se guardó de manera correcta",
              "success"
            );
            setRecipientInfo((prevState) => ({
              ...prevState,
              id_recipient: res.data.new_id_concurrent_recipient,
              save_recipient: false,
              update_recipient: true,
            }));
            handleCreateGuideTrust();
          } else if (res.data.status_Code === 401) {
            setOpenModalLoadding(false);
            notification("La sesión ha caducado");
            logout();
          } else {
            setOpenModalLoadding(false);
            notification("Ocurrió un error al guardar el destinatario");
          }
        })
        .catch((err) => {
          setOpenModalLoadding(false);
          notification(
            "Error de conexión, no se pudo guardar el destinatario, revisa tu conexión de internet"
          );
        });
    } else if (recipient_info.update_recipient) {
      updateRecipient(payloadRecipient)
        .then((res) => {
          if (res.data.status_Code === 200) {
            notification(
              "El destinatario se actualizó de manera correcta",
              "success"
            );
            handleCreateGuideTrust();
          } else if (res.data.status_Code === 401) {
            setOpenModalLoadding(false);
            notification("La sesión ha caducado");
            logout();
          } else {
            setOpenModalLoadding(false);
            notification("Ocurrió un error al actualizar el destinatario");
          }
        })
        .catch((err) => {
          setOpenModalLoadding(false);
          notification(
            "Error de conexión, no se pudo actualizar el destinatario, revisa tu conexión de internet"
          );
        });
    } else {
      handleCreateGuideTrust();
    }
  };

  // Crear guia Confianza
  const handleCreateGuideTrust = () => {
    let payload = getPayload();

    if (feeChoose.company === "LIBER") {
      createGuideForm(payload, accessToken)
        .then((res) => {
          if (
            res.data.status_Code === 201 &&
            res.data.status_Message === AUTH_MESSAGES.GUIDES_WAS_ADDED
          ) {
            disableDirty();
            setOpenModalLoadding(false);
            notification("La guía se genero correctamente", "success");
            navigate(ROUTES_CONSTANTS.ORDENES);
            const new_total_credits = res.data.new_total_credits;
            dispatch(setCredits(new_total_credits));
          } else if (res.data.status_Code === 401) {
            setOpenModalLoadding(false);
            notification("La sesión ha caducado");
            logout();
          } else if (
            res.data.status_Code === 200 &&
            res.data.status_Message === "insufficient credits"
          ) {
            setOpenModalLoadding(false);
            setOpenModalError(true);
          } else {
            setOpenModalLoadding(false);
            notification(
              "Ocurrio un error, no se pudo generar correctamente la guía"
            );
          }
        })
        .catch((err) => {
          setOpenModalLoadding(false);
          notification(
            "Ocurrio un error, no se pudo generar correctamente la guía"
          );
        });
    } else {
      createGuideThird(payload)
        .then((res) => {
          if (
            res.data.status_Code === 201 &&
            res.data.status_Message === "third guide was created"
          ) {
            disableDirty();
            setOpenModalLoadding(false);
            notification("La guía se genero correctamente", "success");
            navigate(ROUTES_CONSTANTS.THIRD_ORDENES);
            const new_total_credits = res.data.new_total_credits;
            dispatch(setCredits(new_total_credits));
          } else if (res.data.status_Code === 401) {
            setOpenModalLoadding(false);
            notification("La sesión ha caducado");
            logout();
          } else if (
            res.data.status_Code === 200 &&
            res.data.status_Message === "insufficient credits"
          ) {
            setOpenModalLoadding(false);
            setOpenModalError(true);
          } else if (res.data.status_Code === 406) {
            setOpenModalLoadding(false);
            setOpenAutopaqueteModal(true);
            setMessageAutopaquete(res.data.status_Message);
          } else if (res.data.is_autopaquete) {
            setOpenModalLoadding(false);
            setOpenAutopaqueteModal(true);
            setMessageAutopaquete(
              "No fue posible generar la guía, ya que, por el momento la paquetería seleccionada no se encuentra disponible, puede intentar con otra o intentarlo más tarde"
            );
          } else {
            setOpenModalLoadding(false);
            notification(
              "Ocurrio un error, no se pudo generar correctamente la guía"
            );
          }
        })
        .catch((err) => {
          setOpenModalLoadding(false);
          notification(
            "Ocurrio un error, no se pudo generar correctamente la guía"
          );
        });
    }
  };

  // Cambio de tipo de pago
  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();
    let checked = false;
    if (radioGroup) {
      setMetodoPago(radioGroup.value);
      checked = radioGroup.value === props.value;
      if (metodoPago !== "creditos") {
        setPaymentStatus("faltante");
      } else {
        setPaymentStatus("pagada");
      }
    }
    return <StyledFormControlLabel checked={checked} {...props} />;
  }
  const StyledFormControlLabel = styled((props) => (
    <FormControlLabel {...props} />
  ))(({ checked }) => ({
    ".MuiFormControlLabel-label": checked && {
      color: "f44336",
    },
  }));

  const handleChangeSelect = (e) => {
    setToCollect(e.target.value);
    setAmountReceivable(0);
    setAmountCorrect(false);
  };

  const handleAmountReceivable = (value) => {
    setAmountReceivable(value);

    value > 0 && value <= 6000
      ? setAmountCorrect(true)
      : setAmountCorrect(false);
  };

  return (
    <div>
      <Typography className={classes.title}>Proceso de pago</Typography>

      {feeChoose.company === "LIBER" && (
        <Fragment>
          <FormControl className={classes.selectDirection}>
            <Typography className={classes.inputDescription}>
              Por cobrar <strong style={{ color: "red" }}>*</strong>
            </Typography>
            <Select
              variant="outlined"
              onChange={(e) => handleChangeSelect(e)}
              value={toCollect}
              IconComponent={ExpandMoreIcon}
              className={classes.styleSelect}
            >
              <MenuItem value="NONE">Elige una opción..</MenuItem>
              <MenuItem value="SI">SI</MenuItem>
              <MenuItem value="NO">NO</MenuItem>
            </Select>
          </FormControl>

          {toCollect === "SI" && (
            <FormControl className={classes.smallTextField}>
              <Typography className={classes.inputDescription}>
                Monto por cobrar <strong style={{ color: "red" }}>*</strong>
              </Typography>
              <TextField
                variant="outlined"
                value={amountReceivable}
                size="small"
                type="number"
                error={!amountCorrect}
                helperText={
                  !amountCorrect &&
                  "La cartidad debe ser mayor a 0 y menor 6000"
                }
                onChange={(e) => handleAmountReceivable(e.target.value)}
                inputProps={{
                  className: classes.inputTextFile,
                }}
              />
            </FormControl>
          )}
        </Fragment>
      )}

      {/* Si es de confianza */}
      {trustedUser.trust_business && feeChoose.company === "LIBER" ? (
        <>
          <Box className={classes.container}>
            <Typography className={classes.text}>
              Tú formas parte de nuestros usuarios de confianza elige tu método
              de pago preferido.
            </Typography>
            <Box className={classes.containerPrice}>
              <Typography className={classes.textPrice}>Monto Total</Typography>
              <Typography className={classes.price}>
                ${feeChoose.total}MXN
              </Typography>
            </Box>
          </Box>
          <RadioGroup
            name="use-radio-group"
            defaultValue="creditos"
            className={classes.radioButtons}
          >
            <MyFormControlLabel
              value="creditos"
              label={
                <Typography className={classes.radioButtonText}>
                  Pagar con creditos
                </Typography>
              }
              control={<Radio />}
            />
            <MyFormControlLabel
              value="recoleccion"
              label={
                <Typography className={classes.radioButtonText}>
                  Pagar al momento de la recolección
                </Typography>
              }
              control={<Radio />}
            />
          </RadioGroup>
          {/* Metodo pago creditos */}
          {metodoPago === "creditos" ? (
            <>
              <Box className={classes.containerImg}>
                <img
                  src={imgPayGuide}
                  alt=""
                  className={classes.imgPayCredits}
                />
              </Box>
              <Grid item xs={12} md={12}>
                <Box className={classes.containerButtons}>
                  <Button
                    variant="contained"
                    className={classes.backButton}
                    onClick={handleClickBackStep}
                  >
                    Regresar
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.payButton}
                    onClick={handleClick}
                    disabled={
                      feeChoose.company === "LIBER"
                        ? toCollect === "SI"
                          ? amountReceivable > 0 && amountReceivable <= 6000
                            ? false
                            : true
                          : toCollect === "NO"
                          ? false
                          : true
                        : false
                    }
                  >
                    Pagar
                  </Button>
                </Box>
              </Grid>
            </>
          ) : (
            // Metodo pago recoleccion
            <>
              <Box className={classes.containerImg}>
                <img
                  src={imgPayGuideRecoletion}
                  alt=""
                  className={classes.imgPayCredits}
                />
              </Box>
              <Grid item xs={12} md={12}>
                <Box className={classes.containerButtons}>
                  <Button
                    variant="contained"
                    className={classes.backButton}
                    onClick={handleClickBackStep}
                  >
                    Regresar
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.payButton}
                    onClick={handleOpen}
                    disabled={
                      feeChoose.company === "LIBER"
                        ? toCollect === "SI"
                          ? amountReceivable > 0 && amountReceivable <= 6000
                            ? false
                            : true
                          : toCollect === "NO"
                          ? false
                          : true
                        : false
                    }
                  >
                    Pagar
                  </Button>
                </Box>
              </Grid>
            </>
          )}
        </>
      ) : (
        // No es de confianza
        <>
          <Box className={classes.container}>
            <Typography className={classes.text}>
              El costo del envió se descontará de tu crédito
            </Typography>
            <Box className={classes.containerPrice}>
              <Typography className={classes.textPrice}>Monto Total</Typography>
              <Typography className={classes.price}>
                ${feeChoose.total}MXN
              </Typography>
            </Box>
          </Box>
          <Box className={classes.containerImg}>
            <img src={imgPayGuide} alt="" className={classes.imgPayCredits} />
          </Box>
          <Grid item xs={12} md={12}>
            <Box className={classes.containerButtons}>
              <Button
                variant="contained"
                className={classes.backButton}
                onClick={handleClickBackStep}
              >
                Regresar
              </Button>
              <Button
                variant="contained"
                className={classes.payButton}
                onClick={handleClick}
                disabled={
                  feeChoose.company === "LIBER"
                    ? toCollect === "SI"
                      ? amountReceivable > 0 && amountReceivable <= 6000
                        ? false
                        : true
                      : toCollect === "NO"
                      ? false
                      : true
                    : false
                }
              >
                Pagar
              </Button>
            </Box>
          </Grid>
        </>
      )}
      {/* Modal Confianza */}
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Typography id="modal-modal-title" className={classes.titleModal}>
            Pagar al momento de recoleccion
          </Typography>
          <Typography
            id="modal-modal-description"
            className={classes.textModal}
          >
            Estoy de acuerdo que hare el pago de esta guía al momento <br />
            de que el repartidor llegue por el paquete al domicilio de origen
          </Typography>
          <Box className={classes.containerButtonsModal}>
            <Button
              variant="contained"
              className={classes.backButton}
              onClick={handleClose}
            >
              {" "}
              Regresar{" "}
            </Button>
            <Button
              variant="contained"
              className={classes.payButton}
              onClick={() => {
                setOpenModal(false);
                handleClick();
              }}
            >
              {" "}
              Generar guía
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* Modal Error */}
      <Modal
        open={openModalError}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Typography id="modal-modal-title" className={classes.titleModal}>
            Creditos Insufientes
          </Typography>
          <Typography
            id="modal-modal-description"
            className={classes.textModal}
          >
            Puedes ponerte en contacto con el administrador para conseguir
            <br />
            mas creditos
            <br />
            Contacta mediante WhatsApp para mayor información
          </Typography>
          <Box className={classes.containerButtonsModal}>
            <Button
              variant="contained"
              className={classes.backButton}
              onClick={handleCloseError}
            >
              {" "}
              Regresar{" "}
            </Button>
            <a
              variant="contained"
              href={whatsappLink}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.WTSPButton}
            >
              Contactar
              <img src={imgWhatsApp} alt="" />
            </a>
          </Box>
        </Box>
      </Modal>
      {/* Modal Loadding */}
      <Loader showLoader={openModalLoadding} text="Generando guía..." />
      <ErrorAutopaqueteModal
        open={openAutopaqueteModal}
        handleClose={handleCloseAutopaquete}
        message={messageAutopaquete}
      />
    </div>
  );
};

export default PymentOfGuides;
