import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../constants/fontConsts";

const FooterStyles = makeStyles((theme) => ({
  mainContainer: {
    height: "100%",
    
    background: "linear-gradient(180deg, #EAF4FF 0%, rgba(254, 254, 254, 0) 100%)",
    // boxShadow: `0px -1px 9px 1px rgba(0, 0, 0, 0.25)`,
  },
  contentContainer: {
    padding: 50,
  },
  footerHeader: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 16,
    textAlign:"center",
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  footerHeaderContact: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 16,
    textAlign:"start",
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  centerContent: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  centerContentColumn: {
    display: "flex",
    justifyContent: "center",
   
    flexDirection:'column'
  },
  sectionItems: {
    marginTop: 15,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 14,
    textAlign:"center",
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
  },
  a:{
    textDecoration:"none",
    color:"#000"
  },
  socialMediaIconsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  socialMediaIcons: {
    marginLeft: 10,
    width: 30,
    height: 30,
    [theme.breakpoints.down("sm")]: {
      width: 25,
      height: 25,
    },
  },
  followUsContainer: {
    display: "flex",
    marginTop: 30,
    flexDirection: "column",
  },
  addressContainer: {
    display: "flex",
    marginTop: 30,
    flexDirection: "column",
    textAlign:"justify",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10%",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "18%",
    },
    [theme.breakpoints.down(370)]: {
      marginLeft: "12%",
    },
  },
  addressInfo: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 14,
    marginRight: 10,
    marginBottom: 10,
    marginTop: 15,
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
  },
  footerLogo: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  logoLiber: {
    marginLeft: 50,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
}));
export default FooterStyles;
