export const ROUTES_CONSTANTS = {
  LANDING: "/",
  INICIAR_SESION: "/iniciarSesion",
  REGISTRO: "/registro",
  RASTREO: "/rastreo",
  RASTREOQR: "/rastreo/:trackingCode",
  FAQS: "/preguntasFrecuentes",
  NOSOTROS: "/nosotros",
  ZONAS: "/zonasEnvios",
  COTIZACION: "/cotizacion",
  PERFIL: "/perfil",
  MIS_ENVIOS: "/misEnvios",
  INFORMACION_ENVIOS: "/informacionEnvios",
  ORDENES: "/ordenes",
  THIRD_ORDENES: "/ordenesDeTerceros",
  CREACION_DE_GUIAS: "/creacionDeGuias",
  TIPO_ENVIO: "/tipoEnvio",
  FORM_CREAR_GUIA: "/crearGuiarFormulario",
  DIMENSIONES_RESUMEN_GUIAS: "/dimensionesResumenGuia",
  COTIZACION_DASHBOARD: "/cotizacionCliente",
  GENERAR_GUIAS_MASIVO: "/generarGuiasMasiva",
  TYC: "/TerminosYCondiciones",
  POLITICA_PRIVACIDAD: "/PoliticaDePrivacidad",
  LOTS: "/Lotes",
  BALANCE: "/Saldo",
  PAYMENT_SUCCESS: "/procesoDePago/exitoso",
  PAYMENT_FAILED: "/procesoDePago/fallido",
  CREDITS: "/Creditos",
};
export const URLCONST = {
  IFRAME_MAPS_CLIENTS:
    "https://www.google.com/maps/d/embed?mid=1v2c8tJTvPIqcgcSc2O49sMGuRabmMGdp&ehbc=2E312F",
};
