import toast from "react-hot-toast";
import { POPPINS_FONTS } from "../constants/fontConsts";

export const notification = (text, type = "error") => {
  let style = {
    borderRadius: "10px",
    background: "#333",
    color: "#fff",
    fontFamily: POPPINS_FONTS.POPPINS_REGULAR,
    padding:10,
  };

  let options = {
    duration: 4000,
    position: "top-center",
    style: style,
  };

  if (type === "success") toast.success(text, options);

  if (type === "error") toast.error(text, options);
};
