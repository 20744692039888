import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../../constants/fontConsts";
import { MAIN_COLORS } from "../../../../constants/theme/colors";

const InsufficientCreditsModalStyle = makeStyles((theme) => ({
  content: {
    width: 700,
    height: 350,
    background: "white",
    borderRadius: 10,
    boxShadow: 24,
    padding: 20,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(859)]: {
      height: 400,
      width: 500,
      fontSize: 12,
    },
    [theme.breakpoints.down(600)]: {
      height: 380,
      width: 450,
      fontSize: 12,
    },
    [theme.breakpoints.down(500)]: {
      height: 350,
      width: 350,
      fontSize: 12,
    },
  },
  title: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 24,
    marginTop: 20,
    [theme.breakpoints.down(600)]: {
      fontSize: 20,
    },
    [theme.breakpoints.down(500)]: {
      fontSize: 18,
    },
  },
  text: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 18,
    color: "#5A5151",
    margin: "60px 0px",
    [theme.breakpoints.down(600)]: {
      fontSize: 16,
      marginTop: 40,
      marginBottom: 70,
    },
    [theme.breakpoints.down(500)]: {
      fontSize: 15,
    },
  },
  containerButtons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  backButton: {
    height: 45,
    width: 195,
    backgroundColor: MAIN_COLORS.WHITE,
    // color: MAIN_COLORS.BLACK,
    borderRadius: 10,
    boxShadow: "none",
    border: "1px solid",
    borderColor: MAIN_COLORS.MAIN_RED_20_OPACITY,
    textTransform: "none",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 15,
    color: MAIN_COLORS.MAIN_RED,
    marginRight: 50,
    "&:hover": {
      backgroundColor: MAIN_COLORS.WHITE,
      boxShadow: "none",
      border: "1px solid",
      borderColor: MAIN_COLORS.MAIN_RED,
    },
    [theme.breakpoints.down("lg")]: {
      height: 40,
      width: 150,
      fontSize: 12,
    },
    [theme.breakpoints.down(859)]: {
      height: 35,
      width: 120,
      fontSize: 12,
    },
  },
  WTSPButton: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: 45,
    width: 195,
    backgroundColor: MAIN_COLORS.WHITE,
    borderRadius: 10,
    textTransform: "none",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.1)",
    fontSize: 15,
    color: MAIN_COLORS.BLACK,
    textDecoration: "none",
    [theme.breakpoints.down("lg")]: {
      height: 40,
      width: 150,
      fontSize: 12,
    },
    [theme.breakpoints.down(859)]: {
      height: 35,
      width: 120,
      fontSize: 12,
    },
    "&:hover": {
      backgroundColor: "#EAE5E5",
    },
  },
}));
export default InsufficientCreditsModalStyle;
