import { Box, Button, Typography } from '@material-ui/core'
import React from 'react'
import TypesPackagesStyle from './TypesPackagesStyle'
import VisibilityIcon from '@material-ui/icons/Visibility';

const TypesPackages = () => {
const classes = TypesPackagesStyle();
  return (
    <Box>
        <Box style={{display:"flex", justifyContent:"end"}}>
            <Box className={classes.containerEye}>
                <VisibilityIcon style={{fontSize:20}}/>
                <Typography className={classes.textEye}>
                    Revisa que las dimensiones de tu paquete  sean correctas, ya que si  
                    detecta un sobrepeso, se generará un costo extra al momento de la 
                    recolección.
                </Typography>
            </Box>
        </Box>
    
        <Box className={classes.containerInformation}>
            <Typography className={classes.titleContainer}>Resumen de los datos</Typography>
            <Box className={classes.containerSeparado}>
                <Typography className={classes.textContainerSeparado}>Destinatario</Typography>
                <Button>Cambiar</Button>
            </Box>
            <Box>
                <Typography className={classes.titleText}>Nombre</Typography>
                <Typography className={classes.descriptionText}>Alejandra Gonzales Rodriguez</Typography>
            </Box>
            <Box>
                <Typography className={classes.titleText}>Empresa/Negocio</Typography>
                <Typography className={classes.descriptionText}>Cuev</Typography>
            </Box>
            <Box>
                <Typography className={classes.titleText}>Teléfono</Typography>
                <Typography className={classes.descriptionText}>333 678 45 33</Typography>
            </Box>
            <Box>
                <Typography className={classes.titleText}>Dirección</Typography>
                <Typography className={classes.descriptionText}>Tulipanes 90, El Duero, 59663. Guadalajara, Jalisco.</Typography>
            </Box>
            <Box>
                <Typography className={classes.titleText}>Referencias</Typography>
                <Typography className={classes.descriptionText}>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmo</Typography>
            </Box>

            <Box className={classes.containerSeparadoAbajo}>
                <Typography className={classes.textContainerSeparado}>Remitente</Typography>
                <Button>Cambiar</Button>
            </Box>
            <Box>
                <Typography className={classes.titleText}>Nombre</Typography>
                <Typography className={classes.descriptionText}>Alejandra González Rodriguez</Typography>
            </Box>
            <Box>
                <Typography className={classes.titleText}>EmpresaNegocio</Typography>
                <Typography className={classes.descriptionText}>Cuev</Typography>
            </Box>
            <Box>
                <Typography className={classes.titleText}>Teléfono</Typography>
                <Typography className={classes.descriptionText}>333 678 45 33</Typography>
            </Box>
            <Box>
                <Typography className={classes.titleText}>Dirección</Typography>
                <Typography className={classes.descriptionText}>Tulipanes 90, El Duero, 59663. Guadalajara, Jalisco.</Typography>
            </Box>
            <Box>
                <Typography className={classes.titleText}>Referencias</Typography>
                <Typography className={classes.descriptionText}>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmo</Typography>
            </Box>

        </Box>
    </Box>
  )
}

export default TypesPackages