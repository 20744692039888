// Creation GuidesForm/ FormBottomButtons
import { Box, Button } from "@material-ui/core";
import React from "react";
import FormBottomButtonStyles from "./FormBottomButtonsStyles";

const FormBottomButtons = (props) => {
  const { handleNext, handleBack, disableNext = false } = props;
  const classes = FormBottomButtonStyles();
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.buttonsContainer}>
        {handleBack && (
          <Button
            variant="contained"
            className={classes.backButton}
            onClick={handleBack}
          >
            Regresar
          </Button>
        )}
        {handleNext && (
          <Button
            disabled={disableNext}
            variant="contained"
            className={classes.nextButton}
            onClick={handleNext}
          >
            Siguiente
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default FormBottomButtons;
