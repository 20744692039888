import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/fontConsts";

const DownloadExcelStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  containerLink: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
  },
  imagen: {
    width: 85,
    height: 85,
    /* min-height: 980px*/
    "@media (min-height: 980px) and (max-width: 950px)": {
      width: 55,
      height: 55,
    },
    "@media (min-height: 980px) and (max-width: 650px)": {
      width: 40,
      height: 40,
    },
    "@media (min-height: 980px) and (max-width: 470px)": {
      width: 35,
      height: 35,
    },

    /* max-height: 979px*/
    "@media (max-height: 979px) and (min-width: 601px)": {
      height: 50,
      width: 50,
    },
    "@media (max-height: 979px) and (max-width: 600px)": {
      height: 40,
      width: 40,
    },
    "@media (max-height: 979px) and (max-width: 470px)": {
      width: 35,
      height: 35,
    },

    /* max-height: 840px*/
    "@media (max-height: 840px) and (min-width: 769px)": {
      width: 40,
      height: 40,
    },
    "@media (max-height: 840px) and (max-width: 768px)": {
      height: 35,
      width: 35,
    },
    "@media (max-height: 840px) and (max-width: 470px)": {
      width: 30,
      height: 30,
    },
    
    /* max-height: 740px*/
    "@media (max-height: 740px) and (min-width: 769px)": {
      width: 35,
      height: 35,
    },
    "@media (max-height: 740px) and (max-width: 768px)": {
      width: 30,
      height: 30,
    },
    "@media (max-height: 740px) and (max-width: 470px)": {
      width: 25,
      height: 25,
    },

    /* max-height: 640px*/
    "@media (max-height: 640px) and (min-width: 769px)": {
      width: 30,
      height: 30,
    },
    "@media (max-height: 640px) and (max-width: 768px)": {
      width: 26,
      height: 26,
    },
    "@media (max-height: 640px) and (max-width: 470px)": {
      width: 23,
      height: 23,
    },
  },
  box: {
    border: "1px solid rgba(0, 0, 0, 0.25)",
    borderRadius: 15,
    width: 450,
    height: 250,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    /* min-height: 980px*/
    "@media (min-height: 980px) and (max-width: 950px)": {
      height: 250,
      width: 300,
    },
    "@media (min-height: 980px) and (max-width: 650px)": {
      width: 220,
      height: 170,
    },
    "@media (min-height: 980px) and (max-width: 470px)": {
      width: 200,
      height: 150,
    },

    /* max-height: 979px*/
    "@media (max-height: 979px) and (min-width: 601px)": {
      height: 200,
      width: 300,
    },
    "@media (max-height: 979px) and (max-width: 600px)": {
      width: 220,
      height: 170,
    },
    "@media (max-height: 979px) and (max-width: 470px)": {
      width: 200,
      height: 150,
    },
    
    /* max-height: 840px*/
    "@media (max-height: 840px) and (min-width: 769px)": {
      width: 250,
      height: 170,
    },
    "@media (max-height: 840px) and (max-width: 768px)": {
      width: 220,
      height: 150,
    },
    "@media (max-height: 840px) and (max-width: 470px)": {
      width: 200,
      height: 150,
    },
    
    /* max-height: 740px*/
    "@media (max-height: 740px) and (min-width: 769px)": {
      width: 200,
      height: 140,
    },
    "@media (max-height: 740px) and (max-width: 768px)": {
      width: 180,
      height: 120,
    },
    "@media (max-height: 740px) and (max-width: 470px)": {
      width: 160,
      height: 120,
    },

    /* max-height: 640px*/
    "@media (max-height: 640px) and (min-width: 769px)": {
      width: 180,
      height: 120,
    },
    "@media (max-height: 640px) and (max-width: 768px)": {
      width: 160,
      height: 100,
    },
    "@media (max-height: 640px) and (max-width: 470px)": {
      width: 140,
      height: 80,
    },
  },
  label: {
    background: "#D15252",
    color: "#fff",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    padding: "10px 30px",
    marginTop: 50,
    borderRadius: 10,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
    /* min-height: 980px*/
    "@media (min-height: 980px) and (max-width: 950px)": {
      marginTop: 30,
      padding: "7px 0px",
      width: 120,
      textAlign: "center",
      fontSize: 14,
    },
    "@media (min-height: 980px) and (max-width: 650px)": {
      marginTop: 20,
      padding: "7px 0px",
      width: 100,
      textAlign: "center",
      fontSize: 11,
    },
    "@media (min-height: 980px) and (max-width: 470px)": {
      marginTop: 20,
      width: 80,
      fontSize: 10,
    },

    /* max-height: 979px*/
    "@media (max-height: 979px) and (min-width: 601px)": {
      marginTop: 30,
      padding: "7px 0px",
      width: 120,
      textAlign: "center",
      fontSize: 14,
    },
    "@media (max-height: 979px) and (max-width: 600px)": {
      marginTop: 20,
      padding: "7px 0px",
      width: 100,
      textAlign: "center",
      fontSize: 11,
    },
    "@media (max-height: 979px) and (max-width: 470px)": {
      marginTop: 20,
      width: 80,
      fontSize: 10,
    },
    
    /* max-height: 740px*/
    "@media (max-height: 740px) and (min-width: 769px)": {
      marginTop: 20,
      padding: "7px 0px",
      width: 100,
      textAlign: "center",
      fontSize: 11,
    },
    "@media (max-height: 740px) and (max-width: 768px)": {
      marginTop: 20,
      width: 80,
      fontSize: 10,
    },
    "@media (max-height: 740px) and (max-width: 470px)": {
      marginTop: 15,
      width: 70,
      fontSize: 9.5,
      padding: "5px 0px",
    },

    /* max-height: 640px*/
    "@media (max-height: 640px) and (min-width: 769px)": {
      marginTop: 18,
      width: 80,
      fontSize: 10.5,
    },
    "@media (max-height: 640px) and (max-width: 768px)": {
      marginTop: 15,
      width: 70,
      fontSize: 9.5,
      padding: "5px 0px",
    },
    "@media (max-height: 640px) and (max-width: 470px)": {
      marginTop: 10,
      width: 70,
      fontSize: 9.5,
      padding: "3px 0px",
    },
  },
  containerLinkErrors: {
    width: "100%",
    marginBottom: 20,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14.5,
    },
    [theme.breakpoints.down(640)]: {
      fontSize: 13,
    },
    [theme.breakpoints.down(470)]: {
      fontSize: 11,
    },
  },
  linkErrors: {
    "&:visited": {
      opacity: 0.1,
    },
  },
}));
export default DownloadExcelStyle;