import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS, POPPINS_FONTS } from "../../../constants/fontConsts";

const FormRegisteStyle = makeStyles((theme) => ({
  mainContainer: {
    height: "100vh",
    backgroundColor: "#CC3F3F",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up(960)]: {
      borderRadius: "100px 0px",
    },
    [theme.breakpoints.down(960)]: {
      width: "100vw",
    },
  },
  formContainer: {
    width: "100%",
    height: "100%",
    borderRadius: "100px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingBottom: 10,
    [theme.breakpoints.down(960)]: {
      maxWidth: 500,
      width: "95%",
      maxHeight: 750,
      height: "95%",
      borderRadius: 30,
      background: "white",
    },
    [theme.breakpoints.down(600)]: {
      width: "80%",
      background: "none",
    },
    "@media (max-height: 800px) and (max-width: 960px) and (min-width: 600px)":
      {
        paddingBottom: "0px !important",
        height: "80% !important",
      },
    "@media (max-height: 600px) and (max-width: 960px) and (min-width: 600px)":
      {
        height: "95% !important",
      },
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down(600)]: {
      display: "none",
    },
    "@media (max-height: 800px) and (max-width: 960px)": {
      display: "none",
    },
  },
  liberLogo: {
    width: 250,
    height: 250,
    marginTop: -50,
    [theme.breakpoints.up(960)]: {
      display: "none",
    },
    [theme.breakpoints.down(960)]: {
      width: 150,
      height: 150,
      marginTop: -10,
    },
  },
  createAccount: {
    fontFamily: POPPINS_FONTS.POPPINS_BOLD,
    fontSize: 40,
    color: "#fff",
    [theme.breakpoints.down(960)]: {
      fontSize: 30,
      color: "#000",
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 25,
      color: "#fff",
    },
    "@media (max-height: 700px) and (min-width: 961px)": {
      fontSize: 30,
    },

    "@media (max-height: 800px) and (max-width: 960px)": {
      marginBottom: 30,
    },
    "@media (max-height: 700px) and (max-width: 960px) and (min-width: 600px)":
      {
        marginBottom: 15,
        fontSize: 25,
      },
  },
  subText: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    marginTop: 10,
    marginBottom: 20,
    fontSize: 20,
    color: "#fff",
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down(960)]: {
      color: "#000",
      fontSize: 15,
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down(600)]: {
      color: "#fff",
    },
    "@media (max-height: 700px) and (min-width: 961px)": {
      fontSize: 18,
    },

    "@media (max-height: 800px) and (max-width: 960px)": {
      display: "none",
    },
  },
  inputComponent: {
    borderRadius: 15,
    width: 400,
    height: 55,
    backgroundColor: "#FFF0DF",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 15,
    [theme.breakpoints.down("lg")]: {
      width: 400,
      backgroundColor: "#fff",
    },
    [theme.breakpoints.down(600)]: {
      width: 280,
    },
    "@media (max-height: 700px) and (max-width: 960px) and (min-width: 600px)":
      {
        height: 45,
        width: 350,
      },
  },
  inputsContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },

  errorLabel: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 10,
    color: "#fff",
    [theme.breakpoints.down(960)]: {
      color: "#000",
    },
    [theme.breakpoints.down(600)]: {
      color: "#fff",
    },
  },
  loginLink: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_EXTRABOLD,
    cursor: "pointer",
    fontSize: 14,
  },
  IhaveAccount: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 15,
    marginTop: 20,
    [theme.breakpoints.up(960)]: {
      color: "#fff",
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 13,
      color: "#fff !important",
    },
  },
  marginTYC: {
    marginTop: 10,
    [theme.breakpoints.down(600)]: {
      marginTop: 15,
      marginBottom: 5,
    },
  },
  acceptTYC: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 13,
    textAlign: "center",
    lineHeight: 2,
    marginTop: 5,
    textDecoration: "none",
    [theme.breakpoints.up(960)]: {
      color: "#fff",
    },
    [theme.breakpoints.down(600)]: {
      color: "#fff",
    },
  },
  tycLink: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_EXTRABOLD,
    textDecoration: "none",
    color: "black",
    [theme.breakpoints.up(960)]: {
      color: "#fff",
    },
    [theme.breakpoints.down(600)]: {
      color: "#fff",
    },
  },
  privacyLink: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_EXTRABOLD,
    textDecoration: "none",
    color: "black",
    [theme.breakpoints.up(960)]: {
      color: "#fff",
    },
    [theme.breakpoints.down(600)]: {
      color: "#fff",
    },
  },
  buttonCrear: {
    width: 200,
    height: 50,
    background: "#FFA284 !important",
    borderRadius: "10px !important",
    border: "1px solid #FFA284",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    color: "#fff !important",
    //margin: "30px 50px !important",
    fontSize: 15,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    marginTop: "30px !important",
    marginBottom: "0px !important",
    marginLeft: "0px !important",
    marginRight: "0px !important",
    "&:hover": {
      backgroundColor: "#fff !important",
      color: "#F8822E !important",
    },
    "&.Mui-disabled": {
      background: "#bfbdbd !important",
      color: "white",
    },
    [theme.breakpoints.down(600)]: {
      width: 150,
      height: 40,
      fontSize: 13.5,
    },

    "@media (max-height: 700px) and (max-width: 960px) and (min-width: 600px)":
      {
        height: 45,
        width: 180,
      },
  },
  contentLoader: {
    height: 30,
    marginLeft: 20,
  },
  loader: {
    width: 30,
    height: 30,
  },
  root: {
    color: "#fff !important",
    "&$checked": {
      color: "#fff !important",
    },
    [theme.breakpoints.down(960)]: {
      color: "#CC3F3F !important",
      "&$checked": {
        color: "#CC3F3F !important",
      },
    },
    [theme.breakpoints.down(600)]: {
      color: "#fff !important",
      "&$checked": {
        color: "#fff !important",
      },
    },
  },
  checked: {},
}));

export default FormRegisteStyle;
