import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS, POPPINS_FONTS } from "../../constants/fontConsts";

const ShippingZonesStyles = makeStyles((theme) => ({
    headerText: {
        fontFamily: POPPINS_FONTS.POPPINS_BOLD,
        fontSize: 40,
        textAlign: "start",
        marginLeft: 80,
        marginRight: 250,
        [theme.breakpoints.down("sm")]: {
          fontSize: 30,
          marginRight: 30,
          marginLeft: 30,
          marginBottom: 50,
        },
    },
    container:{
    paddingTop:140,
    background:"linear-gradient(10.79deg, #FFFFFF 2.97%, #EFF1FC 59.78%, #FFFFFF 97.12%)",
    height:"auto"
    },
    textAlign:{
        textAlign:"center",
        fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
        fontSize:20,
        padding:"30px 30px"
    },
    containerMap:{
        display: "flex",
        justifyContent:"center",
        alignItems: "center"
    },
    iframeMaps:{
        width:880,
        height:420,
        marginBottom:50
    }

}))

export default ShippingZonesStyles