import { makeStyles } from "@material-ui/core";

const PerfilStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
  },
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
  content: {
    [theme.breakpoints.down(670)]: {
      width: "100%",
    },
  },
}));
export default PerfilStyle;
